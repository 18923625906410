import QueryString from 'query-string';
import { FetchService } from '.';

export default class PrizeService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
    this.getReport = this.getReport.bind(this);
    this.setOptions = this.setOptions.bind(this);
  }

  get(query) {
    return this.Get('/prizes?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(venue_id, name, description, date, photo) {
    const formData = new FormData();

    formData.append('venue_id', venue_id);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('date', date);
    if (photo) {
      formData.append('photo', photo);
    }

    return this.Post('/prizes', { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  put(prize_id, name, description, date, photo) {
    const formData = new FormData();

    formData.append('prize_id', prize_id);
    formData.append('name', name);
    formData.append('description', description);
    formData.append('date', date);
    if (photo) {
      formData.append('photo', photo);
    }

    return this.Put('/prizes', { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(prize_id) {
    return this.Post('/prizes/delete', { body: JSON.stringify({ prize_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getReport(query) {
    return this.Get('/prizes/report?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  setOptions(venue_id, type, metric) {
    return this.Put('/prizes/type', { body: JSON.stringify({ venue_id, type, metric }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
