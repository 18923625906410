import QueryString from 'query-string';
import { FetchService } from '.';

export default class CategoryService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
  }

  get(query) {
    return this.Get('/categories?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
