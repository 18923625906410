import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Converter, EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, DateTimePicker, Checkbox } from '../fields';
import { ModalLayout } from '../layouts';
import { TransferService } from '../../services';
import Constants from '../../Constants';

import '../../styles/modals/modal.css';
import '../../styles/modals/savetransfer.css';

class SaveTransferModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      transferId: null,
      venue: null,
      paidAmount: '',
      paidAt: moment().toDate(),
      status: Constants.TRANSFER_STATUSES[0],
      invalidPost: {
        venue_id: false,
        paid_amount: false,
        paid_at: false,
        status: false,
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);
    this.onStatusChange = this.onStatusChange.bind(this);

    this.transferService = new TransferService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      transferId: null,
      venue: null,
      paidAmount: '',
      paidAt: moment().toDate(),
      status: Constants.TRANSFER_STATUSES[0],
      invalidPost: {
        venue_id: false,
        paid_amount: false,
        paid_at: false,
        status: false,
      },
    });
  }

  show(set = true, venue = null) {
    this.resetFields();
    this.setState({
      show: set,
      venue: venue,
    });
  }

  setFields(transfer) {
    this.setState({
      transferId: transfer._id,
      paidAmount: transfer.paid_amount,
      paidAt: moment(transfer.paid_at).toDate(),
      status: transfer.status,
    });
  }

  async onSaveBtnCLick() {
    const { transferId, venue, paidAmount, paidAt, status, invalidPost } = this.state;
    const { onComplete } = this.props;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = transferId
      ? await this.transferService.put(transferId, venue ? venue._id : null, paidAmount, paidAt, status)
      : await this.transferService.post(venue ? venue._id : null, paidAmount, paidAt, status);

    if (result && result.success) {
      this.show(false);
      onComplete();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  onStatusChange(checked, value) {
    if (checked) {
      this.setState({ status: value });
    }
  }

  render() {
    const { show, loading, transferId, venue, paidAmount, paidAt, status, invalidPost } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout title={transferId ? t('Update Transfer') : t('Add Transfer')} width={580} subtitle={venue.name}>
          <Row>
            <Col md={6}>
              <DateTimePicker
                label={t('Paid At')}
                defaultValue={paidAt}
                onChange={(date) => {
                  this.setState({ paidAt: date });
                }}
              />
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t('Paid Amount')}
                error={invalidPost.paid_amount}
                errorText={t('Paid Amount is required')}
                value={paidAmount}
                onChange={(e) => this.setState({ paidAmount: Converter.onlyNumber(e.target.value, true, true) })}
                rightBtnLabel={Constants.CURRENCIES[venue.currency].symbol}
              ></TextField>
            </Col>
            <Col md={12}>
              <p className='save-transfer-title'>{t('Status')}</p>
              <div className='save-transfer-checkbox'>
                {Constants.TRANSFER_STATUSES.map((value) => {
                  return <Checkbox key={value} text={t(value)} value={value} checked={status === value ? true : false} onChange={this.onStatusChange} />;
                })}
              </div>
            </Col>
          </Row>

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={transferId ? t('UPDATE') : t('SAVE')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnCLick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveTransferModal);
