import QueryString from 'query-string';
import { FetchService } from '.';

export default class EmployeeService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.getbyid = this.getbyid.bind(this);
    this.post = this.post.bind(this);
    this.updateGeneral = this.updateGeneral.bind(this);
    this.updateRole = this.updateRole.bind(this);
    this.updateVenue = this.updateVenue.bind(this);
    this.delete = this.delete.bind(this);
  }

  get(query) {
    return this.Get('/employees?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getbyid(venue_id, user_id) {
    return this.Post('/employees/getbyid', { body: JSON.stringify({ venue_id, user_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(venue_id, first_name, last_name, email, password, role) {
    password = password && password.length > 0 ? password : undefined;
    return this.Post('/employees', { body: JSON.stringify({ venue_id, first_name, last_name, email, password, role }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateGeneral(venue_id, user_id, first_name, last_name, password) {
    password = password && password.length > 0 ? password : undefined;
    return this.Put('/employees/general', { body: JSON.stringify({ venue_id, user_id, first_name, last_name, password }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateRole(venue_id, user_id, role, permissions) {
    return this.Put('/employees/role', { body: JSON.stringify({ venue_id, user_id, role, permissions }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateVenue(venue_id, user_id, new_venue_id) {
    return this.Put('/employees/venue', { body: JSON.stringify({ venue_id, user_id, new_venue_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(venue_id, user_id) {
    return this.Post('/employees/revoke', { body: JSON.stringify({ venue_id, user_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
