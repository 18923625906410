import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DefaultTable } from '../components/tables';
import { TransferService } from '../services';
import { LocalStorage, Converter } from '../utils';
import { Toast } from '../components/alerts';
import { SaveTransferModal } from '../components/modals';
import Constants from '../Constants';

import '../styles/transfer.css';

class TransferPage extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      loading: false,
      searchText: '',
      columns: [
        {
          title: t('VENUE'),
          field: 'venue.name',
        },
        {
          title: t('PAID AMOUNT'),
          field: ['paid_amount', 'venue.currency'],
        },
        {
          title: t('DATE'),
          field: 'paid_at',
          render: (value) => {
            return moment(value).format('YYYY-MM-DD');
          },
        },
        {
          title: t('STATUS'),
          field: 'status',
        },
        {
          title: t('ACTIONS'),
          field: 'actions',
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
          action: true,
        },
      ],
      rows: [],
      totalIncome: 0,
      totalPaid: 0,
      filter: LocalStorage.getFilter(),
      pagination: {
        skip: 0,
        limit: 10,
        count: 0,
      },
    };

    this.fetchTransfers = this.fetchTransfers.bind(this);
    this.addTransferBtnClick = this.addTransferBtnClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onActionChange = this.onActionChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.onSaveTransferComplete = this.onSaveTransferComplete.bind(this);

    this.transferService = new TransferService();
  }

  componentDidMount() {
    this.fetchTransfers();
  }

  async fetchTransfers() {
    this.setState({ loading: true });

    const { searchText, filter, pagination } = this.state;
    const result = await this.transferService.get({
      search: searchText,
      venue_id: filter.venue ? filter.venue._id : undefined,
      start_date: filter.date && filter.date.start ? filter.date.start.formated : undefined,
      end_date: filter.date && filter.date.end ? filter.date.end.formated : undefined,
      skip: pagination.skip || 0,
      limit: pagination.limit || 10,
    });

    if (result && result.success) {
      this.setState({ totalIncome: result.net_income, totalPaid: result.total_paid, rows: result.transfers, pagination: result.pagination, loading: false });

      if (this.table) {
        const filter = this.table.getFilter();

        filter.setFilterFields('venue', result.selected_venue);
        filter.onStartDateChange(Converter.toLocalDateTime(result.start_date), 'YYYY-MM-DD');
        filter.onEndDateChange(Converter.toLocalDateTime(result.end_date), 'YYYY-MM-DD');
        filter.showTags();
      }
    } else if (result && result.ecode === 214) {
      this.setState({ rows: [], loading: false });
    }
  }

  addTransferBtnClick() {
    const { filter } = this.state;
    const { t } = this.props;

    if (filter.venue) {
      this.saveTransferModal.show(true, filter.venue);
    } else {
      Toast.warning(t('Please select a venue from filter!'));
    }
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
        pagination: {
          skip: 0,
          limit: 10,
          count: 0,
        },
      },
      () => {
        this.fetchTransfers();
      }
    );
  }

  async onActionChange(data, action) {
    const { filter } = this.state;
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'EDIT':
        if (filter.venue) {
          this.saveTransferModal.show(true, filter.venue);
          this.saveTransferModal.setFields(data);
        } else {
          Toast.warning(t('Please select a venue from filter!'));
        }
        break;
      case 'BANK DETAILS':
        break;
      case 'DELETE':
        this.setState({ loading: true });
        result = await this.transferService.delete(data._id);
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchTransfers();
    } else {
      this.setState({ loading: false });
    }
  }

  onFilterChange(filter) {
    this.setState({ filter: filter }, () => {
      this.fetchTransfers();
    });
  }

  onPaginationChange(type, value) {
    switch (type) {
      case 'LIMIT':
        this.setState({ pagination: { ...this.state.pagination, skip: 0, limit: value } }, () => {
          this.fetchTransfers();
        });
        break;
      case 'NAVIGATE':
        this.setState({ pagination: value }, () => {
          this.fetchTransfers();
        });
        break;
      default:
        break;
    }
  }

  onSaveTransferComplete() {
    this.fetchTransfers();
  }

  render() {
    const { loading, filter, columns, rows, searchText, pagination, totalIncome, totalPaid } = this.state;
    const { t, label } = this.props;
    const currencySymbol = Constants.CURRENCIES[filter.venue ? filter.venue.currency : 'GBP'].symbol;

    return (
      <div className='content'>
        <SaveTransferModal onRef={(ref) => (this.saveTransferModal = ref)} onComplete={this.onSaveTransferComplete} />
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            <button className='content-title-button' onClick={this.addTransferBtnClick}>
              <FontAwesomeIcon icon={faPlus} /> {t('ADD TRANSFER')}
            </button>
          </div>
        </div>
        <div className='content-body'>
          <div className='transfer-page-net-div'>
            <Row>
              <Col md={4} className='transfer-summary-col'>
                <div className='transfer-summary-card'>
                  <p className='transfer-summary-card-title'>{t('Net Income')}</p>
                  <p className='transfer-summary-card-result-large'>{`${currencySymbol} ${Converter.fixedFloat(totalIncome).toFixed(2)}`}</p>
                </div>
              </Col>
              <Col md={4} className='transfer-summary-col'>
                <div className='transfer-summary-card'>
                  <p className='transfer-summary-card-title'>{t('Paid')}</p>
                  <p className='transfer-summary-card-result-large'>{`${currencySymbol} ${Converter.fixedFloat(totalPaid).toFixed(2)}`}</p>
                </div>
              </Col>
              <Col md={4} className='transfer-summary-col'>
                <div className='transfer-summary-card'>
                  <p className='transfer-summary-card-title'>{t('Balance')}</p>
                  <p className={`transfer-summary-card-result-large ${totalIncome - totalPaid > 0 ? 'red' : ''}`}>{`${currencySymbol} ${Converter.fixedFloat(totalIncome - totalPaid).toFixed(
                    2
                  )}`}</p>
                </div>
              </Col>
            </Row>
          </div>
          <DefaultTable
            onRef={(ref) => (this.table = ref)}
            columns={columns}
            rows={rows}
            searchText={searchText}
            loading={loading}
            filter={['venue', 'date']}
            pagination={pagination}
            actions={[
              {
                label: 'EDIT',
                title: t('EDIT'),
              },
              {
                label: 'BANK DETAILS',
                title: t('BANK DETAILS'),
              },
              {
                label: 'DELETE',
                title: t('DELETE'),
                red: true,
              },
            ]}
            onSearchChange={this.onSearchChange}
            onActionChange={this.onActionChange}
            onFilterChange={this.onFilterChange}
            onPaginationChange={this.onPaginationChange}
          ></DefaultTable>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(TransferPage));
