import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enGB as en, fr, it, es, tr } from 'date-fns/locale';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { withTranslation } from 'react-i18next';
import { TextField } from '.';

import '../../styles/fields/datetimepicker.css';

class DateTimePicker extends React.Component {
  constructor(props) {
    super(props);

    setDefaultLocale('en');
    registerLocale('en', en);
    registerLocale('fr', fr);
    registerLocale('it', it);
    registerLocale('es', es);
    registerLocale('tr', tr);

    this.onChange = this.onChange.bind(this);
    this.onRightBtnClick = this.onRightBtnClick.bind(this);
  }

  onChange(date) {
    const { onChange } = this.props;

    onChange(date);
  }

  onRightBtnClick() {
    this.datePicker.setOpen(true);
  }

  render() {
    const { t, label, showTimeSelect, minDate, rightBtn, defaultValue, timeIntervals, onlyTime, calendarClassName, error, errorText } = this.props;
    const language = localStorage.getItem('language') || 'en';

    return (
      <DatePicker
        locale={language}
        timeCaption=''
        showTimeSelect={showTimeSelect}
        timeFormat='HH:mm'
        timeIntervals={timeIntervals}
        minDate={minDate}
        dateFormat={onlyTime ? 'HH:mm' : showTimeSelect ? 'dd MMM yyyy - HH:mm' : 'dd MMM yyyy'}
        selected={defaultValue}
        calendarClassName={calendarClassName || ''}
        customInput={
          <TextField
            fullWidth
            error={error}
            errorText={errorText}
            readOnly
            label={label}
            value={defaultValue}
            rightBtnLabel={rightBtn ? onlyTime ? <FontAwesomeIcon icon={faClock} /> : t('CHANGE') : null}
            rightBtnClick={this.onRightBtnClick}
          />
        }
        onChange={this.onChange}
        ref={(ref) => {
          this.datePicker = ref;
        }}
      />
    );
  }
}

DateTimePicker.propTypes = {
  label: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  rightBtn: PropTypes.bool,
  timeIntervals: PropTypes.number,
  calendarClassName: PropTypes.string,
  defaultValue: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.any]),
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

DateTimePicker.defaultProps = {
  showTimeSelect: false,
  minDate: moment('2020-01-01').toDate(),
  rightBtn: true,
  timeIntervals: 15,
  onChange: Function.prototype,
};

export default withTranslation()(DateTimePicker);
