import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import Constants from '../../Constants';
import { withRouter } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faFlag } from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { ProgressBar, DropDown } from '../fields';
import { Validator } from '../../utils';
import staduimIcon from '../../assets/images/stadium.png';

import '../../styles/cards/event.css';

class EventCard extends React.Component {
  render() {
    const { t, selectedVenue, event, actions, onActionChange } = this.props;
    const date = moment(event.date).format('DD MMMM yyyy - HH:ss');
    const percent = (event.stats.total_qty / event.goal) * 100;

    return (
      <Col md={6}>
        <div className='event-card-container'>
          <div className='event-card-top'>
            <div className='event-card-top-dropdown'>
              <DropDown bull={true} actions={actions} data={event} onChange={onActionChange} />
            </div>
            <div className='event-card-top-left'>
              <div className='event-card-top-images'>
                <img className='event-card-home-image' src={event.teams[0].icon} alt='' />
                <img className='event-card-back-image' src={event.venue.photo || staduimIcon} alt='' />
                <img className='event-card-guest-image' src={event.teams[1].icon} alt='' />
              </div>
            </div>
            <div className='event-card-top-right'>
              <p className='event-card-top-title'>
                {event.teams[0].name} vs {event.teams[1].name}
              </p>
              <p className='event-card-top-subtitle'>{date}</p>
              <p className='event-card-top-title-venue'>
                <FontAwesomeIcon icon={faMapMarkerAlt} /> {event.venue.name}
              </p>
            </div>
          </div>
          <hr />
          <div className='event-card-body'>
            <div className='event-card-body-top'>
              <span>
                {t('Stock Sold')} {event.stats.total_qty} / {event.goal}
              </span>
              <span>
                {t('Goal')} {event.goal}
              </span>
            </div>
            <div className='event-card-body-mid'>
              <ProgressBar progress={percent} height='4px' threshold='80' />
            </div>
            <div className='event-card-body-alt'>
              <div>
                <FontAwesomeIcon icon={faFlag} /> {Validator.countUniqueKeys('bar_id', event.bar_products).length} {t('Bars')}
              </div>
              <div>
                {/* <FontAwesomeIcon icon={faUsers} /> 0 */}
              </div>
              <div>
                {/* <FontAwesomeIcon icon={faTicketAlt} /> 0 */}
              </div>
              <div>
                <b>{Constants.CURRENCIES[selectedVenue.currency].symbol}</b> {event.stats.total_amount.toFixed(2)}
              </div>
            </div>
          </div>
        </div>
      </Col>
    );
  }
}

EventCard.propTypes = {
  selectedVenue: PropTypes.object,
  event: PropTypes.object,
  actions: PropTypes.array,
  onActionChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

EventCard.defaultProps = {
  actions: [],
  onActionChange: Function.prototype,
};

export default withTranslation()(withRouter(EventCard));
