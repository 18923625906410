import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import { Converter } from '../../utils';

import '../../styles/cards/prizebar.css';

const COLORS = ['#ffc800', '#aeb8ce', '#d6652f'];

class PrizeBarCard extends React.Component {
  render() {
    const { report, index, metric } = this.props;
    return (
      <div className='prize-list'>
        <Row>
          <Col md={6} className='prize-col'>
            {index < 3 && <FontAwesomeIcon icon={faMedal} size='3x' style={{ color: COLORS[index] }} />}
            <span className='prize-title'>{report.bar.name}</span>
          </Col>
          <Col md={6} className='prize-col'>
            <span className='prize-time'>{metric === 'AVERAGE_TIME' ? Converter.humanizeTime(report.average_waiting) : report.total_qty}</span>
          </Col>
        </Row>
      </div>
    );
  }
}

PrizeBarCard.propTypes = {
  report: PropTypes.any,
  index: PropTypes.number,
};

PrizeBarCard.defaultProps = {
  index: 0,
};

export default withTranslation()(PrizeBarCard);
