import React from 'react';
import ImmutabilityHelper from 'immutability-helper';
import Constants from '../../Constants';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { ProductCategoryService } from '../../services';
import { DefaultButton } from '../buttons';
import { TextField } from '../fields';
import { Toast } from '../alerts';
import { ProductCategoryCard } from '../cards';
import { ModalLayout } from '../layouts';
import { EntityError } from '../../utils';

import '../../styles/modals/modal.css';

class ProductCategoryModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      venue: null,
      name: '',
      productCategories: [],
      unit: Constants.UNITS[0],
      invalidPost: {
        venue_id: false,
        name: false,
      },
    };

    this.fetchProductCategories = this.fetchProductCategories.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.onNameChange = this.onNameChange.bind(this);
    this.onSaveBtnClick = this.onSaveBtnClick.bind(this);
    this.onProductCategoryDelete = this.onProductCategoryDelete.bind(this);
    this.moveProductCategoryCard = this.moveProductCategoryCard.bind(this);

    this.productCategoryService = new ProductCategoryService();
  }

  async componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      venue: null,
      name: '',
      productCategories: [],
      unit: Constants.UNITS[0],
      invalidPost: {
        venue_id: false,
        name: false,
      },
    });
  }

  show(set = true, venue = null) {
    this.resetFields();
    this.setState(
      {
        show: set,
        venue: venue,
      },
      async () => {
        if (set) {
          await this.fetchProductCategories();
        }
      }
    );
  }

  onNameChange(name) {
    this.setState({ name: name });
  }

  async fetchProductCategories() {
    const { venue } = this.state;

    const result = await this.productCategoryService.get({ venue_id: venue._id });

    if (result && result.success) {
      this.setState({ productCategories: result.product_categories });
    }
  }

  async onSaveBtnClick() {
    const { venue, name, invalidPost } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = await this.productCategoryService.post(venue._id, name);

    if (result && result.success) {
      this.setState({ name: '' });
      await this.fetchProductCategories();
    } else if (result && (result.ecode === 422 || result.ecode === 6010)) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  async onProductCategoryDelete(productCategory) {
    this.setState({
      loading: true,
    });

    const result = await this.productCategoryService.delete(productCategory._id);

    if (result && result.success) {
      await this.fetchProductCategories();
    }

    this.setState({
      loading: false,
    });
  }

  async moveProductCategoryCard(dragIndex, hoverIndex, eventName) {
    const { venue, productCategories } = this.state;
    const { t } = this.props;

    if (eventName === 'MOVE') {
      this.setState({
        productCategories: ImmutabilityHelper(productCategories, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, productCategories[dragIndex]],
          ],
        }),
      });
    } else if (eventName === 'DROP') {
      const result = await this.productCategoryService.put(
        venue ? venue._id : null,
        productCategories.map((productCategory) => {
          return {
            _id: productCategory._id,
            index: productCategory.index,
          };
        })
      );

      await this.fetchProductCategories();

      if (!result || !result.success) {
        Toast.warning(t('Categories could be ordered. Please try again later!'));
      }
    }
  }

  render() {
    const { show, loading, name, productCategories, invalidPost } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout title={t('Categories')} width={530}>
          <Row>
            <Col md={12}>
              {(!productCategories || productCategories.length < 1) && <p><b>{t('No Categories Found')}</b></p>}
              {productCategories.map((productCategory, index) => {
                productCategories[index].index = index;

                return (
                  <ProductCategoryCard
                    key={productCategory._id}
                    id={productCategory._id}
                    name={productCategory.name}
                    index={index}
                    moveProductCategoryCard={this.moveProductCategoryCard}
                    dropProductCategoryCard={this.moveProductCategoryCard}
                    productCategory={productCategory}
                    onDelete={this.onProductCategoryDelete}
                  />
                );
              })}
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Name')}
                error={invalidPost.name}
                errorText={t('Name is required')}
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.onSaveBtnClick();
                  }
                }}
                rightBtnLabel={loading ? null : t('+ Add')}
                rightBtnClick={loading ? null : this.onSaveBtnClick}
              ></TextField>
            </Col>
          </Row>

          <div className='modal-buttons-container'>
            <div className='modal-button-full'>{loading || <DefaultButton text={t('CLOSE')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(ProductCategoryModal);
