import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { AuthService } from '../services';
import { TextField } from '../components/fields';
import { EntityError } from '../utils';
import { LoadingButton } from '../components/buttons';
import logo from '../assets/logos/banner/logoxxxhdpi.png';

import '../styles/login.css';

class ResetPasswordPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      email: '',
      invalidPost: {
        email: false,
      },
      success: false,
    };

    this.resetBtnClick = this.resetBtnClick.bind(this);
    this.loginBtnClick = this.loginBtnClick.bind(this);

    this.authService = new AuthService();
  }

  async resetBtnClick() {
    const { email, invalidPost } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
      success: false,
    });

    const resetPassword = await this.authService.resetPassword(email);
    if (resetPassword && resetPassword.success) {
      this.setState({
        loading: false,
        success: true,
      });
    } else if (resetPassword && resetPassword.ecode === 422) {
      this.setState({
        loading: false,
        invalidPost: EntityError.setInputErrors(invalidPost, resetPassword.errors),
      });
    } else {
      this.setState({
        loading: false,
        invalidPost: {
          email: true,
        },
      });
    }
  }

  loginBtnClick() {
    const { history } = this.props;

    history.push('/login');
  }

  render() {
    const { loading, email, invalidPost, success } = this.state;
    const { t } = this.props;

    return (
      <div className='main-form overflow-y'>
        <div className='login-form'>
          <div className='login-form-header'>
            <img src={logo} className='img-fluid' alt=''></img>
          </div>
          <div className='login-form-body'>
            {success ? (
              <div className='rounded-form'>
                <h2>{t('Password Reset Request Sent')}</h2>
                <p>
                  {t('emailCheckText')} {email}
                </p>
                <hr />
                <br />
                <p>
                  <LoadingButton text={t('Go back to login')} onClick={this.loginBtnClick}></LoadingButton>
                </p>
              </div>
            ) : (
              <div className='rounded-form'>
                <p className='login-form-title'>{t('Password Reset Request')}</p>
                <p className='login-form-title-alt'>{t('Please input your email')}</p>

                <form className='login-form'>
                  <Row>
                    <Col md={12}>
                      <TextField
                        label={t('Email Address')}
                        fullWidth
                        value={email}
                        error={invalidPost.email}
                        errorText={t('This email is not registered')}
                        onChange={(e) => this.setState({ email: e.target.value })}
                      ></TextField>
                    </Col>
                  </Row>
                </form>
                <br />
                <LoadingButton type={'button'} text={t('SEND')} state={loading ? 'LOADING' : 'DONE'} onClick={this.resetBtnClick} fullWidth />
                <hr />
                <p>
                  <span className='login-btn' onClick={this.loginBtnClick}>
                    {t('Go back to login')}
                  </span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ResetPasswordPage));
