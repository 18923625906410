import React from 'react';
import i18n from 'i18next';
import { BrowserRouter, Redirect, Switch } from 'react-router-dom';
import { initReactI18next } from 'react-i18next';
import { LoginLayout, SwitchLayout, AuthLayout, MainLayout } from './components/layouts';
import { en, tr, it } from './locales';
import {
  LoginPage,
  SignupPage,
  ProfilePage,
  ConfirmationPage,
  CustomerConfirmationPage,
  ResetPage,
  CustomerResetPage,
  ResetPassword,
  SwitchPage,
  MainPage,
  VenuePage,
  BarPage,
  EmployeePage,
  EmployeeDetailPage,
  ProductPage,
  EventPage,
  PrizePage,
  ReportPage,
  CustomerPage,
  DiscountPage,
  SettingPage,
  InventoryPage,
  CustomerConfirmEmailPage,
  SumupCallbackPage,
  GatewayPage,
  TransferPage,
} from './pages';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/es';
import 'moment/locale/tr';
import moment from 'moment';

class App extends React.Component {
  constructor(props) {
    super(props);

    const language = localStorage.getItem('language') || 'en';
    moment.locale(language);

    i18n.use(initReactI18next).init({
      resources: {
        en: { translation: en },
        tr: { translation: tr },
        it: { translation: it },
      },
      lng: 'en',
      fallbackLng: 'en',
      keySeparator: false,
      interpolation: {
        escapeValue: false,
      },
      saveMissing: true,
    });
    i18n.changeLanguage(localStorage.getItem('language') || 'en');
    // i18n.on('missingKey', async (lngs, namespace, key, res) => {});
  }

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <LoginLayout exact path='/login' layout={MainLayout} component={LoginPage} />
          <LoginLayout exact path='/signup' layout={MainLayout} component={SignupPage} />
          <LoginLayout exact path='/confirmation/:status' layout={MainLayout} component={ConfirmationPage} />
          <LoginLayout exact path='/customer/confirmation/:status' layout={MainLayout} component={CustomerConfirmationPage} />
          <LoginLayout exact path='/reset/:user_id/:token' layout={MainLayout} component={ResetPage} />
          <LoginLayout exact path='/reset/password' layout={MainLayout} component={ResetPassword} />
          <LoginLayout exact path='/customers/reset/:customer_id/:token' layout={MainLayout} component={CustomerResetPage} />
          <LoginLayout exact path='/customers/confirm_email/:customer_id/:token/:new_email' layout={MainLayout} component={CustomerConfirmEmailPage} />
          <SwitchLayout exact path='/sumup/callback' layout={MainLayout} component={SumupCallbackPage} />
          <SwitchLayout exact path='/switch' layout={MainLayout} component={SwitchPage} />
          <AuthLayout exact path='/main' layout={MainLayout} component={MainPage} />
          <AuthLayout exact path='/profile' layout={MainLayout} component={ProfilePage} />
          <AuthLayout exact path='/venues' layout={MainLayout} component={VenuePage} />
          <AuthLayout exact path='/bars' layout={MainLayout} component={BarPage} />
          <AuthLayout exact path='/employees' layout={MainLayout} component={EmployeePage} />
          <AuthLayout exact path='/employees/detail' layout={MainLayout} component={EmployeeDetailPage} />
          <AuthLayout exact path='/products' layout={MainLayout} component={ProductPage} />
          <AuthLayout exact path='/inventory' layout={MainLayout} component={InventoryPage} />
          <AuthLayout exact path='/prizes' layout={MainLayout} component={PrizePage} />
          <AuthLayout exact path='/customers' layout={MainLayout} component={CustomerPage} />
          <AuthLayout exact path='/events' layout={MainLayout} component={EventPage} />
          <AuthLayout exact path='/reports' layout={MainLayout} component={ReportPage} />
          <AuthLayout exact path='/discounts' layout={MainLayout} component={DiscountPage} />
          <AuthLayout exact path='/gateways' layout={MainLayout} component={GatewayPage} />
          <AuthLayout exact path='/transfers' layout={MainLayout} component={TransferPage} />
          <AuthLayout exact path='/settings' layout={MainLayout} component={SettingPage} />
          <Redirect to='/main' />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
