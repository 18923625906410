import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/fields/progressbar.css';

class ProgressBar extends React.Component {
  render() {
    const { progress, height, threshold } = this.props;

    return (
      <div className='progress-bar' style={{ height: height }}>
        <div
          className='progress-bar-thumb'
          style={{
            width: (progress > 100 ? 100 : progress).toString() + '%',
          }}
        ></div>
        {threshold && <div className='progress-bar-stop' style={{ left: threshold + '%' }}></div>}
      </div>
    );
  }
}

ProgressBar.propTypes = {
  progress: PropTypes.number,
  threshold: PropTypes.string,
  height: PropTypes.string,
};

ProgressBar.defaultProps = {
  progress: 0,
  threshold: null,
  height: '10px',
};

export default ProgressBar;
