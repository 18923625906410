import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { LoadingButton } from '../components/buttons';
import logo from '../assets/logos/banner/logoxxxhdpi.png';

import '../styles/login.css';

class ConfirmationPage extends React.Component {
  constructor(props) {
    super(props);

    this.loginBtnClick = this.loginBtnClick.bind(this);
  }

  loginBtnClick() {
    const { history } = this.props;

    history.push('/login');
  }

  render() {
    const { t, match } = this.props;
    const status = match && match.params ? match.params.status : 'error';

    return (
      <div className='main-form overflow-y'>
        <div className='login-form'>
          <div className='login-form-header'>
            <img src={logo} className='img-fluid' alt=''></img>
          </div>
          <div className='login-form-body'>
            <div className='rounded-form'>
              {status === 'success' ? (
                <div>
                  <p>
                    <FontAwesomeIcon icon={faCheck} size='10x' color='#4bb543' />
                  </p>
                  {t('Your email has been confirmed')}
                </div>
              ) : (
                <div>
                  <p>
                    <FontAwesomeIcon icon={faExclamation} size='10x' color='#ff0033' />
                  </p>
                  {t('We could not verify your email. Please try again!')}
                </div>
              )}
              <hr />
              <p>
                <LoadingButton text={t('Go back to login')} onClick={this.loginBtnClick}></LoadingButton>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ConfirmationPage));
