import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { AuthService } from '../services';
import { TextField } from '../components/fields';
import { EntityError } from '../utils';
import { LoadingButton } from '../components/buttons';
import logo from '../assets/logos/banner/logoxxxhdpi.png';

import '../styles/login.css';

class ResetPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      password: '',
      confirmPassword: '',
      invalidPost: {
        user_id: false,
        refresh_token: false,
        password: false,
        confirm_password: false,
        invalid_credential: false,
      },
      success: false,
    };

    this.resetBtnClick = this.resetBtnClick.bind(this);
    this.loginBtnClick = this.loginBtnClick.bind(this);

    this.authService = new AuthService();
  }

  async resetBtnClick() {
    const { password, confirmPassword, invalidPost } = this.state;
    const { match } = this.props;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
      success: false,
    });

    const userId = match && match.params ? match.params.user_id : null;
    const refreshToken = match && match.params ? match.params.token : null;

    const reset = await this.authService.reset(userId, refreshToken, password, confirmPassword);
    if (reset && reset.success) {
      this.setState({
        loading: false,
        success: true,
      });
    } else if (reset && reset.ecode === 422) {
      this.setState({
        loading: false,
        invalidPost: EntityError.setInputErrors(invalidPost, reset.errors),
      });
    } else {
      this.setState({
        loading: false,
        invalidPost: {
          ...this.state.invalidPost,
          invalid_credential: true,
        },
      });
    }
  }

  loginBtnClick() {
    const { history } = this.props;

    history.push('/login');
  }

  render() {
    const { loading, invalidPost, success } = this.state;
    const { t } = this.props;

    return (
      <div className='main-form overflow-y'>
        <div className='login-form'>
          <div className='login-form-header'>
            <img src={logo} className='img-fluid' alt=''></img>
          </div>
          <div className='login-form-body'>
            {success ? (
              <div className='rounded-form'>
                <h2>{t('Reset Your Password')}</h2>
                <p>{t('Your password has been updated!')}</p>
                <hr />
                <br />
                <p>
                  <LoadingButton text={t('Go back to login')} onClick={this.loginBtnClick}></LoadingButton>
                </p>
              </div>
            ) : (
              <div className='rounded-form'>
                <p className='login-form-title'>{t('Reset Your Password')}</p>
                <p className='login-form-title-alt'>{t('Please define your password here')}</p>

                <form className='login-form'>
                  <Row>
                    <Col md={12}>
                      <TextField
                        label={t('Password')}
                        fullWidth
                        type='password'
                        error={invalidPost.password}
                        errorText={t('Password must be more than 6 characters')}
                        onChange={(e) => this.setState({ password: e.target.value })}
                      ></TextField>
                    </Col>
                    <Col md={12}>
                      <TextField
                        label={t('Confirm Password')}
                        fullWidth
                        type='password'
                        error={invalidPost.confirm_password}
                        errorText={t('Please confirm that password is matched')}
                        onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                      ></TextField>
                    </Col>
                  </Row>
                </form>
                <br />
                {invalidPost.invalid_credential && <span className='error-text'>{t('Invalid Credentials')}</span>}
                <br />
                <br />
                <LoadingButton type={'button'} text={t('RESET')} state={loading ? 'LOADING' : 'DONE'} onClick={this.resetBtnClick} fullWidth />
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ResetPage));
