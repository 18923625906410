import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import plusIcon from '../../assets/images/rounded-add-button.png';

class SideBarButton extends React.Component {
  render() {
    const { history, path, label, faIcon, plusBtn, onClick, onPlusBtnClick } = this.props;
    const pathname = history.location.pathname;

    return (
      <div
        className={`side-menu-link ${pathname === path && 'active'}`}
        path={path}
        onClick={() => {
          onClick(path);
        }}
      >
        <div className='side-menu-link-left'>
          <FontAwesomeIcon icon={faIcon} />
        </div>
        <span className='side-menu-link-label'>{label}</span>
        {plusBtn && (
          <div className='side-menu-link-right' onClick={onPlusBtnClick}>
            <img src={plusIcon} alt='' />
          </div>
        )}
      </div>
    );
  }
}

SideBarButton.propTypes = {
  label: PropTypes.string,
  path: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onPlusBtnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

SideBarButton.defaultProps = {
  onClick: Function.prototype,
  onPlusBtnClick: Function.prototype,
};

export default withTranslation()(withRouter(SideBarButton));
