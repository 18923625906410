export default class LocalStorage {
  static getSelectedUser() {
    try {
      const selectedUser = localStorage.getItem('selected_user');

      return selectedUser ? JSON.parse(selectedUser) : null;
    } catch (error) {
      return null;
    }
  }

  static setSelectedUser(user) {
    return localStorage.setItem('selected_user', JSON.stringify(user));
  }

  static clearSelectedUser() {
    return localStorage.removeItem('selected_user');
  }

  static getUsers() {
    try {
      const users = localStorage.getItem('users');

      return users ? JSON.parse(users) : {};
    } catch (error) {
      return {};
    }
  }

  static addUser(user) {
    const users = LocalStorage.getUsers();

    users[user._id] = user;

    localStorage.setItem('users', JSON.stringify(users));
    localStorage.setItem('selected_user', user);
  }

  static removeUser(user) {
    const users = LocalStorage.getUsers();

    delete users[user._id];

    localStorage.setItem('users', JSON.stringify(users));
  }

  static getFilter() {
    const filter = localStorage.getItem('filter');

    try {
      return filter ? JSON.parse(filter) : {};
    } catch (error) {
      console.error('LocalStorage.getFilter', error);
      return {};
    }
  }

  static setFilter(data) {
    localStorage.setItem('filter', JSON.stringify(data));
  }

  static getAppPayments() {
    const value = localStorage.getItem('app_payments');

    return value === 'true' ? true : false;
  }

  static setAppPayments(value) {
    localStorage.setItem('app_payments', value === true ? 'true' : 'false');
  }
}
