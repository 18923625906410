import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Tab } from '../components/fields';
import { GeneralCard, RoleCard, VenueCard } from '../components/cards';

import '../styles/employeedetail.css';

class EmployeeDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeIndex: 0,
    };

    this.onBackBtnClick = this.onBackBtnClick.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
  }

  onBackBtnClick() {
    const { history } = this.props;

    history.push('/employees');
  }

  onTabClick(index) {
    this.setState({ activeIndex: index });
  }

  render() {
    const { activeIndex } = this.state;
    const { t, location } = this.props;
    const employee = location.state.employee;

    return (
      <div className='content'>
        <div className='content-title'>
          <div className='content-title-label'>
            <span className='content-title-label-back' onClick={this.onBackBtnClick}>
              <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
            </span>
            <span className='content-title-label-span'>
              {t('User')}: <span>{employee.user.first_name + ' ' + employee.user.last_name}</span>
            </span>
          </div>
          <div className='content-title-buttons'></div>
        </div>
        <div className='content-body'>
          <Tab tabs={[{ title: t('GENERAL') }, { title: t('ROLES') }, { title: t('VENUE') }]} onTabClick={this.onTabClick} />

          {activeIndex === 0 && (
            <div className='employee-detail-container'>
              <p className='employee-detail-title'>{t('General')}</p>
              <GeneralCard employee={employee} />
            </div>
          )}
          {activeIndex === 1 && (
            <div className='employee-detail-container'>
              <p className='employee-detail-title'>{t('Roles')}</p>
              <RoleCard employee={employee} />
            </div>
          )}
          {activeIndex === 2 && (
            <div className='employee-detail-container'>
              <p className='employee-detail-title'>{t('Venue')}</p>
              <VenueCard employee={employee} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(EmployeeDetailPage));
