import moment from 'moment';

export default class Converter {
  static toLocalDateTime(date, format = null) {
    return format ? moment(moment.utc(date)).format(format) : moment(moment.utc(date));
  }

  static removeSpaces(text) {
    return text.replace(/\s/g, '');
  }

  static capitalize(string) {
    if (!string || string.length < 1) return string;

    const splitted = string.split(' ');

    let text = '';

    for (let i = 0; i < splitted.length; i++) {
      const word = splitted[i];
      text += word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() + ' ';
    }

    // IF LAST CHARACTER IS SPACE THEN REMOVE IT
    if (/\s+$/.test(text)) {
      text = text.slice(0, -1);
    }

    return text;
  }

  static onlyNumber(text, dot = true, negative = false) {
    return text.replace(new RegExp(`[^\\d${dot ? '.' : ''}${negative ? '-' : ''}]`, 'g'), '');
  }

  static fixedFloat(value, fixed = 2) {
    const x = Math.pow(10, Number(fixed) + 1);
    return parseFloat((Number(value) + 1 / x).toFixed(fixed));
  }

  static getValueByUnit(value, unit) {
    switch (unit) {
      case 'L':
        return value.toFixed(2);
      default:
        return value;
    }
  }

  static humanizeTime(time) {
    let text = '';
    const hours = moment.duration(time, 'seconds').get('hours') || 0;
    const minutes = moment.duration(time, 'seconds').get('minutes') || 0;
    const seconds = moment.duration(time, 'seconds').get('seconds') || 0;

    if (hours > 0) {
      text += hours + 'h ';
    }

    text += (minutes > 9 ? minutes.toString() : '0' + minutes.toString()) + 'm ';
    text += (seconds > 9 ? seconds.toString() : '0' + seconds.toString()) + 's ';

    return text;
  }

  static getFileNameFromHeader(text) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(text);

    if (matches != null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    } else {
      return null;
    }
  }

  static openingHoursToTime(openingHours) {
    let result = {};

    for (const day in openingHours) {
      let openingHour = openingHours[day];
      result[day] = {};

      if (!openingHour.closed) {
        result[day].start = moment(openingHour.start).utc().format('HH:mm');
        result[day].end = moment(openingHour.end).utc().format('HH:mm');
        result[day].closed = false;
      } else {
        result[day].start = '00:00';
        result[day].end = '23:59';
        result[day].closed = true;
      }
    }

    return result;
  }

  static removeHTTPPath(url) {
    return url.split('/').pop() || url;
  }

  static convertCurrencyToNonZeroDecimal(amount, currency, reverse = false) {
    const ZERO_DECIMAL_CURRENCIES = ['BIF', 'CLP', 'DJF', 'GNF', 'JPY', 'KMF', 'KRW', 'MGA', 'PYG', 'RWF', 'UGX', 'VND', 'VUV', 'XAF', 'XOF', 'XPF'];

    if (ZERO_DECIMAL_CURRENCIES.indexOf(currency.toUpperCase()) > -1) {
      return parseInt(parseFloat(amount.toFixed(2)).toFixed(0));
    } else {
      if (reverse) {
        return parseFloat(parseFloat(amount / 100).toFixed(2));
      } else {
        return parseInt((parseFloat(amount.toFixed(2)) * 100).toFixed(0));
      }
    }
  }

  static spliceString(str, index, count, add) {
    let arr = str.split('');
    arr.splice(index, count, add);
    return arr.join('');
  }

  static lastWordInString(string, separators) {
    let splittedWords = [];

    for (let i = 0; i < separators.length; i++) {
      const lastIndex = string.lastIndexOf(separators[i]);

      if (lastIndex >= 0) {
        splittedWords.push({ index: lastIndex + 1, word: string.substring(lastIndex + 1, string.length) });
      }
    }

    return splittedWords.length > 0 ? splittedWords.sort((a, b) => (a.index > b.index ? 1 : -1)).pop() : { index: 0, word: string };
  }
}
