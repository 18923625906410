import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

class DefaultButton extends React.Component {
  render() {
    const { fullWidth, textAlign, fontSize, onClick, text, state, rightIcon, ...rest } = this.props;

    return (
      <button
        {...rest}
        style={{ width: fullWidth ? '100%' : 'auto', textAlign: rightIcon ? 'left' : textAlign || 'center', fontSize: fontSize }}
        className='default-button'
        color='primary'
        onClick={onClick}
      >
        <span>{text}</span>
        {rightIcon && (
          <div style={{ float: 'right' }}>
            <FontAwesomeIcon icon={rightIcon}></FontAwesomeIcon>
          </div>
        )}
      </button>
    );
  }
}

DefaultButton.propTypes = {
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
  fontSize: PropTypes.string,
};

DefaultButton.defaultProps = {
  fullWidth: false,
  fontSize: '1em',
};

export default DefaultButton;
