import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { EmployeeService } from '../../services';
import { DefaultButton } from '../buttons';
import { ModalLayout } from '../layouts';
import userIcon from '../../assets/images/user.png';

import '../../styles/modals/modal.css';
import '../../styles/modals/savecustomer.css';

class SaveCustomerModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      customer: null,
    };

    this.show = this.show.bind(this);

    this.employeeService = new EmployeeService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  show(set = true, customer = null) {
    this.setState({
      show: set,
      customer: customer,
    });
  }

  render() {
    const { show, loading, customer } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout>
          <div className='save-customer-container'>
            <Row>
              <Col md={12}>
                <img className='save-customer-img' src={customer.profile_photo && customer.profile_photo.length > 0 ? customer.profile_photo : userIcon} alt='' />
              </Col>
              <Col md={12}>
                <span className='save-customer-title'>
                  {customer.first_name} {customer.last_name}
                </span>
              </Col>
              <Col md={12}>
                <span className='save-customer-label'>{customer.email}</span>
              </Col>
              <Col md={12}>
                <span className='save-customer-label'>{customer.phone_number}</span>
              </Col>
            </Row>
          </div>

          <div className='modal-buttons-container'>
            <div className='modal-button-full-width'>{loading || <DefaultButton text={t('CLOSE')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveCustomerModal);
