export default {
  CURRENCIES: {
    GBP: {
      symbol: '£',
    },
    EUR: {
      symbol: '€',
    },
  },
  COUNTRIES: {
    GB: {
      name: 'United Kingdom of Great Britain and Northern Ireland',
      coordinates: { lat: 51.509865, lng: -0.118092 },
    },
    IT: {
      name: 'Italy',
      coordinates: { lat: 41.902782, lng: 12.496366 },
    },
  },
  MOBILE_APP_URL: 'drinkingapp://',
  UNITS: ['L', 'ml', 'pints', 'bottles', 'units'],
  PAYMENT_METHODS: {
    CASH: 'CASH',
    CARD: 'CARD',
  },
  TERMINAL_PROVIDERS: {
    PAYMENTSENSE: 'PAYMENTSENSE',
    SUMUP: 'SUMUP',
  },
  TRANSFER_STATUSES: ['WAITING', 'PENDING', 'COMPLETED'],
  LANGUAGES: [
    {
      lang: 'en',
      name: 'English',
    },
    {
      lang: 'it',
      name: 'Italiano',
    },
    {
      lang: 'tr',
      name: 'Türkçe',
    },
  ],
  DELIVERY_OPTIONS: ['TABLE_SERVICE', 'SEAT_SERVICE', 'ROOM_SERVICE', 'BOX_SERVICE', 'ZONE_SERVICE'],
};
