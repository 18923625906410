import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { DefaultTable } from '../components/tables';
import { SaveEmployeeModal, AddUserModal } from '../components/modals';
import { Confirm } from '../components/alerts';
import { EmployeeService } from '../services';
import { LocalStorage } from '../utils';

import '../styles/employee.css';

class EmployeePage extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;
    const filter = LocalStorage.getFilter();

    this.state = {
      loading: true,
      searchText: '',
      columns: [
        {
          title: t('NAME'),
          field: ['user.first_name', 'user.last_name'],
        },
        {
          title: t('EMAIL'),
          field: 'user.email',
        },
        {
          title: t('ROLE'),
          field: 'role',
          capitalize: true,
          removeSpecialCharacters: true,
        },
        {
          title: t('VENUE'),
          field: 'venue.name',
        },
        {
          title: t('ACTIONS'),
          field: 'actions',
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
          action: true,
        },
      ],
      rows: [],
      selectedEmployeeId: null,
      selectedVenue: filter && filter.venue ? filter.venue : null,
      pagination: {
        skip: 0,
        limit: 10,
        count: 0,
      },
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onAddUserBtnClick = this.onAddUserBtnClick.bind(this);
    this.addEmployeeBtnClick = this.addEmployeeBtnClick.bind(this);
    this.onAddUserComplete = this.onAddUserComplete.bind(this);
    this.onAddEmployeeComplete = this.onAddEmployeeComplete.bind(this);
    this.onActionChange = this.onActionChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onCellClick = this.onCellClick.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);

    this.employeeService = new EmployeeService();
  }

  componentDidMount() {
    this.fetchEmployees();
  }

  async fetchEmployees() {
    const { searchText, selectedVenue, pagination } = this.state;
    const result = await this.employeeService.get({
      search: searchText,
      venue_id: selectedVenue ? selectedVenue._id : null,
      skip: pagination.skip || 0,
      limit: pagination.limit || 10,
    });

    if (result && result.success) {
      let state = { rows: result.employees, pagination: result.pagination, loading: false };

      // IF ALREADY VENUE IS SELECTED, THEN UPDATE FILTER
      if (result.selected_venue) {
        state['selectedVenue'] = result.selected_venue;

        if (this.table) {
          const filter = this.table.getFilter();

          filter.setFilterFields('venue', result.selected_venue);
          filter.showTags();
        }
      }

      this.setState(state);
    } else if (result && result.ecode === 214) {
      this.setState({ rows: [], loading: false });
    }
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
        pagination: {
          skip: 0,
          limit: 10,
          count: 0,
        },
      },
      () => {
        this.fetchEmployees();
      }
    );
  }

  onSearchBtnClick() {}

  onAddUserBtnClick() {
    this.addUserModal.show(true);
  }

  addEmployeeBtnClick() {
    this.setState(
      {
        selectedEmployeeId: null,
      },
      () => {
        this.saveEmployeeModal.show(true);
      }
    );
  }

  onAddUserComplete() {
    this.fetchEmployees();
  }

  onAddEmployeeComplete(result) {
    if (result) {
      this.fetchEmployees();
    }
  }

  async onActionChange(data, action) {
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'REVOKE':
        const confirm = await Confirm.open(t('Remove Employee'), 'Are you sure to revoke this user from venue?', 'Yes', 'No');
        if (confirm) {
          this.setState({ loading: true });
          result = await this.employeeService.delete(data.venue._id, data.user._id);
        }
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchEmployees();
    } else {
      this.setState({ loading: false });
    }
  }

  onFilterChange(filter) {
    if (filter) {
      this.setState({ selectedVenue: filter.venue }, () => {
        this.fetchEmployees();
      });
    }
  }

  onCellClick(column, row, rowIndex) {
    const { history } = this.props;

    history.push({
      pathname: '/employees/detail',
      state: { employee: row },
    });
  }

  onPaginationChange(type, value) {
    switch (type) {
      case 'LIMIT':
        this.setState({ pagination: { ...this.state.pagination, skip: 0, limit: value } }, () => {
          this.fetchEmployees();
        });
        break;
      case 'NAVIGATE':
        this.setState({ pagination: value }, () => {
          this.fetchEmployees();
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { loading, columns, rows, searchText, selectedVenue, selectedEmployeeId, pagination } = this.state;
    const { t, me, label } = this.props;

    return (
      <div className='content'>
        <AddUserModal onRef={(ref) => (this.addUserModal = ref)} onComplete={this.onAddUserComplete}></AddUserModal>
        <SaveEmployeeModal employeeId={selectedEmployeeId} onRef={(ref) => (this.saveEmployeeModal = ref)} onComplete={this.onAddEmployeeComplete}></SaveEmployeeModal>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
              <button className='content-title-button' onClick={this.onAddUserBtnClick}>
                <FontAwesomeIcon icon={faUserPlus} /> {t('ADD USER')}
              </button>
            )}
            {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
              <button className='content-title-button' onClick={this.addEmployeeBtnClick}>
                <FontAwesomeIcon icon={faPlus} /> {t('ADD EMPLOYEE')}
              </button>
            )}
          </div>
        </div>
        <div className='content-body'>
          <DefaultTable
            onRef={(ref) => (this.table = ref)}
            columns={columns}
            rows={rows}
            searchText={searchText}
            loading={loading}
            filter={['venue']}
            pagination={pagination}
            selectedFilter={selectedVenue}
            actions={[
              {
                label: 'REVOKE',
                title: t('REVOKE'),
                red: true,
              },
            ]}
            onSearchChange={this.onSearchChange}
            onActionChange={this.onActionChange}
            onFilterChange={this.onFilterChange}
            onCellClick={this.onCellClick}
            onPaginationChange={this.onPaginationChange}
          ></DefaultTable>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(EmployeePage));
