import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, Select, DateTimePicker, Uploader } from '../fields';
import { ModalLayout } from '../layouts';
import { PrizeService } from '../../services';

import '../../styles/modals/modal.css';
import '../../styles/modals/saveprize.css';

class SavePrizeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      prizeId: null,
      date: moment().add(1, 'day').toDate(),
      venue: null,
      name: '',
      description: '',
      photo: null,
      invalidPost: {
        date: false,
        venue_id: false,
        name: false,
        description: false,
        photo: false,
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);
    this.onPrizePhotoChange = this.onPrizePhotoChange.bind(this);

    this.prizeService = new PrizeService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      prizeId: null,
      date: moment().add(1, 'day').toDate(),
      venue: null,
      name: '',
      description: '',
      photo: null,
      invalidPost: {
        date: false,
        venue_id: false,
        name: false,
        description: false,
        photo: false,
      },
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  setFields(prize) {
    this.setState({
      prizeId: prize._id,
      date: moment(prize.date).toDate(),
      name: prize.name,
      description: prize.description,
      venue: prize.venue,
    });
  }

  async onSaveBtnCLick() {
    const { prizeId, venue, name, description, date, photo, invalidPost } = this.state;
    const { onComplete } = this.props;
    const formatedDate = moment(date).set('hour', 23).set('minute', 59).set('second', 59).toISOString();

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = prizeId
      ? await this.prizeService.put(prizeId, name, description, formatedDate, photo)
      : await this.prizeService.post(venue ? venue._id : null, name, description, formatedDate, photo);

    if (result && result.success) {
      this.show(false);
      onComplete();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  onPrizePhotoChange(photo) {
    this.setState({
      photo: photo,
    });
  }

  render() {
    const { show, loading, prizeId, name, description, venue, date, invalidPost } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout title={prizeId ? t('Update Prize') : t('Add Prize')}>
          <Row>
            <Col md={12}>
              <DateTimePicker
                label={t('Date')}
                minDate={moment().add(1, 'day').toDate()}
                defaultValue={date}
                onChange={(date) => {
                  this.setState({ date: date });
                }}
              />
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Name')}
                error={invalidPost.name}
                errorText={t('Name is required')}
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Description')}
                error={invalidPost.description}
                errorText={t('Description must be less than 255 characters')}
                value={description}
                onChange={(e) => this.setState({ description: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <Select
                fullWidth
                type='venues'
                field='name'
                label={t('Venue')}
                disabled={prizeId ? true : false}
                error={invalidPost.venue_id}
                errorText={t('Venue is required')}
                defaultValue={venue}
                onChange={(venue) => this.setState({ venue: venue })}
              ></Select>
            </Col>
            <Col md={12}>
              <p className='add-prize-subtitle'>{t('Prize Image')}</p>
              <Uploader onChange={this.onPrizePhotoChange} />
            </Col>
          </Row>

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={prizeId ? t('UPDATE') : t('SAVE PRIZE')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnCLick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SavePrizeModal);
