import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { VenueService } from '../../services';
import { EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, Select } from '../fields';
import { ModalLayout } from '../layouts';

import '../../styles/modals/modal.css';

class AddUserModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      email: '',
      venue: null,
      role: null,
      invalidPost: {
        email: false,
        venue_id: false,
        role: false,
        not_found: false,
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.onAddBtnClick = this.onAddBtnClick.bind(this);

    this.venueService = new VenueService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      email: '',
      venue: null,
      role: null,
      invalidPost: {
        email: false,
        venue_id: false,
        role: false,
        not_found: false,
      },
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  async onAddBtnClick() {
    const { onComplete } = this.props;
    const { venue, email, role, invalidPost } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = await this.venueService.addPermission(venue ? venue._id : null, email, role ? role.id : null);

    if (result && result.success) {
      this.show(false);
      onComplete();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    } else if (result && result.ecode === 2020) {
      this.setState({
        invalidPost: {
          ...invalidPost,
          not_found: true,
        },
      });
    }

    this.setState({ loading: false });
  }

  render() {
    const { show, loading, venue, email, role, invalidPost } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout title={t('Add User')}>
          <Row>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Email')}
                error={invalidPost.email || invalidPost.not_found}
                errorText={t('Email is an invalid')}
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <Select
                fullWidth
                type='venues'
                field='name'
                label={t('Venue')}
                error={invalidPost.venue_id}
                errorText={t('Venue is required')}
                defaultValue={venue}
                onChange={(venue) => this.setState({ venue: venue })}
              ></Select>
            </Col>
            <Col md={12}>
              <Select
                fullWidth
                type='roles'
                field='name'
                label={t('Roles')}
                error={invalidPost.role}
                errorText={t('Role is required')}
                capitalize={true}
                defaultValue={role}
                onChange={(role) => this.setState({ role: role })}
              ></Select>
            </Col>
          </Row>

          {invalidPost.not_found && <span className='error-text'>{t('This email could not be found or activated.')}</span>}

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={t('ADD')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onAddBtnClick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(AddUserModal);
