import React from 'react';
import MediaQuery from 'react-responsive';
import { Route, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { faInbox, faMapMarkerAlt, faFlag, faSuitcase, faBeer, faTrophy, faUsers, faCalendarAlt, faChartLine, faPercent, faCogs, faBoxes, faPlug, faReceipt } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../../services';
import { Header, Sidebar } from '../partials';
import { Converter } from '../../utils';

import '../../styles/alerts/toast.css';
import '../../styles/layouts/auth.css';

class AuthLayout extends React.Component {
  static menus = [
    { path: '/main', label: 'ORDERS', faIcon: faInbox, roles: ['ADMIN', 'MANAGER', 'BARTENDER', 'CHEF', 'ACCOUNTANT'] },
    { path: '/profile', label: 'PROFILE', hidden: true },
    { path: '/venues', label: 'VENUES', faIcon: faMapMarkerAlt, plusBtn: true, roles: ['ADMIN', 'MANAGER', 'BARTENDER', 'CHEF', 'ACCOUNTANT'] },
    { path: '/bars', label: 'BARS', faIcon: faFlag, plusBtn: true, roles: ['ADMIN', 'MANAGER', 'BARTENDER', 'CHEF', 'ACCOUNTANT'] },
    { path: '/employees', label: 'EMPLOYEES', faIcon: faSuitcase, plusBtn: true, roles: ['ADMIN', 'MANAGER'] },
    { path: '/products', label: 'PRODUCTS', faIcon: faBeer, plusBtn: true, roles: ['ADMIN', 'MANAGER', 'BARTENDER', 'CHEF', 'ACCOUNTANT'] },
    { path: '/inventory', label: 'INVENTORY', faIcon: faBoxes, plusBtn: true, roles: ['ADMIN', 'MANAGER', 'BARTENDER', 'CHEF', 'ACCOUNTANT'] },
    { path: '/prizes', label: 'PRIZES', faIcon: faTrophy, plusBtn: true, roles: ['ADMIN', 'MANAGER', 'BARTENDER'] },
    { path: '/customers', label: 'CUSTOMERS', faIcon: faUsers, roles: ['SUPERADMIN'] },
    { path: '/events', label: 'EVENTS', faIcon: faCalendarAlt, roles: ['ADMIN', 'MANAGER'] },
    { path: '/reports', label: 'REPORTS', faIcon: faChartLine, roles: ['ADMIN', 'ACCOUNTANT'] },
    { path: '/discounts', label: 'DISCOUNTS', faIcon: faPercent, roles: ['ADMIN', 'MANAGER'] },
    { path: '/gateways', label: 'GATEWAYS', faIcon: faPlug, roles: ['ADMIN'] },
    { path: '/transfers', label: 'TRANSFERS', faIcon: faReceipt, roles: ['SUPERADMIN'] },
    { path: '/settings', label: 'SETTINGS', faIcon: faCogs, roles: ['SUPERADMIN'] },
  ];

  constructor(props) {
    super(props);

    this.state = {
      authed: false,
      me: null,
      label: null,
      sidebar: false,
    };

    this.me = this.me.bind(this);
    this.onLogoClick = this.onLogoClick.bind(this);
    this.onSideBarChange = this.onSideBarChange.bind(this);
    this.onBarBtnClick = this.onBarBtnClick.bind(this);

    this.authService = new AuthService();
  }

  async componentDidMount() {
    const { history } = this.props;
    const menu = AuthLayout.menus.find((menu) => menu.path === history.location.pathname);

    this.onHistoryChanged = history.listen((location, action) => {
      const menu = AuthLayout.menus.find((menu) => menu.path === location.pathname);

      this.setState({
        label: menu ? Converter.capitalize(menu.label) : null,
      });
    });

    this.setState({
      label: menu ? Converter.capitalize(menu.label) : null,
    });

    await this.me();
  }

  componentWillUnmount() {
    // UNLISTEN HISTORY
    this.onHistoryChanged();
  }

  async me() {
    const { history } = this.props;
    const me = await this.authService.me();

    if (!me) {
      history.push('/login');
    } else {
      this.setState({ authed: true, me: me ? me.me : null });
    }
  }

  onLogoClick() {
    const { history } = this.props;

    history.push('/login');
  }

  onSideBarChange(path) {
    const { sidebar } = this.state;
    const { history } = this.props;

    history.push(path);

    this.setState({ sidebar: !sidebar }, () => {
      const sidebarElem = document.getElementById('sidebar');
      const sidebarOverlayElem = document.getElementById('sidebar-overlay');

      sidebarElem.className = 'side';
      if (sidebarOverlayElem) {
        sidebarOverlayElem.className = 'sidebar-overlay-hide';
      }
    });
  }

  onBarBtnClick() {
    const { sidebar } = this.state;

    this.setState({ sidebar: !sidebar }, () => {
      const sidebarElem = document.getElementById('sidebar');
      const sidebarOverlayElem = document.getElementById('sidebar-overlay');

      sidebarElem.className = sidebar ? 'side' : 'side-open';
      if (sidebarOverlayElem) {
        sidebarOverlayElem.className = sidebar ? 'sidebar-overlay-hide' : 'sidebar-overlay';
      }
    });
  }

  render() {
    const { authed, me, label, sidebar } = this.state;
    const { t, history, component: Component, layout: Layout, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => (
          <DndProvider backend={HTML5Backend}>
            <Layout>
              {authed && (
                <div className='background'>
                  <ToastContainer />
                  <Header me={me} onLogoClick={this.onLogoClick} onBarBtnClick={this.onBarBtnClick} />

                  <MediaQuery maxWidth={767}>
                    <div id='sidebar-overlay' className='sidebar-overlay-hide'></div>
                  </MediaQuery>

                  <div className='body'>
                    <Sidebar sidebar={sidebar} me={me} menus={AuthLayout.menus} onSideBarChange={this.onSideBarChange} />

                    <Component me={me} label={t(label)} {...props} />
                  </div>
                </div>
              )}
            </Layout>
          </DndProvider>
        )}
      />
    );
  }
}

export default withTranslation()(withRouter(AuthLayout));
