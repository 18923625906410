import React from 'react';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faBars, faBell, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faBell as farBell } from '@fortawesome/free-regular-svg-icons';
import logo from '../../assets/logos/banner/logoxxxhdpi.png';
import userlogo from '../../assets/images/user.png';

import '../../styles/partials/header.css';
import { NotificationService } from '../../services';

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      hasNotification: true,
      showNotifications: false,
      notifications: [],
    };

    this.handleClick = this.handleClick.bind(this);
    this.fetchNotifications = this.fetchNotifications.bind(this);
    this.onProfileBtnClick = this.onProfileBtnClick.bind(this);
    this.onInviteBtnClick = this.onInviteBtnClick.bind(this);
    this.onSwitchBtnClick = this.onSwitchBtnClick.bind(this);
    this.onReadAllBtnClick = this.onReadAllBtnClick.bind(this);

    this.notificationService = new NotificationService();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);

    this.fetchNotifications();
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  async fetchNotifications() {
    const result = await this.notificationService.get();

    if (result && result.notifications) {
      this.setState({ notifications: result.notifications });
    }
  }

  handleClick(e) {
    const { showNotifications } = this.state;
    const className = e.target.className;

    if (this.node && this.node.contains(e.target) && !showNotifications) {
      this.setState({ showNotifications: true });
    } else if (className !== 'notification-list-div' && className !== 'notification-clear-all') {
      this.setState({ showNotifications: false });
    }
  }

  onProfileBtnClick() {
    const { history } = this.props;

    history.push('/profile');
  }

  onInviteBtnClick() {}

  onSwitchBtnClick() {
    const { history } = this.props;

    history.push('/switch');
  }

  async onReadAllBtnClick() {
    this.setState({ loading: true });

    await this.notificationService.readAll();

    this.setState({ loading: false, notifications: [], showNotifications: false });
  }

  render() {
    const { loading, showNotifications, notifications } = this.state;
    const { t, logoClick, me, onBarBtnClick } = this.props;

    return (
      <div className='header'>
        <div className='header-wrapper'>
          <MediaQuery maxWidth={767}>
            <span className='sidebar-menu-btn' onClick={onBarBtnClick}>
              <FontAwesomeIcon icon={faBars} size='lg' />
            </span>
          </MediaQuery>
          <img src={logo} className='img-fluid logo' alt='' onClick={logoClick}></img>
          <div className='header-right'>
            <MediaQuery minWidth={576}>
              <div className='notification-btn' ref={(ref) => (this.node = ref)}>
                {notifications.length > 0 ? <span className='notification-dot'></span> : ''}
                <FontAwesomeIcon icon={showNotifications ? farBell : faBell} fixedWidth />
              </div>
            </MediaQuery>
            {showNotifications &&
              (loading ? (
                <div className='alerts-container loading'>
                  <ReactLoading type='spin' color='#000' height='24px' width='24px' />
                </div>
              ) : (
                <div className='alerts-container'>
                  {!notifications.length && <span className='notification-no-alert'>{t('No notification')}</span>}
                  {notifications && notifications.length > 0 && (
                    <span className='notification-clear-all' onClick={this.onReadAllBtnClick}>
                      {t('Clear All')}
                    </span>
                  )}
                  {notifications &&
                    notifications.map((notification, index) => {
                      return (
                        <div key={`alert_${index}`} className='notification-list-div'>
                          {notification.type === 'LOW_STOCK' && (
                            <div>
                              <FontAwesomeIcon icon={faExclamation} color='#ffcc00' fixedWidth />
                              {`${t('Low Stock Alert')}: `}
                              <span className='notification-ingredient-name'>{notification.data.name}</span>
                              {` ${t('at')} ${notification.data.venue.name}`}
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              ))}
            <div className='header-right-profile' onClick={this.onProfileBtnClick}>
              <img src={me.profile_photo && me.profile_photo.length > 0 ? me.profile_photo + '?v=' + moment().unix() : userlogo} className='img-fluid profile-photo' alt=''></img>
              <div className='header-right-profile-text'>
                <span>
                  <strong>{me && me.first_name + ' ' + me.last_name}</strong>
                </span>
                <span>{me && me.email}</span>
              </div>
            </div>
            <button className='switch-account-btn' onClick={this.onSwitchBtnClick}>
              <MediaQuery minWidth={576}>{t('SWITCH ACCOUNT')}</MediaQuery>
              <MediaQuery maxWidth={577}>
                <FontAwesomeIcon icon={faSignOutAlt} />
              </MediaQuery>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Header));
