import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { EmployeeService } from '../../services';
import { EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, Select } from '../fields';
import { ModalLayout } from '../layouts';

import '../../styles/modals/modal.css';

class SaveEmployeeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      venue: null,
      role: null,
      invalidPost: {
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        venue_id: false,
        role: false,
        duplicate_email: false,
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);

    this.employeeService = new EmployeeService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      venue: null,
      role: null,
      invalidPost: {
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        venue_id: false,
        role: false,
        duplicate_email: false,
      },
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  setFields(employee) {
    this.setState({
      firstName: employee.user.first_name,
      lastName: employee.user.last_name,
      email: employee.user.email,
      venue: employee.venue,
      role: employee.role,
    });
  }

  async onSaveBtnCLick() {
    const { employeeId, onComplete } = this.props;
    const { firstName, lastName, email, password, venue, role, invalidPost } = this.state;
    const venue_id = venue ? venue._id : null;
    const role_name = role ? role.name : null;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = employeeId
      ? await this.employeeService.updateGeneral(venue_id, employeeId, firstName, lastName, email, password)
      : await this.employeeService.post(venue_id, firstName, lastName, email, password, role_name);

    if (result && result.success) {
      this.show(false);

      onComplete(true);
    } else if (result && result.ecode === 409) {
      this.setState({
        invalidPost: {
          ...this.state.invalidPost,
          duplicate_email: true,
        },
      });
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
      // CHECK MEMBER(S) HAVE PERMISSION TO VENUE
    } else if (result && result.ecode === 3020) {
      this.setState({
        invalidMembers: true,
      });
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const { show, loading, firstName, lastName, email, venue, role, invalidPost } = this.state;
    const { t, employeeId } = this.props;

    return (
      show && (
        <ModalLayout title={employeeId ? t('Update Employee') : t('Add Employee')}>
          <Row>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('First Name')}
                error={invalidPost.first_name}
                errorText={t('First Name is required')}
                value={firstName}
                onChange={(e) => this.setState({ firstName: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Last Name')}
                error={invalidPost.last_name}
                errorText={t('Last Name is required')}
                value={lastName}
                onChange={(e) => this.setState({ lastName: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Email')}
                error={invalidPost.email}
                errorText={t('Email is an invalid')}
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextField
                label={t('Password')}
                fullWidth
                type='password'
                error={invalidPost.password}
                errorText={t('Password must be more than 6 characters')}
                onChange={(e) => this.setState({ password: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <Select
                fullWidth
                type='venues'
                field='name'
                label={t('Venue')}
                error={invalidPost.venue_id}
                errorText={t('Venue is required')}
                defaultValue={venue}
                onChange={(venue) => this.setState({ venue: venue })}
              ></Select>
            </Col>
            <Col md={12}>
              <Select
                fullWidth
                type='roles'
                field='name'
                label={t('Roles')}
                error={invalidPost.role}
                errorText={t('Role is required')}
                capitalize={true}
                defaultValue={role}
                onChange={(role) => this.setState({ role: role })}
              ></Select>
            </Col>
          </Row>

          {invalidPost.duplicate_email && <span className='error-text'>{t('This email has already been taken. Please check the email.')}</span>}

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={employeeId ? t('UPDATE') : t('SAVE USER')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnCLick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveEmployeeModal);
