import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import Constants from '../../Constants';

const countries = Object.keys(Constants.COUNTRIES).map((key) => {
  return {
    id: key,
    name: Constants.COUNTRIES[key].name,
    value: key,
  };
});

class CountrySelect extends React.Component {
  constructor(props) {
    super(props);

    const { defaultValue } = this.props;

    this.state = {
      loading: false,
      typing: false,
      typingTimeout: 0,
      showDropDown: false,
      searchText: defaultValue ? defaultValue : '',
      data: countries,
      selectedValue: defaultValue ? defaultValue : null,
    };

    this.reset = this.reset.bind(this);
    this.setValue = this.setValue.bind(this);
    this.selectValue = this.selectValue.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.fetchList = this.fetchList.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchKeyUp = this.onSearchKeyUp.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);

    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  reset() {
    this.setState({
      loading: false,
      typing: false,
      typingTimeout: 0,
      showDropDown: false,
      searchText: '',
      data: countries,
      selectedValue: null,
    });
  }

  setValue(value) {
    this.setState({ searchText: value });
  }

  selectValue(value) {
    return value.value;
  }

  handleClick(e) {
    const id = e.target.id;
    const { selectedValue, searchText } = this.state;
    const { renderNoResult } = this.props;
    const lastText = selectedValue ? selectedValue : searchText;

    if (!this.node.contains(e.target) && id !== 'select-dropdown' && !(renderNoResult && e.target.className === renderNoResult.props.className)) {
      this.setState({ showDropDown: false, searchText: lastText });
    }
  }

  fetchList(clear = false) {
    const { searchText } = this.state;

    this.setState({ searchText: clear ? '' : searchText, showDropDown: true, loading: true }, async () => {
      this.setState({ data: countries, loading: false });
    });
  }

  onSearchChange(e) {
    this.setState({ searchText: e.target.value });
  }

  onSearchKeyUp() {
    const { typingTimeout } = this.state;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(() => {
        this.fetchList();
      }, 500),
    });
  }

  onOptionChange(row) {
    const { onChange } = this.props;

    if (row) {
      this.setState({ searchText: this.selectValue(row), selectedValue: this.selectValue(row), showDropDown: false }, () => {
        onChange(row);
      });
    }
  }

  render() {
    const { loading, showDropDown, data, searchText } = this.state;
    const { t, fullWidth, label, size, error, errorText, disabled, renderNoResult } = this.props;

    return (
      <div className={`outlined-textfield ${size} ${error && 'outlined-textfield-error'}`} style={{ width: fullWidth ? '100%' : 'auto' }}>
        <input
          ref={(ref) => (this.node = ref)}
          className={`${size}`}
          placeholder=' '
          autoComplete='off'
          disabled={disabled}
          value={searchText || ''}
          onClick={() => {
            this.fetchList(true);
          }}
          onChange={this.onSearchChange}
          onKeyUp={this.onSearchKeyUp}
        />
        <label className={`${size}`}>{label}</label>
        {error && <span className='outlined-textfield-error-text'>{errorText}</span>}
        {showDropDown &&
          (loading ? (
            <div className='dropdown-loading'>
              <div className='margin-auto'>
                <ReactLoading type='spin' color='#000' height='24px' width='24px' />
              </div>
            </div>
          ) : (
            <div className='dropdown-content'>
              <div className='dropdown-content-wrapper'>
                {data.length === 0 && (
                  <div
                    id='dropdown-content-span-no-found'
                    className='dropdown-content-span-no-found'
                    onClick={() => {
                      this.setState({ showDropDown: false });
                    }}
                  >
                    {renderNoResult ? renderNoResult : t('No Data Found')}
                  </div>
                )}
                {data.map((row) => {
                  const id = row._id || row.id || row;
                  const name = row && row.name.length > 0 ? row.name : t('None');

                  return (
                    <span
                      id='select-dropdown'
                      className='dropdown-content-span'
                      key={id}
                      onClick={() => {
                        this.onOptionChange(row);
                      }}
                    >
                      {`${name} (${row.value})`}
                    </span>
                  );
                })}
              </div>
            </div>
          ))}
      </div>
    );
  }
}

CountrySelect.propTypes = {
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  renderNoResult: PropTypes.oneOfType([PropTypes.element, PropTypes.any]),
};

CountrySelect.defaultProps = {
  size: 'md',
};

export default withTranslation()(CountrySelect);
