import QueryString from 'query-string';
import { FetchService } from '.';

export default class ProductService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.getStock = this.getStock.bind(this);
    this.getBarProductList = this.getBarProductList.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.changeEnabled = this.changeEnabled.bind(this);
    this.delete = this.delete.bind(this);
    this.updateBarEnabled = this.updateBarEnabled.bind(this);
  }

  get(query) {
    return this.Get('/products?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getStock(query) {
    return this.Get('/products/stock?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getBarProductList(query) {
    return this.Get('/products/listbybar?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(venue_id, name, note, photo, prices, options, enabled = true, product_category_id = null, is_food = false, is_exclusive = false, external_reference = '') {
    const formData = new FormData();

    formData.append('venue_id', venue_id);
    formData.append('name', name);
    formData.append('prices', JSON.stringify(prices || []));
    formData.append('options', JSON.stringify(options || []));
    formData.append('enabled', enabled);
    formData.append('is_food', is_food);
    formData.append('is_exclusive', is_exclusive);
    formData.append('external_reference', external_reference);
    if (product_category_id) {
      formData.append('product_category_id', product_category_id);
    }
    if (note) {
      formData.append('note', note);
    }
    if (photo) {
      formData.append('photo', photo);
    }

    return this.Post('/products', { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  put(venue_id, product_id, name, note, photo, prices, options, product_category_id = null, is_food = false, is_exclusive = false, external_reference = '') {
    const formData = new FormData();

    formData.append('venue_id', venue_id);
    formData.append('product_id', product_id);
    formData.append('name', name);
    formData.append('prices', JSON.stringify(prices || []));
    formData.append('options', JSON.stringify(options || []));
    formData.append('is_food', is_food);
    formData.append('is_exclusive', is_exclusive);
    formData.append('external_reference', external_reference);
    if (product_category_id) {
      formData.append('product_category_id', product_category_id);
    }
    if (note) {
      formData.append('note', note);
    }
    if (photo) {
      formData.append('photo', photo);
    }

    return this.Put('/products', { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  changeEnabled(venue_id, product_id, enabled = true) {
    return this.Put('/products/enabled', { body: JSON.stringify({ venue_id, product_id, enabled }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(venue_id, product_id) {
    return this.Post('/products/delete', { body: JSON.stringify({ venue_id, product_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateBarEnabled(bar_id, product_id, enabled = true) {
    return this.Put('/products/bar/enabled', { body: JSON.stringify({ bar_id, product_id, enabled }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
