export default class Validator {
  static filter(key, value, array, ne = false) {
    if (Array.isArray(array)) {
      return array.filter((object) => {
        if (ne) {
          return object[key] !== value;
        } else {
          return object[key] === value;
        }
      });
    } else {
      return null;
    }
  }

  static containsInArray(key, value, array, ne) {
    if (Array.isArray(array)) {
      const result = Validator.filter(key, value, array, ne);

      return result.length > 0;
    } else {
      return false;
    }
  }

  static populateArray(key, array) {
    return array.map((item) => {
      return item[key] !== null ? item[key] : null;
    });
  }

  static setFieldInArray(key, value, field, fieldValue, array) {
    if (Array.isArray(array)) {
      for (let i = 0; i < array.length; i++) {
        const item = array[i];

        if (item.hasOwnProperty(key) && item[key] === value) {
          item[field] = fieldValue;
        }
      }

      return array;
    } else {
      return array;
    }
  }

  static countUniqueKeys(key, array) {
    if (!Array.isArray(array)) {
      return 0;
    }

    const keys = Validator.populateArray(key, array);
    const unique_keys = keys.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });

    return unique_keys;
  }
}
