import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { AuthService } from '../../services';

class LoginLayout extends React.Component {
  constructor(props) {
    super(props);

    this.me = this.me.bind(this);

    this.authService = new AuthService();
  }

  async me() {
    const { history } = this.props;
    const me = await this.authService.me();

    if (me) {
      history.push('/main');
    }
  }

  componentDidMount() {
    this.me();
  }

  render() {
    const { component: Component, layout: Layout, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component {...props} />
          </Layout>
        )}
      />
    );
  }
}

export default withRouter(LoginLayout);
