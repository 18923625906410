import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag, faMapMarkerAlt, faTimes, faCalendarAlt } from '@fortawesome/free-solid-svg-icons';

// FILTER STYLE

// ICONS
const icons = {
  venue: faFlag,
  bar: faMapMarkerAlt,
  date: faCalendarAlt,
};

class FilterTag extends React.Component {
  constructor(props) {
    super(props);

    this.setIcon = this.setIcon.bind(this);
  }

  setIcon(type) {
    return icons[type];
  }

  render() {
    const { label, type, onRemove } = this.props;
    return (
      <div className='filter-tag'>
        <span className='filter-tag-part'>
          <FontAwesomeIcon icon={this.setIcon(type)} />
        </span>
        <span className='filter-tag-part'>{label}</span>
        <span
          className='filter-tag-part pointer'
          onClick={() => {
            onRemove(type);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    );
  }
}

FilterTag.propTypes = {
  dataId: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['venue', 'bar', 'date']),
};

FilterTag.defaultProps = {
  type: 'venue',
};

export default withTranslation()(FilterTag);
