import React from 'react';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faBars } from '@fortawesome/free-solid-svg-icons';

import '../../styles/cards/productcategory.css';

export const ProductCategoryCard = ({ id, index, name, productCategory, onDelete, moveProductCategoryCard, dropProductCategoryCard }) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: 'ProductCategoryCard',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      moveProductCategoryCard(dragIndex, hoverIndex, 'MOVE');
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: 'ProductCategoryCard',
    item: () => {
      return { id, index, name };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item) => {
      if (!ref.current) {
        return;
      }

      dropProductCategoryCard(null, null, 'DROP');
    },
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));

  return (
    <div className='product-category-card' ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      <div className='product-category-card-body'>
        <p className='product-category-card-body-title'>
          <FontAwesomeIcon icon={faBars} size={'sm'} color='#110000'></FontAwesomeIcon> {productCategory.name}
        </p>
      </div>
      <div className='product-category-card-right'>
        <div className='product-category-remove-btn' onClick={() => onDelete(productCategory)}>
          <FontAwesomeIcon icon={faTimes} size={'sm'} color='#222b45'></FontAwesomeIcon>
        </div>
      </div>
    </div>
  );
};

ProductCategoryCard.propTypes = {
  productCategory: PropTypes.any,
  onDelete: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

ProductCategoryCard.defaultProps = {
  onDelete: Function.prototype,
};

export default withTranslation()(ProductCategoryCard);
