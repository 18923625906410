import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Converter } from '../../utils';

import '../../styles/cards/terminals.css';

class TerminalsCard extends React.Component {
  render() {
    const { t, title, rows, currency, currencySymbol } = this.props;

    return (
      <div className='terminals-card-container'>
        <p className='terminals-card-title'>{title}</p>
        <div className='terminals-card-table-container'>
          {rows && rows.length > 0 ? (
            <table className='terminals-card-table'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'>{t('Terminal ID')}</th>
                  <th scope='col'>{t('Status')}</th>
                  <th scope='col'>{t('Total')}</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => {
                  return (
                    <tr key={`tid.${index}`}>
                      <td>{index + 1}.</td>
                      <td>{row.tid}</td>
                      <td>{row.reportResult}</td>
                      <td>
                        {currencySymbol} {row.balances ? Converter.convertCurrencyToNonZeroDecimal(row.balances.totalAmount, currency, true).toFixed(2) : '-'}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>{t('No Data Found')}</p>
          )}
        </div>
      </div>
    );
  }
}

TerminalsCard.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array,
  currency: PropTypes.string,
  currencySymbol: PropTypes.string,
};

TerminalsCard.defaultProps = {
  title: '',
  rows: [],
  currency: '',
  currencySymbol: '',
};

export default withTranslation()(TerminalsCard);
