import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { LoadingButton, DefaultButton } from '../buttons';
import { Checkbox } from '../fields';
import { ModalLayout } from '../layouts';
import { VenueService } from '../../services';

import '../../styles/modals/modal.css';

class IngredientOptionsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      venue: null,
      notifications: {
        lsAlertMethod: 'NONE',
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.onSaveBtnClick = this.onSaveBtnClick.bind(this);
    this.onNotificationTypeChange = this.onNotificationTypeChange.bind(this);

    this.venueService = new VenueService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      venue: null,
      notifications: {
        lsAlertMethod: 'NONE',
      },
    });
  }

  show(set = true, venue = null) {
    this.resetFields();
    this.setState({
      show: set,
      venue: venue,
      notifications: {
        lsAlertMethod: venue && venue.notifications ? venue.notifications.ls_alert_method : 'NONE',
      },
    });
  }

  async onSaveBtnClick() {
    const { venue, notifications } = this.state;
    const { onComplete } = this.props;

    const result = await this.venueService.setNotifications(venue ? venue._id : null, notifications.lsAlertMethod);
    if (result && result.success) {
      onComplete(true);
      this.show(false);
    }
  }

  onNotificationTypeChange(checked, value) {
    if (checked) {
      this.setState({
        notifications: {
          ...this.state.notifications,
          lsAlertMethod: value,
        },
      });
    }
  }

  render() {
    const { show, loading, venue, notifications } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout title={t('Options')} subtitle={venue.name}>
          <Row>
            <Col md={12}>
              <Row>
                <Col md={12}>
                  <p className='modal-title'>{t('Low Stock Alert Method')}</p>
                  <div className='modal-inline-checkboxes'>
                    <Checkbox text={t('None')} value='NONE' checked={notifications.lsAlertMethod === 'NONE'} onChange={this.onNotificationTypeChange} />
                    <Checkbox text={t('Alert')} value='ALERT' checked={notifications.lsAlertMethod === 'ALERT'} onChange={this.onNotificationTypeChange} />
                    <Checkbox text={t('Email')} value='EMAIL' checked={notifications.lsAlertMethod === 'EMAIL'} onChange={this.onNotificationTypeChange} />
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={t('SAVE')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnClick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(IngredientOptionsModal);
