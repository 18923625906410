import React from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import '../../styles/cards/eventproduct.css';

class EventProductCard extends React.Component {
  render() {
    const { value, barProduct, onSwitchChange } = this.props;

    return (
      <div className='event-product'>
        <div className='event-product-item-container'>
          <div className='event-product-item-image'>
            <img src={barProduct.product.photo} alt='' />
          </div>
          <div className='event-product-item-body'>
            <p className='event-product-item-title'>{barProduct.product.name}</p>
          </div>
          <div className='event-product-item-right'>
            <ReactSwitch
              checked={value}
              onChange={(checked) => {
                onSwitchChange(checked, barProduct);
              }}
              onColor='#fec802'
              onHandleColor='#ffffff'
              handleDiameter={16}
              uncheckedIcon={false}
              checkedIcon={false}
              height={24}
              width={48}
            />
          </div>
        </div>
      </div>
    );
  }
}

EventProductCard.propTypes = {
  value: PropTypes.bool,
  barProduct: PropTypes.object,
  onSwitchChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

EventProductCard.defaultProps = {
  value: true,
  onSwitchChange: Function.prototype,
};

export default withTranslation()(withRouter(EventProductCard));
