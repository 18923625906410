import QueryString from 'query-string';
import { FetchService } from '.';

export default class CustomerService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.reset = this.reset.bind(this);
    this.confirmEmail = this.confirmEmail.bind(this);
  }

  get(query) {
    return this.Get('/customers?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  reset(customer_id, refresh_token, password, confirm_password) {
    return this.Post('/customers/reset', {
      body: JSON.stringify({
        customer_id,
        refresh_token,
        password,
        confirm_password,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  confirmEmail(customer_id, refresh_token, password, confirm_password, new_email) {
    return this.Post('/customers/confirm/email', {
      body: JSON.stringify({
        customer_id,
        refresh_token,
        password,
        confirm_password,
        new_email
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
