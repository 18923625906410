import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DateTimePicker } from '.';

import '../../styles/fields/timepickerfield.css';

class TimePickerField extends React.Component {
  constructor(props) {
    super(props);

    const { value } = this.props;

    this.state = {
      value: value,
    };

    this.onChange = this.onChange.bind(this);
  }

  onChange(value) {
    const { onChange } = this.props;

    this.setState({ value }, () => {
      if (onChange) {
        onChange(value);
      }
    });
  }

  onRightBtnClick() {
    this.datePicker.setOpen(true);
  }

  render() {
    const { value } = this.state;

    return (
      <DateTimePicker className='width-100' calendarClassName='react-datapicker-no-month' showTimeSelect={true} defaultValue={value} onlyTime={true} onChange={this.onChange} />
    );
  }
}

TimePickerField.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

TimePickerField.defaultProps = {
  onChange: Function.prototype,
};

export default withTranslation()(TimePickerField);
