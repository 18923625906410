import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

class Tab extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      activeIndex: 0,
    };

    this.onTabClick = this.onTabClick.bind(this);
  }

  onTabClick(index, tab) {
    const { onTabClick } = this.props;

    this.setState(
      {
        activeIndex: index,
      },
      () => {
        onTabClick(index, tab);
      }
    );
  }

  render() {
    const { activeIndex } = this.state;
    const { tabs } = this.props;

    return (
      <div className='tab-header'>
        {tabs.map((tab, index) => {
          return (
            <div
              key={index}
              className={`tab-header-menu ${activeIndex === index ? 'tab-active' : ''}`}
              onClick={() => {
                this.onTabClick(index, tab);
              }}
            >
              {tab.title}
            </div>
          );
        })}
      </div>
    );
  }
}

Tab.propTypes = {
  tabs: PropTypes.array,
  onTabClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

Tab.defaultProps = {
  onTabClick: Function.prototype,
};

export default withTranslation()(Tab);
