import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { ModalLayout } from '../layouts';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, TextFieldWithDropDown, Checkbox, DropDown, GatewayPathField } from '../fields';
import { GatewayService } from '../../services';
import { EntityError, Validator } from '../../utils';
import { GatewayFieldsModal } from '.';
import { Toast } from '../alerts';

import '../../styles/modals/modal.css';
import '../../styles/modals/gateway.css';

class GatewayModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      venue: null,
      profile: null,
      profiles: [],
      name: '',
      authorizationScheme: 'Basic',
      authorizationParameter: '',
      authorizationBase64: false,
      endpointUrl: '',
      route: 'POST_TRANSACTION',
      routeMethod: 'GET',
      routePath: null,
      routeEnabled: true,
      routeFields: null,
      routes: [],
      selectedRoute: null,
      headerKey: null,
      headerValue: null,
      headers: [],
      invalidPost: {
        name: false,
        authorization_scheme: false,
        authorization_parameter: false,
        authorization_base64: false,
        endpoint_url: false,
        routes: false,
        path: false,
        duplicated: false,
      },
    };

    this.show = this.show.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.setFields = this.setFields.bind(this);
    this.fetchProfiles = this.fetchProfiles.bind(this);
    this.getMethodActions = this.getMethodActions.bind(this);
    this.onAddBtnClick = this.onAddBtnClick.bind(this);
    this.onGatewayFieldsModalComplete = this.onGatewayFieldsModalComplete.bind(this);
    this.onAddHeaderBtnClick = this.onAddHeaderBtnClick.bind(this);
    this.onAddRouteBtnClick = this.onAddRouteBtnClick.bind(this);
    this.onImportBtnClick = this.onImportBtnClick.bind(this);

    this.gatewayService = new GatewayService();
  }

  componentDidMount() {
    this.fetchProfiles();

    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  show(set = true, venue = null) {
    this.setState({
      venue: venue,
      show: set,
    });
  }

  resetFields() {
    this.setState({
      profile: null,
      name: '',
      authorizationScheme: 'Basic',
      authorizationParameter: '',
      authorizationBase64: false,
      endpointUrl: '',
      route: 'POST_TRANSACTION',
      routeMethod: 'GET',
      routePath: null,
      routeEnabled: true,
      routeFields: null,
      routes: [],
      selectedRoute: null,
      headerKey: null,
      headerValue: null,
      headers: [],
      invalidPost: {
        name: false,
        authorization_scheme: false,
        authorization_parameter: false,
        authorization_base64: false,
        endpoint_url: false,
        routes: false,
        path: false,
        duplicated: false,
      },
    });
  }

  setFields(gateway) {
    this.setState({
      name: gateway.name,
      authorizationScheme: gateway.authorization ? gateway.authorization.scheme : null,
      authorizationParameter: gateway.authorization ? gateway.authorization.parameter : null,
      authorizationBase64: gateway.authorization ? gateway.authorization.base64 : null,
      endpointUrl: gateway.endpoint.url,
      headers: gateway.headers || [],
      routes: gateway.routes || [],
      selectedRoute: null,
    });
  }

  async fetchProfiles() {
    const result = await this.gatewayService.getProfiles();
    if (result && result.success) {
      this.setState({ profiles: result.profiles });
    }
  }

  getMethodActions() {
    return [
      { label: 'GET', title: 'GET', value: 'GET' },
      { label: 'POST', title: 'POST', value: 'POST' },
      { label: 'PUT', title: 'PUT', value: 'PUT' },
      { label: 'DELETE', title: 'DELETE', value: 'DELETE' },
    ];
  }

  async onAddBtnClick() {
    const { venue, name, authorizationScheme, authorizationParameter, authorizationBase64, endpointUrl, headers, routes, invalidPost } = this.state;
    const { gatewayId, onComplete } = this.props;
    // REMOVE route.fields IF it's null
    const formattedRoutes = routes.map((route) => {
      return {
        ...route,
        fields: route.fields || undefined,
      };
    });

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = gatewayId
      ? await this.gatewayService.put(gatewayId, name, authorizationScheme, authorizationParameter, authorizationBase64, headers, endpointUrl, formattedRoutes)
      : await this.gatewayService.post(venue ? venue._id : null, name, authorizationScheme, authorizationParameter, authorizationBase64, headers, endpointUrl, formattedRoutes);

    if (result && result.success) {
      this.show(false);
      onComplete();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({ loading: false });
  }

  onGatewayFieldsModalComplete(fields) {
    const { selectedRoute, routes } = this.state;
    const { t } = this.props;

    try {
      if (selectedRoute === null) {
        this.setState({
          routeFields: fields,
        });
      } else {
        const newRoutes = [...routes];
        newRoutes[selectedRoute].fields = fields;

        this.setState({
          routes: newRoutes,
        });
      }
    } catch (error) {
      Toast.alert(t('Fields could be set, please try again later!'));
    }
  }

  onAddHeaderBtnClick() {
    const { headers, headerKey, headerValue, invalidPost } = this.state;

    this.setState({ invalidPost: EntityError.setInputErrors(invalidPost, null, true) });

    let valid = true;
    if (!headerKey || headerKey.length < 1) {
      valid = false;
      this.setState({ invalidPost: { ...invalidPost, header_key: true } });
    }
    if (!headerValue || headerValue.length < 1) {
      valid = false;
      this.setState({ invalidPost: { ...invalidPost, header_value: true } });
    }

    if (valid) {
      this.setState({
        headerKey: null,
        headerValue: null,
        headers: [...headers, { key: headerKey, value: headerValue }],
      });
    }
  }

  onAddRouteBtnClick() {
    const { routes, route, routePath, routeMethod, routeEnabled, routeFields, invalidPost } = this.state;

    this.setState({ invalidPost: EntityError.setInputErrors(invalidPost, null, true) });

    let valid = true;
    if (!routePath || routePath.length < 1) {
      valid = false;
      this.setState({ invalidPost: { ...invalidPost, path: true } });
    }
    if (Validator.containsInArray('route', route, routes)) {
      valid = false;
      this.setState({ invalidPost: { ...invalidPost, duplicated: true } });
    }

    if (valid) {
      this.setState({
        route: 'POST_TRANSACTION',
        routeFields: null,
        routeMethod: 'GET',
        routePath: null,
        routes: [
          ...routes,
          {
            route: route,
            path: routePath,
            method: routeMethod,
            enabled: routeEnabled,
            fields: routeFields,
          },
        ],
      });
    }
  }

  onImportBtnClick() {
    const { profile, profiles } = this.state;

    if (profile) {
      const list = profiles.filter((p) => p._id === profile.id);
      const data = list && list.length > 0 ? list[0] : null;

      if (data) {
        this.setState({
          endpointUrl: data.endpoint.url,
          headers: data.headers || [],
          routes: data.routes || [],
        });
      }
    }
  }

  render() {
    const {
      loading,
      show,
      venue,
      profile,
      profiles,
      name,
      authorizationScheme,
      authorizationParameter,
      authorizationBase64,
      endpointUrl,
      routes,
      route,
      routeMethod,
      routePath,
      routeFields,
      headers,
      headerKey,
      headerValue,
      invalidPost,
    } = this.state;
    const { t, gatewayId } = this.props;

    return (
      show && (
        <ModalLayout title={t('Add Gateway')} width={640} subtitle={venue.name}>
          <GatewayFieldsModal onRef={(ref) => (this.gatewayFieldsModal = ref)} onComplete={this.onGatewayFieldsModalComplete} />
          <Row>
            <Col md={12}>
              <TextField
                label={t('Name')}
                fullWidth
                value={name}
                error={invalidPost.name}
                errorText={t('Name is required')}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <p className='gateway-subtitle'>{t('Authorization')}</p>
            </Col>
            <Col md={8}>
              <TextFieldWithDropDown
                label={t('Parameter')}
                type='password'
                fullWidth
                value={authorizationParameter}
                error={invalidPost.authorization_parameter}
                errorText={t('Parameter is an invalid')}
                onChange={(e) => this.setState({ authorizationParameter: e.target.value })}
                dropdownLabel={authorizationScheme || 'Basic'}
                dropdownActions={[
                  { label: 'Basic', title: 'Basic' },
                  { label: 'Bearer', title: 'Bearer' },
                  { label: 'Digest', title: 'Digest' },
                ]}
                dropdownOnChange={(data, selectedValue) => {
                  this.setState({
                    authorizationScheme: selectedValue.label,
                  });
                }}
                style={{ paddingRight: '40px' }}
              ></TextFieldWithDropDown>
            </Col>
            <Col md={4}>
              <div className='gateway-checkbox-container'>
                <Checkbox
                  text={t('Base64')}
                  value={false}
                  checked={authorizationBase64}
                  onChange={(checked) => {
                    this.setState({
                      authorizationBase64: checked,
                    });
                  }}
                />
              </div>
            </Col>
            <Col md={12}>
              <small>{t('Authentication parameter can be combination of username and password or api key and secret key. Please concatenate with a colon character')} ":"</small>
            </Col>
            <Col md={12}>
              <p className='gateway-subtitle'>{t('Import Profile')}</p>
            </Col>
            {profiles && profiles.length > 0 && (
              <Col md={12}>
                <DropDown
                  label={profile ? profile.label : t('Please select a profile')}
                  dropdownStyle='gateway-profile-dropdown'
                  buttonStyle='gateway-profile-dropdown-btn'
                  actions={profiles.map((p) => {
                    return { label: p.name, title: p.name, id: p._id };
                  })}
                  onChange={(data, selectedValue) => {
                    this.setState({
                      profile: selectedValue,
                    });
                  }}
                />
                <button className='gateway-import-btn' onClick={this.onImportBtnClick}>
                  {t('Import')}
                </button>
              </Col>
            )}
            <Col md={12}>
              <p className='gateway-subtitle'>{t('Endpoint')}</p>
            </Col>
            <Col md={12}>
              <TextField
                label={t('Endpoint Url')}
                fullWidth
                value={endpointUrl}
                error={invalidPost.endpoint_url}
                errorText={t('Endpoint Url is an invalid')}
                onChange={(e) => this.setState({ endpointUrl: e.target.value })}
                rightBtnLabel={<FontAwesomeIcon icon={faLink} color='#555' />}
                style={{ paddingRight: '40px' }}
              ></TextField>
            </Col>
            <Col md={12}>
              <p className='gateway-subtitle'>{t('Headers')}</p>
              <div className='row gateway-routes-container'>
                <div className='gateway-routes-col col-sides'>
                  <TextField
                    label={t('Key')}
                    fullWidth
                    value={headerKey || ''}
                    error={invalidPost.header_key}
                    errorText={t('Key is required')}
                    onChange={(e) => this.setState({ headerKey: e.target.value })}
                  ></TextField>
                </div>
                <div className='gateway-routes-col col-mid'>
                  <TextField
                    label={t('Value')}
                    fullWidth
                    value={headerValue || ''}
                    error={invalidPost.header_value}
                    errorText={t('Key is required')}
                    onChange={(e) => this.setState({ headerValue: e.target.value })}
                  ></TextField>
                </div>
                <div className='gateway-routes-col col-btn'>
                  <button className='gateway-routes-add-btn' onClick={this.onAddHeaderBtnClick}>
                    <FontAwesomeIcon icon={faPlus} size='1x' color='#222b45'></FontAwesomeIcon>
                  </button>
                </div>
              </div>
              <div className='gateway-routes-container'>
                {headers.map((header, index) => {
                  return (
                    <div className='row gateway-routes-list' key={`ph_${index}`}>
                      <div className='gateway-routes-col col-sides'>{header.key}</div>
                      <div className='gateway-routes-col col-mid'>{header.value}</div>
                      <div className='gateway-routes-col col-btn'>
                        <button
                          className='gateway-routes-remove-btn'
                          onClick={() => {
                            headers.splice(index, 1);
                            this.setState({ headers });
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} size='1x' color='#ff6961'></FontAwesomeIcon>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>
            <Col md={12}>
              <p className='gateway-subtitle'>{t('Routes')}</p>
              <div className='row gateway-routes-container'>
                <div className='gateway-routes-col col-sides'>
                  <DropDown
                    label={route}
                    dropdownStyle='gateway-route-type-dropdown'
                    buttonStyle='gateway-route-type-dropdown-btn'
                    actions={[
                      { label: 'POST_TRANSACTION', title: 'POST_TRANSACTION' },
                      { label: 'REFUND', title: 'REFUND' },
                    ]}
                    onChange={(data, selectedValue) => {
                      this.setState({
                        route: selectedValue.label,
                      });
                    }}
                  />
                </div>
                <div className='gateway-routes-col col-mid'>
                  <GatewayPathField
                    label={t('Path')}
                    value={routePath || ''}
                    query={{ list: false }}
                    error={invalidPost.path}
                    errorText={t('Path is required')}
                    onChange={(value) => {
                      this.setState({ routePath: value });
                    }}
                    dropdownLabel={routeMethod || 'GET'}
                    dropdownActions={this.getMethodActions()}
                    dropdownOnChange={(data, selectedValue) => {
                      this.setState({
                        routeMethod: selectedValue.value,
                      });
                    }}
                    inputStyle={{ paddingRight: '100px' }}
                    fullWidth
                  />
                  <div className='gateway-fields-div'>
                    <span
                      className='gateway-fields-btn'
                      onClick={() => {
                        if (this.gatewayFieldsModal) {
                          this.setState(
                            {
                              selectedRoute: null,
                            },
                            () => {
                              this.gatewayFieldsModal.show(true, true, null, routeFields || null);
                            }
                          );
                        }
                      }}
                    >
                      {t('Fields')}
                    </span>
                  </div>
                </div>
                <div className='gateway-routes-col col-btn'>
                  <button className='gateway-routes-add-btn' onClick={this.onAddRouteBtnClick}>
                    <FontAwesomeIcon icon={faPlus} size='1x' color='#222b45'></FontAwesomeIcon>
                  </button>
                </div>
                {invalidPost.duplicated && <span className='error-text'>{t('Duplicated Route')}</span>}
              </div>
              <div className='gateway-routes-container'>
                {routes.map((route, index) => {
                  return (
                    <div className='row gateway-routes-list' key={`rl_${index}`}>
                      <div className='gateway-routes-col col-sides'>
                        <small>{route.route}</small>
                      </div>
                      <div className='gateway-routes-col col-mid'>
                        <b>{route.method}</b> {route.path}
                        {'  '}
                        <span
                          className='gateway-fields-btn'
                          onClick={() => {
                            if (this.gatewayFieldsModal) {
                              this.setState(
                                {
                                  selectedRoute: index,
                                },
                                () => {
                                  this.gatewayFieldsModal.show(true, true, null, route.fields || null);
                                }
                              );
                            }
                          }}
                        >
                          {t('Fields')}
                        </span>
                      </div>
                      <div className='gateway-routes-col col-btn'>
                        <button
                          className='gateway-routes-remove-btn'
                          onClick={() => {
                            routes.splice(index, 1);
                            this.setState({ routes });
                          }}
                        >
                          <FontAwesomeIcon icon={faMinus} size='1x' color='#ff6961'></FontAwesomeIcon>
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </Col>

            <Col md={12}>
              <small>{t('Please define which route will be called after transactions. For example')}: POST /transaction</small>
            </Col>
          </Row>

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={gatewayId ? t('UPDATE') : t('ADD')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onAddBtnClick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(GatewayModal);
