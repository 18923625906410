export default class EntityError {
  static setInputErrors(states, errors, reset = false) {
    if (reset) {
      for (const key in states) {
        states[key] = false;
      }
    } else {
      for (let i = 0; i < errors.length; i++) {
        const error = errors[i];

        if (states.hasOwnProperty(error.param)) {
          states[error.param] = true;
        } else {
          states[error.param] = false;
        }
      }
    }

    return states;
  }
}
