import React from 'react';
import { withTranslation } from 'react-i18next';
import L from 'leaflet';
import { Map as LeafletMap, TileLayer, Marker } from 'react-leaflet';
import ReactLeafletSearch from 'react-leaflet-search';
import pin256 from '../../assets/images/pin256.png';

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: 6,
      searchZoom: 15,
    };

    this.setZoom = this.setZoom.bind(this);
    this.onZoomend = this.onZoomend.bind(this);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  setZoom(zoom) {
    this.setState({ zoom: zoom });
  }

  onZoomend(e) {
    const zoom = e.target._zoom;

    this.setState({ zoom: zoom });
  }

  render() {
    const { zoom, searchZoom } = this.state;
    const { t, containerClass, coordinates, defaultCoordinate, search, marker, error, errorText, ...options } = this.props;
    const tileUrl = 'https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png';

    return (
      <div className={containerClass}>
        <LeafletMap
          className='map'
          center={coordinates.lng && coordinates.lat ? coordinates : defaultCoordinate}
          zoom={zoom}
          attributionControl={false}
          onZoomend={this.onZoomend}
          {...options}
        >
          <TileLayer url={tileUrl} />
          {search && (
            <ReactLeafletSearch inputPlaceholder={t('Search Address')} zoom={searchZoom} showPopup={false} showMarker={false} closeResultsOnClick={true}></ReactLeafletSearch>
          )}
          {marker && coordinates.lng && coordinates.lat && (
            <Marker
              position={coordinates}
              icon={L.icon({
                iconUrl: pin256,
                iconSize: [30, 30],
              })}
            ></Marker>
          )}
        </LeafletMap>
        {error && <span className='map-error-text'>{errorText}</span>}
      </div>
    );
  }
}

export default withTranslation()(Map);
