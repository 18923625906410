import React from 'react';
import PropTypes from 'prop-types';

class TextField extends React.Component {
  render() {
    const { fullWidth, isTextarea, label, size, leftBtnLabel, leftBtnClick, rightBtnLabel, rightBtnClick, error, errorText, onRef, ...rest } = this.props;
    return (
      <div className={`outlined-textfield ${size} ${isTextarea ? 'textareafield' : ''} ${error ? 'outlined-textfield-error' : ''}`} style={{ width: fullWidth ? '100%' : 'auto' }}>
        {isTextarea ? (
          <textarea className={`${size}`} placeholder=' ' ref={onRef} {...rest} />
        ) : (
          <input className={`${size}`} placeholder=' ' ref={onRef} {...rest} />
        )}
        <label className={`${size}`}>{label}</label>
        {leftBtnLabel ? (
          <div className='textfield-left-btn' onClick={rightBtnClick}>
            {leftBtnLabel}
          </div>
        ) : (
          ''
        )}
        {error && <span className='outlined-textfield-error-text'>{errorText}</span>}
        {rightBtnLabel ? (
          <div className='textfield-right-btn' onClick={rightBtnClick}>
            {rightBtnLabel}
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

TextField.propTypes = {
  isTextarea: PropTypes.bool,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  onRef: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

TextField.defaultProps = {
  isTextarea: false,
  size: 'md',
};

export default TextField;
