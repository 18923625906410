import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DefaultTable } from '../components/tables';
import { SaveCustomerModal } from '../components/modals';
import { CustomerService } from '../services';
import { Converter, LocalStorage } from '../utils';

import '../styles/customer.css';

class CustomerPage extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      loading: false,
      searchText: '',
      columns: [
        {
          title: t('ID'),
          field: 'id',
          titleStyle: {
            width: '100px',
          },
        },
        {
          title: t('NAME'),
          field: ['first_name', 'last_name'],
        },
        {
          title: t('PHONE NUMBER'),
          field: 'phone_number',
        },
        {
          title: t('ORDERS'),
          field: 'order_count',
          titleStyle: {
            width: '100px',
          },
        },
        {
          title: t('PICKED UP'),
          field: 'order_completed_count',
          titleStyle: {
            width: '120px',
          },
        },
        {
          title: t('LAST ACTIVITY'),
          field: 'last_login',
          render: (value) => {
            return moment(value).fromNow();
          },
        },
        {
          title: t('STATUS'),
          field: 'active',
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
          render: (value) => {
            return value ? t('Active') : t('Inactive');
          },
        },
        {
          title: t('ACTIONS'),
          field: 'actions',
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
          action: true,
        },
      ],
      rows: [],
      filter: LocalStorage.getFilter(),
      pagination: {
        skip: 0,
        limit: 10,
        count: 0,
      },
    };

    this.fetchCustomers = this.fetchCustomers.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onActionChange = this.onActionChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);

    this.customerService = new CustomerService();
  }

  componentDidMount() {
    this.fetchCustomers();
  }

  async fetchCustomers() {
    this.setState({ loading: true });

    const { searchText, filter, pagination } = this.state;
    const result = await this.customerService.get({
      search: searchText,
      start_date: filter.date && filter.date.start ? filter.date.start.formated : undefined,
      end_date: filter.date && filter.date.end ? filter.date.end.formated : undefined,
      skip: pagination.skip || 0,
      limit: pagination.limit || 10,
    });

    if (result && result.success) {
      this.setState({ rows: result.customers, pagination: result.pagination, loading: false });

      if (this.table) {
        const filter = this.table.getFilter();
        filter.onStartDateChange(Converter.toLocalDateTime(result.start_date), 'YYYY-MM-DD');
        filter.onEndDateChange(Converter.toLocalDateTime(result.end_date), 'YYYY-MM-DD');
        filter.showTags();
      }
    } else if (result && result.ecode === 214) {
      this.setState({ rows: [], loading: false });
    }
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
        pagination: {
          skip: 0,
          limit: 10,
          count: 0,
        },
      },
      () => {
        this.fetchCustomers();
      }
    );
  }

  onActionChange(data, action) {
    switch (action.label) {
      case 'VIEW':
        this.saveCustomerModal.show(true, data);
        break;
      default:
        break;
    }
  }

  onFilterChange(filter) {
    this.setState({ filter: filter }, () => {
      this.fetchCustomers();
    });
  }

  onPaginationChange(type, value) {
    switch (type) {
      case 'LIMIT':
        this.setState({ pagination: { ...this.state.pagination, skip: 0, limit: value } }, () => {
          this.fetchCustomers();
        });
        break;
      case 'NAVIGATE':
        this.setState({ pagination: value }, () => {
          this.fetchCustomers();
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { loading, columns, rows, searchText, pagination } = this.state;
    const { t, label } = this.props;

    return (
      <div className='content'>
        <SaveCustomerModal
          onRef={(ref) => {
            this.saveCustomerModal = ref;
          }}
        />
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'></div>
        </div>
        <div className='content-body'>
          <DefaultTable
            onRef={(ref) => (this.table = ref)}
            columns={columns}
            rows={rows}
            searchText={searchText}
            loading={loading}
            filter={['date']}
            pagination={pagination}
            actions={[
              {
                label: 'VIEW',
                title: t('VIEW'),
              },
            ]}
            onSearchChange={this.onSearchChange}
            onActionChange={this.onActionChange}
            onFilterChange={this.onFilterChange}
            onPaginationChange={this.onPaginationChange}
          ></DefaultTable>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(CustomerPage));
