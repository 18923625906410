import React from 'react';
import moment from 'moment';
import i18n from 'i18next';
import { withRouter } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { AuthService } from '../services';
import { DropDown, TextField } from '../components/fields';
import { LoadingButton } from '../components/buttons';
import { LocalStorage } from '../utils';
import Constants from '../Constants';
import logo from '../assets/logos/banner/logoxxxhdpi.png';

import '../styles/login.css';

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    const selectedUser = LocalStorage.getSelectedUser();
    const language = localStorage.getItem('language') || 'en';

    this.state = {
      loading: false,
      email: selectedUser ? selectedUser.email : '',
      password: null,
      invalidLogin: false,
      language: language,
    };

    this.login = this.login.bind(this);
    this.loginBtnClick = this.loginBtnClick.bind(this);
    this.signupBtnClick = this.signupBtnClick.bind(this);
    this.forgetBtnClick = this.forgetBtnClick.bind(this);
    this.onPasswordKeyDown = this.onPasswordKeyDown.bind(this);

    this.authService = new AuthService();
  }

  async login() {
    const { email, password } = this.state;
    const { history } = this.props;

    this.setState({
      loading: true,
      invalidLogin: false,
    });

    try {
      const result = await this.authService.login(email, password);

      if (result && result.success) {
        LocalStorage.addUser(result.me);

        history.push('/main');
      } else {
        this.setState({
          loading: false,
          invalidLogin: true,
        });
      }
    } catch (error) {
      this.setState({
        loading: false,
      });
    }
  }

  loginBtnClick() {
    this.login();
  }

  onPasswordKeyDown(e) {
    if (e.key === 'Enter') {
      this.login();
    }
  }

  signupBtnClick() {
    const { history } = this.props;

    history.push('/signup');
  }

  forgetBtnClick() {
    const { history } = this.props;

    history.push('/reset/password');
  }

  render() {
    const { loading, email, invalidLogin, language } = this.state;
    const { t } = this.props;
    const selectedLang = Constants.LANGUAGES.filter((lang) => lang.lang === language).map((obj) => obj.name)[0];

    return (
      <div className='main-form overflow-y'>
        <div className='login-form'>
          <div className='login-form-header'>
            <img src={logo} className='img-fluid' alt=''></img>
          </div>
          <div className='login-form-body'>
            <div className='rounded-form'>
              <p className='login-form-title'>{t('Log In')}</p>
              <p className='login-form-title-alt'>{t('Enter your email and password to login')}</p>

              <form className='login-form'>
                <Row>
                  <Col md={12}>
                    <TextField label={t('Working Email')} fullWidth value={email} error={invalidLogin} onChange={(e) => this.setState({ email: e.target.value })}></TextField>
                  </Col>
                  <Col md={12}>
                    <TextField
                      label={t('Password')}
                      fullWidth
                      type='password'
                      error={invalidLogin}
                      errorText={t('Invalid Email/Password')}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      onKeyDown={this.onPasswordKeyDown}
                      rightBtnLabel={t('FORGET')}
                      rightBtnClick={this.forgetBtnClick}
                    ></TextField>
                  </Col>
                </Row>
                <LoadingButton fullWidth ype={'button'} text={t('SIGN IN')} fontSize='1.1em' state={loading ? 'LOADING' : 'DONE'} onClick={this.loginBtnClick} />
                <br />
                <br />
                <p>
                  {t('Don’t have an account yet?')}{' '}
                  <span className='signup-btn' onClick={this.signupBtnClick}>
                    {t('Sign Up')}
                  </span>
                </p>
                <br />
                <br />
                <DropDown
                  bold={false}
                  dropdownStyle='login-lang-dropdown'
                  buttonStyle='login-lang-dropdown-btn'
                  label={selectedLang}
                  actions={Constants.LANGUAGES.map((lang) => {
                    return {
                      label: lang.name,
                      title: lang.name,
                      value: lang.lang,
                    };
                  })}
                  onChange={(data, selectedValue) => {
                    this.setState({ language: selectedValue.value }, () => {
                      localStorage.setItem('language', selectedValue.value);
                      i18n.changeLanguage(selectedValue.value);
                      moment.locale(selectedValue.value);
                    });
                  }}
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(LoginPage));
