import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '../services';
import { LoadingButton } from '../components/buttons';
import { LocalStorage } from '../utils';
import logo from '../assets/logos/banner/logoxxxhdpi.png';
import userlogo from '../assets/images/user.png';

import '../styles/switch.css';

class SwitchPage extends React.Component {
  constructor(props) {
    super(props);

    const users = LocalStorage.getUsers();

    this.state = {
      users: users,
      logoutloading: false,
    };

    this.onBackBtnClick = this.onBackBtnClick.bind(this);
    this.onSwitchBtnClick = this.onSwitchBtnClick.bind(this);
    this.onUserRemoveBtnClick = this.onUserRemoveBtnClick.bind(this);

    this.authService = new AuthService();
  }

  onBackBtnClick() {
    const { history } = this.props;

    history.push('/main');
  }

  async onSwitchBtnClick(user) {
    this.setState({ logoutloading: true });

    const { history } = this.props;
    const logout = this.authService.logout();

    try {
      if (user) {
        LocalStorage.setSelectedUser(user);
      } else {
        LocalStorage.clearSelectedUser();
      }

      this.setState({ logoutloading: false });
      if (logout) {
        history.push('/login');
      }
    } catch (error) {
      console.error(error);
      this.setState({ logoutloading: false });
    }
  }

  onUserRemoveBtnClick(user) {
    LocalStorage.removeUser(user);

    const users = LocalStorage.getUsers();

    this.setState({ users: users });
  }

  render() {
    const { users, logoutloading } = this.state;
    const { t } = this.props;

    const userKeys = Object.keys(users);

    return (
      <div className='main-form overflow-y'>
        <div className='switch-form'>
          <div className='switch-form-header'>
            <img src={logo} className='img-fluid' alt=''></img>
          </div>
          <div className='switch-form-body'>
            <div className='rounded-form'>
              <p className='switch-form-title'>{t('Switch Account')}</p>

              <div className='switch-form-round'>
                <p className='switch-form-round-title'>{t('Choose Account')}</p>
                {userKeys.length === 0 && (
                  <div className='switch-form-no-found'>
                    {t('No Accounts Found')}
                  </div>
                )}

                {userKeys &&
                  userKeys.map((key) => {
                    const user = users[key];

                    return (
                      <div key={key} className='switch-form-user'>
                        <div className='switch-form-user-photo'>
                          <img src={user.profile_photo && user.profile_photo.length > 0 ? user.profile_photo : userlogo} alt='' />
                        </div>
                        <div className='switch-form-user-body'>
                          <p className='switch-form-user-title'>{user.first_name + ' ' + user.last_name}</p>
                          <p className='switch-form-user-subtitle'>{user.email}</p>
                          <p
                            className='switch-form-user-remove'
                            onClick={() => {
                              this.onUserRemoveBtnClick(user);
                            }}
                          >
                            {t('Remove')}
                          </p>
                        </div>
                        <div className='switch-form-user-right'>
                          <button
                            className='switch-form-user-btn'
                            onClick={() => {
                              this.onSwitchBtnClick(user);
                            }}
                          >
                            {t('LOGIN')}
                          </button>
                        </div>
                      </div>
                    );
                  })}
              </div>
              <div className='switch-form-splitter'>
                <hr />
                <p className='switch-form-splitter-text'>{t('OR')}</p>
              </div>
              <div className='switch-form-buttons'>
                <LoadingButton
                  fullWidth
                  type={'button'}
                  text={t('LOG IN WITH YOUR EMAIL')}
                  fontSize='1.1em'
                  state={logoutloading ? 'LOADING' : 'DONE'}
                  onClick={() => {
                    this.onSwitchBtnClick();
                  }}
                  rightIcon={faEnvelope}
                />
                <br />
                <LoadingButton fullWidth type={'button'} text={t('BACK')} fontSize='1.1em' onClick={this.onBackBtnClick} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SwitchPage));
