import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';

const DropDown = ({ data, bull, label, placeholder, isTable, isAbsolute, noMaxHeight, up, actions, dropdownStyle, buttonStyle, hideArrow, bold, onChange, onOpen }) => {
  const node = useRef();

  const [open, setOpen] = useState(false);

  const show = () => {
    setOpen(!open);
    onOpen(!open);
  };

  const handleChange = (selectedValue) => {
    onChange(data, selectedValue);
    setOpen(false);
    onOpen(false);
  };

  useEffect(() => {
    const handleClick = (e) => {
      if (node.current.contains(e.target)) {
        return;
      }

      setOpen(false);
      onOpen(false);
    };

    document.addEventListener('mousedown', handleClick);

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [setOpen, onOpen]);

  return (
    <div className={`dropdown ${dropdownStyle || ''}`} ref={node}>
      {open && up && (
        <div className={`${isTable ? 'table-dropdown-content' : 'dropdown-content'} bottom-up ${isAbsolute ? 'table-dropdown-content-absolute' : ''}`}>
          {actions.map((action) => {
            return (
              <span key={action.label} className={`${action.red ? 'red' : ''}`} onClick={(e) => handleChange(action)}>
                {action.title}
              </span>
            );
          })}
        </div>
      )}
      {placeholder && placeholder.length > 0 && <label>{placeholder}</label>}
      {bull ? (
        <button type='button' className='dropdown-button action-btn' onClick={(e) => show()}>
          &bull;&bull;&bull;
        </button>
      ) : (
        <button type='button' className={`dropdown-button ${buttonStyle || ''}`} onClick={(e) => show()}>
          <span>{bold ? <b>{label}</b> : label}</span> {!hideArrow && <FontAwesomeIcon icon={faSortDown} />}
        </button>
      )}
      {open && !up && (
        <div className={`${isTable ? 'dropdown-content-table-wrapper' : 'dropdown-content-wrapper'} ${noMaxHeight ? 'dropdown-no-max-height' : ''}`}>
          <div className={`${isTable ? 'table-dropdown-content' : 'dropdown-content'} ${isAbsolute ? 'table-dropdown-content-absolute' : ''}`}>
            {actions.map((action) => {
              return (
                <span key={action.label} className={`${action.red ? 'red' : ''}`} onClick={(e) => handleChange(action)}>
                  {action.title}
                </span>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

DropDown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  bull: PropTypes.bool,
  isTable: PropTypes.bool,
  isAbsolute: PropTypes.bool,
  noMaxHeight: PropTypes.bool,
  up: PropTypes.bool,
  bold: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onOpen: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

DropDown.defaultProps = {
  placeholder: '',
  bull: false,
  isTable: false,
  isAbsolute: false,
  noMaxHeight: false,
  up: false,
  bold: true,
  onChange: Function.prototype,
  onOpen: Function.prototype,
};

export default DropDown;
