import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { VenueService } from '../../services';
import { EntityError } from '../../utils';
import { DefaultButton, LoadingButton, IconButton } from '../buttons';
import { TextField, Checkbox } from '../fields';
import { ModalLayout } from '../layouts';
import sumupIcon from '../../assets/images/sumup.png';

import '../../styles/modals/modal.css';
import '../../styles/modals/terminaldetailsvenue.css';

class TerminalDetailsVenueModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      unset: false,
      venue: null,
      provider: 'PAYMENTSENSE',
      username: '',
      password: '',
      accountName: '',
      invalidPost: {
        provider: false,
        username: false,
        password: false,
        account_name: false,
        invalid: false,
      },
    };

    this.show = this.show.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.setFields = this.setFields.bind(this);
    this.authSumup = this.authSumup.bind(this);
    this.onProviderChanged = this.onProviderChanged.bind(this);
    this.onSaveBtnClick = this.onSaveBtnClick.bind(this);

    this.venueService = new VenueService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      unset: false,
      venue: null,
      provider: 'PAYMENTSENSE',
      username: '',
      password: '',
      accountName: '',
      invalidPost: {
        provider: false,
        username: false,
        password: false,
        account_name: false,
        invalid: false,
      },
    });
  }

  setFields(venue) {
    this.setState({
      venue: venue,
      provider: venue.terminal_details && venue.terminal_details.provider ? venue.terminal_details.provider : null,
      username: venue.terminal_details && venue.terminal_details.username ? venue.terminal_details.username : '',
      password: venue.terminal_details && venue.terminal_details.password ? venue.terminal_details.password : '',
      accountName: venue.terminal_details && venue.terminal_details.account_name ? venue.terminal_details.account_name : '',
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  async authSumup(code) {
    const { venueId, onComplete } = this.props;
    const { invalidPost } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = await this.venueService.generateSumupToken(venueId, code);

    if (result && result.success) {
      onComplete(true);

      this.show(false);
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, Array.isArray(result.errors) ? result.errors[0].nestedErrors : result.errors),
      });
    } else if (result && result.ecode === 2040) {
      this.setState({
        invalidPost: {
          ...invalidPost,
          invalid: true,
        },
      });
    }

    this.setState({
      loading: false,
    });
  }

  onProviderChanged(checked, value) {
    if (checked) {
      this.setState({
        provider: value,
      });
    }
  }

  async onSaveBtnClick() {
    const { venueId, onComplete } = this.props;
    const { invalidPost, provider, username, password, accountName } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = await this.venueService.changeTerminalDetails(venueId, provider, username, password, accountName, provider ? false : true);

    if (result && result.success) {
      if (provider === 'SUMUP') {
        // SUMUP OAUTH 2.0
        window.open(result.url, 'SumUp', 'width=600,height=600');
        window.onmessage = async (e) => {
          const code = e.data && e.data.code ? e.data.code : null;
          if (code) {
            this.authSumup(code);
          } else {
            this.setState({
              invalidPost: {
                ...invalidPost,
                invalid: true,
              },
            });
          }
        };
      } else {
        onComplete(true);

        this.show(false);
      }
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, Array.isArray(result.errors) ? result.errors[0].nestedErrors : result.errors),
      });
    } else if (result && result.ecode === 2040) {
      this.setState({
        invalidPost: {
          ...invalidPost,
          invalid: true,
        },
      });
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const { show, loading, provider, username, password, accountName, invalidPost } = this.state;
    const { t, venue } = this.props;

    return (
      show && (
        <ModalLayout title={t('Terminal Provider Details')} subtitle={venue.name} width={640}>
          <Row>
            <Col md={12}>
              <p className='terminal-details-venue-title'>{t('Provider')}</p>
              <Checkbox text='PaymentSense' value={'PAYMENTSENSE'} checked={provider === 'PAYMENTSENSE' ? true : false} onChange={this.onProviderChanged} />
              <Checkbox text='SumUp' value={'SUMUP'} checked={provider === 'SUMUP' ? true : false} onChange={this.onProviderChanged} />
              <Checkbox text={t('None')} value={null} checked={provider === null ? true : false} onChange={this.onProviderChanged} />
            </Col>
            {provider === 'PAYMENTSENSE' && (
              <Col md={12}>
                <p className='terminal-details-venue-title'>{t('Credentials')}</p>
                <Row>
                  <Col md={12}>
                    <TextField
                      fullWidth
                      label={t('Account Name')}
                      error={invalidPost.account_name}
                      errorText={t('Account name is an invalid')}
                      onChange={(e) => this.setState({ accountName: e.target.value })}
                      value={accountName}
                    ></TextField>
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      label={t('Username')}
                      error={invalidPost.username}
                      errorText={t('Username is an invalid')}
                      onChange={(e) => this.setState({ username: e.target.value })}
                      value={username}
                    ></TextField>
                  </Col>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      label={t('Password')}
                      error={invalidPost.password}
                      errorText={t('Password is an invalid')}
                      onChange={(e) => this.setState({ password: e.target.value })}
                      value={password}
                      rightBtnLabel={<FontAwesomeIcon icon={faTimes} />}
                      rightBtnClick={() => {
                        this.setState({ password: '' });
                      }}
                      type='password'
                      style={{ paddingRight: '40px' }}
                    ></TextField>
                  </Col>
                </Row>
              </Col>
            )}
            {provider === 'SUMUP' && (
              <Col md={12}>
                <p className='terminal-details-venue-title'>{t('Credentials')}</p>
                <Row>
                  <Col md={12}>
                    <center>
                      <IconButton btnClass='terminal-details-sumup-btn' iconClass='terminal-details-sumup-btn-icon' icon={sumupIcon} text={t('Login with SumUp Account')} onClick={this.onSaveBtnClick} />
                    </center>
                  </Col>
                </Row>
              </Col>
            )}
          </Row>

          {invalidPost.invalid && (
            <span className='error-text'>
              {t('Invalid Credentials.')}
              <br />
              {t('Please check your username/password/access token parameters.')}
            </span>
          )}

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CLOSE')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={t('SAVE')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnClick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(TerminalDetailsVenueModal);
