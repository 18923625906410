import Converter from './Converter';

export default class Generator {
  static randomString(length = 7) {
    return Math.random().toString(36).substring(length);
  }

  static updateByNotation(obj, is, value) {
    if (typeof is === 'string') return Generator.updateByNotation(obj, is.split('.'), value);
    else if (is.length === 1 && value !== undefined) return (obj[is[0]] = value);
    else if (is.length === 0) return obj;
    else return Generator.updateByNotation(obj[is[0]], is.slice(1), value);
  }

  static firstValueOfArray(field, array) {
    if (!Array.isArray(array) || array.length === 0) {
      return 0;
    }

    const result = array.filter((data) => (field ? data[field] || null : data || null));

    return result.length > 0 ? result[0][field] || 0 : 0;
  }

  static lastValueOfArray(field, array) {
    if (!Array.isArray(array) || array.length === 0) {
      return 0;
    }

    const last = array.filter((data) => (field ? data[field] || null : data || null)).pop();

    return last ? last[field] : 0;
  }

  static sumOfFieldInArray(field, array) {
    if (!Array.isArray(array)) {
      return 0;
    }

    const sum = array.reduce((a, b) => {
      b = b[field] || 0;

      return a + b;
    }, 0);

    return Converter.fixedFloat(sum);
  }

  static averageOfFieldInArray(field, array) {
    if (!Array.isArray(array)) {
      return 0;
    }

    let counter = 0;

    const sum = array.reduce((a, b) => {
      b = b[field] || 0;

      if (b > 0) {
        counter++;
      }

      return a + b;
    }, 0);

    return Converter.fixedFloat(sum / (counter === 0 ? 1 : counter));
  }

  static getChangeByPercent(field, array) {
    if (!Array.isArray(array)) {
      return 0;
    }

    const firstValue = Generator.firstValueOfArray(field, array);
    const lastValue = Generator.lastValueOfArray(field, array);
    const diffValue = lastValue - firstValue;
    const orginalValue = firstValue <= 0 ? 1 : firstValue;
    const percent = diffValue >= 0 ? (diffValue / orginalValue) * 100 : (diffValue / orginalValue) * 100;

    return parseFloat(percent.toFixed(2));
  }

  static downloadFile(file, filename, mimetype) {
    const element = document.createElement('a');
    element.setAttribute('href', `data:${mimetype};charset=utf-8,` + encodeURIComponent(file));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }

  static getPlatform() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/windows phone/i.test(userAgent)) {
      return 'WINDOWS_PHONE';
    }
    if (/android/i.test(userAgent)) {
      return 'ANDROID';
    }
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'IOS';
    }

    return null;
  }
}
