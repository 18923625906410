import React from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import { Select } from '../components/fields';
import { DiscountCard } from '../components/cards';
import { SaveDiscountModal } from '../components/modals';
import { Confirm, Toast } from '../components/alerts';
import { DiscountService } from '../services';
import { LocalStorage } from '../utils';

import '../styles/discount.css';

class DiscountPage extends React.Component {
  constructor(props) {
    super(props);

    const filter = LocalStorage.getFilter();

    this.state = {
      loading: false,
      venue: filter && filter.venue ? filter.venue : null,
      discounts: [],
    };

    this.fetchDiscounts = this.fetchDiscounts.bind(this);
    this.addDiscountBtnClick = this.addDiscountBtnClick.bind(this);
    this.onVenueSelectChange = this.onVenueSelectChange.bind(this);
    this.onAddDiscountComplete = this.onAddDiscountComplete.bind(this);
    this.onDiscountActionChange = this.onDiscountActionChange.bind(this);

    this.discountService = new DiscountService();
  }

  componentDidMount() {
    this.fetchDiscounts();
  }

  async fetchDiscounts() {
    this.setState({ loading: true });

    const { venue } = this.state;
    const discountResult = await this.discountService.get({ venue_id: venue ? venue._id : undefined });

    if (discountResult && discountResult.success) {
      this.setState({
        discounts: discountResult.discounts,
        venue: discountResult.selected_venue,
        loading: false,
      });

      if (this.venueSelect) {
        this.venueSelect.setValue(discountResult.selected_venue.name);
      }
    } else if (discountResult && discountResult.ecode === 214) {
      this.setState({ discounts: [], loading: false });
    }
  }

  addDiscountBtnClick() {
    const { venue } = this.state;
    const { t } = this.props;

    if (venue) {
      this.saveDiscountModal.show(true, venue);
    } else {
      Toast.warning(t('Please select a venue from filter!'));
    }
  }

  onAddDiscountComplete() {
    this.fetchDiscounts();
  }

  onVenueSelectChange(venue) {
    this.setState({ venue: venue }, () => {
      this.fetchDiscounts();
    });
  }

  async onDiscountActionChange(data, action) {
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'EDIT':
        this.saveDiscountModal.show(true);
        this.saveDiscountModal.setFields(data);
        break;
      case 'DELETE':
        const confirm = await Confirm.open(t('Discount Deletion'), t('Are you sure to delete?'), t('Yes'), t('No'));
        if (confirm) {
          this.setState({ loading: true });
          result = await this.discountService.delete(data._id);
        }
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchDiscounts();
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, discounts } = this.state;
    const { t, label, me } = this.props;

    return (
      <div className='content'>
        <SaveDiscountModal onRef={(ref) => (this.saveDiscountModal = ref)} onComplete={this.onAddDiscountComplete} />
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
              <button className='content-title-button' onClick={this.addDiscountBtnClick}>
                <FontAwesomeIcon icon={faPlus} /> {t('ADD DISCOUNT')}
              </button>
            )}
          </div>
        </div>
        <div className='content-body'>
          <div className='content-header'>
            <Row>
              <Col md={12}>
                <p className='discount-container-title'>{t('Filters')}</p>
                <div className='discount-spacer' />
              </Col>
              <Col md={3}>
                <Select fullWidth type='venues' field='name' size='sm' label={t('Venue')} onChange={this.onVenueSelectChange} onRef={(ref) => (this.venueSelect = ref)}></Select>
              </Col>
            </Row>
          </div>

          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : (
            <Row>
              <Col md={12}>
                <div className='discount-container'>
                  <p className='discount-container-title'>{t('Discounts')}</p>
                  <div className='discount-spacer' />
                  <div className='discount-container-inline'>
                    {discounts && discounts.length > 0 ? (
                      discounts.map((discount, index) => {
                        return (
                          <DiscountCard
                            key={index}
                            discount={discount}
                            data={discount}
                            actions={[
                              { label: 'EDIT', title: t('EDIT') },
                              { label: 'DELETE', title: t('DELETE'), red: true },
                            ]}
                            onChange={this.onDiscountActionChange}
                          />
                        );
                      })
                    ) : (
                      <p className='discount-no-content'>{t('No Data Found')}</p>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(DiscountPage));
