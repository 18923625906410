import React from 'react';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus, faTimes, faEdit } from '@fortawesome/free-solid-svg-icons';
import { ModalLayout } from '../layouts';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, GatewayConditionField, DropDown } from '../fields';
import { Converter } from '../../utils';
import { Toast } from '../alerts';

import '../../styles/modals/modal.css';
import '../../styles/modals/gatewayfields.css';

const MAIN_TYPES = {
  ROOT: 'ROOT',
  ARRAY: 'ARRAY',
  OBJECT: 'OBJECT',
};

const VALUE_TYPES = ['string', 'number', 'boolean', 'dynamic', 'array', 'object'];

class GatewayFieldsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      update: false,
      updateKey: null,
      key: null,
      keyError: false,
      value: null,
      valueError: false,
      valueType: VALUE_TYPES[0],
      store: {},
      mainType: null,
    };

    this.show = this.show.bind(this);
    this.reset = this.reset.bind(this);
    this.formatValue = this.formatValue.bind(this);
    this.onAddKeyValueBtnClick = this.onAddKeyValueBtnClick.bind(this);
    this.onAddBtnClick = this.onAddBtnClick.bind(this);
    this.onGatewayFieldsModalComplete = this.onGatewayFieldsModalComplete.bind(this);
    this.onGatewayFieldsModalUpdate = this.onGatewayFieldsModalUpdate.bind(this);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  show(set = true, clear = false, mainType = null, data = null, update = false, updateKey = null) {
    if (clear) {
      this.reset(() => {
        this.setState({
          show: set,
          update: update,
          updateKey: updateKey,
          mainType: mainType === null ? MAIN_TYPES.ROOT : mainType,
          store: data ? data : {},
        });
      });
    } else {
      this.setState({
        show: set,
        update: update,
        updateKey: updateKey,
        mainType: mainType === null ? MAIN_TYPES.ROOT : mainType,
        store: data ? data : {},
      });
    }
  }

  reset(callback) {
    callback = callback ? callback : Function.prototype;

    this.setState(
      {
        update: false,
        updateKey: null,
        key: null,
        keyError: false,
        value: null,
        valueError: false,
        valueType: VALUE_TYPES[0],
        store: {},
        mainType: null,
      },
      () => {
        callback();
      }
    );
  }

  formatValue(value, updateKey) {
    const { t } = this.props;

    if (typeof value === 'object') {
      const isArray = Array.isArray(value);

      return (
        <button
          className='gateway-row-update-btn'
          onClick={() => {
            if (this.gatewayFieldsModal) {
              this.gatewayFieldsModal.show(true, true, isArray ? MAIN_TYPES.ARRAY : MAIN_TYPES.OBJECT, isArray ? value[0] : value, true, updateKey);
            }
          }}
        >
          <FontAwesomeIcon icon={faEdit} color='#11100d' /> {t('Edit')}
        </button>
      );
    } else {
      return value.toString();
    }
  }

  onAddKeyValueBtnClick() {
    const { key, value, valueType, store } = this.state;

    this.setState({
      keyError: false,
      valueError: false,
    });

    let finalValue = null;
    let valid = true;

    if (!key || key.length < 1) {
      valid = false;
      this.setState({
        keyError: true,
      });
    }
    if (!value || value.length < 1) {
      valid = false;
      this.setState({
        valueError: true,
      });
    }
    try {
      switch (valueType) {
        case 'string':
          finalValue = value.toString();
          break;
        case 'number':
          finalValue = parseFloat(value);
          finalValue = isNaN(finalValue) ? null : finalValue;
          break;
        case 'boolean':
          const lowerCasedValue = value.toString().toLowerCase();
          finalValue = lowerCasedValue === 'true' ? true : lowerCasedValue === 'false' ? false : null;
          break;
        case 'dynamic':
          finalValue = `{${value}}`;
          break;
        case 'array':
          finalValue = value;
          break;
        case 'object':
          finalValue = value;
          break;
        default:
          break;
      }
    } catch (error) {
      valid = false;
      this.setState({
        valueError: true,
      });
    }
    if (finalValue === null) {
      valid = false;
      this.setState({
        valueError: true,
      });
    }

    if (valid) {
      this.setState({
        key: null,
        value: null,
        store: {
          ...store,
          [key]: finalValue,
        },
      });
    }
  }

  onAddBtnClick() {
    const { update, store, mainType, updateKey } = this.state;
    const { onComplete, onUpdate } = this.props;

    if (mainType === MAIN_TYPES.ROOT) {
      onComplete(store);
    } else {
      const callback = update ? onUpdate : onComplete;
      callback(store, mainType, updateKey);
    }

    this.show(false);
  }

  onGatewayFieldsModalComplete(childStore, mainType) {
    if (mainType !== MAIN_TYPES.ROOT) {
      this.setState({
        value: mainType === MAIN_TYPES.ARRAY ? [childStore] : childStore,
      });
    }
  }

  onGatewayFieldsModalUpdate(childStore, mainType, updateKey) {
    const { store } = this.state;
    const { t } = this.props;

    try {
      this.setState({
        store: {
          ...store,
          [updateKey]: mainType === MAIN_TYPES.ARRAY ? [childStore] : childStore,
        },
      });
    } catch (error) {
      Toast.warning(t('Row could not be updated!'));
    }
  }

  render() {
    const { loading, show, update, key, keyError, value, valueError, valueType, store, mainType } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout title={mainType === MAIN_TYPES.ARRAY ? t('Array') : mainType === MAIN_TYPES.OBJECT ? t('Object') : t('Fields')} width={680}>
          <GatewayFieldsModal t={t} onRef={(ref) => (this.gatewayFieldsModal = ref)} onComplete={this.onGatewayFieldsModalComplete} onUpdate={this.onGatewayFieldsModalUpdate} />
          <div className='gateway-kv-container'>
            <div className='gateway-kv-col'>
              <TextField
                label={t('Key')}
                fullWidth
                value={key || ''}
                error={keyError}
                errorText={t('Key must be defined')}
                onChange={(e) =>
                  this.setState({
                    key: e.target.value,
                  })
                }
              ></TextField>
            </div>
            <div className='gateway-kv-col'>
              {(valueType === 'string' || valueType === 'number' || valueType === 'boolean') && (
                <TextField
                  label={t('Value')}
                  fullWidth
                  value={value || ''}
                  error={valueError}
                  errorText={t('Value is an invalid')}
                  onChange={(e) =>
                    this.setState({
                      value: e.target.value,
                    })
                  }
                  rightBtnLabel={<FontAwesomeIcon icon={faTimes} />}
                  rightBtnClick={() => {
                    this.setState({
                      value: null,
                    });
                  }}
                  style={{ paddingRight: '50px' }}
                ></TextField>
              )}
              {valueType === 'dynamic' && (
                <GatewayConditionField
                  label={t('Value')}
                  value={value || ''}
                  query={{ list: mainType === MAIN_TYPES.ARRAY ? true : false }}
                  error={valueError}
                  errorText={t('Value is an invalid')}
                  onChange={(value) => {
                    this.setState({ value });
                  }}
                  fullWidth
                />
              )}
              {valueType === 'array' && (
                <div className='gateway-kv-btn-container'>
                  <button
                    className='gateway-array-add-btn'
                    onClick={() => {
                      if (this.gatewayFieldsModal) {
                        this.gatewayFieldsModal.show(true, true, MAIN_TYPES.ARRAY, value);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} size='1x' color='#222b45'></FontAwesomeIcon> {value === null ? t('Add Array') : t('Update Array')}
                  </button>
                </div>
              )}
              {valueType === 'object' && (
                <div className='gateway-kv-btn-container'>
                  <button
                    className='gateway-array-add-btn'
                    onClick={() => {
                      if (this.gatewayFieldsModal) {
                        this.gatewayFieldsModal.show(true, true, MAIN_TYPES.OBJECT, value);
                      }
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} size='1x' color='#222b45'></FontAwesomeIcon> {value === null ? t('Add Object') : t('Update Object')}
                  </button>
                </div>
              )}
            </div>
            <div className='gateway-kv-col-dropdown'>
              <DropDown
                dropdownStyle='gateway-kv-type-style'
                buttonStyle='gateway-kv-type-dropdown'
                label={Converter.capitalize(valueType)}
                actions={VALUE_TYPES.map((x) => {
                  return { label: Converter.capitalize(x), title: Converter.capitalize(x), value: x };
                })}
                onChange={(data, selectedValue) => {
                  this.setState({
                    value: null,
                    valueType: selectedValue.value,
                  });
                }}
              />
            </div>
            <div className='gateway-kv-col-last'>
              <div className='gateway-kv-btn-container'>
                <button className='gateway-kv-add-btn' onClick={this.onAddKeyValueBtnClick}>
                  <FontAwesomeIcon icon={faPlus} size='1x' color='#222b45'></FontAwesomeIcon>
                </button>
              </div>
            </div>
          </div>

          {store &&
            Object.keys(store).map((key, index) => {
              const value = store[key];

              return (
                <div className='gateway-kv-container kv-pad-10' key={`rw_${index}`}>
                  <div className='gateway-kv-col col-row-list'>{key}</div>
                  <div className='gateway-kv-col col-row-list'>{this.formatValue(value, key)}</div>
                  <div className='gateway-kv-col col-row-last'>{Converter.capitalize(Array.isArray(value) ? 'array' : typeof value)}</div>
                  <div className='gateway-kv-col-row-last'>
                    <div className='gateway-kv-btn-container'>
                      <button
                        className='gateway-kv-remove-btn'
                        onClick={() => {
                          const newStore = { ...store };
                          delete newStore[key];
                          this.setState({
                            store: newStore,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} size='1x' color='#ff6961'></FontAwesomeIcon>
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton
                text={mainType === MAIN_TYPES.ROOT ? t('SAVE') : update ? t('UPDATE') : t('ADD')}
                state={loading ? 'LOADING' : 'DONE'}
                onClick={this.onAddBtnClick}
                fullWidth
              ></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(GatewayFieldsModal);
