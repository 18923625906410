import i18n from 'i18next';
import { EventEmitter } from 'events';
import { Toast } from '../components/alerts';
import { Converter, Generator } from '../utils';

export default class FetchService extends EventEmitter {
  Get(url, options) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    return fetch(`/v1${url}`, {
      headers,
      method: 'GET',
      credentials: 'same-origin',
      ...options,
    })
      .then(this.CheckStatus.bind(this))
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  GetBlob(url, options) {
    return fetch(`/v1${url}`, {
      method: 'GET',
      credentials: 'same-origin',
      ...options,
    })
      .then(this.CheckStatus.bind(this))
      .then((response) =>
        response.blob().then((blob) => {
          return { blob: blob, response: response };
        })
      )
      .then((data) => {
        const disposition = data.response.headers.get('content-disposition');
        const filename = Converter.getFileNameFromHeader(disposition);
        const blob = data.blob;
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');

        link.download = filename || Generator.randomString(10);
        link.href = url;
        document.body.appendChild(link);

        link.click();
        link.parentNode.removeChild(link);

        return true;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  Post(url, options, multipart = false) {
    const headers = {
      Accept: 'application/json',
    };

    if (!multipart) {
      headers['Content-Type'] = 'application/json';
    }

    return fetch(`/v1${url}`, {
      headers,
      method: 'POST',
      credentials: 'same-origin',
      ...options,
    })
      .then(this.CheckStatus.bind(this))
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  Put(url, options, multipart = false) {
    const headers = {
      Accept: 'application/json',
    };

    if (!multipart) {
      headers['Content-Type'] = 'application/json';
    }

    return fetch(`/v1${url}`, {
      headers,
      method: 'PUT',
      credentials: 'same-origin',
      ...options,
    })
      .then(this.CheckStatus.bind(this))
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  Delete(url, options) {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };

    return fetch(`/v1${url}`, {
      headers,
      method: 'DELETE',
      credentials: 'same-origin',
      ...options,
    })
      .then(this.CheckStatus.bind(this))
      .then((response) => {
        if (response) {
          return response.json();
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async CheckStatus(response) {
    if (response.status >= 200 && response.status < 300) {
      return response;
    } else if (response.status === 401) {
      // RELOAD PAGE EXCEPT LOGIN ERROR
      const result = await response.json();

      if (result && result.ecode !== 1000) {
        window.location.reload();
      }
    } else if (response.status === 403) {
      // FORBIDDEN USER
      Toast.warning(i18n.t('You have no permission to do this process!'));
    } else if (response.status === 404) {
      // NOT FOUND ERROR
      return response;
    } else if (response.status === 409) {
      // DUPLICATE ERROR
      return response;
    } else if (response.status === 422) {
      // ENTITY ERROR
      return response;
    } else {
      var error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
  }
}
