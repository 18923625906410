import QueryString from 'query-string';
import { FetchService } from '.';

export default class OrderService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.getById = this.getById.bind(this);
    this.refund = this.refund.bind(this);
    this.refundSumup = this.refundSumup.bind(this);
    this.getTerminalStatus = this.getTerminalStatus.bind(this);
    this.startRefundByTerminal = this.startRefundByTerminal.bind(this);
    this.countUncompletedOrders = this.countUncompletedOrders.bind(this);
    this.setUncompletedOrders = this.setUncompletedOrders.bind(this);
  }

  get(query) {
    return this.Get('/orders?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getById(order_id) {
    return this.Post('/orders/getbyid', { body: JSON.stringify({ order_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  refund(order_id, reason) {
    return this.Post('/orders/refund', { body: JSON.stringify({ order_id, reason }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  refundSumup(order_id, reason) {
    return this.Post('/orders/refund/sumup', { body: JSON.stringify({ order_id, reason }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getTerminalStatus(order_id, terminal_id, request_id) {
    return this.Post('/orders/terminal/status', { body: JSON.stringify({ order_id, terminal_id, request_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  startRefundByTerminal(order_id, terminal_id) {
    return this.Post('/orders/refund/terminal/start', { body: JSON.stringify({ order_id, terminal_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  countUncompletedOrders(venue_id) {
    return this.Post('/orders/count/uncompleted', { body: JSON.stringify({ venue_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  setUncompletedOrders(venue_id) {
    return this.Post('/orders/set/uncompleted', { body: JSON.stringify({ venue_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
