import { FetchService } from '.';

export default class RoleService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
  }

  get() {
    return this.Get('/roles', { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}