import React from 'react';
import Chart from 'chart.js';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DropDown } from '../fields';

import '../../styles/charts/line.css';

Chart.defaults.global.defaultFontColor = '#000';
Chart.defaults.global.defaultFontFamily = 'Avenir Next LT Pro';

class LineChart extends React.Component {
  constructor(props) {
    super(props);

    this.chart = React.createRef();
  }

  componentDidMount() {
    const { labels, datasets, renderXLabel, renderYLabel, renderTooltip } = this.props;
    const chart = this.chart.current.getContext('2d');

    new Chart(chart, {
      type: 'line',
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          callbacks: renderTooltip || {
            label: (tooltipItem) => {
              return tooltipItem.yLabel.toString();
            },
          },
        },
        scales: {
          xAxes: [
            {
              gridLines: {
                zeroLineColor: '#e3e6ee',
              },
              ticks: {
                autoSkip: true,
                maxTicksLimit: 10,
                callback: (value) => {
                  return renderYLabel ? renderYLabel(value) : value;
                },
              },
            },
          ],
          yAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontSize: 14,
                callback: (value) => {
                  return renderXLabel ? renderXLabel(value) : value;
                },
              },
            },
          ],
        },
        legend: {
          position: 'bottom',
          align: 'start',
          labels: {
            fontColor: '#000',
            fontSize: 14,
            fontStyle: '600',
            usePointStyle: true,
            padding: 20,
          },
        },
      },
    });
  }

  render() {
    const { t, title, onActionBtnClick } = this.props;

    return (
      <div className='chart-line-container'>
        <div className='chart-line-dropdown'>
          <DropDown
            bull={true}
            data={{ field: 'reports' }}
            actions={[
              { label: 'PDF', title: t('PDF'), type: 'pdf' },
              { label: 'CSV', title: t('CSV'), type: 'csv' },
            ]}
            onChange={onActionBtnClick}
          />
        </div>

        <p className='chart-line-title'>{title}</p>
        <div className='chart-line-wrapper'>
          <canvas ref={this.chart} />
        </div>
      </div>
    );
  }
}

LineChart.propTypes = {
  title: PropTypes.string,
  labels: PropTypes.arrayOf(PropTypes.string),
  datasets: PropTypes.arrayOf(PropTypes.object),
  renderXLabel: PropTypes.func,
  renderYLabel: PropTypes.func,
  onActionBtnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

LineChart.defaultProps = {
  title: '',
  renderXLabel: null,
  renderYLabel: null,
  onActionBtnClick: Function.prototype,
};

export default withTranslation()(LineChart);
