import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { SideBarButton } from '../buttons';
import { SaveEventModal } from '../modals';

import '../../styles/partials/sidebar.css';

class Sidebar extends React.Component {
  constructor(props) {
    super(props);

    this.onCreateEventBtnClick = this.onCreateEventBtnClick.bind(this);
    this.onSaveEventComplete = this.onSaveEventComplete.bind(this);
    this.onSideBarBtnClick = this.onSideBarBtnClick.bind(this);
  }

  onCreateEventBtnClick() {
    this.saveEventModal.show(true);
  }

  onSaveEventComplete() {
    const { history } = this.props;

    history.replace('/events');
    // TO REFRESH EVENT PAGE
    history.go(0);
  }

  onSideBarBtnClick(path) {
    const { onSideBarChange } = this.props;

    onSideBarChange(path);
  }

  render() {
    const { t, me, menus } = this.props;

    return (
      <div id='sidebar' className='side'>
        <SaveEventModal
          onRef={(ref) => {
            this.saveEventModal = ref;
          }}
          onComplete={this.onSaveEventComplete}
        />
        <div className='side-menu-alt'>
          {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
            <button onClick={this.onCreateEventBtnClick}>
              <FontAwesomeIcon icon={faCalendarPlus} size='lg' /> {t('CREATE EVENT')}
            </button>
          )}
        </div>
        <div className='side-menu'>
          {menus.map((menu) => {
            if (!menu.hidden && (menu.roles.includes(me.role) || me.is_superadmin)) {
              return (
                <SideBarButton
                  key={menu.label}
                  path={menu.path}
                  label={t(menu.label)}
                  faIcon={menu.faIcon}
                  plusBtn={menu.plusBtn ? true : false}
                  onClick={this.onSideBarBtnClick}
                ></SideBarButton>
              );
            } else {
              return null;
            }
          })}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(Sidebar));
