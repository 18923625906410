import React from 'react';
import moment from 'moment';
import i18n from 'i18next';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Toast } from '../components/alerts';
import { LoadingButton } from '../components/buttons';
import { DropDown, TextField } from '../components/fields';
import { AuthService, ProfileService } from '../services';
import { EntityError } from '../utils';
import Constants from '../Constants';
import userlogo from '../assets/images/user.png';

import '../styles/profile.css';

class ProfilePage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      saving: false,
      me: null,
      firstName: '',
      lastName: '',
      password: '',
      newPassword: '',
      confirmPassword: '',
      profilePhoto: '',
      language: 'en',
      invalidPost: {
        first_name: false,
        last_name: false,
        password: false,
        new_password: false,
        confirm_password: false,
        photo: false,
      },
    };

    this.profilePhotoFile = React.createRef();

    this.me = this.me.bind(this);
    this.onSaveGeneralBtnClick = this.onSaveGeneralBtnClick.bind(this);
    this.onSavePasswordBtnClick = this.onSavePasswordBtnClick.bind(this);
    this.onChangeProfilePhotoBtnClick = this.onChangeProfilePhotoBtnClick.bind(this);
    this.onProfilePhotoChange = this.onProfilePhotoChange.bind(this);
    this.onSaveLanguageBtnClick = this.onSaveLanguageBtnClick.bind(this);

    this.authService = new AuthService();
    this.profileService = new ProfileService();
  }

  async componentDidMount() {
    await this.me();
  }

  async me() {
    this.setState({ loading: true });

    const language = localStorage.getItem('language') || 'en';
    const result = await this.authService.me();

    if (result && result.success) {
      const me = result.me;

      this.setState({
        me: me,
        firstName: me.first_name,
        lastName: me.last_name,
        profilePhoto: me.profile_photo && me.profile_photo.length > 0 ? me.profile_photo + '?v=' + moment().unix() : null,
        loading: false,
        language: language,
      });
    }
  }

  async onSaveGeneralBtnClick() {
    const { firstName, lastName, invalidPost } = this.state;
    const { t } = this.props;

    this.setState({ saving: true, invalidPost: EntityError.setInputErrors(invalidPost, null, true) });

    const result = await this.profileService.updateGeneral(firstName, lastName);

    if (result && result.success) {
      Toast.success(t('Updated'));
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({ saving: false });
  }

  async onSavePasswordBtnClick() {
    const { password, newPassword, confirmPassword, invalidPost } = this.state;
    const { t } = this.props;

    this.setState({ saving: true, invalidPost: EntityError.setInputErrors(invalidPost, null, true) });

    const result = await this.profileService.updatePassword(password, newPassword, confirmPassword);

    if (result && result.success) {
      this.setState({ password: '', newPassword: '', confirmPassword: '' });

      Toast.success(t('Updated'));
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({ saving: false });
  }

  onChangeProfilePhotoBtnClick() {
    this.profilePhotoFile.current.click();
  }

  async onProfilePhotoChange() {
    const { invalidPost } = this.state;
    const { t } = this.props;

    this.setState({ saving: true, invalidPost: EntityError.setInputErrors(invalidPost, null, true) });

    const file =
      this.profilePhotoFile.current && this.profilePhotoFile.current.files && this.profilePhotoFile.current.files.length > 0 ? this.profilePhotoFile.current.files[0] : null;

    if (!file) return;

    const result = await this.profileService.updateProfilePhoto(file);

    if (result && result.success) {
      this.setState(
        {
          profilePhoto: result.photo + '?v=' + moment().unix(),
        },
        () => {
          Toast.success(t('Updated'));
        }
      );
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({ saving: false });
  }

  onSaveLanguageBtnClick() {
    const { language } = this.state;
    const { t } = this.props;

    localStorage.setItem('language', language);
    i18n.changeLanguage(language);
    moment.locale(language);

    Toast.success(t('Updated'));
  }

  render() {
    const { loading, saving, firstName, lastName, password, newPassword, confirmPassword, profilePhoto, language, invalidPost } = this.state;
    const { t, label } = this.props;
    const selectedLang = Constants.LANGUAGES.filter((lang) => lang.lang === language).map((obj) => obj.name)[0];

    return (
      <div className='content'>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'></div>
        </div>

        <div className='content-body'>
          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : (
            <div>
              <Row>
                <Col md={6} className='profile-card-col'>
                  <div className='profile-card-container'>
                    <p className='profile-card-title'>{t('General')}</p>
                    <Row>
                      <Col md={12}>
                        <TextField
                          label={t('First Name')}
                          fullWidth
                          value={firstName}
                          error={invalidPost.first_name}
                          errorText={t('First Name is required')}
                          onChange={(e) => this.setState({ firstName: e.target.value })}
                        ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          label={t('Last Name')}
                          fullWidth
                          value={lastName}
                          error={invalidPost.last_name}
                          errorText={t('Last Name is required')}
                          onChange={(e) => this.setState({ lastName: e.target.value })}
                        ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className='text-right'>
                        <LoadingButton text={t('Save')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onSaveGeneralBtnClick} />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6} className='profile-card-col'>
                  <div className='profile-card-container'>
                    <p className='profile-card-title'>{t('Password')}</p>
                    <Row>
                      <Col md={12}>
                        <TextField
                          label={t('Current Password')}
                          fullWidth
                          type='password'
                          value={password}
                          error={invalidPost.password}
                          errorText={t('Current password is an invalid')}
                          onChange={(e) => this.setState({ password: e.target.value })}
                        ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          label={t('New Password')}
                          fullWidth
                          type='password'
                          value={newPassword}
                          error={invalidPost.new_password}
                          errorText={t('Password must be more than 6 characters')}
                          onChange={(e) => this.setState({ newPassword: e.target.value })}
                        ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          label={t('Confirm New Password')}
                          fullWidth
                          type='password'
                          value={confirmPassword}
                          error={invalidPost.confirm_password}
                          errorText={t('Please confirm that password is matched')}
                          onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                        ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className='text-right'>
                        <LoadingButton text={t('Save')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onSavePasswordBtnClick} />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6} className='profile-card-col'>
                  <div className='profile-card-container'>
                    <p className='profile-card-title'>{t('Profile Photo')}</p>

                    <Row>
                      <Col md={12} className='text-center'>
                        <img className='profile-card-photo' src={profilePhoto && profilePhoto.length > 0 ? profilePhoto : userlogo} alt='' />
                      </Col>
                      <Col md={12} className='text-center'>
                        <input
                          id='profilePhotoFile'
                          type='file'
                          className='profile-uploader-file'
                          accept='image/x-png,image/jpeg,image/png'
                          onChange={this.onProfilePhotoChange}
                          ref={this.profilePhotoFile}
                        ></input>
                        <LoadingButton text={t('Change')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onChangeProfilePhotoBtnClick} />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6} className='profile-card-col'>
                  <div className='profile-card-container'>
                    <p className='profile-card-title'>{t('Language')}</p>

                    <Row>
                      <Col md={12}>
                        <DropDown
                          bold={false}
                          dropdownStyle='profile-card-lang-dropdown'
                          buttonStyle='profile-card-lang-dropdown-btn'
                          label={selectedLang}
                          actions={Constants.LANGUAGES.map((lang) => {
                            return {
                              label: lang.name,
                              title: lang.name,
                              value: lang.lang,
                            };
                          })}
                          onChange={(data, selectedValue) => {
                            this.setState({ language: selectedValue.value });
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className='text-right'>
                        <LoadingButton text={t('Save')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onSaveLanguageBtnClick} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ProfilePage));
