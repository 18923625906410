import React from 'react';
import moment from 'moment-timezone';
import ReactSwitch from 'react-switch';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { VenueService } from '../../services';
import { EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TimePickerField } from '../fields';
import { Converter } from '../../utils';
import { ModalLayout } from '../layouts';

import '../../styles/modals/modal.css';
import '../../styles/modals/saveopeninghours.css';

const DAYS = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];

class SaveOpeningHoursModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      openingHours: {},
      invalidPost: {
        venue_id: false,
        opening_hours: false,
      },
    };

    this.show = this.show.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.setFields = this.setFields.bind(this);
    this.setTime = this.setTime.bind(this);
    this.setClosed = this.setClosed.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);

    this.venueService = new VenueService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      openingHours: {},
      invalidPost: {
        venue_id: false,
        opening_hours: false,
      },
    });
  }

  setFields(venue) {
    this.setState({
      openingHours: { ...venue.opening_hours },
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  setTime(time, day, start = false) {
    const { openingHours } = this.state;

    openingHours[day][start ? 'start' : 'end'] = moment(time).toDate();

    this.setState({ openingHours: openingHours });
  }

  setClosed(day, closed = false) {
    const { openingHours } = this.state;

    openingHours[day].closed = closed;

    this.setState({ openingHours: openingHours });
  }

  async onSaveBtnCLick() {
    const { venueId, onComplete } = this.props;
    const { invalidPost, openingHours } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const timezoneOffset = moment().utcOffset();
    const formatedOpeningHours = Converter.openingHoursToTime(openingHours);
    const result = await this.venueService.changeOpeningHours(venueId, formatedOpeningHours, timezoneOffset);

    if (result && result.success) {
      this.show(false);

      onComplete(true);
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const { show, loading, openingHours } = this.state;
    const { t, venue } = this.props;

    return (
      show && (
        <ModalLayout title={t('Opening Hours')} subtitle={venue.name} width={640}>
          {DAYS.map((day) => {
            return (
              <Row key={day} className='opening-hours-area'>
                <Col md={2}>
                  <div className='opening-hours-row'>
                    <span>{Converter.capitalize(t(day))}</span>{' '}
                  </div>
                </Col>
                <Col md={4}>
                  <TimePickerField
                    value={openingHours[day].start ? moment(openingHours[day].start).toDate() : moment('2199-01-01 00:00').toDate()}
                    onChange={(time) => {
                      this.setTime(time, day, true);
                    }}
                  />
                </Col>
                <Col md={4}>
                  <TimePickerField
                    value={openingHours[day].end ? moment(openingHours[day].end).toDate() : moment('2199-01-01 23:59').toDate()}
                    onChange={(time) => {
                      this.setTime(time, day, false);
                    }}
                  />
                </Col>
                <Col md={2}>
                  <div className='opening-hours-column'>
                    <span className='opening-hours-alt-title'>{t('Closed')}</span>
                    <ReactSwitch
                      checked={openingHours[day].closed || false}
                      onChange={(checked) => {
                        this.setClosed(day, checked);
                      }}
                      onColor='#fec802'
                      onHandleColor='#ffffff'
                      handleDiameter={24}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      height={32}
                      width={52}
                    />
                  </div>
                </Col>
              </Row>
            );
          })}

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={t('SAVE')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnCLick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveOpeningHoursModal);
