import React from 'react';
import PropTypes from 'prop-types';

class IconButton extends React.Component {
  render() {
    const { fullWidth, align, fontSize, onClick, text, state, btnClass, icon, iconClass, ...rest } = this.props;

    return (
      <button {...rest} style={{ width: fullWidth ? '100%' : 'auto', fontSize: fontSize }} className={`icon-button ${btnClass || ''}`} onClick={onClick}>
        {icon && align === 'left' && <img src={icon} alt='Icon Button' className={iconClass}/>}
        <span style={{ marginLeft: '5px', marginRight: '5px' }}>{text}</span>
        {icon && align === 'right' && <img src={icon} alt='Icon Button' className={iconClass}/>}
      </button>
    );
  }
}

IconButton.propTypes = {
  text: PropTypes.string,
  align: PropTypes.oneOf(['left', 'right']),
  fullWidth: PropTypes.bool,
  fontSize: PropTypes.string,
};

IconButton.defaultProps = {
  align: 'left',
  fullWidth: false,
  fontSize: '1em',
};

export default IconButton;
