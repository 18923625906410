import React from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import { withRouter } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { DropDown } from '../fields';
import { ProductService } from '../../services';

import '../../styles/cards/venuereserve.css';

class VenueReserveCard extends React.Component {
  constructor(props) {
    super(props);

    this.onEnabledChanged = this.onEnabledChanged.bind(this);
    this.onBarEnabledChanged = this.onBarEnabledChanged.bind(this);

    this.productService = new ProductService();
  }

  async onEnabledChanged(checked) {
    const { product, onEnabledChange, onEnabledChangeError } = this.props;

    const result = await this.productService.changeEnabled(product.venue._id, product._id, checked);
    if (result && result.success) {
      onEnabledChange(checked, product._id);
    } else {
      onEnabledChangeError(result && result.errors && result.errors.toString());
    }
  }

  async onBarEnabledChanged(checked, barProduct) {
    const { product, onBarEnabledChange, onEnabledChangeError } = this.props;

    const result = await this.productService.updateBarEnabled(barProduct.bar._id, product._id, checked);
    if (result && result.success) {
      onBarEnabledChange(checked, product._id, barProduct.bar._id);
    } else {
      onEnabledChangeError(result && result.errors && result.errors.toString());
    }
  }

  render() {
    const { t, product, actions, onChange } = this.props;

    const barProducts =
      product &&
      product.barproducts &&
      product.barproducts.sort((a, b) => {
        return a.bar.name > b.bar.name ? 1 : -1;
      });

    return (
      <Col md={4}>
        <div className='venue-card-container'>
          <div className='venue-card-image'>
            <img src={product.photo} alt='' />
          </div>
          <div className='venue-card-body'>
            <div>
              <p className='venue-card-title'>{product.name}</p>
              <p className='venue-card-subtitle'>{product.category ? product.category.name : t('Unspecified')}</p>
              <p className='venue-card-subtitle'>{product.note || ''}</p>
              <ReactSwitch
                checked={product.enabled}
                onChange={this.onEnabledChanged}
                onColor='#fec802'
                onHandleColor='#ffffff'
                handleDiameter={16}
                uncheckedIcon={false}
                checkedIcon={false}
                height={24}
                width={46}
              />
            </div>
          </div>
          <div className='venue-card-right'>
            <DropDown bull={true} actions={actions} data={product} onChange={onChange} />
          </div>
          <div className='venue-card-list-container'>
            {barProducts.map((barProduct) => {
              return (
                <div key={barProduct._id} className='venue-card-list'>
                  <div className='venue-card-list-item-container'>
                    <div className='venue-card-list-item-body'>
                      <div>
                        <p className='venue-card-list-item-title'>{barProduct.bar.name}</p>
                      </div>
                      <ReactSwitch
                        checked={barProduct.enabled}
                        onChange={(checked) => {
                          this.onBarEnabledChanged(checked, barProduct);
                        }}
                        onColor='#fec802'
                        onHandleColor='#ffffff'
                        handleDiameter={16}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={24}
                        width={46}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Col>
    );
  }
}

VenueReserveCard.propTypes = {
  product: PropTypes.object,
  actions: PropTypes.array,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

VenueReserveCard.defaultProps = {
  actions: [],
  onChange: Function.prototype,
};

export default withTranslation()(withRouter(VenueReserveCard));
