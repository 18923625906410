import i18n from 'i18next';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { Parser } from 'json2csv';
import { Generator } from '../utils';
import Converter from './Converter';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const calculateProductTotals = (rows) => {
  const totalQty = rows.reduce((a, b) => {
    return a + b.total_qty;
  }, 0);
  const discountTotal = rows.reduce((a, b) => {
    return a + b.discount_total;
  }, 0);
  const totalAmount = rows.reduce((a, b) => {
    return a + b.total_amount;
  }, 0);

  return {
    totalQty,
    discountTotal,
    totalAmount,
  };
};

const salesAsCSV = (
  data,
  filename,
  paymentProvider,
  transferSettings,
  totalServiceCharge = 0,
  grossIncome = 0,
  totalOrders = 0,
  paymentFeeTotal = 0,
  transferTotal = 0,
  netIncome = 0,
  tipTotal = 0,
  currencySymbol = ''
) => {
  const json2csvParser = new Parser({
    fields: [
      { label: i18n.t('Date'), value: '_id' },
      { label: i18n.t('Total') + ' ' + currencySymbol, value: 'total' },
      { label: i18n.t('Avg. Waiting Time (sec)'), value: 'average_waiting' },
      { label: i18n.t('Total Orders'), value: 'total_orders' },
      { label: i18n.t('Total Drinks'), value: 'total_qty' },
    ],
  });

  const csv = json2csvParser.parse(data);

  Generator.downloadFile(csv, filename, 'text/csv');
};

const barsAsCSV = (
  data,
  filename,
  paymentProvider,
  transferSettings,
  totalServiceCharge = 0,
  grossIncome = 0,
  totalOrders = 0,
  paymentFeeTotal = 0,
  transferTotal = 0,
  netIncome = 0,
  tipTotal = 0,
  currencySymbol = ''
) => {
  const json2csvParser = new Parser({
    fields: [
      { label: i18n.t('Teams'), value: 'bar.name' },
      { label: i18n.t('Amount') + ' ' + currencySymbol, value: 'total' },
      { label: i18n.t('Order served'), value: 'total_orders' },
      { label: i18n.t('Drinks made'), value: 'total_qty' },
      { label: i18n.t('Members'), value: 'bar.member_count' },
      { label: i18n.t('Avg. Waiting Time (sec)'), value: 'average_waiting' },
    ],
  });

  const csv = json2csvParser.parse(data);

  Generator.downloadFile(csv, filename, 'text/csv');
};

const productsAsCSV = (
  data,
  filename,
  paymentProvider,
  transferSettings,
  totalServiceCharge = 0,
  grossIncome = 0,
  totalOrders = 0,
  paymentFeeTotal = 0,
  transferTotal = 0,
  netIncome = 0,
  tipTotal = 0,
  currencySymbol = ''
) => {
  const rows = [...data];
  const { totalQty, discountTotal, totalAmount } = calculateProductTotals(rows);

  let totals = {};
  totals.title = i18n.t('Totals');
  totals.total_qty = totalQty;
  totals.discount_total = discountTotal;
  totals.total_amount = totalAmount;

  let tips = {};
  tips.title = `${i18n.t('Tips')}`;
  tips['product.name'] = Converter.fixedFloat(tipTotal);

  let serviceFee = {};
  serviceFee.title = `${i18n.t('Service Charge')}`;
  serviceFee['product.name'] = Converter.fixedFloat(totalServiceCharge);

  let subtotal = {};
  subtotal.title = `${i18n.t('Gross Income')}`;
  subtotal['product.name'] = Converter.fixedFloat(grossIncome);

  let third = {};
  third.title = `${i18n.t('Third Party Payment Fees')} (${paymentProvider.rate}% + ${paymentProvider.fixed_rate} ${currencySymbol})`;
  third['product.name'] = Converter.fixedFloat(paymentFeeTotal);

  let fees = {};
  fees.title = `${i18n.t('DrinKing Fee')} (${transferSettings.rate}% + ${transferSettings.fixed_rate} ${currencySymbol})`;
  fees['product.name'] = Converter.fixedFloat(transferTotal);

  let revenue = {};
  revenue.title = i18n.t('Total Revenue');
  revenue['product.name'] = Converter.fixedFloat(netIncome);

  rows.push(totals);
  rows.push(tips);
  rows.push(serviceFee);
  rows.push(subtotal);
  rows.push(third);
  rows.push(fees);
  rows.push(revenue);

  const json2csvParser = new Parser({
    fields: [
      { label: ' ', value: 'title' },
      { label: i18n.t('Brand'), value: 'product.name' },
      { label: i18n.t('Items Sold'), value: 'total_qty' },
      { label: `${i18n.t('Discount')} ${currencySymbol}`, value: 'discount_total' },
      { label: `${i18n.t('Amount')} ${currencySymbol}`, value: 'total_amount' },
    ],
  });

  const csv = json2csvParser.parse(rows);

  Generator.downloadFile(csv, filename, 'text/csv');
};

const salesAsPDF = (
  sales,
  filename,
  paymentProvider,
  transferSettings,
  totalServiceCharge = 0,
  grossIncome = 0,
  totalOrders = 0,
  paymentFeeTotal = 0,
  transferTotal = 0,
  netIncome = 0,
  tipTotal = 0,
  currencySymbol = ''
) => {
  const rows = sales.map((sale) => {
    return [sale._id, sale.total, sale.average_waiting.toFixed(0), sale.total_orders, sale.total_qty];
  });

  pdfMake
    .createPdf({
      content: [
        {
          text: i18n.t('Sales performance'),
          style: 'header',
        },
        {
          table: {
            widths: ['*', '*', '*', '*', '*'],
            body: [
              [
                { text: i18n.t('Date'), style: { bold: true } },
                { text: i18n.t('Total') + ' ' + currencySymbol, style: { bold: true } },
                { text: i18n.t('Avg. Waiting Time (sec)'), style: { bold: true } },
                { text: i18n.t('Total Orders'), style: { bold: true } },
                { text: i18n.t('Total Drinks'), style: { bold: true } },
              ],
              ...rows,
            ],
          },
          style: 'table',
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        table: {
          margin: [0, 5, 0, 15],
        },
      },
    })
    .download(filename);
};

const barsAsPDF = (
  bars,
  filename,
  paymentProvider,
  transferSettings,
  totalServiceCharge = 0,
  grossIncome = 0,
  totalOrders = 0,
  paymentFeeTotal = 0,
  transferTotal = 0,
  netIncome = 0,
  tipTotal = 0,
  currencySymbol = ''
) => {
  const rows = bars.map((bar) => {
    return [bar.bar.name, bar.total, bar.total_orders, bar.total_qty, bar.bar.member_count, bar.average_waiting];
  });

  pdfMake
    .createPdf({
      content: [
        {
          text: i18n.t('Top Performing Bars'),
          style: 'header',
        },
        {
          table: {
            widths: ['*', '*', '*', '*', '*', '*'],
            body: [
              [
                { text: i18n.t('Teams'), style: { bold: true } },
                { text: i18n.t('Amount') + ' ' + currencySymbol, style: { bold: true } },
                { text: i18n.t('Order served'), style: { bold: true } },
                { text: i18n.t('Drinks made'), style: { bold: true } },
                { text: i18n.t('Members'), style: { bold: true } },
                { text: i18n.t('Avg. Waiting Time (sec)'), style: { bold: true } },
              ],
              ...rows,
            ],
          },
          style: 'table',
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        table: {
          margin: [0, 5, 0, 15],
        },
      },
    })
    .download(filename);
};

const productsAsPDF = (
  products,
  filename,
  paymentProvider,
  transferSettings,
  totalServiceCharge = 0,
  grossIncome = 0,
  totalOrders = 0,
  paymentFeeTotal = 0,
  transferTotal = 0,
  netIncome = 0,
  tipTotal = 0,
  currencySymbol = ''
) => {
  const rows = [...products];
  const { totalQty, discountTotal, totalAmount } = calculateProductTotals(rows);

  const productRows = products.map((product) => {
    return [
      product.product.name,
      product.total_qty.toFixed(0),
      { text: product.discount_total.toFixed(2), alignment: 'right' },
      { text: product.total_amount.toFixed(2), alignment: 'right' },
    ];
  });
  productRows.push([
    { text: i18n.t('Totals'), alignment: 'left', bold: true },
    { text: totalQty, alignment: 'left', bold: true },
    { text: discountTotal.toFixed(2), alignment: 'right', bold: true },
    { text: totalAmount.toFixed(2), alignment: 'right', bold: true },
  ]);

  pdfMake
    .createPdf({
      content: [
        {
          text: i18n.t('Total Sales'),
          style: 'header',
        },
        {
          table: {
            widths: ['*', '*', '*', '*', '*', '*'],
            body: [
              [
                { text: `${i18n.t('Tips')} ${currencySymbol}`, style: { bold: true } },
                { text: `${i18n.t('Service Charge')} ${currencySymbol}`, style: { bold: true } },
                { text: `${i18n.t('Gross Income')} ${currencySymbol}`, style: { bold: true } },
                { text: `${i18n.t('DrinKing Fee')} ${currencySymbol}`, style: { bold: true } },
                { text: `${i18n.t('Third Party Payment Fees')} ${paymentProvider.rate}% + ${paymentProvider.fixed_rate} ${currencySymbol}`, style: { bold: true } },
                { text: `${i18n.t('Total Revenue')} ${currencySymbol}`, style: { bold: true } },
              ],
              ...[
                [
                  { text: tipTotal.toFixed(2), alignment: 'right' },
                  { text: totalServiceCharge.toFixed(2), alignment: 'right' },
                  { text: grossIncome.toFixed(2), alignment: 'right' },
                  { text: transferTotal.toFixed(2), alignment: 'right' },
                  { text: paymentFeeTotal.toFixed(2), alignment: 'right' },
                  { text: netIncome.toFixed(2), alignment: 'right', style: { bold: true } },
                ],
              ],
            ],
          },
          style: 'table',
        },
        {
          table: {
            widths: ['*', '*', '*', '*'],
            body: [
              [
                { text: i18n.t('Brand'), style: { bold: true } },
                { text: i18n.t('Items Sold'), style: { bold: true } },
                { text: i18n.t('Discount') + ' ' + currencySymbol, style: { bold: true } },
                { text: i18n.t('Amount') + ' ' + currencySymbol, style: { bold: true } },
              ],
              ...productRows,
            ],
          },
          style: 'table',
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
        table: {
          margin: [0, 5, 0, 15],
        },
      },
    })
    .download(filename);
};

export default {
  csv: {
    reports: salesAsCSV,
    bars: barsAsCSV,
    products: productsAsCSV,
  },
  pdf: {
    reports: salesAsPDF,
    bars: barsAsPDF,
    products: productsAsPDF,
  },
};
