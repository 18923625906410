import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Toast } from '../alerts';
import { LoadingButton } from '../buttons';
import { Checkbox } from '../fields';
import { RoleService, EmployeeService } from '../../services';

import '../../styles/cards/role.css';

class RoleCard extends React.Component {
  constructor(props) {
    super(props);

    const { employee } = this.props;

    this.state = {
      loading: false,
      saving: false,
      roles: [],
      selectedRole: employee.role,
      employee: employee,
    };

    this.onRoleChange = this.onRoleChange.bind(this);
    this.onSaveBtnClick = this.onSaveBtnClick.bind(this);

    this.roleService = new RoleService();
    this.employeeService = new EmployeeService();
  }

  async componentDidMount() {
    const result = await this.roleService.get();

    if (result && result.success) {
      this.setState({
        roles: result.roles,
      });
    }
  }

  onRoleChange(checked, role) {
    if (checked) {
      this.setState({ selectedRole: role });
    }
  }

  async onSaveBtnClick() {
    const { employee, selectedRole } = this.state;
    const { t, history } = this.props;

    this.setState({ saving: true });

    const result = await this.employeeService.updateRole(employee.venue._id, employee.user._id, selectedRole);

    if (result && result.success) {
      const employeeResult = await this.employeeService.getbyid(employee.venue._id, employee.user._id);

      if (employeeResult && employeeResult.success) {
        // UPDATE HISTORY
        history.replace({
          pathname: '/employees/detail',
          state: { employee: employeeResult.employee },
        });

        Toast.success(t('Updated'));
      }
    }

    this.setState({ saving: false });
  }

  render() {
    const { loading, saving, roles, selectedRole } = this.state;
    const { t } = this.props;

    return (
      <div className='rolecard-container'>
        {loading ? (
          <div className='margin-auto'>
            <ReactLoading type='spin' color='#000' height='24px' width='24px' />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={12}>
                {roles.map((role) => {
                  return (
                    <div key={role._id} className='rolecard-list'>
                      <div className='rolecard-checkbox'>
                        <Checkbox text={t(role.name)} value={role.id} checked={selectedRole === role.id ? true : false} onChange={this.onRoleChange} />
                      </div>
                      <span className='rolecard-description'>{t(role.description)}</span>
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row>
              <Col md={12} className='text-right'>
                <LoadingButton text={t('Save')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnClick} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

RoleCard.propTypes = {
  employee: PropTypes.object,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onSaveBtnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

RoleCard.defaultProps = {
  onChange: Function.prototype,
  onSaveBtnClick: Function.prototype,
};

export default withTranslation()(withRouter(RoleCard));
