import React from 'react';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { ModalLayout } from '../layouts';
import { DefaultButton, LoadingButton } from '../buttons';
import { OrderService } from '../../services';
import { TextField, Select } from '../fields';
import { Toast } from '../alerts';
import { Converter, EntityError } from '../../utils';
import { RefundStatusCard } from '../cards';
import Constants from '../../Constants';
import beerIcon from '../../assets/images/beer.png';

import '../../styles/modals/modal.css';
import '../../styles/modals/refundorder.css';

class RefundOrderModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      order: null,
      currency: 'GBP',
      paymentCard: null,
      reason: '',
      terminal: null,
      showTerminalCard: false,
      terminalProvider: null,
      invalidPost: {
        terminal_id: false,
      },
    };

    this.show = this.show.bind(this);
    this.fetchOrder = this.fetchOrder.bind(this);
    this.processRefund = this.processRefund.bind(this);
    this.paymentSenseRefund = this.paymentSenseRefund.bind(this);
    this.sumupRefund = this.sumupRefund.bind(this);
    this.onRefundBtnClick = this.onRefundBtnClick.bind(this);
    this.onRefundCompleted = this.onRefundCompleted.bind(this);

    this.orderService = new OrderService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  show(set = true, order = null, currency = 'GBP', terminalProvider = null) {
    this.setState(
      {
        show: set,
        order: order,
        currency: currency,
        terminal: null,
        showTerminalCard: false,
        terminalProvider: terminalProvider,
      },
      async () => {
        if (set) {
          await this.fetchOrder();
        }
      }
    );
  }

  async fetchOrder() {
    const { order } = this.state;
    const { t } = this.props;

    this.setState({ loading: true });

    const result = await this.orderService.getById(order._id);

    if (result && result.success) {
      this.setState({ paymentCard: result.payment_card });
    } else {
      Toast.warning(t('Order could not fetch. Please try it again later!'));
    }

    this.setState({ loading: false });
  }

  async processRefund() {
    const { order, reason, invalidPost } = this.state;
    const { t } = this.props;

    this.setState({ loading: true, invalidPost: EntityError.setInputErrors(invalidPost, null, true) });

    const result = await this.orderService.refund(order._id, reason && reason.length > 0 ? reason : null);

    if (result && result.success) {
      this.onRefundCompleted();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    } else if (result && result.ecode === 8130) {
      Toast.warning(t('Payment might have already been refunded or not completed'));
    } else {
      Toast.error(t("Refund could not be done. Please check refund's state from payment provider or try it again later!"));
    }

    this.setState({ loading: false });
  }

  async paymentSenseRefund() {
    const { order, terminal, reason, invalidPost } = this.state;

    if (!terminal) {
      this.setState({ invalidPost: { ...invalidPost, terminal_id: true } });
    } else {
      this.setState({ showTerminalCard: true, invalidPost: EntityError.setInputErrors(invalidPost, null, true) }, async () => {
        await this.refundStatusCard.start(terminal, order, reason && reason.length > 0 ? reason : null);
      });
    }
  }

  async sumupRefund() {
    const { order, reason, invalidPost } = this.state;
    const { t } = this.props;

    this.setState({ loading: true, invalidPost: EntityError.setInputErrors(invalidPost, null, true) });

    const result = await this.orderService.refundSumup(order._id, reason && reason.length > 0 ? reason : null);

    if (result && result.success) {
      this.onRefundCompleted();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    } else if (result && result.ecode === 8130) {
      Toast.warning(t('Payment might have already been refunded or not completed'));
    } else {
      Toast.error(t("Refund could not be done. Please check refund's state from payment provider or try it again later!"));
    }

    this.setState({ loading: false });
  }

  async onRefundBtnClick() {
    const { order, terminalProvider } = this.state;
    const issuedByBartender = order.status !== 'REFUNDED' && !order.customer && order.payment_method === Constants.PAYMENT_METHODS.CARD;

    if (issuedByBartender) {
      if (terminalProvider === Constants.TERMINAL_PROVIDERS.PAYMENTSENSE) {
        await this.paymentSenseRefund();
      } else if (terminalProvider === Constants.TERMINAL_PROVIDERS.SUMUP) {
        await this.sumupRefund();
      }
    } else {
      await this.processRefund();
    }
  }

  onRefundCompleted() {
    const { t, onComplete } = this.props;

    Toast.info(t('The refund has been completed'));

    this.show(false);
    onComplete(true);
  }

  render() {
    const { loading, show, order, currency, paymentCard, reason, showTerminalCard, terminalProvider, invalidPost } = this.state;
    const { t } = this.props;
    const serviceFee = order && order.service_fee ? order.service_fee.total : 0;

    return (
      show && (
        <ModalLayout width={530}>
          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : showTerminalCard ? (
            <RefundStatusCard
              onRef={(ref) => (this.refundStatusCard = ref)}
              onClose={() => {
                this.setState({ showTerminalCard: false });
              }}
              onComplete={(success) => this.onRefundCompleted(success)}
            />
          ) : (
            <>
              <span className='refund-order-card-header-top'>{t('Refund Order')}</span>
              <Row>
                <Col md={12}>
                  <p>
                    <span className='refund-order-card-span'>{t('Order ID')}</span>
                    {`#${order.id}`}
                  </p>
                  <p>
                    <span className='refund-order-card-span'>{t('Order Status')}</span>
                    <b>{`${Converter.capitalize(order.status)}`}</b>
                  </p>
                  {order.status === 'REFUNDED' && order.refund && (
                    <p>
                      <span className='refund-order-card-span'>{t('Reason')}</span>
                      {`${order.refund.reason}`}
                    </p>
                  )}
                  <p>
                    <span className='refund-order-card-span'>{t('Customer')}</span>
                    {order.customer ? `${order.customer.first_name} ${order.customer.last_name}` : 'ISSUED BY BARTENDER'}
                  </p>
                  <p>
                    <span className='refund-order-card-span'>{t('Email')}</span>
                    {order.customer ? `${order.customer.email}` : '-'}
                  </p>
                  <p>
                    <span className='refund-order-card-span'>{t('Payment Method')}</span>
                    {order.payment_method || t('Unknown')}
                  </p>
                  {order.transaction_id && (
                    <p>
                      <span className='refund-order-card-span'>{t('Transaction Id')}</span>
                      {order.transaction_id}
                    </p>
                  )}
                  {paymentCard && (
                    <p>
                      <span className='refund-order-card-span'>{t('Card Details')}</span>
                      {`${Converter.capitalize(paymentCard.card_brand)} ${Converter.capitalize(paymentCard.card_type)} ****${paymentCard.last_4}`}
                    </p>
                  )}
                  {order.refund && order.refund.date && (
                    <p>
                      <span className='refund-order-card-span'>{t('Refund Date')}</span>
                      {moment(order.refund.date).format('YYYY-MM-DD HH:mm')}
                    </p>
                  )}
                </Col>
                {order.status !== 'REFUNDED' && !order.customer && terminalProvider === Constants.TERMINAL_PROVIDERS.PAYMENTSENSE && (
                  <Col md={12}>
                    <Select
                      fullWidth
                      type='terminals'
                      field='tid'
                      size='sm'
                      method={'getTerminals'}
                      label={t('Terminal')}
                      query={{ venue_id: order.venue._id, currency: order.venue.currency }}
                      onChange={(terminal) => this.setState({ terminal })}
                      error={invalidPost.terminal_id}
                      errorText={'Please select a terminal'}
                    ></Select>
                  </Col>
                )}
                {order.status !== 'REFUNDED' && (
                  <Col md={12}>
                    <TextField fullWidth label={t('Reason')} value={reason} onChange={(e) => this.setState({ reason: e.target.value })}></TextField>
                  </Col>
                )}
                <Col md={12}>
                  {order &&
                    order.list &&
                    order.list.map((item) => {
                      return (
                        <div key={item._id} className='refund-order-card-container'>
                          <div className='refund-order-card-image'>
                            <img src={item.product.photo || beerIcon} alt='' />
                          </div>
                          <div className='refund-order-card-body'>
                            <p className='refund-order-card-title'>
                              {item.qty}x {item.product.name} <span className='refund-order-card-title-span'>{'(' + item.name + ')'}</span>
                            </p>
                            <p className='refund-order-card-subtitle'>
                              <span className='refund-order-card-subtitle-alt'>{item.product.note}</span>
                            </p>
                            {item.discount_id && (
                              <p className='refund-order-card-subtitle'>
                                <span className='refund-order-card-subtitle-alt'>{item.discount_name}</span>
                              </p>
                            )}
                          </div>
                          <div className='refund-order-card-right'>
                            <span>
                              {Constants.CURRENCIES[currency].symbol} {item.total_price.toFixed(2)}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                </Col>
                <Col md={12}>
                  <span className='refund-order-grand-total'>
                    <span className='refund-order-span'>{t('Subtotal')}</span>
                    {Constants.CURRENCIES[currency].symbol} {Converter.fixedFloat(order.list.reduce((a, b) => a + b.total_price || 0, 0)).toFixed(2)}
                  </span>
                </Col>
                <Col md={12}>
                  <span className='refund-order-discount-total'>
                    <span className='refund-order-span'>{t('Discount')}</span>
                    {Constants.CURRENCIES[currency].symbol} {order.discount_total && order.discount_total > 0 ? order.discount_total.toFixed(2) : '0.00'}
                  </span>
                </Col>
                <Col md={12}>
                  <span className='refund-order-total'>
                    <span className='refund-order-span'>
                      {t('Service Charge')} ({order.service_fee ? Converter.fixedFloat(order.service_fee.rate).toFixed(2) : '0.00'}%)
                    </span>
                    {Constants.CURRENCIES[currency].symbol} {Converter.fixedFloat(serviceFee).toFixed(2)}
                  </span>
                </Col>
                {order.tip && (
                  <Col md={12}>
                    <span className='refund-order-total'>
                      <span className='refund-order-span'>{t('Tip')}</span>
                      {Constants.CURRENCIES[currency].symbol} {order.tip ? Converter.fixedFloat(order.tip.amount).toFixed(2) : '0.00'}
                    </span>
                  </Col>
                )}
                <Col md={12}>
                  <span className='refund-order-grand-total'>
                    <span className='refund-order-span'>{t('Total')}</span>
                    {Constants.CURRENCIES[currency].symbol} {order.grand_total.toFixed(2)}
                  </span>
                </Col>
              </Row>
              <div className='modal-buttons-container'>
                <div className={order.status === 'REFUNDED' ? 'modal-button-full-width' : 'modal-button'}>
                  {loading || <DefaultButton text={t('CLOSE')} onClick={() => this.show(false)} fullWidth></DefaultButton>}
                </div>
                {order.status !== 'REFUNDED' && (
                  <div className='modal-button'>
                    <LoadingButton text={t('REFUND')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onRefundBtnClick} fullWidth></LoadingButton>
                  </div>
                )}
              </div>
            </>
          )}
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(RefundOrderModal);
