import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { ProductService } from '../../services';
import { EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { AddOptionField, TextField, Select, Uploader, Checkbox, AddOptionSecondField } from '../fields';
import { ModalLayout } from '../layouts';
import { Toast } from '../alerts';

import '../../styles/modals/modal.css';
import '../../styles/modals/saveproduct.css';

class SaveProductModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      venue: null,
      productId: null,
      name: '',
      note: '',
      photo: null,
      prices: [],
      options: [],
      isExclusive: false,
      isFood: false,
      category: null,
      externalReference: null,
      invalidPost: {
        venue_id: false,
        productId: false,
        name: false,
        note: false,
        photo: false,
        prices: false,
        category: false,
        external_reference: false,
        product_category_id: false,
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);
    this.onProductPhotoChange = this.onProductPhotoChange.bind(this);
    this.onOptionAdded = this.onOptionAdded.bind(this);
    this.onOptionRemoved = this.onOptionRemoved.bind(this);
    this.onOptionSecondAdded = this.onOptionSecondAdded.bind(this);
    this.onOptionSecondRemoved = this.onOptionSecondRemoved.bind(this);
    this.onExclusiveChange = this.onExclusiveChange.bind(this);
    this.onIsFoodChange = this.onIsFoodChange.bind(this);
    this.onProductCategoryChange = this.onProductCategoryChange.bind(this);

    this.productService = new ProductService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      venue: null,
      productId: null,
      name: '',
      note: '',
      photo: null,
      prices: [],
      options: [],
      isExclusive: false,
      isFood: false,
      category: null,
      externalReference: null,
      invalidPost: {
        venue_id: false,
        productId: false,
        name: false,
        note: false,
        photo: false,
        prices: false,
        category: false,
        external_reference: false,
        product_category_id: false,
      },
    });
  }

  show(set = true, venue = null) {
    this.resetFields();
    this.setState({
      show: set,
      venue: venue,
    });
  }

  setFields(product) {
    this.setState({
      venue: product.venue,
      productId: product._id,
      name: product.name,
      note: product.note,
      unit: product.unit,
      photo: null,
      prices: [...product.prices],
      options: product.options ? [...product.options] : [],
      category: product.category,
      isExclusive: product.is_exclusive || false,
      isFood: product.is_food,
      externalReference: product.external_reference,
    });
  }

  async onSaveBtnCLick() {
    const { venue, productId, name, note, photo, prices, options, isFood, isExclusive, category, externalReference, invalidPost } = this.state;
    const { t, onComplete } = this.props;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });
    console.log(prices, options, isExclusive);

    this.setState({
      loading: false,
    });

    const result = productId
      ? await this.productService.put(venue ? venue._id : null, productId, name, note, photo, prices, options || [], category ? category._id : null, isFood, isExclusive, externalReference)
      : await this.productService.post(venue ? venue._id : null, name, note, photo, prices, options || [], true, category ? category._id : null, isFood, isExclusive, externalReference);

    if (result && result.success) {
      this.show(false);
      Toast.success(productId ? t('Updated') : t('Saved'));

      onComplete();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  onProductPhotoChange(photo) {
    this.setState({
      photo: photo,
    });
  }

  onOptionAdded(value) {
    const { prices } = this.state;

    prices.push(value);
    prices.sort((a, b) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });

    this.setState({ prices: prices });
  }

  onOptionRemoved(index) {
    const { prices } = this.state;

    prices.splice(index, 1);
    this.setState({ prices: prices });
  }

  onOptionSecondAdded(value) {
    const { options } = this.state;

    options.push(value);
    options.sort((a, b) => {
      return a.timestamp > b.timestamp ? 1 : -1;
    });

    this.setState({ options: options });
  }

  onOptionSecondRemoved(index) {
    const { options } = this.state;

    options.splice(index, 1);
    this.setState({ options: options });
  }

  onExclusiveChange(isExclusive) {
    this.setState({ isExclusive: isExclusive });
  }

  onIsFoodChange(isFood) {
    this.setState({ isFood: isFood });
  }

  onProductCategoryChange(productCategory) {
    this.setState({ category: productCategory });
  }

  render() {
    const { show, loading, venue, productId, name, note, prices, options, isExclusive, isFood, category, externalReference, invalidPost } = this.state;
    const { t, onNoResultFound } = this.props;

    return (
      show && (
        <ModalLayout title={productId ? t('Update Product') : t('Add Product')} width={720}>
          <Row>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Name')}
                error={invalidPost.name}
                errorText={t('Name is required')}
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextField fullWidth label={t('Note')} value={note || ''} onChange={(e) => this.setState({ note: e.target.value })}></TextField>
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('External Reference')}
                value={externalReference}
                onChange={(e) => this.setState({ externalReference: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <Select
                fullWidth
                type='product_categories'
                field='name'
                label={t('Category')}
                error={invalidPost.product_category_id}
                errorText={t('Category is required')}
                value={category}
                query={{ venue_id: venue._id }}
                defaultValue={category}
                onChange={this.onProductCategoryChange}
                renderNoResult={
                  <div className='add-product-no-result' onClick={onNoResultFound}>
                    + {t('Please click to add new category')}
                  </div>
                }
              ></Select>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <p className='add-product-subtitle'>{t('Options')}</p>
              <div className='add-product-checkbox-container'>
                <Checkbox text={t('Food')} value={false} checked={isFood} onChange={this.onIsFoodChange} />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AddOptionField venue={venue} prices={prices} onOptionAdded={this.onOptionAdded} onOptionRemoved={this.onOptionRemoved} />
              {invalidPost.prices && (
                <Col md={12}>
                  <span className='error-text'>{t('Please add a price for this product!')}</span>
                </Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <AddOptionSecondField
                venue={venue}
                options={options}
                isExclusive={isExclusive}
                onOptionAdded={this.onOptionSecondAdded}
                onOptionRemoved={this.onOptionSecondRemoved}
                onExclusiveChange={this.onExclusiveChange}
              />
              {invalidPost.options && (
                <Col md={12}>
                  <span className='error-text'>{t('Please check your inputs!')}</span>
                </Col>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={12}>
              <p className='add-product-subtitle'>{t('Product Image')}</p>
              <Uploader onChange={this.onProductPhotoChange} />
            </Col>
          </Row>

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton
                text={productId ? t('UPDATE PRODUCT') : t('SAVE PRODUCT')}
                state={loading ? 'LOADING' : 'DONE'}
                onClick={this.onSaveBtnCLick}
                fullWidth
              ></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveProductModal);
