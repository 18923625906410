import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';
import { DropDown } from '../fields';
import { Converter } from '../../utils';

import '../../styles/cards/topbars.css';

class TopBarsCard extends React.Component {
  render() {
    const { t, title, rows, currencySymbol, onActionBtnClick } = this.props;

    return (
      <div className='top-bars-card-container'>
        <div className='top-bars-card-dropdown'>
          <DropDown
            bull={true}
            data={{ field: 'bars' }}
            actions={[
              { label: 'PDF', title: t('PDF'), type: 'pdf' },
              { label: 'CSV', title: t('CSV'), type: 'csv' },
            ]}
            onChange={onActionBtnClick}
          />
        </div>

        <p className='top-bars-card-title'>{title}</p>
        {rows && rows.length > 0 ? (
          <table className='top-bars-table'>
            <thead>
              <tr>
                <th scope='col'></th>
                <th scope='col'>{t('Teams')}</th>
                <th scope='col'>{t('Amount')}</th>
                <th scope='col'>{t('Order served')}</th>
                <th scope='col'>{t('Drinks made')}</th>
                <th scope='col'>{t('Members')}</th>
                <th scope='col'>{t('Avg. waiting time')}</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((row, index) => {
                return (
                  <tr key={row._id}>
                    <td>{index < 3 && <FontAwesomeIcon icon={faMedal} size='3x' />}</td>
                    <td>{row.bar.name}</td>
                    <td>
                      {currencySymbol} {row.total.toFixed(2)}
                    </td>
                    <td>{row.total_orders}</td>
                    <td>{row.total_qty}</td>
                    <td>{row.bar.member_count}</td>
                    <td>{Converter.humanizeTime(row.average_waiting)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : (
          <p>{t('No Data Found')}</p>
        )}
      </div>
    );
  }
}

TopBarsCard.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array,
  currencySymbol: PropTypes.string,
  onActionBtnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

TopBarsCard.defaultProps = {
  title: '',
  rows: [],
  currencySymbol: '',
  onActionBtnClick: Function.prototype,
};

export default withTranslation()(TopBarsCard);
