import React from 'react';
import Constants from '../../Constants';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { IngredientService } from '../../services';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, TextFieldWithDropDown } from '../fields';
import { ModalLayout } from '../layouts';
import { Converter, EntityError } from '../../utils';

import '../../styles/modals/modal.css';

class IngredientModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      ingredientId: null,
      name: '',
      note: '',
      venue: null,
      venueReserve: '',
      threshold: '',
      unit: Constants.UNITS[0],
      barReserves: {},
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);

    this.ingredientService = new IngredientService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      ingredientId: null,
      name: '',
      note: '',
      venue: null,
      venueReserve: '',
      threshold: '',
      unit: Constants.UNITS[0],
      barReserves: {},
      invalidPost: {
        name: false,
        venue_id: false,
      },
    });
  }

  show(set = true, venue = null) {
    this.resetFields();
    this.setState({
      show: set,
      venue: venue,
    });
  }

  setFields(ingredient) {
    const { barReserves } = this.state;

    this.setState({
      ingredientId: ingredient._id,
      name: ingredient.name,
      note: ingredient.note,
      venue: ingredient.venue,
      venueReserve: ingredient.venue_reserve,
      unit: ingredient.unit,
      threshold: ingredient.threshold || '',
    });

    for (const index in ingredient.bar_reserves) {
      const barReserve = ingredient.bar_reserves[index];
      barReserves[barReserve.bar._id] = barReserve.reserve.toString();

      this.setState({ barReserves: barReserves });
    }
  }

  async onSaveBtnCLick() {
    const { ingredientId, venue, name, note, unit, threshold, venueReserve, barReserves, invalidPost } = this.state;
    const { onComplete } = this.props;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const formattedBarReserves = Object.keys(barReserves).map((barId) => {
      return {
        bar_id: barId,
        reserve: parseFloat(barReserves[barId]),
      };
    });
    const result = ingredientId
      ? await this.ingredientService.put(ingredientId, venue ? venue._id : null, name, note, unit, venueReserve, formattedBarReserves, threshold ? parseFloat(threshold) : 0)
      : await this.ingredientService.post(venue ? venue._id : null, name, note, unit, venueReserve, formattedBarReserves, threshold ? parseFloat(threshold) : 0);

    if (result && result.success) {
      onComplete();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const { show, loading, ingredientId, venue, name, note, threshold, venueReserve, unit, barReserves, invalidPost } = this.state;
    const { t, bars } = this.props;

    return (
      show && (
        <ModalLayout title={ingredientId ? t('Update Ingredient') : t('Add Ingredient')} width={520} subtitle={venue.name}>
          <Row>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Name')}
                error={invalidPost.name}
                errorText={t('Name is required')}
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Note')}
                error={invalidPost.note}
                errorText={t('Note must less than 512 characters')}
                value={note}
                onChange={(e) => this.setState({ note: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextFieldWithDropDown
                fullWidth
                label={t('Threshold (for low stock)')}
                error={invalidPost.threshold}
                errorText={t('Threshold is invalid')}
                value={threshold}
                onChange={(e) => this.setState({ threshold: Converter.onlyNumber(e.target.value) })}
                hideArrow={true}
                dropdownLabel={unit}
                dropdownActions={Constants.UNITS.map((label) => {
                  return { label: label, title: label };
                })}
                dropdownOnChange={(data, value) => {
                  this.setState({ unit: value.label });
                }}
              />
            </Col>
            <Col md={12}>
              <TextFieldWithDropDown
                fullWidth
                label={t('Venue Reserve')}
                error={invalidPost.reserve}
                errorText={t('Venue reserve is invalid')}
                value={venueReserve}
                onChange={(e) => this.setState({ venueReserve: Converter.onlyNumber(e.target.value) })}
                hideArrow={true}
                dropdownLabel={unit}
                dropdownActions={Constants.UNITS.map((label) => {
                  return { label: label, title: label };
                })}
                dropdownOnChange={(data, value) => {
                  this.setState({ unit: value.label });
                }}
              />
            </Col>
            {bars &&
              bars.map((bar) => {
                return (
                  <Col md={12} key={bar._id}>
                    <TextFieldWithDropDown
                      fullWidth
                      label={`${bar.name} ${t('Reserve')}`}
                      error={invalidPost.reserve}
                      errorText={t('Reserve is invalid')}
                      value={barReserves[bar._id]}
                      onChange={(e) => {
                        this.setState({ barReserves: { ...barReserves, [bar._id]: Converter.onlyNumber(e.target.value) } });
                      }}
                      hideArrow={true}
                      dropdownLabel={unit}
                      dropdownActions={Constants.UNITS.map((label) => {
                        return { label: label, title: label };
                      })}
                      dropdownOnChange={(data, value) => {
                        this.setState({ unit: value.label });
                      }}
                    />
                  </Col>
                );
              })}
          </Row>

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={ingredientId ? t('UPDATE') : t('SAVE INGREDIENT')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnCLick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(IngredientModal);
