import React from "react";
import { withTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import { VenueService } from "../../services";
import { Converter, EntityError } from "../../utils";
import { DefaultButton, LoadingButton } from "../buttons";
import {
  TextField,
  Checkbox,
  Map,
  Uploader,
  TagInput,
  DropDown,
  CountrySelect,
} from "../fields";
import { ModalLayout } from "../layouts";

import "../../styles/modals/modal.css";
import "../../styles/modals/savevenue.css";
import "leaflet/dist/leaflet.css";
import Constants from "../../Constants";

class SaveVenueModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      name: "",
      email: "",
      address: "",
      phone: "",
      postCode: "",
      lat: null,
      lng: null,
      seatCodes: [],
      photo: null,
      serviceType: 1,
      isStadium: false,
      seatFirstNumber: "",
      seatLastNumber: "",
      isQrcodeRequiredTableService: true,
      serviceFeeRate: 0,
      currency: "GBP",
      country: "GB",
      vatNumber: "",
      vatRate: 0,
      deliveryOptions: [],
      invalidPost: {
        name: false,
        email: false,
        address: false,
        phone: false,
        postCode: false,
        lng: false,
        lat: false,
        photo: false,
        seat_first_number: false,
        seat_last_number: false,
        is_qrcode_required_ts: false,
        currency: false,
        country: false,
        vat_number: false,
        vat_rate: false,
        delivery_options: false,
      },
    };

    this.show = this.show.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);
    this.onServiceTypeChange = this.onServiceTypeChange.bind(this);
    this.onIsStadiumChange = this.onIsStadiumChange.bind(this);
    this.onDeliveryOptionChange = this.onDeliveryOptionChange.bind(this);
    this.onIsQrcodeRequiredTableServiceChange =
      this.onIsQrcodeRequiredTableServiceChange.bind(this);
    this.onProductPhotoChange = this.onProductPhotoChange.bind(this);
    this.onMapClick = this.onMapClick.bind(this);

    this.venueService = new VenueService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      name: "",
      email: "",
      address: "",
      phone: "",
      postCode: "",
      lat: null,
      lng: null,
      seatCodes: [],
      photo: null,
      serviceType: 1,
      isStadium: false,
      seatFirstNumber: "",
      seatLastNumber: "",
      isQrcodeRequiredTableService: true,
      serviceFeeRate: 0,
      currency: "GBP",
      country: "GB",
      vatNumber: "",
      vatRate: 0,
      deliveryOptions: [],
      invalidPost: {
        name: false,
        email: false,
        address: false,
        phone: false,
        postCode: false,
        lng: false,
        lat: false,
        photo: false,
        seat_first_number: false,
        seat_last_number: false,
        is_qrcode_required_ts: false,
        currency: false,
        country: false,
        vat_number: false,
        vat_rate: false,
        delivery_options: false,
      },
    });
  }

  show(set = true) {
    const { venueId } = this.props;

    this.resetFields();
    this.setState({
      show: set,
    });

    if (this.map) {
      this.map.setZoom(venueId ? 15 : 13);
    }
  }

  setFields(venue) {
    this.setState({
      name: venue.name,
      email: venue.email,
      address: venue.contacts.address,
      phone: venue.contacts.phone,
      postCode: venue.contacts.post_code,
      lng: venue.location.coordinates[0],
      lat: venue.location.coordinates[1],
      serviceType: venue.service_type,
      isStadium: venue.is_stadium,
      seatFirstNumber: venue.seat_numbers.first_number,
      seatLastNumber: venue.seat_numbers.last_number,
      seatCodes: venue.seat_codes || [],
      isQrcodeRequiredTableService: venue.is_qrcode_required
        ? venue.is_qrcode_required.for_tableservice
        : true,
      serviceFeeRate: venue.service_fee ? venue.service_fee.rate : 0,
      currency: venue.currency ? venue.currency : null,
      country: venue.country ? venue.country : null,
      vatNumber: venue.vat ? venue.vat.number : "",
      vatRate: venue.vat ? venue.vat.rate : 0,
      deliveryOptions: venue.delivery_options || [],
    });
  }

  onServiceTypeChange(checked, serviceType) {
    if (checked) {
      this.setState({ serviceType: serviceType });
    }
  }

  onIsStadiumChange(checked) {
    this.setState({ isStadium: checked });
  }

  onDeliveryOptionChange(checked, value) {
    const { deliveryOptions } = this.state;
    checked
      ? deliveryOptions.push(value)
      : deliveryOptions.splice(deliveryOptions.indexOf(value), 1);

    this.setState({ deliveryOptions });
  }

  onIsQrcodeRequiredTableServiceChange(checked) {
    this.setState({ isQrcodeRequiredTableService: checked });
  }

  onProductPhotoChange(photo) {
    this.setState({
      photo: photo,
    });
  }

  onMapClick(e) {
    const location = e.latlng && e.latlng.lat && e.latlng.lng ? e.latlng : null;

    if (location) {
      this.setState({
        lng: location.lng,
        lat: location.lat,
      });
    }
  }

  async onSaveBtnCLick() {
    const { venueId, onComplete } = this.props;
    const {
      invalidPost,
      name,
      email,
      address,
      phone,
      postCode,
      lng,
      lat,
      photo,
      serviceType,
      isStadium,
      seatFirstNumber,
      seatLastNumber,
      seatCodes,
      isQrcodeRequiredTableService,
      serviceFeeRate,
      currency,
      country,
      vatNumber,
      vatRate,
      deliveryOptions,
    } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = venueId
      ? await this.venueService.put(
          venueId,
          name,
          email,
          address,
          phone,
          postCode,
          lng,
          lat,
          photo,
          serviceType,
          isStadium,
          seatFirstNumber,
          seatLastNumber,
          seatCodes && seatCodes.length > 0 ? seatCodes : null,
          isQrcodeRequiredTableService,
          serviceFeeRate,
          country,
          vatNumber,
          vatRate,
          deliveryOptions
        )
      : await this.venueService.post(
          name,
          email,
          address,
          phone,
          postCode,
          lng,
          lat,
          photo,
          serviceType,
          isStadium,
          seatFirstNumber,
          seatLastNumber,
          seatCodes && seatCodes.length > 0 ? seatCodes : null,
          isQrcodeRequiredTableService,
          serviceFeeRate,
          currency,
          country,
          vatNumber,
          vatRate,
          deliveryOptions
        );

    if (result && result.success) {
      this.show(false);

      onComplete(true);
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const {
      show,
      loading,
      name,
      email,
      address,
      phone,
      postCode,
      lng,
      lat,
      serviceType,
      isStadium,
      deliveryOptions,
      seatFirstNumber,
      seatLastNumber,
      seatCodes,
      isQrcodeRequiredTableService,
      serviceFeeRate,
      currency,
      country,
      vatNumber,
      vatRate,
      invalidPost,
    } = this.state;
    const { t, venueId } = this.props;

    return (
      show && (
        <ModalLayout
          title={venueId ? t("Update Venue") : t("Add Venue")}
          width={640}
        >
          <Row>
            <Col md={6}>
              <TextField
                fullWidth
                label={t("Name")}
                error={invalidPost.name}
                errorText={t("Name is required")}
                onChange={(e) => this.setState({ name: e.target.value })}
                value={name}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t("Email")}
                error={invalidPost.email}
                errorText={t("Email is an invalid")}
                type="email"
                onChange={(e) => this.setState({ email: e.target.value })}
                value={email}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t("Phone")}
                onChange={(e) => this.setState({ phone: e.target.value })}
                value={phone}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t("Post Code")}
                onChange={(e) => this.setState({ postCode: e.target.value })}
                value={postCode}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t("Address")}
                error={invalidPost.address}
                errorText={t("Address is required")}
                onChange={(e) => this.setState({ address: e.target.value })}
                value={address}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t("Seat First Number")}
                error={invalidPost.seat_first_number}
                errorText={t("Seat Number is required")}
                onChange={(e) =>
                  this.setState({ seatFirstNumber: e.target.value })
                }
                value={seatFirstNumber}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t("Seat Last Number")}
                error={invalidPost.seat_last_number}
                errorText={t("Seat Number is required")}
                onChange={(e) =>
                  this.setState({ seatLastNumber: e.target.value })
                }
                value={seatLastNumber}
              ></TextField>
            </Col>
            <Col md={4}>
              <p className="add-venue-subtitle">{t("Fees")}</p>
              <TextField
                label={t("Service Charge")}
                fullWidth
                value={serviceFeeRate}
                error={invalidPost.service_fee_rate}
                errorText={t("Service Charge must be numeric")}
                onChange={(e) =>
                  this.setState({
                    serviceFeeRate: Converter.onlyNumber(e.target.value),
                  })
                }
                rightBtnLabel={"%"}
              ></TextField>
            </Col>
            <Col md={4}>
              <p className="add-venue-subtitle">{t("Currency")}</p>
              {venueId ? (
                <span className="add-venue-currency-text">{`${currency} ${Constants.CURRENCIES[currency].symbol}`}</span>
              ) : (
                <DropDown
                  label={`${currency} ${Constants.CURRENCIES[currency].symbol}`}
                  bold={false}
                  dropdownStyle="add-venue-currency-dropdown"
                  buttonStyle="add-venue-currency-dropdown-btn"
                  actions={Object.keys(Constants.CURRENCIES).map((key) => {
                    return {
                      label: `${key} ${Constants.CURRENCIES[key].symbol}`,
                      title: `${key} ${Constants.CURRENCIES[key].symbol}`,
                      id: key,
                    };
                  })}
                  onChange={(data, selectedValue) => {
                    this.setState({ currency: selectedValue.id });
                  }}
                />
              )}
            </Col>
            <Col md={12}>
              <p className="add-venue-subtitle">{t("VAT")}</p>
              <Row>
                <Col md={4}>
                  <TextField
                    label={t("Rate")}
                    fullWidth
                    value={vatRate}
                    error={invalidPost.vat_rate}
                    errorText={t("Value is an invalid")}
                    onChange={(e) =>
                      this.setState({
                        vatRate: Converter.onlyNumber(e.target.value),
                      })
                    }
                    rightBtnLabel={"%"}
                  ></TextField>
                </Col>
                <Col md={8}>
                  <TextField
                    label={t("VAT Registration Number")}
                    fullWidth
                    value={vatNumber}
                    error={invalidPost.vat_number}
                    errorText={t("Value is an invalid")}
                    onChange={(e) =>
                      this.setState({ vatNumber: e.target.value })
                    }
                  ></TextField>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <p className="add-venue-subtitle">{t("Seat Codes")}</p>
              <TagInput
                tags={seatCodes}
                onlyLetter={true}
                upperCase={true}
                placeholder={t(
                  "Please press the enter key to add new seat code"
                )}
                onTagsChanged={(tags) => {
                  this.setState({ seatCodes: tags });
                }}
              />
            </Col>
            <Col md={12}>
              <p className="add-venue-subtitle">{t("Options")}</p>
              <Row>
                <Col sm={6}>
                  <div className="add-venue-service-list">
                    <Checkbox
                      text={t("Pick up & Table Service / Seat Order")}
                      value={1}
                      checked={serviceType === 1 ? true : false}
                      onChange={this.onServiceTypeChange}
                    />
                    <Checkbox
                      text={t("Pick up")}
                      value={2}
                      checked={serviceType === 2 ? true : false}
                      onChange={this.onServiceTypeChange}
                    />
                    <Checkbox
                      text={t("Table Service / Seat Order")}
                      value={3}
                      checked={serviceType === 3 ? true : false}
                      onChange={this.onServiceTypeChange}
                    />
                  </div>
                </Col>
                <Col sm={6}>
                  <div className="add-venue-service-list">
                    <Checkbox
                      text={t("Stadium")}
                      value={true}
                      checked={isStadium}
                      onChange={this.onIsStadiumChange}
                    />
                    <Checkbox
                      text={t("QR Code Required For Table Service")}
                      value={true}
                      checked={isQrcodeRequiredTableService}
                      onChange={this.onIsQrcodeRequiredTableServiceChange}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <p className="add-venue-subtitle">{t("Delivery Options")}</p>
              <Row>
                <Col sm={6}>
                  <div className="add-venue-service-list">
                    <Checkbox
                      text={t("Table Service")}
                      value={Constants.DELIVERY_OPTIONS[0]}
                      checked={
                        deliveryOptions.indexOf(Constants.DELIVERY_OPTIONS[0]) >
                        -1
                      }
                      onChange={this.onDeliveryOptionChange}
                    />
                    <Checkbox
                      text={t("Seat Service")}
                      value={Constants.DELIVERY_OPTIONS[1]}
                      checked={
                        deliveryOptions.indexOf(Constants.DELIVERY_OPTIONS[1]) >
                        -1
                      }
                      onChange={this.onDeliveryOptionChange}
                    />
                    <Checkbox
                      text={t("Room Service")}
                      value={Constants.DELIVERY_OPTIONS[2]}
                      checked={
                        deliveryOptions.indexOf(Constants.DELIVERY_OPTIONS[2]) >
                        -1
                      }
                      onChange={this.onDeliveryOptionChange}
                    />
                    <Checkbox
                      text={t("Box Service")}
                      value={Constants.DELIVERY_OPTIONS[3]}
                      checked={
                        deliveryOptions.indexOf(Constants.DELIVERY_OPTIONS[3]) >
                        -1
                      }
                      onChange={this.onDeliveryOptionChange}
                    />
                    <Checkbox
                      text={t("Zone Service")}
                      value={Constants.DELIVERY_OPTIONS[4]}
                      checked={
                        deliveryOptions.indexOf(Constants.DELIVERY_OPTIONS[4]) >
                        -1
                      }
                      onChange={this.onDeliveryOptionChange}
                    />
                  </div>
                </Col>
              </Row>
            </Col>
            <Col md={12}>
              <p className="add-venue-subtitle">{t("Photo")}</p>
              <Uploader onChange={this.onProductPhotoChange} />
            </Col>
          </Row>
          <p className="add-venue-subtitle">{t("Location")}</p>
          <Row>
            <Col md={4}>
              <CountrySelect
                label={t("Country")}
                error={invalidPost.country}
                errorText={t("Country is required")}
                defaultValue={country}
                onChange={(data) => {
                  this.setState({ country: data.value });
                }}
              />
            </Col>
            <Col md={12}>
              <Map
                containerClass="add-venue-map"
                coordinates={{
                  lng,
                  lat,
                }}
                defaultCoordinate={Constants.COUNTRIES[country].coordinates}
                search={true}
                marker={true}
                error={invalidPost.lng || invalidPost.lat}
                errorText={t("Please pin the venue address")}
                onRef={(ref) => (this.map = ref)}
                onClick={this.onMapClick}
              ></Map>
            </Col>
          </Row>

          <div className="modal-buttons-container">
            <div className="modal-button">
              {loading || (
                <DefaultButton
                  text={t("CANCEL")}
                  onClick={() => this.show(false)}
                  fullWidth
                ></DefaultButton>
              )}
            </div>
            <div className="modal-button">
              <LoadingButton
                text={venueId ? t("UPDATE") : t("SAVE VENUE")}
                state={loading ? "LOADING" : "DONE"}
                onClick={this.onSaveBtnCLick}
                fullWidth
              ></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveVenueModal);
