import React from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { Row, Col } from 'react-bootstrap';
import { Select, DropDown } from '../components/fields';
import { PrizeCard, PrizeBarCard, PrizeUserCard } from '../components/cards';
import { SavePrizeModal, OptionsPrizeModal } from '../components/modals';
import { Confirm } from '../components/alerts';
import { PrizeService } from '../services';
import { LocalStorage } from '../utils';

import '../styles/prize.css';

class PrizePage extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;
    const filter = LocalStorage.getFilter();

    this.state = {
      loading: false,
      reports: null,
      prizes: null,
      venue: filter && filter.venue ? filter.venue : null,
      actionIndex: 0,
      actions: [
        { label: 'Daily', title: t('Daily'), index: 0 },
        { label: 'Weekly', title: t('Weekly'), index: 1 },
        { label: 'Monthly', title: t('Monthly'), index: 2 },
      ],
    };

    this.fetchPrizes = this.fetchPrizes.bind(this);
    this.setOptions = this.setOptions.bind(this);
    this.onVenueSelectChange = this.onVenueSelectChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
    this.onAddPrizeComplete = this.onAddPrizeComplete.bind(this);
    this.onOptionsPrizeComplete = this.onOptionsPrizeComplete.bind(this);
    this.onPrizeActionChange = this.onPrizeActionChange.bind(this);

    this.prizeService = new PrizeService();
  }

  componentDidMount() {
    this.fetchPrizes();
  }

  async fetchPrizes() {
    this.setState({ loading: true });

    const { venue, actionIndex } = this.state;
    const reportResult = await this.prizeService.getReport({ venue_id: venue ? venue._id : undefined, index: actionIndex });

    if (reportResult && reportResult.success) {
      this.setState({
        reports: reportResult.reports,
        venue: reportResult.selected_venue,
        actionIndex: reportResult.index,
        loading: false,
      });

      if (this.venueSelect) {
        this.venueSelect.setValue(reportResult.selected_venue.name);
      }
    }

    const prizeResult = await this.prizeService.get({ venue_id: venue ? venue._id : undefined });

    if (prizeResult && prizeResult.success) {
      this.setState({ prizes: prizeResult.prizes, loading: false });
    } else if (prizeResult && prizeResult.ecode === 214) {
      this.setState({ prizes: [], loading: false });
    }
  }

  async setOptions() {}

  onVenueSelectChange(venue) {
    this.setState({ venue: venue }, () => {
      this.fetchPrizes();
    });
  }

  onDateChange(data, selectedValue) {
    this.setState({ actionIndex: selectedValue.index }, () => {
      this.fetchPrizes();
    });
  }

  onAddPrizeComplete() {
    this.fetchPrizes();
  }

  onOptionsPrizeComplete() {
    this.fetchPrizes();
  }

  async onPrizeActionChange(data, action) {
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'EDIT':
        this.savePrizeModal.show(true);
        this.savePrizeModal.setFields(data);
        break;
      case 'DELETE':
        const confirm = await Confirm.open(t('Prize Deletion'), t('Are you sure to delete?'), t('Yes'), t('No'));
        if (confirm) {
          this.setState({ loading: true });
          result = await this.prizeService.delete(data._id);
        }
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchPrizes();
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, venue, reports, prizes, actionIndex, actions } = this.state;
    const { t, me, label } = this.props;

    return (
      <div className='content'>
        <SavePrizeModal onRef={(ref) => (this.savePrizeModal = ref)} onComplete={this.onAddPrizeComplete} />
        <OptionsPrizeModal venue={venue} onRef={(ref) => (this.optionsPrizeModal = ref)} onComplete={this.onOptionsPrizeComplete}></OptionsPrizeModal>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
              <button
                className='content-title-button'
                onClick={() => {
                  this.savePrizeModal.show(true);
                }}
              >
                <FontAwesomeIcon icon={faPlus} /> {t('ADD PRIZE')}
              </button>
            )}
          </div>
        </div>
        <div className='content-body'>
          <div className='content-header'>
            <Row>
              <Col md={12}>
                <p className='prize-container-title'>{t('Filters')}</p>
                <div className='prize-spacer' />
              </Col>
              <Col md={3}>
                <Select fullWidth type='venues' field='name' size='sm' label={t('Venue')} onChange={this.onVenueSelectChange} onRef={(ref) => (this.venueSelect = ref)}></Select>
              </Col>
              <Col md={3}>
                <DropDown label={actions[actionIndex].title} buttonStyle='prize-dropdown-btn' actions={actions} onChange={this.onDateChange} />
              </Col>
            </Row>
          </div>

          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : (
            <Row>
              <Col md={12}>
                <div className='prize-container'>
                  <p className='prize-container-title'>{t('Prizes')}</p>
                  <div className='prize-spacer' />
                  <div className='prize-container-inline'>
                    {prizes && prizes.length > 0 ? (
                      prizes.map((prize, index) => {
                        return (
                          <PrizeCard
                            key={index}
                            prize={prize}
                            data={prize}
                            actions={[
                              { label: 'EDIT', title: t('EDIT') },
                              { label: 'DELETE', title: t('DELETE'), red: true },
                            ]}
                            onChange={this.onPrizeActionChange}
                          />
                        );
                      })
                    ) : (
                      <p className='prize-no-content'>{t('No Data Found')}</p>
                    )}
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <div className='prize-container'>
                  <div className='prize-container-header'>
                    <p className='prize-container-title'>{t('Leaderboard')}</p>
                    <span
                      className='prize-container-option-btn'
                      onClick={() => {
                        this.optionsPrizeModal.show(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faCog}></FontAwesomeIcon>
                    </span>
                  </div>
                  <hr></hr>
                  <Row>
                    <Col md={6}>
                      <p className='prize-container-title'>{venue && venue.prize_types.type === 'BAR' ? t('Bars') : t('Bartender')}</p>
                    </Col>
                    <Col md={6} className='text-right'>
                      <p className='prize-container-subtitle'>{venue && venue.prize_types.metric === 'AVERAGE_TIME' ? t('Average time to make a drink') : t('Number sold')}</p>
                    </Col>
                  </Row>

                  {reports && reports.length > 0 ? (
                    reports.map((report, index) => {
                      return venue.prize_types.type === 'BAR' ? (
                        <PrizeBarCard key={index} report={report} index={index} metric={venue.prize_types.metric} />
                      ) : (
                        <PrizeUserCard key={index} report={report} index={index} metric={venue.prize_types.metric} />
                      );
                    })
                  ) : (
                    <p className='prize-no-content'>{t('No Data Found')}</p>
                  )}
                </div>
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(PrizePage));
