import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { BarService } from '../../services';
import { EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, Select, AddMemberField, Uploader, Checkbox } from '../fields';
import { ModalLayout } from '../layouts';
import { Validator } from '../../utils';

import '../../styles/modals/modal.css';

class SaveBarModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      name: '',
      email: '',
      venue: null,
      members: [],
      photo: null,
      seatFirstNumber: '',
      seatLastNumber: '',
      serviceType: 1,
      invalidPost: {
        name: false,
        email: false,
        venue_id: false,
        members: false,
        photo: false,
        seat_first_number: false,
        seat_last_number: false,
        seat_number: false,
      },
      invalidMembers: false,
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);
    this.onProductPhotoChange = this.onProductPhotoChange.bind(this);
    this.onMemberFieldChange = this.onMemberFieldChange.bind(this);
    this.onServiceTypeChange = this.onServiceTypeChange.bind(this);

    this.barService = new BarService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      name: '',
      email: '',
      venue: null,
      members: [],
      photo: null,
      seatFirstNumber: '',
      seatLastNumber: '',
      serviceType: 1,
      invalidPost: {
        name: false,
        email: false,
        venue_id: false,
        members: false,
        photo: false,
        seat_first_number: false,
        seat_last_number: false,
        seat_number: false,
      },
      invalidMembers: false,
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  setFields(bar) {
    this.setState({
      name: bar.name,
      email: bar.email,
      venue: bar.venue,
      members: bar.members,
      seatFirstNumber: bar.seat_ranges.first_number,
      seatLastNumber: bar.seat_ranges.last_number,
      serviceType: bar.service_type !== null ? bar.service_type : bar.venue.service_type,
    });
  }

  async onSaveBtnCLick() {
    const { barId, onComplete } = this.props;
    const { invalidPost, venue, name, email, members, photo, seatFirstNumber, seatLastNumber, serviceType } = this.state;
    const memberIds = Validator.populateArray('_id', members);
    const venue_id = venue ? venue._id : null;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = barId
      ? await this.barService.put(barId, name, email, memberIds, photo, seatFirstNumber, seatLastNumber, serviceType)
      : await this.barService.post(venue_id, name, email, memberIds, photo, seatFirstNumber, seatLastNumber, serviceType);

    if (result && result.success) {
      this.show(false);

      onComplete(true);
    } else if (result && (result.ecode === 422 || result.ecode === 3040)) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    } else if (result && result.ecode === 3020) {
      // CHECK MEMBER(S) HAVE PERMISSION TO VENUE
      this.setState({
        invalidMembers: true,
      });
    }

    this.setState({
      loading: false,
    });
  }

  onProductPhotoChange(photo) {
    this.setState({
      photo: photo,
    });
  }

  onMemberFieldChange(members) {
    this.setState({ members: members });
  }

  onServiceTypeChange(checked, serviceType) {
    if (checked) {
      this.setState({ serviceType: serviceType });
    }
  }

  render() {
    const { show, loading, name, email, venue, members, invalidPost, seatFirstNumber, seatLastNumber, serviceType, invalidMembers } = this.state;
    const { t, barId } = this.props;

    return (
      show && (
        <ModalLayout title={barId ? t('Update Bar') : t('Add Bar')} width={640}>
          <Row>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Name')}
                error={invalidPost.name}
                errorText={t('Name is required')}
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Email')}
                error={invalidPost.email}
                errorText={t('Email is invalid')}
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              ></TextField>
            </Col>
            <Col md={12}>
              <Select
                fullWidth
                type='venues'
                field='name'
                label={t('Venue')}
                error={invalidPost.venue_id}
                errorText={t('Venue is required')}
                value={venue}
                disabled={barId ? true : false}
                defaultValue={venue}
                onChange={(venue) => this.setState({ venue: venue })}
              ></Select>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t('Seat First Number')}
                error={invalidPost.seat_first_number}
                errorText={t('Seat Number is required')}
                onChange={(e) => this.setState({ seatFirstNumber: e.target.value })}
                value={seatFirstNumber}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t('Seat Last Number')}
                error={invalidPost.seat_last_number}
                errorText={t('Seat Number is required')}
                onChange={(e) => this.setState({ seatLastNumber: e.target.value })}
                value={seatLastNumber}
              ></TextField>
            </Col>
            {invalidPost.seat_number && (
              <Col md={12}>
                <span className='error-text'>{t('Seat Number is not in range.')}</span>
              </Col>
            )}
            <Col md={12}>
              <p className='add-venue-subtitle'>{t('Options')}</p>
              <Row>
                {!venue && <Col sm={12}>{t('Please select a venue')}</Col>}
                {venue && venue.service_type === 1 && (
                  <Col sm={6}>
                    <div className='add-venue-service-list'>
                      <Checkbox text={t('Pick up & Table Service / Seat Order')} value={1} checked={serviceType === 1 ? true : false} onChange={this.onServiceTypeChange} />
                      <Checkbox text={t('Pick up')} value={2} checked={serviceType === 2 ? true : false} onChange={this.onServiceTypeChange} />
                      <Checkbox text={t('Table Service / Seat Order')} value={3} checked={serviceType === 3 ? true : false} onChange={this.onServiceTypeChange} />
                    </div>
                  </Col>
                )}
                {venue && venue.service_type === 2 && <Col sm={12}>{t('This venue has only pick up service')}</Col>}
                {venue && venue.service_type === 3 && <Col sm={12}>{t('This venue has only table service')}</Col>}
              </Row>
            </Col>
            <Col md={12}>
              <p className='add-venue-subtitle'>{t('Photo')}</p>
              <Uploader onChange={this.onProductPhotoChange} />
            </Col>
          </Row>

          <AddMemberField
            venueId={venue ? venue._id : null}
            members={members}
            error={invalidMembers}
            errorText={t('Please check the permissions of members')}
            onChange={this.onMemberFieldChange}
          ></AddMemberField>

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={barId ? t('UPDATE') : t('SAVE BAR')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnCLick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveBarModal);
