import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DefaultButton } from '../buttons';
import { Toast } from '../alerts';

import '../../styles/fields/uploader.css';

class Uploader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dragging: false,
      photos: [],
    };

    this.dragCounter = 0;

    this.container = React.createRef();
    this.file = React.createRef();

    this.reset = this.reset.bind(this);
    this.removeFromList = this.removeFromList.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleDragOver = this.handleDragOver.bind(this);
    this.handleDragEnter = this.handleDragEnter.bind(this);
    this.handleDragLeave = this.handleDragLeave.bind(this);
    this.onUploadBtnClick = this.onUploadBtnClick.bind(this);
    this.onPhotoChange = this.onPhotoChange.bind(this);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }

    const div = this.container.current;
    div.addEventListener('drop', this.handleDrop);
    div.addEventListener('dragover', this.handleDragOver);
    div.addEventListener('dragenter', this.handleDragEnter);
    div.addEventListener('dragleave', this.handleDragLeave);
  }
  
  componentWillUnmount() {
    const div = this.container.current;
    div.removeEventListener('drop', this.handleDrop);
    div.removeEventListener('dragover', this.handleDragOver);
    div.removeEventListener('dragenter', this.handleDragEnter);
    div.removeEventListener('dragleave', this.handleDragLeave);
  }

  reset() {
    const { onChange } = this.props;

    this.setState({ photos: [] }, () => {
      this.file.current.value = null;

      onChange(null);
    });
  }

  removeFromList(name) {
    const { photos } = this.state;
    const { onChange } = this.props;

    this.setState(
      {
        photos: photos.filter((photo) => {
          return photo.name !== name;
        }),
      },
      () => {
        if (this.state.photos.length < 1) {
          this.file.current.value = null;
        }

        onChange(this.state.photos);
      }
    );
  }

  handleDragOver(e) {
    e.preventDefault();
    e.stopPropagation();
  }

  handleDrop(e) {
    e.preventDefault();
    e.stopPropagation();

    const { photos } = this.state;
    if (photos && photos.length > 0) return;

    const { t, multiple, onChange } = this.props;

    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const files = e.dataTransfer.files;

      e.dataTransfer.clearData();
      this.dragCounter = 0;

      let valid = true;
      // CHECK FILE TYPE
      for (let i = 0; i < files.length; i++) {
        const file = files[i];

        if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
          valid = false;
        }
      }

      if (valid) {
        this.setState({ dragging: false, photos: Array.from(files) }, () => {
          onChange(multiple ? files : files[0]);
        });
      } else {
        Toast.warning(t('Only jpg or png images can be uploaded!'));
        this.setState({ dragging: false });
      }
    } else {
      this.setState({ dragging: false });
    }
  }

  handleDragEnter(e) {
    e.preventDefault();
    e.stopPropagation();

    const { photos } = this.state;
    if (photos && photos.length > 0) return;

    this.dragCounter++;

    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  }

  handleDragLeave(e) {
    e.preventDefault();
    e.stopPropagation();

    const { photos } = this.state;
    if (photos) return;

    this.dragCounter--;
    if (this.dragCounter > 0) return;

    this.setState({ dragging: false });
  }

  onUploadBtnClick() {
    this.file.current.click();
  }

  onPhotoChange() {
    const { multiple, onChange } = this.props;
    const files = this.file.current && this.file.current.files && this.file.current.files.length > 0 ? this.file.current.files : null;

    if (files) {
      this.setState({ photos: Array.from(files) }, () => {
        onChange(multiple ? files : files[0]);
      });
    } else {
      this.reset();
    }
  }

  render() {
    const { dragging, photos } = this.state;
    const { t, multiple } = this.props;

    return (
      <div className={`uploader-container ${dragging ? 'active' : ''}`} ref={this.container}>
        <input
          id='uploadFile'
          type='file'
          className='uploader-file'
          accept='image/x-png,image/jpeg,image/png'
          onChange={this.onPhotoChange}
          ref={this.file}
          multiple={multiple}
        ></input>
        {photos && photos.length > 0 ? (
          photos.map((photo) => {
            return (
              <div key={photo.name}>
                {photo.name}
                <span
                  className='uploader-remove-btn'
                  onClick={() => {
                    this.removeFromList(photo.name);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </span>
              </div>
            );
          })
        ) : (
          <div>
            <p className='uploader-label '>{multiple ? t('Drag & drop images here') : t('Drag & drop image here')}</p>
            <div className='uploader-splitter'>
              <hr />
              <p className={`${dragging ? 'active' : ''}`}>{t('OR')}</p>
            </div>
            <DefaultButton text={multiple ? t('UPLOAD FILES') : t('UPLOAD FILE')} fontSize='0.7em' onClick={this.onUploadBtnClick} />
          </div>
        )}
      </div>
    );
  }
}

Uploader.propTypes = {
  multiple: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

Uploader.defaultProps = {
  multiple: false,
  onChange: Function.prototype,
};

export default withTranslation()(Uploader);
