import QueryString from "query-string";
import { FetchService } from ".";

export default class VenueService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.changeOpeningHours = this.changeOpeningHours.bind(this);
    this.changeDetails = this.changeDetails.bind(this);
    this.changeBankDetails = this.changeBankDetails.bind(this);
    this.changeTerminalDetails = this.changeTerminalDetails.bind(this);
    this.generateSumupToken = this.generateSumupToken.bind(this);
    this.removeFromPhotoGallery = this.removeFromPhotoGallery.bind(this);
    this.delete = this.delete.bind(this);
    this.getBars = this.getBars.bind(this);
    this.addPermission = this.addPermission.bind(this);
    this.getTerminals = this.getTerminals.bind(this);
    this.setNotifications = this.setNotifications.bind(this);
  }

  get(query) {
    return this.Get("/venues?" + QueryString.stringify(query), {
      cache: "no-store",
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(
    name,
    email,
    address,
    phone,
    post_code,
    lng,
    lat,
    photo = null,
    service_type = 1,
    is_stadium = false,
    seat_first_number = null,
    seat_last_number = null,
    seat_codes = null,
    is_qrcode_required_ts = null,
    service_fee_rate = 0,
    currency = null,
    country = null,
    vat_number = "",
    vat_rate = 0,
    delivery_options = []
  ) {
    const formData = new FormData();

    formData.append("name", name);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("post_code", post_code);
    formData.append("lng", lng);
    formData.append("lat", lat);
    formData.append("service_type", service_type);
    formData.append("is_stadium", is_stadium);
    formData.append("is_qrcode_required_ts", is_qrcode_required_ts);
    formData.append("service_fee_rate", service_fee_rate);
    formData.append("vat_number", vat_number);
    formData.append("vat_rate", vat_rate);;
    if (seat_first_number) {
      formData.append("seat_first_number", seat_first_number);
    }
    if (seat_last_number) {
      formData.append("seat_last_number", seat_last_number);
    }
    if (seat_codes) {
      formData.append("seat_codes", JSON.stringify(seat_codes));
    }
    if (photo) {
      formData.append("photo", photo);
    }
    if (currency) {
      formData.append("currency", currency);
    }
    if (country) {
      formData.append("country", country);
    }
    delivery_options.map((option) => {
      formData.append("delivery_options[]", option);
    });

    return this.Post("/venues", { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  put(
    venue_id,
    name,
    email,
    address,
    phone,
    post_code,
    lng,
    lat,
    photo = null,
    service_type = 1,
    is_stadium = false,
    seat_first_number = null,
    seat_last_number = null,
    seat_codes = null,
    is_qrcode_required_ts = null,
    service_fee_rate = 0,
    country = null,
    vat_number = "",
    vat_rate = 0,
    delivery_options = []
  ) {
    const formData = new FormData();

    formData.append("venue_id", venue_id);
    formData.append("name", name);
    formData.append("email", email);
    formData.append("address", address);
    formData.append("phone", phone);
    formData.append("post_code", post_code);
    formData.append("lng", lng);
    formData.append("lat", lat);
    formData.append("service_type", service_type);
    formData.append("is_stadium", is_stadium);
    formData.append("is_qrcode_required_ts", is_qrcode_required_ts);
    formData.append("service_fee_rate", service_fee_rate);
    formData.append("vat_number", vat_number);
    formData.append("vat_rate", vat_rate);
    if (seat_first_number) {
      formData.append("seat_first_number", seat_first_number);
    }
    if (seat_last_number) {
      formData.append("seat_last_number", seat_last_number);
    }
    if (seat_codes) {
      formData.append("seat_codes", JSON.stringify(seat_codes));
    }
    if (photo) {
      formData.append("photo", photo);
    }
    if (country) {
      formData.append("country", country);
    }
    delivery_options.map((option) => {
      formData.append("delivery_options[]", option);
    });

    return this.Put("/venues", { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  changeOpeningHours(venue_id, opening_hours, timezone_offset = 0) {
    return this.Put("/venues", {
      body: JSON.stringify({ venue_id, opening_hours, timezone_offset }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  changeDetails(
    venue_id,
    photos,
    description = null,
    facebook_link = null,
    twitter_link = null,
    linkedin_link = null,
    instagram_link = null,
    youtube_link = null,
    google_link = null,
    tiktok_link = null,
    website_link = null
  ) {
    const formData = new FormData();

    formData.append("venue_id", venue_id);
    if (description) formData.append("description", description);
    if (facebook_link) formData.append("facebook_link", facebook_link);
    if (twitter_link) formData.append("twitter_link", twitter_link);
    if (linkedin_link) formData.append("linkedin_link", linkedin_link);
    if (instagram_link) formData.append("instagram_link", instagram_link);
    if (youtube_link) formData.append("youtube_link", youtube_link);
    if (google_link) formData.append("google_link", google_link);
    if (tiktok_link) formData.append("tiktok_link", tiktok_link);
    if (website_link) formData.append("website_link", website_link);
    if (photos && photos.length > 0) {
      for (let i = 0; i < photos.length; i++) {
        const photo = photos[i];

        formData.append("photos", photo, photo.name);
      }
    }

    return this.Put("/venues/details", { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  changeBankDetails(
    venue_id,
    name,
    country,
    bank_name,
    account_number,
    sort_code,
    iban,
    swift_code
  ) {
    return this.Put("/venues/bank_details", {
      body: JSON.stringify({
        venue_id,
        name,
        country,
        bank_name,
        account_number,
        sort_code,
        iban: iban || undefined,
        swift_code: swift_code || undefined,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  changeTerminalDetails(
    venue_id,
    provider = null,
    username = null,
    password = null,
    account_name = null,
    unset = false
  ) {
    return this.Put("/venues/terminal_details", {
      body: JSON.stringify({
        venue_id,
        provider: provider || undefined,
        username: username || undefined,
        password: password || undefined,
        account_name: account_name || undefined,
        unset: unset ? true : false,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  generateSumupToken(venue_id, code) {
    return this.Post("/venues/sumup/token", {
      body: JSON.stringify({
        venue_id,
        code,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  removeFromPhotoGallery(venue_id, photo_id) {
    return this.Post("/venues/photo_gallery/remove", {
      body: JSON.stringify({ venue_id, photo_id }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  setStatus(venue_id, status) {
    return this.Put("/venues", { body: JSON.stringify({ venue_id, status }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(venue_id) {
    return this.Post("/venues/delete", { body: JSON.stringify({ venue_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getBars(venue_id) {
    return this.Post("/venues/bars", { body: JSON.stringify({ venue_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  addPermission(venue_id, email, role) {
    return this.Post("/venues/permission/add", {
      body: JSON.stringify({ venue_id, email, role }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getTerminals(query) {
    return this.Get("/venues/terminals?" + QueryString.stringify(query), {
      cache: "no-store",
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  setNotifications(venue_id, ls_alert_method) {
    return this.Put("/venues/notifications", {
      body: JSON.stringify({ venue_id, ls_alert_method }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
