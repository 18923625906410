import QueryString from 'query-string';
import { FetchService } from '.';

export default class TransferService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  get(query) {
    return this.Get('/transfers?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(venue_id, paid_amount, paid_at, status) {
    return this.Post('/transfers', { body: JSON.stringify({ venue_id, paid_amount, paid_at, status }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  put(transfer_id, venue_id, paid_amount, paid_at, status) {
    return this.Put('/transfers', { body: JSON.stringify({ transfer_id, venue_id, paid_amount, paid_at, status }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(transfer_id) {
    return this.Post('/transfers/delete', { body: JSON.stringify({ transfer_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
