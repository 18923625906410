import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import '../../styles/cards/warning.css';

class WarningCard extends React.Component {
  render() {
    const { title, message, btnText, onBtnClick } = this.props;
    return (
      <div className='warning-card'>
        <h1>{title}</h1>
        <p>{message}</p>
        {btnText && (
          <button
            className='warning-card-button'
            onClick={() => {
              onBtnClick();
            }}
          >
            {btnText}
          </button>
        )}
      </div>
    );
  }
}

WarningCard.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  btnText: PropTypes.string,
  onBtnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

WarningCard.defaultProps = {
  title: '',
  message: '',
  btnText: '',
  onBtnClick: Function.prototype,
};

export default withTranslation()(WarningCard);
