import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/fields/taginput.css';

class TagInput extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      input: '',
      isKeyReleased: false,
    };

    this.onKeyDown = this.onKeyDown.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onDeleteTag = this.onDeleteTag.bind(this);
  }

  onKeyDown(e) {
    const { key } = e;
    const { input } = this.state;
    const { tags, onlyLetter, upperCase, maxInput, maxInputLength, onTagsChanged } = this.props;

    let trimmedInput = input.trim().replace(/\s/g, '');
    trimmedInput = onlyLetter ? trimmedInput.replace(/[^A-Za-z]+/g, '') : trimmedInput;
    trimmedInput = upperCase ? trimmedInput.toUpperCase() : trimmedInput;

    if (tags.length >= maxInput) return;

    if ((key === ',' || key === 'Enter') && trimmedInput.length <= maxInputLength && !tags.includes(trimmedInput)) {
      e.preventDefault();

      this.setState(
        {
          tags: [...tags, trimmedInput],
          input: '',
        },
        () => {
          onTagsChanged(this.state.tags);
        }
      );
    }

    if (key === 'Backspace' && !input.length && tags.length) {
      e.preventDefault();

      const tagsCopy = [...tags];
      const poppedTag = tagsCopy.pop();

      this.setState(
        {
          tags: tagsCopy,
          input: poppedTag,
        },
        () => {
          onTagsChanged(this.state.tags);
        }
      );
    }
  }

  onKeyUp(e) {
    this.setState({
      isKeyReleased: true,
    });
  }

  onChange(e) {
    const { value } = e.target;

    this.setState({
      input: value,
    });
  }

  onDeleteTag(index) {
    const { tags, onTagsChanged } = this.props;

    this.setState(
      {
        tags: tags.filter((tag, i) => i !== index),
      },
      () => {
        onTagsChanged(this.state.tags);
      }
    );
  }

  render() {
    const { input } = this.state;
    const { tags, placeholder } = this.props;

    return (
      <div className='taginput-container'>
        {tags &&
          tags.map((tag, index) => (
            <div key={`tag${index}`} className='taginput-tag'>
              {tag}
              <button
                onClick={() => {
                  this.onDeleteTag(index);
                }}
              >
                x
              </button>
            </div>
          ))}
        <input value={input} placeholder={placeholder} onKeyUp={this.onKeyUp} onKeyDown={this.onKeyDown} onChange={this.onChange} />
      </div>
    );
  }
}

TagInput.propTypes = {
  onlyLetter: PropTypes.bool,
  upperCase: PropTypes.bool,
  maxInput: PropTypes.number,
  maxInputLength: PropTypes.number,
  tags: PropTypes.array,
  placeholder: PropTypes.string,
  onTagsChanged: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

TagInput.defaultProps = {
  onlyLetter: false,
  upperCase: false,
  maxInput: 64,
  maxInputLength: 64,
  tags: [],
  placeholder: '',
  onTagsChanged: Function.prototype,
};

export default TagInput;
