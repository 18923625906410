import React from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Toast } from '../components/alerts';
import { TextField, Select } from '../components/fields';
import { LoadingButton } from '../components/buttons';
import { SettingService } from '../services';
import { EntityError } from '../utils';

import '../styles/setting.css';

class SettingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      saving: false,
      transferRate: '',
      transferFixed: '',
      venueTransfer: null,
      venueTransferRate: '',
      venueTransferFixed: '',
      ppRate: '',
      ppFixed: '',
      invalidPost: {
        rate: false,
        fixed_rate: false,
        currency: false,
      },
    };

    this.fetchSettings = this.fetchSettings.bind(this);
    this.updateTransferFee = this.updateTransferFee.bind(this);
    this.fetchVenueTransferFee = this.fetchVenueTransferFee.bind(this);
    this.updatePaymentProvider = this.updatePaymentProvider.bind(this);
    this.onSavePaymentProviderBtnClick = this.onSavePaymentProviderBtnClick.bind(this);
    this.onSavePaymentFeeBtnClick = this.onSavePaymentFeeBtnClick.bind(this);
    this.onSaveVenuePaymentFeeBtnClick = this.onSaveVenuePaymentFeeBtnClick.bind(this);
    this.onFeeTypeChange = this.onFeeTypeChange.bind(this);
    this.onVenueTransferChange = this.onVenueTransferChange.bind(this);

    this.settingService = new SettingService();
  }

  componentDidMount() {
    this.fetchSettings();
  }

  async fetchSettings() {
    this.setState({ loading: true });

    const result = await this.settingService.get();

    if (result && result.success) {
      this.setState({
        ppRate: result.payment_provider_settings ? result.payment_provider_settings.rate : 0,
        ppFixed: result.payment_provider_settings ? result.payment_provider_settings.fixed_rate : 0,
        transferRate: result.transfer_settings ? result.transfer_settings.rate : 0,
        transferFixed: result.transfer_settings ? result.transfer_settings.fixed_rate : 0,
        loading: false,
      });
    }
  }

  async updateTransferFee() {
    this.setState({ saving: true });

    const { transferRate, transferFixed, invalidPost } = this.state;
    const { t } = this.props;
    const result = await this.settingService.updateTransferFees(transferRate, transferFixed);

    if (result && result.success) {
      Toast.success(t('Updated'));

      this.setState({
        saving: false,
      });
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }
  }

  async fetchVenueTransferFee() {
    const { venueTransfer } = this.state;

    const result = await this.settingService.getVenueTransferFees({ venue_id: venueTransfer._id });

    if (result && result.success) {
      this.setState({
        venueTransferRate: result.transfer_settings ? result.transfer_settings.rate : 0,
        venueTransferFixed: result.transfer_settings ? result.transfer_settings.fixed_rate : 0,
        loading: false,
      });
    }
  }

  async updateVenueTransferFee() {
    this.setState({ saving: true });

    const { venueTransfer, venueTransferRate, venueTransferFixed, invalidPost } = this.state;
    const { t } = this.props;
    const result = await this.settingService.updateVenueTransferFees(venueTransfer ? venueTransfer._id : null, venueTransferRate, venueTransferFixed);

    if (result && result.success) {
      Toast.success(t('Updated'));

      this.setState({
        saving: false,
      });
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }
  }

  async updatePaymentProvider() {
    this.setState({ saving: true });

    const { ppRate, ppFixed, invalidPost } = this.state;
    const { t } = this.props;
    const result = await this.settingService.updatePaymentProvider(ppRate, ppFixed);

    if (result && result.success) {
      Toast.success(t('Updated'));

      this.setState({
        saving: false,
      });
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }
  }

  async onSavePaymentFeeBtnClick() {
    await this.updateTransferFee();
  }

  async onSaveVenuePaymentFeeBtnClick() {
    await this.updateVenueTransferFee();
  }

  async onSavePaymentProviderBtnClick() {
    await this.updatePaymentProvider();
  }

  onFeeTypeChange(checked, value) {
    this.setState({ feeType: value });
  }

  onVenueTransferChange(venue) {
    this.setState({ venueTransfer: venue }, async () => {
      await this.fetchVenueTransferFee();
    });
  }

  render() {
    const { loading, saving, venueTransfer, transferRate, transferFixed, venueTransferRate, venueTransferFixed, ppRate, ppFixed, invalidPost } = this.state;
    const { t, label } = this.props;

    return (
      <div className='content'>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
        </div>
        <div className='content-body'>
          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : (
            <div>
              <Row>
                <Col md={6} className='setting-card-col'>
                  <div className='setting-card-container'>
                    <p className='setting-card-title'>{t('Default Transfer Fees')}</p>
                    <Row>
                      <Col md={12}>
                        <TextField
                          label={t('Percent Fee')}
                          fullWidth
                          value={transferRate}
                          error={invalidPost.rate}
                          errorText={t('Percent Fee must be numeric')}
                          onChange={(e) => this.setState({ transferRate: e.target.value })}
                          rightBtnLabel={'%'}
                        ></TextField>
                      </Col>
                      <Col md={12}>
                        <TextField
                          label={t('Fixed Fee')}
                          fullWidth
                          value={transferFixed}
                          error={invalidPost.fixed_rate}
                          errorText={t('Fixed Fee must be numeric')}
                          onChange={(e) => this.setState({ transferFixed: e.target.value })}
                          rightBtnLabel={'£'}
                        ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className='text-right'>
                        <LoadingButton text={t('Save')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onSavePaymentFeeBtnClick} />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6} className='setting-card-col'>
                  <div className='setting-card-container'>
                    <p className='setting-card-title'>{t('Venue Transfer Fees')}</p>
                    <Row>
                      <Col md={12}>
                        <Select
                          fullWidth
                          type='venues'
                          field='name'
                          label={t('Venue')}
                          error={invalidPost.venue_id}
                          errorText={t('Venue is required')}
                          value={venueTransfer}
                          defaultValue={venueTransfer}
                          onChange={this.onVenueTransferChange}
                        ></Select>
                      </Col>
                      <Col md={12}>
                        <TextField
                          label={t('Percent Fee')}
                          fullWidth
                          value={venueTransferRate}
                          error={invalidPost.rate}
                          errorText={t('Transfer Rate must be numeric')}
                          onChange={(e) => this.setState({ venueTransferRate: e.target.value })}
                          rightBtnLabel={'%'}
                        ></TextField>
                      </Col>
                      <Col md={12}>
                        <TextField
                          label={t('Fixed Fee')}
                          fullWidth
                          value={venueTransferFixed}
                          error={invalidPost.fixed_rate}
                          errorText={t('Fixed Fee must be numeric')}
                          onChange={(e) => this.setState({ venueTransferFixed: e.target.value })}
                          rightBtnLabel={'£'}
                        ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className='text-right'>
                        <LoadingButton text={t('Save')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onSaveVenuePaymentFeeBtnClick} />
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col md={6} className='setting-card-col'>
                  <div className='setting-card-container'>
                    <p className='setting-card-title'>{t('Payment Provider Fee')}</p>
                    <Row>
                      <Col md={12}>
                        <TextField
                          label={t('Percent Fee')}
                          fullWidth
                          value={ppRate}
                          error={invalidPost.rate}
                          errorText={t('Percent Fee must be numeric')}
                          onChange={(e) => this.setState({ ppRate: e.target.value })}
                          rightBtnLabel={'%'}
                        ></TextField>
                      </Col>
                      <Col md={12}>
                        <TextField
                          label={t('Fixed Fee')}
                          fullWidth
                          value={ppFixed}
                          error={invalidPost.fixed_rate}
                          errorText={t('Fixed Fee must be numeric')}
                          onChange={(e) => this.setState({ ppFixed: e.target.value })}
                          rightBtnLabel={'£'}
                        ></TextField>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className='text-right'>
                        <LoadingButton text={t('Save')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onSavePaymentProviderBtnClick} />
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SettingPage));
