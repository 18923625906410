import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTimes, faSearch, faFilter } from '@fortawesome/free-solid-svg-icons';
import { Select, FilterTag, DateTimePicker } from '.';
import { Generator, LocalStorage } from '../../utils';

import '../../styles/fields/filter.css';

class Filter extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      searchText: '',
      typing: false,
      typingTimeout: 0,
      showFilter: false,
      disableBar: true,
      showCustomDates: false,
      filterFields: {
        venue: null,
        bar: null,
        date: {
          start: null,
          end: null,
        },
      },
      data: {
        venue: null,
        bar: null,
        date: {
          start: null,
          end: null,
        },
      },
      minDate: null,
      dateRanges: [
        { label: 'Today', title: t('Today'), index: 0 },
        { label: 'Yesterday', title: t('Yesterday'), index: 1 },
        { label: 'This week', title: t('This week'), index: 2 },
        { label: 'This month', title: t('This month'), index: 3 },
        { label: 'Last 90 days', title: t('Last 90 days'), index: 4 },
      ],
      dateRangeIndex: -1,
    };

    this.filterRef = React.createRef();

    this.handleClick = this.handleClick.bind(this);
    this.showTags = this.showTags.bind(this);
    this.setFilterFields = this.setFilterFields.bind(this);
    this.setBarSelect = this.setBarSelect.bind(this);
    this.onSelectChange = this.onSelectChange.bind(this);
    this.onAddBtnClick = this.onAddBtnClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
    this.onFilterBtnClick = this.onFilterBtnClick.bind(this);
    this.onFilterOnBlur = this.onFilterOnBlur.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onFilterTagRemove = this.onFilterTagRemove.bind(this);
    this.onStartDateChange = this.onStartDateChange.bind(this);
    this.onEndDateChange = this.onEndDateChange.bind(this);
    this.onDateChange = this.onDateChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);

    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  handleClick(e) {
    if (this.filterRef.current && !this.filterRef.current.contains(e.target)) {
      this.onFilterOnBlur();
    }
  }

  showTags(set = true) {
    const { data } = this.state;

    if (set) {
      this.setState({
        filterFields: data,
      });
    }
  }

  setFilterFields(type, data = null, callback = null) {
    callback = callback ? callback : Function.prototype;

    let state = {
      data: {
        ...this.state.data,
      },
    };

    if (type) {
      Generator.updateByNotation(state.data, type, data);
    }

    // RESET BAR
    if (type === 'venue') {
      state.data.bar = null;
    }

    LocalStorage.setFilter(state.data);

    this.setState(state, () => {
      this.setBarSelect();

      callback();
    });
  }

  setBarSelect() {
    const { data } = this.state;

    this.setState({ disableBar: data.venue ? false : true });
  }

  onSelectChange(type, data) {
    this.setFilterFields(type, data);
  }

  onAddBtnClick() {
    const { data } = this.state;
    const { onFilterChange } = this.props;

    this.setState(
      {
        showFilter: false,
        filterFields: data,
      },
      () => {
        onFilterChange(this.state.filterFields);
      }
    );
  }

  onSearchChange(e) {
    this.setState({
      searchText: e.target.value,
    });
  }

  onKeyUp(e) {
    const { searchText, typingTimeout } = this.state;
    const { onSearchChange } = this.props;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(() => {
        onSearchChange(searchText);
      }, 500),
    });
  }

  onFilterBtnClick() {
    this.setState({
      showFilter: true,
    });
  }

  onFilterOnBlur() {
    this.setState({
      showFilter: false,
    });
  }

  onFilterChange(type, data) {
    const { onFilterChange } = this.props;

    this.setFilterFields(type, data);
    this.setState(
      {
        showFilter: false,
        filterFields: data,
      },
      () => {
        onFilterChange(this.state.filterFields);
      }
    );
  }

  onFilterTagRemove(type) {
    const { onFilterChange } = this.props;
    const { filterFields } = this.state;

    this.setFilterFields(type, null, () => {
      if (type === 'date') {
        filterFields.date = {
          start: null,
          end: null,
        };
      } else {
        filterFields[type] = null;
      }

      this.setState(
        {
          showFilter: false,
          filterFields: filterFields,
        },
        () => {
          onFilterChange(this.state.filterFields);
        }
      );
    });
  }

  onStartDateChange(date) {
    date = moment(moment(date).format('YYYY-MM-DD'));

    const start = {
      _id: date.unix(),
      date: date.toDate(),
      formated: date.format('YYYY-MM-DD'),
      name: moment(date).format('DD MMM'),
    };

    this.setState(
      {
        minDate: date.toDate(),
        data: {
          ...this.state.data,
          date: {
            ...this.state.data.date,
            start: start,
          },
        },
      },
      () => {
        this.setFilterFields('date.start', start);
      }
    );
  }

  onEndDateChange(date) {
    date = moment(moment(date).format('YYYY-MM-DD'));

    const end = {
      _id: date.unix(),
      date: date.toDate(),
      formated: date.format('YYYY-MM-DD'),
      name: moment(date).format('DD MMM yyyy'),
    };

    this.setState(
      {
        data: {
          ...this.state.data,
          date: {
            ...this.state.data.date,
            end: end,
          },
        },
      },
      () => {
        this.setFilterFields('date.end', end);
      }
    );
  }

  onDateChange(value) {
    this.setState({ dateRangeIndex: value.index }, () => {
      this.onStartDateChange(value.start);
      this.onEndDateChange(value.end);
    });
  }

  render() {
    const { searchText, showFilter, showCustomDates, filterFields, disableBar, data, minDate } = this.state;
    const { t, fields } = this.props;
    const filterFieldKeys = Object.keys(filterFields);

    return (
      <div className='filter-container'>
        <div className='filter-part'>
          <div className='filter-search'>
            <div className='filter-search-btn'>
              <FontAwesomeIcon icon={faSearch}></FontAwesomeIcon>
            </div>
            <input className='filter-search-box' placeholder={t('Search')} value={searchText} onChange={this.onSearchChange} onKeyUp={this.onKeyUp} />
          </div>
          {fields.length > 0 && (
            <div className='filter-btn-area'>
              <div className='filter-btn' onClick={this.onFilterBtnClick}>
                <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>
              </div>
              {showFilter && (
                <div className='filter-div' ref={this.filterRef}>
                  <p className='filter-title'> {t('Filter')}</p>
                  <span className='filter-div-close' onClick={this.onFilterOnBlur}>
                    <FontAwesomeIcon icon={faTimes} />
                  </span>

                  <Row>
                    {fields.indexOf('venue') > -1 && (
                      <Col md={12}>
                        <Select
                          fullWidth
                          type='venues'
                          field='name'
                          size='sm'
                          label={t('Venue')}
                          onChange={(venue) => {
                            this.onSelectChange('venue', venue);
                          }}
                        ></Select>
                      </Col>
                    )}
                    {fields.indexOf('bar') > -1 && (
                      <Col md={12}>
                        <Select
                          fullWidth
                          type='bars'
                          field='name'
                          size='sm'
                          label={t('Bar')}
                          disabled={disableBar}
                          query={filterFields.venue ? { venue_id: filterFields.venue._id } : null}
                          onChange={(bar) => {
                            this.onSelectChange('bar', bar);
                          }}
                        ></Select>
                      </Col>
                    )}
                    {showCustomDates && fields.indexOf('date') > -1 && (
                      <Col md={12}>
                        <DateTimePicker
                          label={t('Start Date')}
                          rightBtn={false}
                          defaultValue={data.date && data.date.start && data.date.start.date}
                          onChange={this.onStartDateChange}
                        />
                      </Col>
                    )}
                    {showCustomDates && fields.indexOf('date') > -1 && (
                      <Col md={12}>
                        <DateTimePicker
                          label={t('End Date')}
                          minDate={minDate}
                          rightBtn={false}
                          defaultValue={data.date && data.date.end && data.date.end.date}
                          onChange={this.onEndDateChange}
                        />
                      </Col>
                    )}
                    {!showCustomDates && fields.indexOf('date') > -1 && (
                      <Col md={12}>
                        <Select fullWidth type='date_ranges' field='name' size='sm' label={t('Date')} onChange={(value) => this.onDateChange(value)} translate></Select>
                      </Col>
                    )}
                    {fields.indexOf('date') > -1 && (
                      <Col md={12}>
                        <span
                          className='filter-show-custom-date'
                          onClick={() => {
                            this.setState({ showCustomDates: !showCustomDates });
                          }}
                        >
                          {showCustomDates ? t('Date') : t('Custom Date')}
                        </span>
                      </Col>
                    )}
                  </Row>

                  <div className='filter-footer'>
                    <button className='filter-add-btn' onClick={this.onAddBtnClick}>
                      <FontAwesomeIcon icon={faSave} size='sm' color='#222b45'></FontAwesomeIcon> {t('SAVE')}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {fields.length > 0 && (
          <div className='filter-header-part'>
            <div className='filter-tag-area'>
              {filterFieldKeys.map((filterFieldKey) => {
                const filterField = filterFields[filterFieldKey];

                if (filterField) {
                  if (filterFieldKey === 'date') {
                    if (filterField.start && filterField.end) {
                      return (
                        <FilterTag
                          key={filterField.start._id}
                          type={filterFieldKey}
                          label={filterField.start.name + ' - ' + filterField.end.name}
                          onRemove={this.onFilterTagRemove}
                        />
                      );
                    } else {
                      return null;
                    }
                  } else {
                    return <FilterTag key={filterField._id || filterField.id} type={filterFieldKey} label={filterField.name} onRemove={this.onFilterTagRemove} />;
                  }
                } else {
                  return null;
                }
              })}
            </div>
          </div>
        )}
      </div>
    );
  }
}

Filter.propTypes = {
  filterFields: PropTypes.object,
  filterFieldKeys: PropTypes.array,
  showFilter: PropTypes.bool,
  fields: PropTypes.arrayOf(PropTypes.oneOf(['venue', 'bar', 'date'])),
  onFilterChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

Filter.defaultProps = {
  fields: [],
  showFilter: false,
};

export default withTranslation()(Filter);
