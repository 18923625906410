import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DropDown } from '../fields';
import voucherIcon from '../../assets/images/voucher.png';

import '../../styles/cards/prize.css';

class PrizeCard extends React.Component {
  render() {
    const { t, prize, actions, data, onChange } = this.props;
    return (
      <div className='prize-card'>
        <div className='prize-card-left'>
          <img src={prize.photo ? prize.photo + '?v=' + moment().unix() : voucherIcon} alt='' />
        </div>
        <div className='prize-card-body'>
          <p className='prize-card-body-title'>{prize.name}</p>
          <p className='prize-card-body-subtitle'>{prize.description}</p>
          <p className='prize-card-body-subtitle'>
            <span className='prize-card-body-title-span'>{t('Expire Date')}</span> {moment(prize.date).format('DD MMM YYYY')}
          </p>
        </div>
        <div className='prize-card-right'>
          <DropDown bull={true} isTable={true} actions={actions} data={data} onChange={onChange} />
        </div>
      </div>
    );
  }
}

PrizeCard.propTypes = {
  prize: PropTypes.any,
  actions: PropTypes.array,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

PrizeCard.defaultProps = {
  actions: [],
  onChange: Function.prototype,
};

export default withTranslation()(PrizeCard);
