import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Converter } from '../../utils';

import userIcon from '../../assets/images/user.png';
import '../../styles/cards/prizeuser.css';

class PrizeUserCard extends React.Component {
  render() {
    const { report, metric } = this.props;
    return (
      <div className='prize-user-list'>
        <Row>
          <Col md={6} className='prize-user-col'>
            <span className='prize-user-title'>
              <img className='prize-user-user-photo' src={report.user.profile_photo && report.user.profile_photo.length > 0 ? report.user.profile_photo : userIcon} alt='' />
              {report.user.first_name} {report.user.last_name}
            </span>
          </Col>
          <Col md={6} className='prize-user-col'>
            <span className='prize-time'>{metric === 'AVERAGE_TIME' ? Converter.humanizeTime(report.average_waiting) : report.total_qty}</span>
          </Col>
        </Row>
      </div>
    );
  }
}

PrizeUserCard.propTypes = {
  report: PropTypes.any,
  index: PropTypes.number,
};

PrizeUserCard.defaultProps = {
  index: 0,
};

export default withTranslation()(PrizeUserCard);
