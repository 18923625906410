import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { DropDown } from '../fields';
import { GatewayService } from '../../services';

import '../../styles/cards/gateway.css';

class GatewayCard extends React.Component {
  constructor(props) {
    super(props);

    this.gatewayService = new GatewayService();
  }

  render() {
    const { t, gateway, actions, onChange } = this.props;

    return (
      <Col md={4}>
        <div className='gateway-card-container'>
          <div className='gateway-card-body'>
            <p className='gateway-card-title'>{gateway.name || ' '}</p>
            <div className='gateway-card-item-container'>
              <p className='gateway-card-item-title'>{t('Endpoint')}</p>
              <p className='gateway-card-item-subtitle'>{gateway.endpoint ? gateway.endpoint.url : ''}</p>
            </div>
          </div>
          <div className='gateway-card-right'>
            <DropDown bull={true} actions={actions} data={gateway} onChange={onChange} />
          </div>
        </div>
      </Col>
    );
  }
}

GatewayCard.propTypes = {
  gateway: PropTypes.object,
  actions: PropTypes.array,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

GatewayCard.defaultProps = {
  actions: [],
  onChange: Function.prototype,
};

export default withTranslation()(withRouter(GatewayCard));
