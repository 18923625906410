import React from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Filter } from '../components/fields';
import { IngredientModal, IngredientOptionsModal } from '../components/modals';
import { Confirm, Toast } from '../components/alerts';
import { IngredientService } from '../services';
import { LocalStorage } from '../utils';
import { IngredientCard } from '../components/cards';

import '../styles/inventory.css';

class InventoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchText: '',
      bars: [],
      filter: LocalStorage.getFilter(),
      ingredients: [],
    };

    this.addIngredientBtnClick = this.addIngredientBtnClick.bind(this);
    this.openOptionsBtnClick = this.openOptionsBtnClick.bind(this);
    this.fetchIngredients = this.fetchIngredients.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onOptionsComplete = this.onOptionsComplete.bind(this);
    this.onIngredientComplete = this.onIngredientComplete.bind(this);
    this.onIngredientCardChange = this.onIngredientCardChange.bind(this);

    this.ingredientService = new IngredientService();
  }

  componentDidMount() {
    this.fetchIngredients();
  }

  addIngredientBtnClick() {
    const { filter } = this.state;
    const { t } = this.props;

    if (filter.venue) {
      this.ingredientModal.show(true, filter.venue);
    } else {
      Toast.warning(t('Please select a venue from filter!'));
    }
  }

  openOptionsBtnClick() {
    const { filter } = this.state;
    const { t } = this.props;

    if (filter.venue) {
      this.ingredientOptionsModal.show(true, filter.venue);
    } else {
      Toast.warning(t('Please select a venue from filter!'));
    }
  }

  async fetchIngredients() {
    const { filter, searchText } = this.state;

    this.setState({ loading: true });

    const ingredientResult = await this.ingredientService.get({ venue_id: filter && filter.venue ? filter.venue._id : null, search: searchText });

    if (ingredientResult && ingredientResult.success) {
      this.setState({ ingredients: ingredientResult.ingredients, bars: ingredientResult.bars || [], filter: { ...filter, venue: ingredientResult.selected_venue } });

      if (this.filter) {
        this.filter.setFilterFields('venue', ingredientResult.selected_venue);
        this.filter.showTags();
      }
    }

    this.setState({ loading: false });
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
      },
      () => {
        this.fetchIngredients();
      }
    );
  }

  onFilterChange(filter) {
    if (filter.venue) {
      this.setState({ filter: filter }, () => {
        this.fetchIngredients();
      });
    } else {
      this.setState({ filter: {}, products: null }, () => {
        this.fetchIngredients();
      });
    }
  }

  onOptionsComplete() {
    const { t } = this.props;

    this.ingredientModal.show(false);
    Toast.success(t('Updated'));

    this.fetchIngredients();
  }

  onIngredientComplete() {
    const { t } = this.props;

    this.ingredientModal.show(false);
    Toast.success(t('Ingredient has been added!'));

    this.fetchIngredients();
  }

  async onIngredientCardChange(data, action) {
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'EDIT':
        this.ingredientModal.show(true);
        this.ingredientModal.setFields(data);
        break;
      case 'DELETE':
        const confirm = await Confirm.open(t('Ingredient Deletion'), t('Are you sure to delete?'), t('Yes'), t('No'));
        if (confirm) {
          this.setState({ loading: true });

          result = await this.ingredientService.delete(data._id);
        }
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchIngredients();
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, ingredients, bars, searchText } = this.state;
    const { t, me, label } = this.props;

    return (
      <div className='content'>
        <IngredientOptionsModal onRef={(ref) => (this.ingredientOptionsModal = ref)} onComplete={this.onOptionsComplete}></IngredientOptionsModal>
        <IngredientModal bars={bars} onRef={(ref) => (this.ingredientModal = ref)} onComplete={this.onIngredientComplete}></IngredientModal>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
              <>
                <button className='content-title-button default' onClick={this.openOptionsBtnClick}>
                  <FontAwesomeIcon icon={faCog} /> {t('OPTIONS')}
                </button>
                <button className='content-title-button' onClick={this.addIngredientBtnClick}>
                  <FontAwesomeIcon icon={faPlus} /> {t('ADD INGREDIENT')}
                </button>
              </>
            )}
          </div>
        </div>
        <div className='content-body'>
          <div className='content-header'>
            <Filter onRef={(ref) => (this.filter = ref)} searchText={searchText} fields={['venue']} onSearchChange={this.onSearchChange} onFilterChange={this.onFilterChange} />
          </div>
          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : (
            <div className='inventory-page-venue'>
              {ingredients && (
                <div>
                  <p className='inventory-page-subtitle'>{t('Ingredients')}</p>
                  <Row>
                    {ingredients.length < 1 && <Col md={12}>{t('No ingredients found')}</Col>}
                    {ingredients.map((ingredient) => {
                      return (
                        <IngredientCard
                          key={ingredient._id}
                          ingredient={ingredient}
                          actions={[
                            {
                              label: 'EDIT',
                              title: t('EDIT'),
                            },
                            {
                              label: 'DELETE',
                              title: t('DELETE'),
                              red: true,
                            },
                          ]}
                          onChange={this.onIngredientCardChange}
                        />
                      );
                    })}
                  </Row>
                </div>
              )}
              <br />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(InventoryPage));
