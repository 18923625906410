import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Converter } from '../../utils';

import '../../styles/cards/sumup.css';

class SumupCard extends React.Component {
  render() {
    const { t, title, result, currencySymbol } = this.props;

    return (
      <div className='sumup-card-container'>
        <p className='sumup-card-title'>{title}</p>
        <p className='sumup-card-title-alt'>{t('It may take a time to get latest transactions')}</p>
        <div className='sumup-card-table-container'>
          <Row>
            <Col md={2}>
              <p className='sumup-card-table-title'>{t('Total Sales')}</p>
              <p className='sumup-card-table-text'>
                {currencySymbol} {result && Converter.fixedFloat(result.total_sales).toFixed(2)}
              </p>
            </Col>
            <Col md={2}>
              <p className='sumup-card-table-title'>{t('Total Refunds')}</p>
              <p className='sumup-card-table-text'>
                {currencySymbol} {result && Converter.fixedFloat(result.total_refunds).toFixed(2)}
              </p>
            </Col>
            <Col md={2}>
              <p className='sumup-card-table-title'>{t('Total Gross')}</p>
              <p className='sumup-card-table-text'>
                {currencySymbol} {result && Converter.fixedFloat(result.total_sales - result.total_refunds).toFixed(2)}
              </p>
            </Col>
            <Col md={2}>
              <p className='sumup-card-table-title'>{t('Number of sales')}</p>
              <p className='sumup-card-table-text'>{result && Converter.fixedFloat(result.count_sales).toFixed(0)}</p>
            </Col>
            <Col md={2}>
              <p className='sumup-card-table-title'>{t('Number of refunds')}</p>
              <p className='sumup-card-table-text'>{result && Converter.fixedFloat(result.count_refunds).toFixed(0)}</p>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

SumupCard.propTypes = {
  title: PropTypes.string,
  result: PropTypes.object,
  currency: PropTypes.string,
  currencySymbol: PropTypes.string,
};

SumupCard.defaultProps = {
  title: '',
  result: { total_refunds: 0, total_sales: 0, count_refunds: 0, count_sales: 0 },
  currency: '',
  currencySymbol: '',
};

export default withTranslation()(SumupCard);
