import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import { DefaultTable } from '../components/tables';
import { Confirm, Toast } from '../components/alerts';
import { DetailsVenueModal, SaveOpeningHoursModal, SaveVenueModal, BankDetailsVenueModal, TerminalDetailsVenueModal, InviteModal } from '../components/modals';
import { VenueService } from '../services';

import '../styles/venue.css';

class VenuePage extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      loading: true,
      searchText: '',
      columns: [
        {
          title: t('NAME'),
          field: 'name',
        },
        {
          title: t('LOCATION'),
          field: 'contacts.address',
        },
        {
          title: t('EMAIL'),
          field: 'email',
        },
        {
          title: t('SERVICE TYPE'),
          field: 'service_type',
          titleStyle: {
            textAlign: 'center',
          },
          style: { textAlign: 'center' },
          render: (value) => {
            switch (value) {
              case 1:
                return t('Pick up and table service');
              case 2:
                return t('Pick up');
              case 3:
                return t('Table service');
              default:
                break;
            }
          },
        },
        {
          title: t('BARS'),
          field: 'bar_count',
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
        },
        {
          title: t('STATUS'),
          field: 'status',
          switch: true,
          titleStyle: {
            textAlign: 'center',
          },
          style: { textAlign: 'center' },
        },
        {
          title: t('ACTIONS'),
          field: 'actions',
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
          action: true,
        },
      ],
      rows: [],
      selectedVenueId: null,
      venue: null,
      pagination: {
        skip: 0,
        limit: 10,
        count: 0,
      },
    };

    this.fetchVenues = this.fetchVenues.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSwitchChange = this.onSwitchChange.bind(this);
    this.inviteBtnClick = this.inviteBtnClick.bind(this);
    this.addVenueBtnClick = this.addVenueBtnClick.bind(this);
    this.onAddVenueComplete = this.onAddVenueComplete.bind(this);
    this.onSaveOpeningHoursComplete = this.onSaveOpeningHoursComplete.bind(this);
    this.onDetailVenueComplete = this.onDetailVenueComplete.bind(this);
    this.onBankDetailVenueComplete = this.onBankDetailVenueComplete.bind(this);
    this.onTerminalDetailVenueComplete = this.onTerminalDetailVenueComplete.bind(this);
    this.onActionChange = this.onActionChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);

    this.venueService = new VenueService();
  }

  componentDidMount() {
    this.fetchVenues();
  }

  async fetchVenues() {
    const { searchText, pagination } = this.state;
    const result = await this.venueService.get({ search: searchText, skip: pagination.skip || 0, limit: pagination.limit || 10 });

    if (result && result.success) {
      this.setState({ rows: result.venues, pagination: result.pagination, loading: false });
    } else if (result && result.ecode === 214) {
      this.setState({ rows: [], loading: false });
    }
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
        pagination: {
          skip: 0,
          limit: 10,
          count: 0,
        },
      },
      () => {
        this.fetchVenues();
      }
    );
  }

  async onSwitchChange(checked, row, rowIndex) {
    const rowId = row._id;
    const result = await this.venueService.setStatus(rowId, checked);

    if (result && result.success) {
      await this.fetchVenues();
    }
  }

  inviteBtnClick() {
    this.inviteModal.show(true);
  }

  addVenueBtnClick() {
    this.setState(
      {
        selectedVenueId: null,
      },
      () => {
        this.saveVenueModal.show(true);
      }
    );
  }

  async onAddVenueComplete(result) {
    const { t } = this.props;

    if (result) {
      await this.fetchVenues();
      Toast.success(t('Updated'));
    }
  }

  async onSaveOpeningHoursComplete(result) {
    const { t } = this.props;

    if (result) {
      await this.fetchVenues();
      Toast.success(t('Updated'));
    }
  }

  async onDetailVenueComplete(result) {
    const { t } = this.props;

    if (result) {
      await this.fetchVenues();
      Toast.success(t('Updated'));
    }
  }

  async onBankDetailVenueComplete(result) {
    const { t } = this.props;

    if (result) {
      await this.fetchVenues();
      Toast.success(t('Updated'));
    }
  }

  async onTerminalDetailVenueComplete(result) {
    const { t } = this.props;

    if (result) {
      await this.fetchVenues();
      Toast.success(t('Updated'));
    }
  }

  async onActionChange(data, action) {
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'EDIT':
        this.setState(
          {
            selectedVenueId: data._id,
            venue: data,
          },
          () => {
            this.saveVenueModal.show(true);
            this.saveVenueModal.setFields(data);
          }
        );
        break;
      case 'OPENING HOURS':
        this.setState(
          {
            selectedVenueId: data._id,
            venue: data,
          },
          () => {
            this.saveOpeningHoursModal.show(true);
            this.saveOpeningHoursModal.setFields(data);
          }
        );
        break;
      case 'DETAILS':
        this.setState(
          {
            selectedVenueId: data._id,
            venue: data,
          },
          () => {
            this.detailsVenueModal.show(true);
            this.detailsVenueModal.setFields(data);
          }
        );
        break;
      case 'BANK DETAILS':
        this.setState(
          {
            selectedVenueId: data._id,
            venue: data,
          },
          () => {
            this.bankDetailsVenueModal.show(true);
            this.bankDetailsVenueModal.setFields(data);
          }
        );
        break;
      case 'TERMINAL PROVIDER':
        this.setState(
          {
            selectedVenueId: data._id,
            venue: data,
          },
          () => {
            this.terminalDetailsVenueModal.show(true);
            this.terminalDetailsVenueModal.setFields(data);
          }
        );
        break;
      case 'DELETE':
        const confirm = await Confirm.open(t('Venue Deletion'), t('Are you sure to delete?'), t('Yes'), t('No'));
        if (confirm) {
          this.setState({ loading: true });
          result = await this.venueService.delete(data._id);
        }
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchVenues();
    } else {
      this.setState({ loading: false });
    }
  }

  onPaginationChange(type, value) {
    switch (type) {
      case 'LIMIT':
        this.setState({ pagination: { ...this.state.pagination, skip: 0, limit: value } }, () => {
          this.fetchVenues();
        });
        break;
      case 'NAVIGATE':
        this.setState({ pagination: value }, () => {
          this.fetchVenues();
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { loading, venue, columns, rows, searchText, selectedVenueId, pagination } = this.state;
    const { t, me, label } = this.props;

    return (
      <div className='content'>
        <InviteModal onRef={(ref) => (this.inviteModal = ref)}></InviteModal>
        <SaveVenueModal venueId={selectedVenueId} onRef={(ref) => (this.saveVenueModal = ref)} onComplete={this.onAddVenueComplete}></SaveVenueModal>
        <SaveOpeningHoursModal
          venueId={selectedVenueId}
          venue={venue}
          onRef={(ref) => (this.saveOpeningHoursModal = ref)}
          onComplete={this.onSaveOpeningHoursComplete}
        ></SaveOpeningHoursModal>
        <DetailsVenueModal venueId={selectedVenueId} venue={venue} onRef={(ref) => (this.detailsVenueModal = ref)} onComplete={this.onDetailVenueComplete}></DetailsVenueModal>
        <BankDetailsVenueModal
          venueId={selectedVenueId}
          venue={venue}
          onRef={(ref) => (this.bankDetailsVenueModal = ref)}
          onComplete={this.onBankDetailVenueComplete}
        ></BankDetailsVenueModal>
        <TerminalDetailsVenueModal
          venueId={selectedVenueId}
          venue={venue}
          onRef={(ref) => (this.terminalDetailsVenueModal = ref)}
          onComplete={this.onTerminalDetailVenueComplete}
        ></TerminalDetailsVenueModal>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
              <button className='content-title-button' onClick={this.inviteBtnClick}>
                <FontAwesomeIcon icon={faUserFriends} /> {t('INVITE')}
              </button>
            )}
            {me.role === 'ADMIN' && (
              <button className='content-title-button' onClick={this.addVenueBtnClick}>
                <FontAwesomeIcon icon={faPlus} /> {t('ADD VENUE')}
              </button>
            )}
          </div>
        </div>
        <div className='content-body'>
          <DefaultTable
            columns={columns}
            rows={rows}
            searchText={searchText}
            loading={loading}
            pagination={pagination}
            actions={[
              {
                label: 'EDIT',
                title: t('EDIT'),
              },
              {
                label: 'OPENING HOURS',
                title: t('OPENING HOURS'),
              },
              {
                label: 'DETAILS',
                title: t('DETAILS'),
              },
              {
                label: 'BANK DETAILS',
                title: t('BANK DETAILS'),
              },
              {
                label: 'TERMINAL PROVIDER',
                title: t('TERMINAL PROVIDER'),
              },
              {
                label: 'DELETE',
                title: t('DELETE'),
                red: true,
              },
            ]}
            onSearchChange={this.onSearchChange}
            onSwitchChange={this.onSwitchChange}
            onActionChange={this.onActionChange}
            onPaginationChange={this.onPaginationChange}
          ></DefaultTable>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(VenuePage));
