import { FetchService } from '.';

export default class AuthService extends FetchService {
  constructor() {
    super();

    this.login = this.login.bind(this);
    this.signup = this.signup.bind(this);
    this.logout = this.logout.bind(this);
    this.me = this.me.bind(this);
    this.reset = this.reset.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  login(email, password) {
    return this.Post('/login', {
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  signup(first_name, last_name, email, password, confirm_password) {
    return this.Post('/signup', {
      body: JSON.stringify({
        first_name,
        last_name,
        email,
        password,
        confirm_password,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  logout() {
    return this.Post('/logout')
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  me() {
    return this.Post('/me')
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  reset(user_id, refresh_token, password, confirm_password) {
    return this.Post('/reset', {
      body: JSON.stringify({
        user_id,
        refresh_token,
        password,
        confirm_password,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  resetPassword(email) {
    return this.Post('/reset/password', {
      body: JSON.stringify({
        email,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
