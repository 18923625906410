import React from 'react';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DefaultTable } from '../components/tables';
import { Tab } from '../components/fields';
import { RefundOrderModal, ViewOrderModal } from '../components/modals';
import { OrderService } from '../services';
import { Converter, LocalStorage } from '../utils';

import '../styles/main.css';
import { WarningCard } from '../components/cards';
import { Toast } from '../components/alerts';

class MainPage extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;

    this.state = {
      loading: false,
      searchText: '',
      columns: [
        {
          title: t('ID'),
          field: 'id',
          titleStyle: {
            width: '100px',
          },
        },
        {
          title: t('VENUE'),
          field: 'venue.name',
        },
        {
          title: t('BAR'),
          field: 'bar.name',
        },
        {
          title: t('ORDER TIME'),
          field: 'date',
          render: (value) => {
            return moment(value).format('DD MMM YYYY, HH:mm');
          },
        },
        {
          title: t('CUSTOMER'),
          field: 'customer',
          titleStyle: {
            width: '200px',
          },
          render: (value) => {
            return value ? value.first_name + ' ' + value.last_name : t('ISSUED BY BARTENDER');
          },
        },
        {
          title: t('STATUS'),
          field: 'status',
          render: (value) => {
            return Converter.capitalize(value);
          },
        },
        {
          title: t('ACTIONS'),
          field: 'actions',
          titleStyle: {
            textAlign: 'center',
          },
          style: { textAlign: 'center' },
          action: true,
        },
      ],
      rows: [],
      filter: LocalStorage.getFilter(),
      status: null,
      selectedVenue: null,
      pagination: {
        skip: 0,
        limit: 10,
        count: 0,
      },
      uncompletedCount: 0,
    };

    this.fetchOrders = this.fetchOrders.bind(this);
    this.countUncompletedOrders = this.countUncompletedOrders.bind(this);
    this.setUncompletedOrders = this.setUncompletedOrders.bind(this);
    this.onTabClick = this.onTabClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onActionChange = this.onActionChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
    this.onRefundOrderCompleted = this.onRefundOrderCompleted.bind(this);
    this.onSetUncompletedOrders = this.onSetUncompletedOrders.bind(this);

    this.orderService = new OrderService();
  }

  componentDidMount() {
    this.fetchOrders();
  }

  async fetchOrders() {
    this.setState({ loading: true });

    const { searchText, filter, status, pagination } = this.state;
    const result = await this.orderService.get({
      search: searchText,
      venue_id: filter.venue ? filter.venue._id : undefined,
      start_date: filter.date && filter.date.start ? filter.date.start.formated : undefined,
      end_date: filter.date && filter.date.end ? filter.date.end.formated : undefined,
      status: status || undefined,
      skip: pagination.skip || 0,
      limit: pagination.limit || 10,
    });

    if (result && result.success) {
      this.setState({ rows: result.orders, selectedVenue: result.selected_venue, pagination: result.pagination, loading: false });

      if (this.table) {
        const filter = this.table.getFilter();
        filter.setFilterFields('venue', result.selected_venue);
        filter.onStartDateChange(Converter.toLocalDateTime(result.start_date), 'YYYY-MM-DD');
        filter.onEndDateChange(Converter.toLocalDateTime(result.end_date), 'YYYY-MM-DD');
        filter.showTags();
      }

      await this.countUncompletedOrders();
    } else {
      this.setState({ loading: false });
    }
  }

  async countUncompletedOrders() {
    const { filter } = this.state;
    const result = await this.orderService.countUncompletedOrders(filter.venue ? filter.venue._id : null);

    if (result && result.success) {
      this.setState({ uncompletedCount: result.count });
    }
  }

  async setUncompletedOrders() {
    const { t } = this.props;
    const { filter } = this.state;
    const result = await this.orderService.setUncompletedOrders(filter.venue ? filter.venue._id : null);

    if (result && result.success) {
      this.setState({ uncompletedCount: 0 });
      Toast.success(t('Orders are completed!'));
    }
  }

  onTabClick(index, tab) {
    this.setState({ status: tab.value }, () => {
      this.fetchOrders();
    });
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
        pagination: {
          skip: 0,
          limit: 10,
          count: 0,
        },
      },
      () => {
        this.fetchOrders();
      }
    );
  }

  onActionChange(data, action) {
    const { selectedVenue } = this.state;

    switch (action.label) {
      case 'VIEW':
        this.viewOrderModal.show(true, data, selectedVenue.currency || null);
        break;
      case 'REFUND':
        this.refundOrderModal.show(true, data, selectedVenue.currency || null, selectedVenue.terminal_provider);
        break;
      default:
        break;
    }
  }

  onFilterChange(filter) {
    this.setState({ filter: filter }, () => {
      this.fetchOrders();
    });
  }

  onPaginationChange(type, value) {
    switch (type) {
      case 'LIMIT':
        this.setState({ pagination: { ...this.state.pagination, skip: 0, limit: value } }, () => {
          this.fetchOrders();
        });
        break;
      case 'NAVIGATE':
        this.setState({ pagination: value }, () => {
          this.fetchOrders();
        });
        break;
      default:
        break;
    }
  }

  async onRefundOrderCompleted() {
    await this.fetchOrders();
  }

  async onSetUncompletedOrders() {
    await this.setUncompletedOrders();
    await this.fetchOrders();
  }

  render() {
    const { loading, columns, rows, searchText, pagination, uncompletedCount } = this.state;
    const { t, label } = this.props;

    return (
      <div className='content'>
        <ViewOrderModal onRef={(ref) => (this.viewOrderModal = ref)} />
        <RefundOrderModal onRef={(ref) => (this.refundOrderModal = ref)} onComplete={this.onRefundOrderCompleted} />
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'></div>
        </div>
        <div className='content-body'>
          <Tab
            tabs={[
              { title: t('ALL'), value: null },
              { title: t('PENDING'), value: 'PENDING' },
              { title: t('PREPARING'), value: 'PREPARING' },
              { title: t('READY'), value: 'READY' },
              { title: t('COMPLETED'), value: 'COMPLETED' },
              { title: t('REFUNDED'), value: 'REFUNDED' },
            ]}
            onTabClick={this.onTabClick}
          />

          {uncompletedCount > 0 && (
            <WarningCard
              title={t('Uncompleted Orders')}
              message={t('There are some uncompleted orders from yesterday. You can complete these orders immediately.')}
              btnText={`${t('Complete Orders')} (${uncompletedCount})`}
              onBtnClick={this.onSetUncompletedOrders}
            />
          )}

          <DefaultTable
            onRef={(ref) => (this.table = ref)}
            columns={columns}
            rows={rows}
            searchText={searchText}
            loading={loading}
            filter={['venue', 'date']}
            pagination={pagination}
            actions={[
              {
                label: 'VIEW',
                title: t('VIEW'),
              },
              {
                label: 'REFUND',
                title: t('REFUND'),
              },
            ]}
            onSearchChange={this.onSearchChange}
            onActionChange={this.onActionChange}
            onFilterChange={this.onFilterChange}
            onPaginationChange={this.onPaginationChange}
          ></DefaultTable>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(MainPage));
