import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { ProfileService } from '../../services';
import { EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField } from '../fields';
import { ModalLayout } from '../layouts';
import { Toast } from '../alerts';

import '../../styles/modals/modal.css';

class InviteModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      email: '',
      invalidPost: {
        email: false,
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.onSendBtnClick = this.onSendBtnClick.bind(this);

    this.profileService = new ProfileService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      email: '',
      venue: null,
      role: null,
      invalidPost: {
        email: false,
        venue_id: false,
        role: false,
        not_found: false,
      },
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  async onSendBtnClick() {
    const { email, invalidPost } = this.state;
    const { t } = this.props;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = await this.profileService.invite(email);

    if (result && result.success) {
      this.show(false);

      Toast.info(t('The invitation has been sent!'));
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({ loading: false });
  }

  render() {
    const { show, loading, email, invalidPost } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout title={t('Invite')}>
          <Row>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Email')}
                error={invalidPost.email || invalidPost.not_found}
                errorText={t('Email is an invalid')}
                value={email}
                onChange={(e) => this.setState({ email: e.target.value })}
              ></TextField>
            </Col>
          </Row>

          {invalidPost.not_found && <span className='error-text'>{t('This email could not be found or activated.')}</span>}

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={t('INVITE')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSendBtnClick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(InviteModal);
