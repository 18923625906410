import React from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Col, Row } from 'react-bootstrap';
import { Filter } from '../components/fields';
import { EventCard } from '../components/cards';
import { Confirm } from '../components/alerts';
import { EventService } from '../services';
import { LocalStorage } from '../utils';
import { Converter } from '../utils';

import '../styles/event.css';

class EventPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchText: '',
      selectedVenue: null,
      events: null,
      filter: LocalStorage.getFilter(),
    };

    this.fetchEvents = this.fetchEvents.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onActionChange = this.onActionChange.bind(this);

    this.eventService = new EventService();
  }

  componentDidMount() {
    this.fetchEvents();
  }

  async fetchEvents() {
    const { searchText, filter } = this.state;
    const result = await this.eventService.get({
      search: searchText,
      venue_id: filter.venue ? filter.venue._id : undefined,
      start_date: filter.date && filter.date.start ? filter.date.start.formated : undefined,
      end_date: filter.date && filter.date.end ? filter.date.end.formated : undefined,
    });

    if (result && result.success) {
      this.setState({ loading: false, events: result.events, selectedVenue: result.selected_venue });

      if (this.filter) {
        this.filter.setFilterFields('venue', result.selected_venue);
        this.filter.onStartDateChange(Converter.toLocalDateTime(result.start_date), 'YYYY-MM-DD');
        this.filter.onEndDateChange(Converter.toLocalDateTime(result.end_date), 'YYYY-MM-DD');
        this.filter.showTags();
      }
    }
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
      },
      () => {
        this.fetchEvents();
      }
    );
  }

  onFilterChange(filter) {
    this.setState({ filter: filter }, () => {
      this.fetchEvents();
    });
  }

  async onActionChange(data, action) {
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'DELETE':
        const confirm = await Confirm.open(t('Event Deletion'), t('Are you sure to delete?'), t('Yes'), t('No'));
        if (confirm) {
          this.setState({ loading: true });
          result = await this.eventService.delete(data._id);
        }
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchEvents();
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, searchText, selectedVenue, events } = this.state;
    const { t, label } = this.props;

    return (
      <div className='content'>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'></div>
        </div>
        <div className='content-body'>
          <div className='content-header'>
            <Filter
              onRef={(ref) => (this.filter = ref)}
              searchText={searchText}
              fields={['venue', 'date']}
              onSearchChange={this.onSearchChange}
              onFilterChange={this.onFilterChange}
            />
          </div>

          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : (
            <Row>
              {(!events || events.length < 1) && (
                <Col md={12}>
                  <p className='event-no-record'>{t('No Data Found')}</p>
                </Col>
              )}
              {events &&
                events.map((event) => {
                  return (
                    <EventCard
                      key={event._id}
                      selectedVenue={selectedVenue}
                      event={event}
                      actions={[
                        {
                          label: 'DELETE',
                          title: t('DELETE'),
                          red: true,
                        },
                      ]}
                      onActionChange={this.onActionChange}
                    />
                  );
                })}
            </Row>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(EventPage));
