import React from 'react';
import PropTypes from 'prop-types';
import { Converter } from '../../utils';

class CheckBox extends React.Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick(e) {
    const checked = e.target.checked;
    const { value, onChange } = this.props;

    this.setState({ checked: checked }, () => {
      onChange(checked, value);
    });
  }

  render() {
    const { text, checked } = this.props;

    return (
      <label className='checkbox-container'>
        {Converter.capitalize(text)}
        <input type='checkbox' checked={checked} onChange={this.onClick} />
        <span className='checkbox-checkmark'></span>
      </label>
    );
  }
}

CheckBox.propTypes = {
  text: PropTypes.string,
  value: PropTypes.any,
  checked: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

CheckBox.defaultProps = {
  text: '',
  value: null,
  checked: false,
  onChange: Function.prototype,
};

export default CheckBox;
