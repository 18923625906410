import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { DropDown } from '../fields';
import { Converter } from '../../utils';
import { IngredientService } from '../../services';

import '../../styles/cards/ingredient.css';

class IngredientCard extends React.Component {
  constructor(props) {
    super(props);

    this.ingredientService = new IngredientService();
  }

  render() {
    const { t, ingredient, actions, onChange } = this.props;
    const venueReserve = Converter.getValueByUnit(ingredient.venue_reserve, ingredient.unit);
    const threshold = ingredient.threshold || 0;

    let totalReserve = 0;
    totalReserve += parseFloat(venueReserve);

    return (
      <Col md={4}>
        <div className='ingredient-card-container'>
          <div className='ingredient-card-body'>
            <p className='ingredient-card-title'>{ingredient.name}</p>
            <div className='ingredient-card-item-container'>
              <p className='ingredient-card-item-title'>{t('Venue Reserve')}</p>
              <p className='ingredient-card-item-subtitle'>
                {venueReserve} {ingredient.unit}
              </p>
            </div>
            {ingredient.bar_reserves &&
              ingredient.bar_reserves.map((barReserve) => {
                const reserve = barReserve.reserve;
                const levelByVenueReserve = reserve <= 0 ? 'red' : threshold >= reserve ? 'orange' : 'white';

                totalReserve += parseFloat(reserve);

                return (
                  <div className={`ingredient-card-item-container ${levelByVenueReserve}`} key={barReserve._id}>
                    <p className='ingredient-card-item-title'>{barReserve.bar.name}</p>
                    <p className='ingredient-card-item-subtitle'>
                      {Converter.getValueByUnit(reserve, ingredient.unit)} {ingredient.unit}
                    </p>
                  </div>
                );
              })}
            <div className='ingredient-card-item-container'>
              <p className='ingredient-card-item-title'>{t('Total Reserve')}</p>
              <p className='ingredient-card-item-subtitle black'>
                {Converter.getValueByUnit(totalReserve, ingredient.unit)} {ingredient.unit}
              </p>
            </div>
          </div>
          <div className='ingredient-card-right'>
            <DropDown bull={true} actions={actions} data={ingredient} onChange={onChange} />
          </div>
        </div>
      </Col>
    );
  }
}

IngredientCard.propTypes = {
  ingredient: PropTypes.object,
  actions: PropTypes.array,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

IngredientCard.defaultProps = {
  actions: [],
  onChange: Function.prototype,
};

export default withTranslation()(withRouter(IngredientCard));
