import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import Lottie from 'react-lottie';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { DefaultButton } from '../buttons';
import { OrderService } from '../../services';
import warningLottie from '../../assets/lotties/warning.json';
import cardMachineLottie from '../../assets/lotties/card-machine.json';

import '../../styles/cards/refundstatus.css';

const STAGES = { COMMUNICATING_WITH_TERMINAL: 0, REFUND_STARTED: 1, PROCESSING_REFUND: 2 };

class RefundStatusCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      stage: 0,
      terminal: null,
      order: null,
      reason: null,
      requestId: null,
      error: null,
      intervalId: null,
    };

    this.clearInterval = this.clearInterval.bind(this);
    this.reset = this.reset.bind(this);
    this.start = this.start.bind(this);
    this.process = this.process.bind(this);
    this.onClose = this.onClose.bind(this);

    this.orderService = new OrderService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  clearInterval() {
    const { intervalId } = this.state;

    if (intervalId !== null) {
      clearInterval(intervalId);
    }
  }

  reset(callback) {
    callback = callback ? callback : Function.prototype;

    this.clearInterval();
    this.setState(
      {
        stage: 0,
        terminal: null,
        order: null,
        reason: null,
        requestId: null,
        error: null,
        intervalId: null,
      },
      () => {
        callback();
      }
    );
  }

  async start(terminal, order, reason) {
    const { t } = this.props;

    this.setState({ stage: 0, requestId: null, error: null, terminal: terminal, order: order, reason: reason }, async () => {
      const terminalId = terminal.tid;
      const result = await this.orderService.startRefundByTerminal(order._id, terminalId);

      if (result && result.success) {
        this.setState({ requestId: result.request_id }, () => {
          this.process();
        });
      } else if (result && result.status === 422 && result.message && result.message.includes('Terminal is busy')) {
        this.setState({ error: `${terminalId} ${t('Terminal is busy. Please check your terminal and try again.')}` });
      }
    });
  }

  async process() {
    const { order, terminal, requestId, reason } = this.state;
    const { t, onComplete } = this.props;

    this.setState({ stage: 1, error: null }, async () => {
      const intervalId = setInterval(async () => {
        const terminalId = terminal.tid;

        const result = await this.orderService.getTerminalStatus(order._id, terminalId, requestId, reason);

        if (result.success && result.transaction_result === result.transaction_result_list[0]) {
          // SUCCESSFUL
          this.reset(() => {
            onComplete();
          });
        } else if (result.success && result.transaction_result === result.transaction_result_list[1]) {
          // CANCELLED
          this.clearInterval();
          this.setState({ error: t('The refund has been cancelled by terminal. Please try again.') });
        } else if (result.success && result.transaction_result === result.transaction_result_list[2]) {
          // TIMED_OUT
          this.clearInterval();
          this.setState({ error: t('The connection is timed out. Please try again.') });
        } else if (result.success && result.transaction_result !== null) {
          // TIMED_OUT
          this.clearInterval();
          this.setState({ error: t('The refund has been declined. Please try again.') });
        } else if (!result.success) {
          this.clearInterval();
          this.setState({ error: t('Connection has been lost. Please try again.') });
        }
      }, 2000);

      this.setState({ intervalId: intervalId });
    });
  }

  onClose() {
    const { onClose } = this.props;

    this.reset(() => {
      onClose();
    });
  }

  render() {
    const { stage, terminal, order, error } = this.state;
    const { t } = this.props;

    return (
      <div className='refund-status-card'>
        {error && (
          <div className='refund-status-card-close' onClick={() => this.onClose()}>
            <FontAwesomeIcon icon={faTimes} />
          </div>
        )}
        {stage === STAGES.COMMUNICATING_WITH_TERMINAL && (
          <div>
            <div className='refund-status-card-icon'>
              {error ? (
                <Lottie
                  width='64px'
                  height='64px'
                  options={{
                    loop: true,
                    animationData: warningLottie,
                  }}
                />
              ) : (
                <ReactLoading type='spin' color='#000' height='36px' width='36px' />
              )}
            </div>
            <p className={`refund-status-card-content ${error ? 'error' : ''}`}>{error ? error : t('Communicating with terminal')}</p>
            {error && (
              <p className='refund-status-card-content'>
                <DefaultButton text={t('Try Again')} onClick={() => this.start(terminal, order)} />
              </p>
            )}
          </div>
        )}
        {stage === STAGES.REFUND_STARTED && (
          <div>
            <div>
              {!error && (
                <p className='refund-status-card-content'>
                  <b>{t('Do NOT close browser or tab!')}</b>
                </p>
              )}
              <div className='refund-status-card-icon'>
                {error ? (
                  <Lottie
                    width='64px'
                    height='64px'
                    options={{
                      loop: true,
                      animationData: warningLottie,
                    }}
                  />
                ) : (
                  <Lottie
                    width='128px'
                    height='128px'
                    options={{
                      loop: true,
                      animationData: cardMachineLottie,
                    }}
                  />
                )}
              </div>
              <p className={`refund-status-card-content ${error ? 'error' : ''}`}>{error ? error : t('Please present the card or input the card details on terminal.')}</p>
              {error && (
                <p className='refund-status-card-content'>
                  <DefaultButton text={t('Close')} onClick={() => this.onClose()} />
                </p>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

RefundStatusCard.propTypes = {
  stage: PropTypes.number,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onClose: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

RefundStatusCard.defaultProps = {
  stage: 0,
  onChange: Function.prototype,
  onClose: Function.prototype,
};

export default withTranslation()(RefundStatusCard);
