import QueryString from 'query-string';
import { FetchService } from '.';

export default class GatewayService extends FetchService {
  constructor() {
    super();

    this.getOrderFields = this.getOrderFields.bind(this);
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
    this.getProfiles = this.getProfiles.bind(this);
  }

  getOrderFields(query) {
    return this.Get('/gateways/orderfields?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  get(query) {
    return this.Get('/gateways?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(venue_id, name, authorization_scheme, authorization_parameter, authorization_base64, headers, endpoint_url, routes, fields = null) {
    return this.Post('/gateways', {
      body: JSON.stringify({
        venue_id,
        name,
        authorization_scheme,
        authorization_parameter,
        authorization_base64,
        headers,
        endpoint_url,
        routes,
        fields: fields || undefined,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  put(gateway_id, name, authorization_scheme, authorization_parameter, authorization_base64, headers, endpoint_url, routes, fields = null) {
    return this.Put('/gateways', {
      body: JSON.stringify({
        gateway_id,
        name,
        authorization_scheme,
        authorization_parameter,
        authorization_base64,
        headers,
        endpoint_url,
        routes,
        fields: fields || undefined,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(gateway_id) {
    return this.Post('/gateways/delete', {
      body: JSON.stringify({
        gateway_id,
      }),
    })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getProfiles(query) {
    return this.Get('/gateways/profiles?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
