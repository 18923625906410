import React from 'react';
import ReactLoading from 'react-loading';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faCheck, faTimes, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { BarService, EmployeeService } from '../../services';
import { Validator } from '../../utils';
import userIcon from '../../assets/images/user.png';

import '../../styles/fields/addmember.css';

class AddMemberField extends React.Component {
  constructor(props) {
    super(props);

    const { members } = this.props;

    this.state = {
      loading: true,
      addPage: false,
      employees: [],
      members: members || [],
    };

    this.addMemberBtnClick = this.addMemberBtnClick.bind(this);
    this.backBtnClick = this.backBtnClick.bind(this);
    this.fetchEmployees = this.fetchEmployees.bind(this);
    this.onEmployeeSelect = this.onEmployeeSelect.bind(this);
    this.removeMemberBtnClick = this.removeMemberBtnClick.bind(this);

    this.barService = new BarService();
    this.employeeService = new EmployeeService();
  }

  async addMemberBtnClick() {
    this.setState({ addPage: true, loading: true });

    await this.fetchEmployees();
  }

  backBtnClick() {
    this.setState({ addPage: false });
  }

  async fetchEmployees() {
    const { venueId } = this.props;
    const result = await this.employeeService.get({ group_by_user: true, venue_id: venueId || undefined });

    if (result && result.success) {
      this.setState({ employees: result.employees, loading: false });
    }
  }

  onEmployeeSelect(employee) {
    const { members } = this.state;
    const { onChange } = this.props;

    // ADD MEMBER IF NOT EXISTS
    const isAlreadyInArray = Validator.containsInArray('_id', employee._id, members);
    if (!isAlreadyInArray) {
      members.push(employee.user);
    }

    this.setState({ addPage: false }, () => {
      onChange(members);
    });
  }

  removeMemberBtnClick(selectedMember) {
    const { members } = this.state;
    const { onChange } = this.props;

    const newMembers = Validator.filter('_id', selectedMember._id, members, true);

    this.setState({ members: newMembers }, () => {
      onChange(newMembers);
    });
  }

  render() {
    const { loading, addPage, employees, members } = this.state;
    const { t, error, errorText } = this.props;

    if (addPage) {
      return (
        <div className='add-member-container'>
          <div className='add-member-header'>
            <span className='add-member-header-span'>{t('Bar Members')}</span>
            <button className='add-member-add-btn' onClick={this.backBtnClick}>
              <FontAwesomeIcon icon={faAngleLeft} size='sm'></FontAwesomeIcon> {t('BACK')}
            </button>
          </div>
          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : (
            employees.map((employee) => {
              return (
                <div key={employee._id} className='add-member-list member-field' onClick={() => this.onEmployeeSelect(employee)}>
                  <div>
                    <img className='add-member-profile' src={userIcon} alt='' />
                    {employee.user.first_name + ' ' + employee.user.last_name}
                  </div>
                  <div>
                    <FontAwesomeIcon icon={faCheck} size={'sm'}></FontAwesomeIcon>
                  </div>
                </div>
              );
            })
          )}
        </div>
      );
    } else {
      return (
        <div className='add-member-container'>
          <div className='add-member-header'>
            <span className='add-member-header-span'>{t('Bar Members')}</span>
            <button className='add-member-add-btn' onClick={this.addMemberBtnClick}>
              <FontAwesomeIcon icon={faPlus} size='sm' color='#222b45'></FontAwesomeIcon> {t('ADD')}
            </button>
          </div>
          <div className='add-member-body'>
            {members.map((member) => {
              return (
                <div key={member._id} className='add-member-list'>
                  <div>
                    <img className='add-member-profile' src={userIcon} alt='' />
                    {member.first_name + ' ' + member.last_name}
                  </div>
                  <div className='remove-member-btn' onClick={() => this.removeMemberBtnClick(member)}>
                    <FontAwesomeIcon icon={faTimes} size={'sm'} color='#222b45'></FontAwesomeIcon>
                  </div>
                </div>
              );
            })}
          </div>
          <div className='add-member-footer'>{error && <span className='error-span'>{errorText}</span>}</div>
        </div>
      );
    }
  }
}

export default withTranslation()(AddMemberField);
