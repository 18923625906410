import React from 'react';
import ReactLoading from 'react-loading';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { GatewayService } from '../../services';
import { Converter } from '../../utils';

const SYMBOLS = ['=', '>', '>=', '<', '=<', '?', ':'];

class GatewayConditionField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      typing: false,
      typingTimeout: 0,
      data: [],
    };

    this.reset = this.reset.bind(this);
    this.fetchList = this.fetchList.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSearchKeyUp = this.onSearchKeyUp.bind(this);
    this.onOptionChange = this.onOptionChange.bind(this);

    this.gatewayService = new GatewayService();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClick);

    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick);
  }

  reset() {
    this.setState({
      loading: false,
      typing: false,
      typingTimeout: 0,
      data: [],
    });
  }

  fetchList() {
    const { query, value } = this.props;
    const searchText = Converter.lastWordInString(value, [...SYMBOLS, '}']).word;

    this.setState({ showDropDown: true, loading: true }, async () => {
      const result = await this.gatewayService.getOrderFields({ search: searchText, ...query });
      const list = [...result.gateway_fields, ...SYMBOLS];

      if (result && result.success) {
        this.setState({ data: list, loading: false });
      } else if (result && result.ecode === 214) {
        this.setState({ data: [], loading: false });
      }
    });
  }

  handleClick(e) {
    const id = e.target.id;

    if (!this.node.contains(e.target) && id !== 'select-dropdown') {
      this.setState({ showDropDown: false });
    }
  }

  onSearchChange(e) {
    const { onChange } = this.props;
    const value = e.target.value;

    onChange(value);
  }

  onSearchKeyUp() {
    const { typingTimeout } = this.state;

    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    this.setState({
      typing: false,
      typingTimeout: setTimeout(() => {
        this.fetchList();
      }, 500),
    });
  }

  onOptionChange(row) {
    const { value, onChange } = this.props;

    if (row) {
      this.setState({ showDropDown: false }, () => {
        if (SYMBOLS.indexOf(row) >= 0) {
          onChange(`${value}${row}`);
        } else {
          const lastWordIndex = Converter.lastWordInString(value, [...SYMBOLS, '}']).index;
          onChange(`${Converter.spliceString(value, lastWordIndex, value.length)}{${row}}`);
        }
      });
    }
  }

  render() {
    const { loading, showDropDown, data } = this.state;
    const { t, value, fullWidth, label, size, error, errorText, disabled } = this.props;

    return (
      <div className={`outlined-textfield ${size} ${error && 'outlined-textfield-error'}`} style={{ width: fullWidth ? '100%' : 'auto' }}>
        <input
          ref={(ref) => (this.node = ref)}
          className={`${size}`}
          placeholder=' '
          autoComplete='off'
          disabled={disabled}
          value={value || ''}
          onClick={() => {
            this.fetchList(true);
          }}
          onChange={this.onSearchChange}
          onKeyUp={this.onSearchKeyUp}
        />
        <label className={`${size}`}>{label}</label>
        {error && <span className='outlined-textfield-error-text'>{errorText}</span>}
        {showDropDown &&
          (loading ? (
            <div className='dropdown-loading'>
              <div className='margin-auto'>
                <ReactLoading type='spin' color='#000' height='24px' width='24px' />
              </div>
            </div>
          ) : (
            <div className='dropdown-content'>
              <div className='dropdown-content-wrapper'>
                {data.length === 0 && (
                  <div
                    id='dropdown-content-span-no-found'
                    className='dropdown-content-span-no-found'
                    onClick={() => {
                      this.setState({ showDropDown: false });
                    }}
                  >
                    {t('No Data Found')}
                  </div>
                )}
                {data.map((row, index) => {
                  const key = `${index}_ix_select`;

                  return (
                    <span
                      id='select-dropdown'
                      className='dropdown-content-span'
                      key={key}
                      onClick={() => {
                        this.onOptionChange(row);
                      }}
                    >
                      {row && row.length > 0 ? row : 'None'}
                    </span>
                  );
                })}
              </div>
            </div>
          ))}
      </div>
    );
  }
}

GatewayConditionField.propTypes = {
  query: PropTypes.object,
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  value: PropTypes.any,
};

GatewayConditionField.defaultProps = {
  query: null,
  size: 'md',
  value: null,
};

export default withTranslation()(GatewayConditionField);
