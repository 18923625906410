import { FetchService } from '.';

export default class ProfileService extends FetchService {
  constructor() {
    super();

    this.updateGeneral = this.updateGeneral.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.updateProfilePhoto = this.updateProfilePhoto.bind(this);
    this.invite = this.invite.bind(this);
  }

  updateGeneral(first_name, last_name) {
    return this.Put('/profile/general', { body: JSON.stringify({ first_name, last_name }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updatePassword(password, new_password, confirm_password) {
    return this.Put('/profile/password', { body: JSON.stringify({ password, new_password, confirm_password }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateProfilePhoto(photo) {
    const formData = new FormData();
    formData.append('photo', photo);

    return this.Put('/profile/photo', { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  invite(email) {
    return this.Post('/profile/invite', { body: JSON.stringify({ email }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
