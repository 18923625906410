import React from 'react';
import qs from 'query-string';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { LoadingButton } from '../components/buttons';
import logo from '../assets/logos/banner/logoxxxhdpi.png';

import '../styles/login.css';

class SumupCallbackPage extends React.Component {
  constructor(props) {
    super(props);

    this.completeBtnClick = this.completeBtnClick.bind(this);
  }

  completeBtnClick() {
    const { location } = this.props;
    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    window.opener.postMessage({ code: query.code });
    window.close();
  }

  render() {
    const { t } = this.props;

    return (
      <div className='main-form overflow-y'>
        <div className='login-form'>
          <div className='login-form-header'>
            <img src={logo} className='img-fluid' alt=''></img>
          </div>
          <div className='login-form-body'>
            <div className='rounded-form'>
              <p>{t('Please close this window to complete the authentication')}</p>
              <p>
                <LoadingButton text={t('Close')} onClick={this.completeBtnClick}></LoadingButton>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SumupCallbackPage));
