import React from 'react';
import PropTypes from 'prop-types';
import DropDown from './DropDown';

class TextFieldWithDropDown extends React.Component {
  render() {
    const { fullWidth, label, size, dropdownLabel, dropdownActions, dropdownOnChange, hideArrow, error, errorText, ...rest } = this.props;

    return (
      <div className={`outlined-textfield ${size} ${error ? 'outlined-textfield-error' : ''}`} style={{ width: fullWidth ? '100%' : 'auto' }}>
        <input className={`${size}`} placeholder=' ' {...rest} />
        <label className={`${size}`}>{label}</label>
        {error && <span className='outlined-textfield-error-text'>{errorText}</span>}
        <div className='textfield-right-dropdown'>
          <DropDown label={dropdownLabel} actions={dropdownActions} hideArrow={hideArrow} onChange={dropdownOnChange} />
        </div>
      </div>
    );
  }
}

TextFieldWithDropDown.propTypes = {
  fullWidth: PropTypes.bool,
  label: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
};

TextFieldWithDropDown.defaultProps = {
  size: 'md',
};

export default TextFieldWithDropDown;
