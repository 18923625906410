import React from 'react';
import PropTypes from 'prop-types';
import ReactLoading from 'react-loading';
import { Row, Col } from 'react-bootstrap';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Toast } from '../alerts';
import { TextField } from '../fields';
import { LoadingButton } from '../buttons';
import { EmployeeService } from '../../services';
import { EntityError } from '../../utils';

import '../../styles/cards/general.css';

class GeneralCard extends React.Component {
  constructor(props) {
    super(props);

    const { employee } = this.props;

    this.state = {
      loading: false,
      saving: false,
      employee: employee,
      firstName: employee.user.first_name,
      lastName: employee.user.last_name,
      invalidPost: {
        first_name: false,
        last_name: false,
      },
    };

    this.onSaveBtnClick = this.onSaveBtnClick.bind(this);

    this.employeeService = new EmployeeService();
  }

  async onSaveBtnClick() {
    const { employee, firstName, lastName, invalidPost } = this.state;
    const { t, history } = this.props;

    this.setState({ saving: true, invalidPost: EntityError.setInputErrors(invalidPost, null, true) });

    const result = await this.employeeService.updateGeneral(employee.venue._id, employee.user._id, firstName, lastName);

    if (result && result.success) {
      const employeeResult = await this.employeeService.getbyid(employee.venue._id, employee.user._id);

      if (employeeResult && employeeResult.success) {
        // UPDATE HISTORY
        history.replace({
          pathname: '/employees/detail',
          state: { employee: employeeResult.employee },
        });

        Toast.success(t('Updated'));
      }
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({ saving: false });
  }

  render() {
    const { loading, saving, firstName, lastName, invalidPost } = this.state;
    const { t } = this.props;

    return (
      <div className='generalcard-container'>
        {loading ? (
          <div className='margin-auto'>
            <ReactLoading type='spin' color='#000' height='24px' width='24px' />
          </div>
        ) : (
          <div>
            <Row>
              <Col md={6}>
                <TextField
                  label={t('First Name')}
                  fullWidth
                  value={firstName}
                  error={invalidPost.first_name}
                  errorText={t('First Name is required')}
                  onChange={(e) => this.setState({ firstName: e.target.value })}
                ></TextField>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <TextField
                  label={t('Last Name')}
                  fullWidth
                  value={lastName}
                  error={invalidPost.last_name}
                  errorText={t('Last Name is required')}
                  onChange={(e) => this.setState({ lastName: e.target.value })}
                ></TextField>
              </Col>
            </Row>
            <Row>
              <Col md={6} className='text-right'>
                <LoadingButton text={t('Save')} state={saving ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnClick} />
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

GeneralCard.propTypes = {
  employee: PropTypes.object,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onSaveBtnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

GeneralCard.defaultProps = {
  onChange: Function.prototype,
  onSaveBtnClick: Function.prototype,
};

export default withTranslation()(withRouter(GeneralCard));
