import QueryString from 'query-string';
import { FetchService } from '.';

export default class SettingService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.updatePaymentProvider = this.updatePaymentProvider.bind(this);
    this.updateTransferFees = this.updateTransferFees.bind(this);
    this.getVenueTransferFees = this.getVenueTransferFees.bind(this);
    this.updateVenueTransferFees = this.updateVenueTransferFees.bind(this);
  }

  get(query = {}) {
    return this.Get('/settings?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updatePaymentProvider(rate, fixed_rate) {
    return this.Post('/settings/paymentprovider', { body: JSON.stringify({ rate, fixed_rate }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateTransferFees(rate, fixed_rate, currency = 'GBP') {
    return this.Post('/settings/transferfees', { body: JSON.stringify({ rate, fixed_rate, currency }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  getVenueTransferFees(query = {}) {
    return this.Get('/settings/venue/transferfees?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  updateVenueTransferFees(venue_id, rate, fixed_rate, currency = 'GBP') {
    return this.Post('/settings/venue/transferfees', { body: JSON.stringify({ venue_id, rate, fixed_rate, currency }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
