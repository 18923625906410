import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { AuthService } from '../services';
import { TextField } from '../components/fields';
import { EntityError } from '../utils';
import { LoadingButton } from '../components/buttons';
import logo from '../assets/logos/banner/logoxxxhdpi.png';

import '../styles/login.css';

class SignupPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      invalidSignup: {
        first_name: false,
        last_name: false,
        email: false,
        password: false,
        confirm_password: false,
        duplicate_email: false,
      },
      success: false,
    };

    this.signup = this.signup.bind(this);
    this.loginBtnClick = this.loginBtnClick.bind(this);
    this.signupBtnClick = this.signupBtnClick.bind(this);

    this.authService = new AuthService();
  }

  async signup() {
    const { firstName, lastName, email, password, confirmPassword, invalidSignup } = this.state;

    this.setState({
      loading: true,
      invalidSignup: EntityError.setInputErrors(invalidSignup, null, true),
      success: false,
    });

    const signup = await this.authService.signup(firstName, lastName, email, password, confirmPassword);
    if (signup && signup.success) {
      this.setState({
        loading: false,
        success: true,
      });
    } else if (signup && signup.ecode === 422) {
      this.setState({
        loading: false,
        invalidSignup: EntityError.setInputErrors(invalidSignup, signup.errors),
      });
    } else if (signup && signup.ecode === 1020) {
      this.setState({
        loading: false,
        invalidSignup: {
          ...this.state.invalidSignup,
          duplicate_email: true,
        },
      });
    }
  }

  loginBtnClick() {
    const { history } = this.props;

    history.push('/login');
  }

  signupBtnClick() {
    this.signup();
  }

  render() {
    const { loading, invalidSignup, firstName, lastName, email, success } = this.state;
    const { t } = this.props;

    return (
      <div className='main-form overflow-y'>
        <div className='login-form'>
          <div className='login-form-header'>
            <img src={logo} className='img-fluid' alt=''></img>
          </div>
          <div className='login-form-body'>
            {success ? (
              <div className='rounded-form'>
                <h2>{t('Thank you')}</h2>
                <p>
                  {t('Your registration was successful and we have sent you a confirmation to your email address at')} <b>{email}</b>
                </p>
                <hr />
                <br />
                <p>
                  <LoadingButton text={t('Go back to login')} onClick={this.loginBtnClick}></LoadingButton>
                </p>
              </div>
            ) : (
              <div className='rounded-form'>
                <p className='login-form-title'>{t('Sign Up')}</p>
                <p className='login-form-title-alt'>{t('Join us now!')}</p>

                <form className='login-form'>
                  <Row>
                    <Col md={12}>
                      <TextField
                        label={t('First Name')}
                        fullWidth
                        value={firstName}
                        error={invalidSignup.first_name}
                        errorText={t('First name is required')}
                        onChange={(e) => this.setState({ firstName: e.target.value })}
                      ></TextField>
                    </Col>
                    <Col md={12}>
                      <TextField
                        label={t('Last Name')}
                        fullWidth
                        value={lastName}
                        error={invalidSignup.last_name}
                        errorText={t('Last name is required')}
                        onChange={(e) => this.setState({ lastName: e.target.value })}
                      ></TextField>
                    </Col>
                    <Col md={12}>
                      <TextField
                        label={t('Working Email')}
                        fullWidth
                        value={email}
                        error={invalidSignup.email}
                        errorText={t('Invalid Email')}
                        onChange={(e) => this.setState({ email: e.target.value })}
                      ></TextField>
                    </Col>
                    <Col md={12}>
                      <TextField
                        label={t('Password')}
                        fullWidth
                        type='password'
                        error={invalidSignup.password}
                        errorText={t('Password must be more than 6 characters')}
                        onChange={(e) => this.setState({ password: e.target.value })}
                      ></TextField>
                    </Col>
                    <Col md={12}>
                      <TextField
                        label={t('Confirm Password')}
                        fullWidth
                        type='password'
                        error={invalidSignup.confirm_password}
                        errorText={t('Please confirm that password is matched')}
                        onChange={(e) => this.setState({ confirmPassword: e.target.value })}
                      ></TextField>
                    </Col>
                  </Row>
                  {invalidSignup.duplicate_email && <span className='error-text'>{t('This email has already been taken. Please sign in or change your email.')}</span>}
                  <br />
                  <br />
                  <LoadingButton type={'button'} text={t('REGISTER')} state={loading ? 'LOADING' : 'DONE'} onClick={this.signupBtnClick} fullWidth />
                  <br />
                  <br />
                  {loading || (
                    <p>
                      {t('You already have an account?')}{' '}
                      <span className='login-btn' onClick={this.loginBtnClick}>
                        {t('Login')}
                      </span>
                    </p>
                  )}
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(SignupPage));
