import React from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col } from 'react-bootstrap';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { GatewayModal } from '../components/modals';
import { Select } from '../components/fields';
import { GatewayService } from '../services';
import { Confirm, Toast } from '../components/alerts';
import { GatewayCard } from '../components/cards';

import '../styles/gateway.css';

class GatewayPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      venue: null,
      gateways: [],
      selectedGatewayId: null,
    };

    this.fetchGateways = this.fetchGateways.bind(this);
    this.addGatewayBtnClick = this.addGatewayBtnClick.bind(this);
    this.onGatewayModalComplete = this.onGatewayModalComplete.bind(this);
    this.onVenueSelectChange = this.onVenueSelectChange.bind(this);
    this.onGatewayCardChange = this.onGatewayCardChange.bind(this);

    this.gatewayService = new GatewayService();
  }

  componentDidMount() {
    this.fetchGateways();
  }

  async fetchGateways() {
    this.setState({ loading: true });

    const { venue } = this.state;
    const result = await this.gatewayService.get({ venue_id: venue ? venue._id : undefined });

    if (result && result.success) {
      this.setState({
        gateways: result.gateways,
        venue: result.selected_venue,
        loading: false,
      });

      if (this.venueSelect) {
        this.venueSelect.setValue(result.selected_venue.name);
      }
    }

    this.setState({ loading: false });
  }

  addGatewayBtnClick() {
    const { t } = this.props;
    const { venue } = this.state;

    if (venue) {
      this.setState(
        {
          selectedGatewayId: null,
        },
        () => {
          this.gatewayModal.show(true, venue);
          this.gatewayModal.resetFields();
        }
      );
    } else {
      Toast.warning(t('Please select a venue from filter!'));
    }
  }

  async onGatewayModalComplete() {
    const { t } = this.props;

    await this.fetchGateways();
    Toast.success(t('Updated'));
  }

  onVenueSelectChange(venue) {
    this.setState({ venue: venue }, () => {
      this.fetchGateways();
    });
  }

  async onGatewayCardChange(data, action) {
    const { venue } = this.state;
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'EDIT':
        this.setState(
          {
            selectedGatewayId: data._id,
          },
          () => {
            this.gatewayModal.show(true, venue);
            this.gatewayModal.resetFields();
            this.gatewayModal.setFields(data);
          }
        );
        break;
      case 'DELETE':
        const confirm = await Confirm.open(t('Gateway Deletion'), t('Are you sure to delete?'), t('Yes'), t('No'));
        if (confirm) {
          this.setState({ loading: true });

          result = await this.gatewayService.delete(data._id);
        }
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchGateways();
    } else {
      this.setState({ loading: false });
    }
  }

  render() {
    const { loading, gateways, selectedGatewayId } = this.state;
    const { t, me, label } = this.props;

    return (
      <div className='content'>
        <GatewayModal gatewayId={selectedGatewayId} onRef={(ref) => (this.gatewayModal = ref)} onComplete={this.onGatewayModalComplete}></GatewayModal>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            {me.role === 'ADMIN' && (
              <button className='content-title-button' onClick={this.addGatewayBtnClick}>
                <FontAwesomeIcon icon={faPlus} /> {t('ADD GATEWAY')}
              </button>
            )}
          </div>
        </div>
        <div className='content-body'>
          <div className='content-header'>
            <Row>
              <Col md={12}>
                <p className='gateway-container-title'>{t('Filters')}</p>
                <div className='gateway-spacer' />
              </Col>
              <Col md={3}>
                <Select fullWidth type='venues' field='name' size='sm' label={t('Venue')} onChange={this.onVenueSelectChange} onRef={(ref) => (this.venueSelect = ref)}></Select>
              </Col>
            </Row>
          </div>

          <div className='gateway-page'>
            {loading ? (
              <div className='margin-auto'>
                <ReactLoading type='spin' color='#000' height='24px' width='24px' />
              </div>
            ) : (
              <Row>
                {gateways.length < 1 && <Col md={12}>{t('No gateways found')}</Col>}
                {gateways.map((gateway) => {
                  return (
                    <GatewayCard
                      key={gateway._id}
                      gateway={gateway}
                      actions={[
                        {
                          label: 'EDIT',
                          title: t('EDIT'),
                        },
                        {
                          label: 'DELETE',
                          title: t('DELETE'),
                          red: true,
                        },
                      ]}
                      onChange={this.onGatewayCardChange}
                    />
                  );
                })}
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(GatewayPage));
