import React from 'react';
import ReactLoading from 'react-loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

class LoadingButton extends React.Component {
  render() {
    const { fullWidth, textAlign, fontSize, onClick, text, state, loadingType, loadingColor, loadingHeight, loadingWidth, rightIcon, ...rest } = this.props;

    return (
      <button
        {...rest}
        style={{ width: fullWidth ? '100%' : 'auto', textAlign: rightIcon ? 'left' : textAlign || 'center', fontSize: fontSize }}
        className='loading-button'
        disabled={state === 'LOADING' ? true : false}
        color='primary'
        onClick={onClick}
      >
        {state === 'LOADING' ? <ReactLoading type={loadingType} color={loadingColor} height={loadingHeight} width={loadingWidth} className='margin-auto' /> : <span>{text}</span>}
        {rightIcon && (
          <div style={{ float: 'right' }}>
            <FontAwesomeIcon icon={rightIcon}></FontAwesomeIcon>
          </div>
        )}
      </button>
    );
  }
}

LoadingButton.propTypes = {
  text: PropTypes.string,
  state: PropTypes.oneOf(['LOADING', 'DONE']),
  fullWidth: PropTypes.bool,
  fontSize: PropTypes.string,
  loadingType: PropTypes.string,
  loadingColor: PropTypes.string,
  loadingHeight: PropTypes.string,
  loadingWidth: PropTypes.string,
};

LoadingButton.defaultProps = {
  state: 'DONE',
  fullWidth: false,
  fontSize: '1em',
  loadingType: 'spin',
  loadingColor: '#fff',
  loadingHeight: '30px',
  loadingWidth: '30px',
};

export default LoadingButton;
