import React from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import beerIcon from '../../assets/images/beer.png';

import '../../styles/cards/eventbar.css';

class EventBarCard extends React.Component {
  render() {
    const { t, value, bar, onSwitchChange } = this.props;

    return (
      <div className='event-bar'>
        <div className='event-bar-item-container'>
          <div className='event-bar-item-image'>
            <img src={beerIcon} alt='' />
          </div>
          <div className='event-bar-item-body'>
            <p className='event-bar-item-title'>{bar.name}</p>
            <p className='event-bar-item-subtitle'>
              {bar.members ? bar.members.length : 0} {t('Members')}
            </p>
          </div>
          <div className='event-bar-item-right'>
            <ReactSwitch
              checked={value}
              onChange={(checked) => {
                onSwitchChange(checked, bar);
              }}
              onColor='#fec802'
              onHandleColor='#ffffff'
              handleDiameter={16}
              uncheckedIcon={false}
              checkedIcon={false}
              height={24}
              width={48}
            />
          </div>
        </div>
      </div>
    );
  }
}

EventBarCard.propTypes = {
  value: PropTypes.bool,
  bar: PropTypes.object,
  onSwitchChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

EventBarCard.defaultProps = {
  value: true,
  onSwitchChange: Function.prototype,
};

export default withTranslation()(withRouter(EventBarCard));
