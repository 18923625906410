import React from 'react';
import PropTypes from 'prop-types';

import '../../styles/layouts/modal.css';

class ModalLayout extends React.Component {
  render() {
    const { title, titleAlign, subtitle, width, children } = this.props;

    return (
      <div className='modal-container'>
        <div className='add-modal' style={{ width: width.toString() + 'px' }}>
          {title && (
            <p className='add-modal-title' style={{ textAlign: titleAlign }}>
              {title}
            </p>
          )}

          {subtitle && <p className='add-modal-subtitle'>{subtitle}</p>}
          <div className='add-modal-body'>{children}</div>
        </div>
      </div>
    );
  }
}

ModalLayout.propTypes = {
  title: PropTypes.string,
  titleAlign: PropTypes.string,
  subtitle: PropTypes.string,
  width: PropTypes.number,
};

ModalLayout.defaultProps = {
  titleAlign: 'center',
  width: 460,
};

export default ModalLayout;
