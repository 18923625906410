import React from 'react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faLink } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faTwitter, faInstagram, faLinkedin, faYoutube, faGoogle, faTiktok } from '@fortawesome/free-brands-svg-icons';
import { VenueService } from '../../services';
import { Converter, EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, Uploader } from '../fields';
import { ModalLayout } from '../layouts';

import '../../styles/modals/modal.css';
import '../../styles/modals/detailsvenue.css';

class DetailsVenueModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      venue: null,
      photoGallery: [],
      description: '',
      photos: [],
      facebookLink: '',
      twitterLink: '',
      instagramLink: '',
      linkedinLink: '',
      youtubeLink: '',
      googleLink: '',
      tiktokLink: '',
      websiteLink: '',
      invalidPost: {
        venue_id: false,
        description: false,
        photos: false,
      },
    };

    this.getVenue = this.getVenue.bind(this);
    this.show = this.show.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnClick = this.onSaveBtnClick.bind(this);
    this.onRemoveBtnClick = this.onRemoveBtnClick.bind(this);
    this.onVenuePhotosChange = this.onVenuePhotosChange.bind(this);

    this.venueService = new VenueService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  async getVenue() {
    const { venueId } = this.props;
    const result = await this.venueService.get({ search: venueId });

    if (result && result.success && result.venues && result.venues.length > 0) {
      const venue = result.venues[0];

      this.setState({ photoGallery: venue.photo_gallery || [], description: venue.description || '', loading: false });
    } else if (result && result.ecode === 214) {
      this.setState({ loading: false });
    }

    if (this.uploader) {
      this.uploader.reset();
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      venue: null,
      photoGallery: [],
      description: '',
      photos: [],
      facebookLink: '',
      twitterLink: '',
      instagramLink: '',
      linkedinLink: '',
      youtubeLink: '',
      googleLink: '',
      tiktokLink: '',
      websiteLink: '',
      invalidPost: {
        venue_id: false,
        description: false,
        photos: false,
      },
    });
  }

  setFields(venue) {
    this.setState({
      photoGallery: venue.photo_gallery || [],
      description: venue.description || '',
      facebookLink: venue.links && venue.links.facebook ? venue.links.facebook : '',
      twitterLink: venue.links && venue.links.twitter ? venue.links.twitter : '',
      linkedinLink: venue.links && venue.links.linkedin ? venue.links.linkedin : '',
      instagramLink: venue.links && venue.links.instagram ? venue.links.instagram : '',
      youtubeLink: venue.links && venue.links.youtube ? venue.links.youtube : '',
      googleLink: venue.links && venue.links.google ? venue.links.google : '',
      tiktokLink: venue.links && venue.links.tiktok ? venue.links.tiktok : '',
      websiteLink: venue.links && venue.links.website ? venue.links.website : '',
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  async onSaveBtnClick() {
    const { venueId, onComplete } = this.props;
    const { invalidPost, facebookLink, twitterLink, linkedinLink, instagramLink, youtubeLink, googleLink, tiktokLink, websiteLink, description, photos } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = await this.venueService.changeDetails(
      venueId,
      photos,
      description,
      facebookLink,
      twitterLink,
      linkedinLink,
      instagramLink,
      youtubeLink,
      googleLink,
      tiktokLink,
      websiteLink
    );

    if (result && result.success) {
      await this.getVenue();

      onComplete(true);
      this.show(false);
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  async onRemoveBtnClick(photoId, e) {
    const { venueId, onComplete } = this.props;
    const { invalidPost } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = await this.venueService.removeFromPhotoGallery(venueId, photoId);

    if (result && result.success) {
      await this.getVenue();

      onComplete(true);
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  onVenuePhotosChange(photos) {
    this.setState({
      photos: photos,
    });
  }

  render() {
    const { show, loading, photoGallery, facebookLink, twitterLink, instagramLink, linkedinLink, youtubeLink, googleLink, tiktokLink, websiteLink, description, invalidPost } =
      this.state;
    const { t, venue } = this.props;

    return (
      show && (
        <ModalLayout title={t('Details')} subtitle={venue.name} width={640}>
          <Row>
            <Col md={12}>
              <p className='details-venue-gallery-title'>{t('Photo Gallery')}</p>
              {photoGallery && photoGallery.length > 0 && (
                <div className='details-venue-gallery-container'>
                  {photoGallery.map((photo) => {
                    return (
                      <div className='details-venue-gallery-area' key={photo._id}>
                        <div
                          className='details-venue-gallery-btn'
                          onClick={(e) => {
                            this.onRemoveBtnClick(photo._id, e);
                          }}
                        >
                          <FontAwesomeIcon icon={faTimes} size={'sm'} color='#fff'></FontAwesomeIcon>
                        </div>
                        <img src={photo.photo} alt='' />
                      </div>
                    );
                  })}
                </div>
              )}
            </Col>
            <Col md={12}>
              <Uploader
                multiple={true}
                onChange={this.onVenuePhotosChange}
                onRef={(ref) => {
                  this.uploader = ref;
                }}
              />
            </Col>
            <Col md={12}>
              <div className='details-venue-spacer' />
            </Col>
            <Col md={12}>
              <p className='details-venue-gallery-title'>{t('Links')}</p>
            </Col>
            <Col md={6}>
              <TextField
                label={t('Facebook')}
                fullWidth
                value={facebookLink}
                error={invalidPost.facebook_link}
                errorText={t('This field must be a link')}
                onChange={(e) => this.setState({ facebookLink: e.target.value })}
                rightBtnLabel={<FontAwesomeIcon icon={faFacebook} color='#4267B2' />}
                style={{ paddingRight: '35px' }}
              ></TextField>
              <div className='details-venue-spacer' />
              <TextField
                label={t('Twitter')}
                fullWidth
                value={Converter.removeHTTPPath(twitterLink)}
                error={invalidPost.twitter_link}
                errorText={t('This field must be a link')}
                onChange={(e) => this.setState({ twitterLink: Converter.removeHTTPPath(e.target.value) })}
                leftBtnLabel={'@'}
                rightBtnLabel={<FontAwesomeIcon icon={faTwitter} color='#1DA1F2' />}
                style={{ paddingLeft: '35px', paddingRight: '35px' }}
              ></TextField>
              <div className='details-venue-spacer' />
              <TextField
                label={t('Instagram')}
                fullWidth
                value={Converter.removeHTTPPath(instagramLink)}
                error={invalidPost.instagram_link}
                errorText={t('This field must be a link')}
                onChange={(e) => this.setState({ instagramLink: Converter.removeHTTPPath(e.target.value) })}
                leftBtnLabel={'@'}
                rightBtnLabel={<FontAwesomeIcon icon={faInstagram} color='#E1306C' />}
                style={{ paddingLeft: '35px', paddingRight: '35px' }}
              ></TextField>
              <div className='details-venue-spacer' />
              <TextField
                label={t('Google')}
                fullWidth
                value={googleLink}
                error={invalidPost.google_link}
                errorText={t('This field must be a link')}
                onChange={(e) => this.setState({ googleLink: e.target.value })}
                rightBtnLabel={<FontAwesomeIcon icon={faGoogle} color='#DB4437' />}
                style={{ paddingRight: '35px' }}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                label={t('Youtube')}
                fullWidth
                value={youtubeLink}
                error={invalidPost.youtube_link}
                errorText={t('This field must be a link')}
                onChange={(e) => this.setState({ youtubeLink: e.target.value })}
                rightBtnLabel={<FontAwesomeIcon icon={faYoutube} color='#FF0000' />}
                style={{ paddingRight: '35px' }}
              ></TextField>
              <div className='details-venue-spacer' />
              <TextField
                label={t('Linkedin')}
                fullWidth
                value={linkedinLink}
                error={invalidPost.linkedin_link}
                errorText={t('This field must be a link')}
                onChange={(e) => this.setState({ linkedinLink: e.target.value })}
                rightBtnLabel={<FontAwesomeIcon icon={faLinkedin} color='#2867B2' />}
                style={{ paddingRight: '35px' }}
              ></TextField>
              <div className='details-venue-spacer' />
              <TextField
                label={t('TikTok')}
                fullWidth
                value={tiktokLink}
                error={invalidPost.tiktok_link}
                errorText={t('This field must be a link')}
                onChange={(e) => this.setState({ tiktokLink: e.target.value })}
                rightBtnLabel={<FontAwesomeIcon icon={faTiktok} color='#69C9D0' />}
                style={{ paddingRight: '35px' }}
              ></TextField>
              <div className='details-venue-spacer' />
              <TextField
                label={t('Website')}
                fullWidth
                value={websiteLink}
                error={invalidPost.website_link}
                errorText={t('This field must be a link')}
                onChange={(e) => this.setState({ websiteLink: e.target.value })}
                rightBtnLabel={<FontAwesomeIcon icon={faLink} color='#555' />}
                style={{ paddingRight: '40px' }}
              ></TextField>
            </Col>
            <Col md={12}>
              <p className='details-venue-gallery-title'>{t('Information')}</p>
              <TextField
                label={t('Description')}
                isTextarea={true}
                error={invalidPost.description}
                errorText={t('Description is required')}
                onChange={(e) => this.setState({ description: e.target.value })}
                value={description}
                maxLength={512}
                fullWidth
              />
            </Col>
          </Row>
          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CLOSE')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={t('SAVE')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnClick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(DetailsVenueModal);
