import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { AuthService } from '../../services';

class SwitchLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authed: false,
    };

    this.me = this.me.bind(this);

    this.authService = new AuthService();
  }

  async me() {
    const { history } = this.props;
    const me = await this.authService.me();

    if (!me) {
      history.push('/login');
    } else {
      this.setState({ authed: true, me: me ? me.me : null });
    }
  }

  async componentDidMount() {
    await this.me();
  }

  render() {
    const { me } = this.state;
    const { t, component: Component, layout: Layout, ...rest } = this.props;

    return (
      <Route
        {...rest}
        render={(props) => (
          <Layout>
            <Component me={me} {...props} />
          </Layout>
        )}
      />
    );
  }
}

export default withTranslation()(withRouter(SwitchLayout));
