import React from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DropDown } from '../fields';

import '../../styles/cards/discount.css';

class DiscountCard extends React.Component {
  render() {
    const { t, discount, actions, data, onChange } = this.props;
    return (
      <div className='discount-card'>
        <div className='discount-card-body'>
          <p className='discount-card-body-title'>{discount.name}</p>
          <p className='discount-card-body-subtitle'>{discount.description}</p>
          <p className='discount-card-body-subtitle'>
            <span className='discount-card-body-title-span'>{t('Start Date')}</span> {moment(discount.start_date).format('DD MMM YYYY HH:mm')}
          </p>
          <p className='discount-card-body-subtitle'>
            <span className='discount-card-body-title-span'>{t('Expire Date')}</span> {moment(discount.end_date).format('DD MMM YYYY HH:mm')}
          </p>
          <p className='discount-card-body-subtitle'>
            <span className='discount-card-body-title-span'>{t('Level')}</span> #{discount.level}
          </p>
          <p className='discount-card-body-subtitle'>
            <span className='discount-card-body-title-span'>{t('Percent')}</span> {discount.rate.percent}%
          </p>
          <p className='discount-card-body-subtitle'>
            <span className='discount-card-body-title-span'>{t('Min. Quantity')}</span> {discount.rate.min_qty}
          </p>
          <p className='discount-card-body-subtitle'>
            <span className='discount-card-body-title-span'>{t('Membership')}</span> {discount.membership_id || '-'}
          </p>
          <p className='discount-card-body-subtitle'>
            <span className='discount-card-body-title-span'>{t('Products')}</span>{' '}
            {discount.products
              ? discount.products.map((product, index) => {
                  return product.name + (discount.products.length - 1 > index ? ', ' : '');
                })
              : ''}
          </p>
        </div>
        <div className='discount-card-right'>
          <DropDown bull={true} isTable={true} actions={actions} data={data} onChange={onChange} />
        </div>
      </div>
    );
  }
}

DiscountCard.propTypes = {
  discount: PropTypes.any,
  actions: PropTypes.array,
  onChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

DiscountCard.defaultProps = {
  actions: [],
  onChange: Function.prototype,
};

export default withTranslation()(DiscountCard);
