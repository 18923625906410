import QueryString from 'query-string';
import { FetchService } from '.';

export default class IngredientService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  get(query) {
    return this.Get('/ingredients?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(venue_id, name, note, unit, venue_reserve, bar_reserves, threshold = 0) {
    return this.Post('/ingredients', { body: JSON.stringify({ venue_id, name, note, unit, venue_reserve, bar_reserves, threshold }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  put(ingredient_id, venue_id, name, note, unit, venue_reserve, bar_reserves, threshold = 0) {
    return this.Put('/ingredients', { body: JSON.stringify({ ingredient_id, venue_id, name, note, unit, venue_reserve, bar_reserves, threshold }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(ingredient_id) {
    return this.Post('/ingredients/delete', { body: JSON.stringify({ ingredient_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
