import React from 'react';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTh } from '@fortawesome/free-solid-svg-icons';
import { Filter } from '../components/fields';
import { SaveProductModal, ProductCategoryModal } from '../components/modals';
import { Toast } from '../components/alerts';
import { VenueReserveCard } from '../components/cards';
import { ProductService } from '../services';
import { LocalStorage } from '../utils';

import '../styles/product.css';

class ProductPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      searchText: '',
      filter: LocalStorage.getFilter(),
      products: null,
      selectedBars: [],
    };

    this.addProductBtnClick = this.addProductBtnClick.bind(this);
    this.categoryBtnClick = this.categoryBtnClick.bind(this);
    this.fetchStock = this.fetchStock.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onAddProductComplete = this.onAddProductComplete.bind(this);
    this.onVenueReserveCardChange = this.onVenueReserveCardChange.bind(this);
    this.onProductEnabledChange = this.onProductEnabledChange.bind(this);
    this.onBarEnabledChange = this.onBarEnabledChange.bind(this);

    this.productService = new ProductService();
  }

  componentDidMount() {
    this.fetchStock();
  }

  addProductBtnClick() {
    const { filter } = this.state;
    const { t } = this.props;

    if (filter.venue) {
      this.saveProductModal.show(true, filter.venue);
    } else {
      Toast.warning(t('Please select a venue from filter!'));
    }
  }

  categoryBtnClick() {
    const { filter } = this.state;
    const { t } = this.props;

    if (filter.venue) {
      this.prodcutCategoryModal.show(true, filter.venue);
    } else {
      Toast.warning(t('Please select a venue from filter!'));
    }
  }

  async fetchStock() {
    const { filter, searchText } = this.state;

    this.setState({ loading: true });

    const productResult = await this.productService.getStock({ venue_id: filter && filter.venue ? filter.venue._id : null, search: searchText });

    if (productResult && productResult.success) {
      this.setState({ products: productResult.products, selectedBars: productResult.selected_bars, filter: { ...filter, venue: productResult.selected_venue } });

      if (this.filter) {
        this.filter.setFilterFields('venue', productResult.selected_venue);
        this.filter.showTags();
      }
    }

    this.setState({ loading: false });
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
      },
      () => {
        this.fetchStock();
      }
    );
  }

  onFilterChange(filter) {
    if (filter.venue) {
      this.setState({ filter: filter }, () => {
        this.fetchStock();
      });
    } else {
      this.setState({ filter: {}, products: null }, () => {
        this.fetchStock();
      });
    }
  }

  onAddProductComplete() {
    this.fetchStock();
  }

  onVenueReserveCardChange(data, action) {
    switch (action.label) {
      case 'EDIT':
        this.saveProductModal.show(true);
        this.saveProductModal.setFields(data);
        break;
      default:
        break;
    }
  }

  onProductEnabledChange(enabled, productId) {
    const { products } = this.state;

    const newProducts = products.map((product) => {
      if (product._id === productId) {
        product.enabled = enabled;
      }
      return product;
    });

    this.setState({ products: newProducts });
  }

  onBarEnabledChange(enabled, productId, barId) {
    const { products } = this.state;

    const newProducts = products.map((product) => {
      if (product._id === productId) {
        product.barproducts = product.barproducts.map((barProduct) => {
          if (barProduct.bar._id === barId) {
            barProduct.enabled = enabled;
          }

          return barProduct;
        });
      }

      return product;
    });

    this.setState({ products: newProducts });
  }

  onEnabledChangeError(error) {
    this.fetchStock();

    if (error) {
      Toast.warning(`Fatal Error Occurred ${error}`);
    }
  }

  render() {
    const { loading, products, searchText, selectedBars } = this.state;
    const { t, me, label } = this.props;

    return (
      <div className='content'>
        <SaveProductModal
          onRef={(ref) => (this.saveProductModal = ref)}
          selectedBars={selectedBars}
          onComplete={this.onAddProductComplete}
          onNoResultFound={() => {
            this.categoryBtnClick();
          }}
        />
        <ProductCategoryModal onRef={(ref) => (this.prodcutCategoryModal = ref)}></ProductCategoryModal>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
              <>
                <button className='content-title-button default' onClick={this.categoryBtnClick}>
                  <FontAwesomeIcon icon={faTh} /> {t('CATEGORIES')}
                </button>
                <button className='content-title-button' onClick={this.addProductBtnClick}>
                  <FontAwesomeIcon icon={faPlus} /> {t('ADD PRODUCT')}
                </button>
              </>
            )}
          </div>
        </div>
        <div className='content-body'>
          <div className='content-header'>
            <Filter onRef={(ref) => (this.filter = ref)} searchText={searchText} fields={['venue']} onSearchChange={this.onSearchChange} onFilterChange={this.onFilterChange} />
          </div>
          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : (
            <div className='product-page-venue'>
              {products && (
                <div>
                  <Row>
                    {products.length < 1 && <Col md={12}>{t('No products found')}</Col>}
                    {products.map((product) => {
                      return (
                        <VenueReserveCard
                          key={product._id}
                          product={product}
                          actions={[
                            {
                              label: 'EDIT',
                              title: t('EDIT'),
                            },
                          ]}
                          onChange={this.onVenueReserveCardChange}
                          onEnabledChange={this.onProductEnabledChange}
                          onBarEnabledChange={this.onBarEnabledChange}
                          onEnabledChangeError={this.onEnabledChangeError}
                        />
                      );
                    })}
                  </Row>
                </div>
              )}
              <br />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ProductPage));
