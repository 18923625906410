import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { DefaultTable } from '../components/tables';
import { SaveBarModal } from '../components/modals';
import { Confirm, Toast } from '../components/alerts';
import { BarService } from '../services';
import { LocalStorage } from '../utils';

import '../styles/bar.css';

class BarPage extends React.Component {
  constructor(props) {
    super(props);

    const { t } = this.props;
    const filter = LocalStorage.getFilter();

    this.state = {
      loading: true,
      searchText: '',
      columns: [
        {
          title: t('NAME'),
          field: 'name',
        },
        {
          title: t('VENUE'),
          field: 'venue.name',
        },
        {
          title: t('EMAIL'),
          field: 'email',
        },
        {
          title: t('MEMBERS'),
          field: 'member_count',
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
        },
        {
          title: t('STATUS'),
          field: 'status',
          switch: true,
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
        },
        {
          title: t('ACTIONS'),
          field: 'actions',
          titleStyle: {
            textAlign: 'center',
            width: '100px',
          },
          style: { textAlign: 'center' },
          action: true,
        },
      ],
      rows: [],
      selectedBarId: null,
      selectedVenue: filter && filter.venue ? filter.venue : null,
      pagination: {
        skip: 0,
        limit: 10,
        count: 0,
      },
    };

    this.onSearchChange = this.onSearchChange.bind(this);
    this.onSwitchChange = this.onSwitchChange.bind(this);
    this.addBarBtnClick = this.addBarBtnClick.bind(this);
    this.onAddBarComplete = this.onAddBarComplete.bind(this);
    this.onActionChange = this.onActionChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);

    this.barService = new BarService();
  }

  componentDidMount() {
    this.fetchBars();
  }

  async fetchBars() {
    const { searchText, selectedVenue, pagination } = this.state;
    const result = await this.barService.get({
      search: searchText,
      venue_id: selectedVenue ? selectedVenue._id : undefined,
      skip: pagination.skip || 0,
      limit: pagination.limit || 10,
    });

    if (result && result.success) {
      const filter = this.table.getFilter();

      if (selectedVenue && filter) {
        filter.setFilterFields('venue', selectedVenue);
        filter.showTags();
      }

      this.setState({ rows: result.bars, pagination: result.pagination, loading: false });
    } else if (result && result.ecode === 214) {
      this.setState({ rows: [], loading: false });
    }
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
        pagination: {
          skip: 0,
          limit: 10,
          count: 0,
        },
      },
      () => {
        this.fetchBars();
      }
    );
  }

  async onSwitchChange(checked, row, rowIndex) {
    const rowId = row._id;
    const result = await this.barService.setStatus(rowId, checked);

    if (result && result.success) {
      await this.fetchBars();
    }
  }

  addBarBtnClick() {
    this.setState(
      {
        selectedBarId: null,
      },
      () => {
        this.saveBarModal.show(true);
      }
    );
  }

  async onAddBarComplete(result) {
    const { t } = this.props;

    if (result) {
      await this.fetchBars();
      Toast.success(t('Updated'));
    }
  }

  async onActionChange(data, action) {
    const { t } = this.props;

    let result = null;

    switch (action.label) {
      case 'EDIT':
        this.setState(
          {
            selectedBarId: data._id,
          },
          () => {
            this.saveBarModal.show(true);
            this.saveBarModal.setFields(data);
          }
        );
        break;
      case 'DELETE':
        const confirm = await Confirm.open(t('Bar Deletion'), t('Are you sure to delete?'), t('Yes'), t('No'));
        if (confirm) {
          this.setState({ loading: true });
          result = await this.barService.delete(data._id);
        }
        break;
      default:
        break;
    }

    if (result && result.success) {
      await this.fetchBars();
    } else {
      this.setState({ loading: false });
    }
  }

  onFilterChange(filter) {
    if (filter) {
      this.setState({ selectedVenue: filter.venue }, () => {
        this.fetchBars();
      });
    }
  }

  onPaginationChange(type, value) {
    switch (type) {
      case 'LIMIT':
        this.setState({ pagination: { ...this.state.pagination, skip: 0, limit: value } }, () => {
          this.fetchBars();
        });
        break;
      case 'NAVIGATE':
        this.setState({ pagination: value }, () => {
          this.fetchBars();
        });
        break;
      default:
        break;
    }
  }

  render() {
    const { loading, columns, rows, searchText, selectedBarId, pagination } = this.state;
    const { t, me, label } = this.props;

    return (
      <div className='content'>
        <SaveBarModal barId={selectedBarId} onRef={(ref) => (this.saveBarModal = ref)} onComplete={this.onAddBarComplete}></SaveBarModal>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'>
            {(me.role === 'ADMIN' || me.role === 'MANAGER') && (
              <button className='content-title-button' onClick={this.addBarBtnClick}>
                <FontAwesomeIcon icon={faPlus} /> {t('ADD BAR')}
              </button>
            )}
          </div>
        </div>
        <div className='content-body'>
          <DefaultTable
            onRef={(ref) => (this.table = ref)}
            columns={columns}
            rows={rows}
            searchText={searchText}
            loading={loading}
            filter={['venue']}
            pagination={pagination}
            actions={[
              {
                label: 'EDIT',
                title: t('EDIT'),
              },
              {
                label: 'DELETE',
                title: t('DELETE'),
                red: true,
              },
            ]}
            onSearchChange={this.onSearchChange}
            onSwitchChange={this.onSwitchChange}
            onActionChange={this.onActionChange}
            onFilterChange={this.onFilterChange}
            onPaginationChange={this.onPaginationChange}
          ></DefaultTable>
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(BarPage));
