import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default class Confirm {
  static async open(title, message, yesBtnLabel, noBtnLabel, success, cancel) {
    return new Promise((resolve) => {
      confirmAlert({
        title: title,
        message: message,
        buttons: [
          {
            label: yesBtnLabel,
            onClick: () => {
              resolve(true);
            },
          },
          {
            label: noBtnLabel,
            onClick: () => {
              resolve(false);
            },
          },
        ],
        overlayClassName: 'confirm-alert',
      });
    });
  }
}
