import React from 'react';
import IBAN from 'iban';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { VenueService } from '../../services';
import { Converter, EntityError } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { CountrySelect, TextField } from '../fields';
import { ModalLayout } from '../layouts';

import '../../styles/modals/modal.css';
import '../../styles/modals/bankdetailsvenue.css';

class BankDetailsVenueModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      venue: null,
      name: '',
      country: '',
      bank_name: '',
      account_number: '',
      sort_code: '',
      iban: '',
      swift_code: '',
      invalidPost: {
        name: false,
        country: false,
        bank_name: false,
        account_number: false,
        iban: false,
      },
    };

    this.show = this.show.bind(this);
    this.resetFields = this.resetFields.bind(this);
    this.setFields = this.setFields.bind(this);
    this.onSaveBtnClick = this.onSaveBtnClick.bind(this);

    this.venueService = new VenueService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      venue: null,
      name: '',
      country: '',
      bank_name: '',
      account_number: '',
      sort_code: '',
      iban: '',
      swift_code: '',
      invalidPost: {
        name: false,
        country: false,
        bank_name: false,
        account_number: false,
        iban: false,
      },
    });
  }

  setFields(venue) {
    this.setState({
      name: venue.bank_details && venue.bank_details.name ? venue.bank_details.name : '',
      country: venue.bank_details && venue.bank_details.country ? venue.bank_details.country : '',
      bank_name: venue.bank_details && venue.bank_details.bank_name ? venue.bank_details.bank_name : '',
      account_number: venue.bank_details && venue.bank_details.account_number ? venue.bank_details.account_number : '',
      sort_code: venue.bank_details && venue.bank_details.sort_code ? venue.bank_details.sort_code : '',
      iban: venue.bank_details && venue.bank_details.iban ? venue.bank_details.iban : '',
      swift_code: venue.bank_details && venue.bank_details.swift_code ? venue.bank_details.swift_code : '',
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  async onSaveBtnClick() {
    const { venueId, onComplete } = this.props;
    const { invalidPost, name, country, bank_name, account_number, sort_code, iban, swift_code } = this.state;

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = await this.venueService.changeBankDetails(venueId, name, country, bank_name, account_number, sort_code, iban ? Converter.removeSpaces(iban) : null, swift_code);

    if (result && result.success) {
      onComplete(true);

      this.show(false);
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  render() {
    const { show, loading, name, country, bank_name, account_number, sort_code, iban, swift_code, invalidPost } = this.state;
    const { t, venue } = this.props;

    return (
      show && (
        <ModalLayout title={t('Bank Details')} subtitle={venue.name} width={640}>
          <Row>
            <Col md={6}>
              <TextField
                fullWidth
                label={t('Name') + ' *'}
                error={invalidPost.name}
                errorText={t('Name is required')}
                onChange={(e) => this.setState({ name: e.target.value })}
                value={name}
                maxLength={32}
              ></TextField>
            </Col>
            <Col md={6}>
              <CountrySelect
                label={t('Country') + ' *'}
                error={invalidPost.country}
                errorText={t('Country is required')}
                defaultValue={country}
                onChange={(data) => {
                  this.setState({ country: data.value });
                }}
              />
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t('Bank Name') + ' *'}
                error={invalidPost.bank_name}
                errorText={t('Bank Name is required')}
                onChange={(e) => this.setState({ bank_name: e.target.value })}
                value={bank_name}
                maxLength={64}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t('Account Number') + ' *'}
                error={invalidPost.account_number}
                errorText={t('Account Number is required')}
                onChange={(e) => this.setState({ account_number: e.target.value })}
                value={account_number}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField fullWidth label={t('Sort Code')} onChange={(e) => this.setState({ sort_code: e.target.value })} value={sort_code}></TextField>
            </Col>
            <Col md={12}>
              <TextField
                onRef={(ref) => (this.ibanInput = ref)}
                fullWidth
                label={t('IBAN')}
                error={invalidPost.iban}
                errorText={t('IBAN Number is an invalid')}
                value={country ? IBAN.printFormat(country + iban.substring(2)) : iban}
                onChange={(e) => this.setState({ iban: e.target.value.toUpperCase() })}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField fullWidth label={t('Swift Code')} onChange={(e) => this.setState({ swift_code: e.target.value })} value={swift_code}></TextField>
            </Col>
          </Row>
          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CLOSE')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={t('SAVE')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnClick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(BankDetailsVenueModal);
