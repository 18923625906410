import { toast } from 'react-toastify';

const defaultOptions = {
  position: 'top-center',
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export default class Toast {
  static info(content, options = {}) {
    options = { ...options, ...defaultOptions };
    options.type = toast.TYPE.INFO;

    toast(content, options);
  }

  static success(content, options = {}) {
    options = { ...options, ...defaultOptions };
    options.type = toast.TYPE.SUCCESS;

    toast(content, options);
  }

  static warning(content, options = {}) {
    options = { ...options, ...defaultOptions };
    options.type = toast.TYPE.WARNING;

    toast(content, options);
  }

  static error(content, options = {}) {
    options = { ...options, ...defaultOptions };
    options.type = toast.TYPE.ERROR;

    toast(content, options);
  }
}
