import React from 'react';
import moment from 'moment';
import ReactLoading from 'react-loading';
import ReactSwitch from 'react-switch';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Filter, Checkbox } from '../components/fields';
import { LineChart } from '../components/charts';
import { SumupCard, TerminalsCard, TopBarsCard, TopProductsCard } from '../components/cards';
import { ReportService } from '../services';
import { Converter, Generator, Validator, Report, LocalStorage } from '../utils';
import Constants from '../Constants';

import '../styles/report.css';

class ReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      tLoading: false,
      searchText: '',
      result: null,
      tResult: null,
      filter: LocalStorage.getFilter(),
      appPayments: LocalStorage.getAppPayments(),
      externalPayments: false,
      externalPaymentType: null,
      report: {
        currencySymbol: '',
        totalOrders: 0,
        totalAmount: 0,
        totalAmountChange: 0,
        totalDrinks: 0,
        totalDrinksChange: 0,
        totalCustomers: 0,
        totalCustomersChange: 0,
        averageWaiting: 0,
        averageWaitingChange: 0,
        totalServiceCharge: 0,
        paymentFeeTotal: 0,
        transferTotal: 0,
        beforeTransferTotal: 0,
        netIncome: 0,
        tipTotal: 0,
      },
    };

    this.fetchReports = this.fetchReports.bind(this);
    this.setReport = this.setReport.bind(this);
    this.onSearchChange = this.onSearchChange.bind(this);
    this.onFilterChange = this.onFilterChange.bind(this);
    this.onActionBtnClick = this.onActionBtnClick.bind(this);
    this.onProductsLimitChange = this.onProductsLimitChange.bind(this);
    this.onExternalPaymentMethodChange = this.onExternalPaymentMethodChange.bind(this);

    this.reportService = new ReportService();
  }

  componentDidMount() {
    this.fetchReports();
  }

  async fetchReports() {
    this.setState({ loading: true });

    const { filter, appPayments, externalPayments, externalPaymentType } = this.state;
    const result = await this.reportService.get({
      venue_id: filter.venue ? filter.venue._id : undefined,
      start_date: filter.date && filter.date.start ? filter.date.start.formated : undefined,
      end_date: filter.date && filter.date.end ? filter.date.end.formated : undefined,
      plimit: filter.plimit ? filter.plimit : undefined,
      payment_by: appPayments ? 'APP' : externalPayments ? 'EXTERNAL' : undefined,
      payment_method: externalPayments ? externalPaymentType || undefined : undefined,
    });

    if (result && result.success) {
      this.setState({ result: result, loading: false }, () => {
        this.setReport();
      });

      if (this.filter) {
        this.filter.setFilterFields('venue', result.selected_venue);
        this.filter.onStartDateChange(Converter.toLocalDateTime(result.start_date), 'YYYY-MM-DD');
        this.filter.onEndDateChange(Converter.toLocalDateTime(result.end_date), 'YYYY-MM-DD');
        this.filter.showTags();
      }
    } else if (result && result.ecode === 214) {
      this.setState({ result: null, loading: false });
    }

    this.setState({ tLoading: true });

    const terminalResult =
      result.selected_venue.terminal_provider === Constants.TERMINAL_PROVIDERS.PAYMENTSENSE
        ? await this.reportService.getTerminals({
            venue_id: filter.venue ? filter.venue._id : undefined,
          })
        : result.selected_venue.terminal_provider === Constants.TERMINAL_PROVIDERS.SUMUP
        ? await this.reportService.getSumup({
            venue_id: filter.venue ? filter.venue._id : undefined,
            start_date: filter.date && filter.date.start ? filter.date.start.formated : undefined,
            end_date: filter.date && filter.date.end ? filter.date.end.formated : undefined,
          })
        : null;

    if (terminalResult && terminalResult.success) {
      this.setState({ tResult: terminalResult, tLoading: false });
    } else if (result && result.ecode === 214) {
      this.setState({ tResult: null, tLoading: false });
    }
  }

  setReport() {
    const { result } = this.state;

    const currencySymbol = result ? Constants.CURRENCIES[result.selected_venue.currency].symbol : '';
    const totalOrders = result ? Generator.sumOfFieldInArray('total_orders', result.reports) : 0;
    const totalAmount = result ? Generator.sumOfFieldInArray('total', result.reports) : 0;
    const totalAmountChange = result ? Generator.getChangeByPercent('total', result.reports) : 0;
    const totalDrinks = result ? Generator.sumOfFieldInArray('total_qty', result.reports) : 0;
    const totalDrinksChange = result ? Generator.getChangeByPercent('total_qty', result.reports) : 0;
    const totalCustomers = result ? Generator.sumOfFieldInArray('total_customers', result.customers) : 0;
    const totalCustomersChange = result ? Generator.getChangeByPercent('total_customers', result.customers) : 0;
    const averageWaiting = result ? Generator.averageOfFieldInArray('average_waiting', result.reports) : 0;
    const averageWaitingChange = result ? Generator.getChangeByPercent('average_waiting', result.reports) : 0;
    const totalServiceCharge = result ? Generator.sumOfFieldInArray('service_charge', result.reports) : 0;
    const paymentFeeTotal = result ? Generator.sumOfFieldInArray('payment_fee', result.reports) : 0;
    const transferTotal = result ? Generator.sumOfFieldInArray('transfer_fee', result.reports) : 0;
    const tipTotal = result ? Generator.sumOfFieldInArray('total_tips', result.reports) : 0;

    const beforeTransferTotal = totalAmount - paymentFeeTotal;
    const netIncome = beforeTransferTotal - transferTotal;

    this.setState({
      report: {
        currencySymbol,
        totalOrders,
        totalAmount,
        totalAmountChange,
        totalDrinks,
        totalDrinksChange,
        totalCustomers,
        totalCustomersChange,
        averageWaiting,
        averageWaitingChange,
        totalServiceCharge,
        paymentFeeTotal,
        transferTotal,
        beforeTransferTotal,
        netIncome,
        tipTotal,
      },
    });
  }

  onSearchChange(searchText) {
    this.setState(
      {
        searchText: searchText,
        loading: true,
      },
      () => {
        this.fetchReports();
      }
    );
  }

  onFilterChange(filter) {
    this.setState({ filter: filter }, () => {
      this.fetchReports();
    });
  }

  onActionBtnClick(data, selectedValue) {
    this.setState({ loading: true });

    const { result, report } = this.state;
    const { currencySymbol, totalOrders, totalServiceCharge, totalAmount, paymentFeeTotal, transferTotal, netIncome, tipTotal } = report;

    const field = data.field;
    const type = selectedValue.type;
    const filename = moment.utc(result.start_date).format('DD MMM') + '-' + moment.utc(result.end_date).format('DD MMM') + '-' + result.selected_venue.name + '.' + type;

    Report[type][field](
      result[field],
      filename,
      result.payment_provider_settings,
      result.transfer_settings,
      totalServiceCharge,
      totalAmount,
      totalOrders,
      paymentFeeTotal,
      transferTotal,
      netIncome,
      tipTotal,
      currencySymbol
    );

    this.setState({ loading: false });
  }

  onProductsLimitChange(data, selectedValue) {
    const { filter } = this.state;
    filter[data.field] = selectedValue.value;

    this.setState({ filter: filter }, () => {
      this.fetchReports();
    });
  }

  onExternalPaymentMethodChange(checked, value) {
    this.setState({ externalPaymentType: value }, () => {
      this.fetchReports();
    });
  }

  render() {
    const { loading, tLoading, filter, searchText, appPayments, externalPayments, externalPaymentType, result, tResult, report } = this.state;
    const {
      currencySymbol,
      totalOrders,
      totalAmount,
      totalAmountChange,
      totalDrinks,
      totalDrinksChange,
      totalCustomers,
      totalCustomersChange,
      averageWaiting,
      averageWaitingChange,
      totalServiceCharge,
      paymentFeeTotal,
      transferTotal,
      netIncome,
      tipTotal
    } = report;
    const { t, label } = this.props;

    return (
      <div className='content'>
        <div className='content-title'>
          <div className='content-title-label'>{label}</div>
          <div className='content-title-buttons'></div>
        </div>
        <div className='content-body'>
          <div className='content-header'>
            <Filter
              onRef={(ref) => (this.filter = ref)}
              searchText={searchText}
              fields={['venue', 'date']}
              onSearchChange={this.onSearchChange}
              onFilterChange={this.onFilterChange}
            />
            <div className='report-filter-alt-container'>
              <div className='report-filter-alt'>
                <span>{t('Application Payments')}</span>
                <ReactSwitch
                  checked={appPayments || false}
                  onChange={(checked) => {
                    LocalStorage.setAppPayments(checked);

                    this.setState({ appPayments: checked, externalPayments: checked ? false : externalPayments }, () => {
                      this.fetchReports();
                    });
                  }}
                  onColor='#fec802'
                  onHandleColor='#ffffff'
                  handleDiameter={16}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={24}
                  width={48}
                ></ReactSwitch>
              </div>
              <div className='report-filter-alt'>
                <span>{t('External Payments')}</span>
                <ReactSwitch
                  checked={externalPayments || false}
                  onChange={(checked) => {
                    this.setState({ externalPayments: checked, appPayments: checked ? false : appPayments }, () => {
                      this.fetchReports();
                    });
                  }}
                  onColor='#fec802'
                  onHandleColor='#ffffff'
                  handleDiameter={16}
                  uncheckedIcon={false}
                  checkedIcon={false}
                  height={24}
                  width={48}
                ></ReactSwitch>
              </div>
              {externalPayments && (
                <div className='report-filter-alt'>
                  <div className='report-filter-alt-checkboxes'>
                    <Checkbox text={t('All')} value={null} checked={externalPaymentType === null ? true : false} onChange={this.onExternalPaymentMethodChange} />
                    <Checkbox
                      text={t('Card')}
                      value={Constants.PAYMENT_METHODS.CARD}
                      checked={externalPaymentType === Constants.PAYMENT_METHODS.CARD ? true : false}
                      onChange={this.onExternalPaymentMethodChange}
                    />
                    <Checkbox
                      text={t('Cash')}
                      value={Constants.PAYMENT_METHODS.CASH}
                      checked={externalPaymentType === Constants.PAYMENT_METHODS.CASH ? true : false}
                      onChange={this.onExternalPaymentMethodChange}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

          {loading ? (
            <div className='margin-auto'>
              <ReactLoading type='spin' color='#000' height='24px' width='24px' />
            </div>
          ) : result ? (
            <div>
              <Row>
                <Col md={3} className='report-summary-col'>
                  <div className='report-summary-card'>
                    <p className='report-summary-card-title'>{t('Order served')}</p>
                    <p className={`report-summary-card-subtitle ${totalAmountChange < 0 ? 'low' : ''}`}>{totalAmountChange + '%'}</p>
                    <p className='report-summary-card-result'>{totalOrders}</p>
                    <p className='report-summary-card-subresult'>
                      {currencySymbol} {tipTotal.toFixed(2)} <small>{t('tips')}</small>
                    </p>
                  </div>
                </Col>
                <Col md={3} className='report-summary-col'>
                  <div className='report-summary-card'>
                    <p className='report-summary-card-title'>{t('Total items')}</p>
                    <p className={`report-summary-card-subtitle ${totalDrinksChange < 0 ? 'low' : ''}`}>{totalDrinksChange + '%'}</p>
                    <p className='report-summary-card-result-large'>{totalDrinks}</p>
                  </div>
                </Col>
                <Col md={3} className='report-summary-col'>
                  <div className='report-summary-card'>
                    <p className='report-summary-card-title'>{t('Active Users')}</p>
                    <p className={`report-summary-card-subtitle ${totalCustomersChange < 0 ? 'low' : ''}`}>{totalCustomersChange + '%'}</p>
                    <p className='report-summary-card-result-large'>{totalCustomers}</p>
                  </div>
                </Col>
                <Col md={3} className='report-summary-col'>
                  <div className='report-summary-card'>
                    <p className='report-summary-card-title'>{t('Avg. Waiting Time')}</p>
                    <p className={`report-summary-card-subtitle ${averageWaitingChange > 0 ? 'low' : ''}`}>{averageWaitingChange + '%'}</p>
                    <p className='report-summary-card-result-large'>{Converter.humanizeTime(averageWaiting)}</p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={3} className='report-summary-col'>
                  <div className='report-summary-card'>
                    <p className='report-summary-card-title'>{t('Service Charge')}</p>
                    <p className='report-summary-card-result-large'>
                      {currencySymbol} {Converter.fixedFloat(totalServiceCharge).toFixed(2)}
                    </p>
                  </div>
                </Col>
                <Col md={3} className='report-summary-col'>
                  <div className='report-summary-card'>
                    <p className='report-summary-card-title'>{t('Gross Income')}</p>
                    <p className='report-summary-card-result-large'>
                      {currencySymbol} {Converter.fixedFloat(totalAmount).toFixed(2)}
                    </p>
                  </div>
                </Col>
                <Col md={3} className='report-summary-col'>
                  <div className='report-summary-card'>
                    <p className='report-summary-card-title'>{t('Fees')}</p>
                    <p className='report-summary-card-result-large'>
                      {currencySymbol} {Converter.fixedFloat(paymentFeeTotal + transferTotal).toFixed(2)}
                    </p>
                  </div>
                </Col>
                <Col md={3} className='report-summary-col'>
                  <div className='report-summary-card'>
                    <p className='report-summary-card-title'>{t('Net Income')}</p>

                    <p className='report-summary-card-result-large'>
                      {currencySymbol} {Converter.fixedFloat(netIncome).toFixed(2)}
                    </p>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <LineChart
                    title={t('Sales performance')}
                    labels={result && result.dates}
                    onActionBtnClick={this.onActionBtnClick}
                    renderXLabel={(value) => {
                      return value.toFixed(0);
                    }}
                    renderYLabel={(value) => {
                      return moment(value).format('DD MMM');
                    }}
                    renderTooltip={{
                      label: (tooltipItem) => {
                        return tooltipItem.datasetIndex === 0 ? currencySymbol + ' ' + tooltipItem.yLabel.toFixed(2) : tooltipItem.yLabel.toFixed(0);
                      },
                    }}
                    datasets={[
                      {
                        label: 'Orders',
                        data: result ? Validator.populateArray('total', result.reports) : [],
                        fill: false,
                        borderColor: '#f6ca45',
                        pointBackgroundColor: '#f6ca45',
                        pointBorderColor: '#f6ca45',
                      },
                      {
                        label: 'Customers',
                        data: result ? Validator.populateArray('total_customers', result.customers) : [],
                        fill: false,
                        borderColor: '#5a90dc',
                        pointBackgroundColor: '#5a90dc',
                        pointBorderColor: '#5a90dc',
                      },
                    ]}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <TopBarsCard title={t('Top Performing Bars')} rows={result && result.bars} currencySymbol={currencySymbol} onActionBtnClick={this.onActionBtnClick} />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <TopProductsCard
                    title={t('Total sales')}
                    plimit={filter.plimit || null}
                    rows={result && result.products}
                    currency={result.selected_venue.currency}
                    currencySymbol={currencySymbol}
                    onActionBtnClick={this.onActionBtnClick}
                    onLimitChange={this.onProductsLimitChange}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  {tLoading ? (
                    <div className='margin-auto'>
                      <ReactLoading type='spin' color='#000' height='24px' width='24px' />
                    </div>
                  ) : result.selected_venue.terminal_provider === Constants.TERMINAL_PROVIDERS.PAYMENTSENSE ? (
                    <TerminalsCard title={t('Terminals')} rows={tResult && tResult.end_of_days} currencySymbol={currencySymbol} />
                  ) : result.selected_venue.terminal_provider === Constants.TERMINAL_PROVIDERS.SUMUP ? (
                    <SumupCard title={t('SumUp')} result={tResult} currencySymbol={currencySymbol} />
                  ) : null}
                </Col>
              </Row>
            </div>
          ) : (
            <p className='report-no-content'>{t('No Report Found. Please select a venue.')}</p>
          )}
        </div>
      </div>
    );
  }
}

export default withTranslation()(withRouter(ReportPage));
