import React from 'react';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { Converter, EntityError, Validator } from '../../utils';
import { DefaultButton, LoadingButton } from '../buttons';
import { TextField, Select, DateTimePicker, Checkbox } from '../fields';
import { ModalLayout } from '../layouts';
import { DiscountService, ProductService } from '../../services';

import '../../styles/modals/modal.css';
import '../../styles/modals/savediscount.css';

class SaveDiscountModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      discountType: 'RATE',
      discountId: null,
      venue: null,
      name: '',
      level: '',
      percent: '',
      minumumQuantity: '',
      buy: '',
      free: '',
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      membership: null,
      category: null,
      products: [],
      selectedProducts: [],
      invalidPost: {
        venue_id: false,
        name: false,
        level: false,
        percent: false,
        minumumQuantity: false,
        start_date: false,
        end_date: false,
        membership_id: false,
        product_ids: false,
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.setFields = this.setFields.bind(this);
    this.convertDiscountType = this.convertDiscountType.bind(this);
    this.onSaveBtnCLick = this.onSaveBtnCLick.bind(this);
    this.onDiscountRateChange = this.onDiscountRateChange.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
    this.onSelectedProductChange = this.onSelectedProductChange.bind(this);

    this.productService = new ProductService();
    this.discountService = new DiscountService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      discountId: null,
      discountType: 'RATE',
      venue: null,
      name: '',
      level: '',
      percent: '',
      minumumQuantity: '',
      buy: '',
      free: '',
      startDate: moment().toDate(),
      endDate: moment().toDate(),
      membership: null,
      category: null,
      products: [],
      selectedProducts: [],
      invalidPost: {
        venue_id: false,
        name: false,
        level: false,
        percent: false,
        minumumQuantity: false,
        start_date: false,
        end_date: false,
        membership_id: false,
        product_ids: false,
      },
    });
  }

  show(set = true, venue = null) {
    this.resetFields();
    this.setState({
      show: set,
      venue: venue,
    });
  }

  setFields(discount) {
    this.setState(
      {
        discountId: discount._id,
        venue: discount.venue,
        products: discount.products,
        name: discount.name,
        level: discount.level,
        percent: discount.rate.percent,
        minumumQuantity: discount.rate.min_qty,
        startDate: moment(discount.start_date).toDate(),
        endDate: moment(discount.end_date).toDate(),
        membership: discount.membership || null,
      },
      () => {
        const minQty = parseInt(discount.rate.min_qty);

        if (minQty > 1) {
          const discountData = this.convertDiscountType(true);

          this.setState({
            discountType: 'FREE_ITEM',
            percent: '',
            buy: discountData.buy,
            free: discountData.free,
          });
        }

        this.setState({
          selectedProducts: discount.products.map((product) => product._id),
        });
      }
    );
  }

  convertDiscountType(reverse = false) {
    const { percent, minumumQuantity, buy, free, discountType } = this.state;

    if (!reverse) {
      if (discountType === 'RATE') {
        return {
          percent: percent,
          min_qty: 1,
        };
      } else {
        const totalQuantity = parseInt(buy) + parseInt(free);

        return {
          percent: parseFloat(parseFloat((parseInt(free) / totalQuantity) * 100).toFixed(2)),
          min_qty: totalQuantity,
        };
      }
    } else {
      const freeQuantity = parseInt(Math.round((parseInt(minumumQuantity) * parseFloat(percent)) / 100));

      return {
        buy: parseInt(parseInt(minumumQuantity) - freeQuantity),
        free: freeQuantity,
      };
    }
  }

  async onSaveBtnCLick() {
    const { discountId, venue, name, level, startDate, endDate, selectedProducts, membership, invalidPost } = this.state;
    const { onComplete } = this.props;
    const formatedStartDate = moment(startDate).toISOString();
    const formatedEndDate = moment(endDate).toISOString();
    const discountData = this.convertDiscountType();

    this.setState({
      loading: true,
      invalidPost: EntityError.setInputErrors(invalidPost, null, true),
    });

    const result = discountId
      ? await this.discountService.put(
          discountId,
          name,
          level,
          discountData.percent,
          discountData.min_qty,
          formatedStartDate,
          formatedEndDate,
          selectedProducts ? selectedProducts : [],
          membership ? membership.id : null
        )
      : await this.discountService.post(
          venue ? venue._id : null,
          name,
          level,
          discountData.percent,
          discountData.min_qty,
          formatedStartDate,
          formatedEndDate,
          selectedProducts ? selectedProducts : [],
          membership ? membership.id : null
        );

    if (result && result.success) {
      this.show(false);
      onComplete();
    } else if (result && result.ecode === 422) {
      this.setState({
        invalidPost: EntityError.setInputErrors(invalidPost, result.errors),
      });
    }

    this.setState({
      loading: false,
    });
  }

  onDiscountRateChange(checked, value) {
    this.setState({ discountType: value });
  }

  async onProductChange(value) {
    const exists = Validator.filter('_id', value._id, this.state.products);
    if (exists.length === 0) {
      this.setState({ products: [...this.state.products, value], selectedProducts: [...this.state.selectedProducts, value._id.toString()] });
    }
  }

  onSelectedProductChange(checked, value) {
    const { selectedProducts } = this.state;

    if (checked) {
      this.setState({ selectedProducts: [...selectedProducts, value] });
    } else {
      const selectedIds = [...selectedProducts];
      selectedIds.splice(selectedProducts.indexOf(value), 1);

      this.setState({ selectedProducts: selectedIds });
    }
  }

  render() {
    const {
      show,
      loading,
      discountType,
      discountId,
      name,
      level,
      percent,
      buy,
      free,
      venue,
      startDate,
      endDate,
      membership,
      category,
      products,
      selectedProducts,
      invalidPost,
    } = this.state;
    const { t } = this.props;

    return (
      show && (
        <ModalLayout title={discountId ? t('Update Discount') : t('Add Discount')} width={720}>
          <Row>
            <Col md={6}>
              <DateTimePicker
                label={t('Start Date')}
                showTimeSelect={true}
                minDate={moment().toDate()}
                defaultValue={startDate}
                onChange={(date) => {
                  this.setState({ startDate: date });
                }}
              />
            </Col>
            <Col md={6}>
              <DateTimePicker
                label={t('End Date')}
                showTimeSelect={true}
                minDate={startDate}
                defaultValue={endDate}
                error={invalidPost.end_date}
                errorText={t('End Date must be after Start Date')}
                onChange={(date) => {
                  this.setState({ endDate: date });
                }}
              />
            </Col>
            <Col md={12}>
              <TextField
                fullWidth
                label={t('Name')}
                error={invalidPost.name}
                errorText={t('Name is required')}
                value={name}
                onChange={(e) => this.setState({ name: e.target.value })}
              ></TextField>
            </Col>
            <Col md={6}>
              <TextField
                fullWidth
                label={t('Level')}
                error={invalidPost.level}
                errorText={t('min 1 max 9999')}
                value={level}
                rightBtnLabel='#'
                onChange={(e) => this.setState({ level: e.target.value })}
              ></TextField>
            </Col>
            <Col md={6}>
              <Select
                fullWidth
                type='memberships'
                field='name'
                label={t('Memberships')}
                error={invalidPost.membership_id}
                errorText={t('Membership ID is required')}
                defaultValue={membership}
                onChange={(value) => this.setState({ membership: value })}
              ></Select>
            </Col>
            <Col md={6}>
              <Select
                fullWidth
                type='products'
                field='name'
                label={t('Products')}
                error={invalidPost.product_ids}
                errorText={t('Products are required')}
                value={category}
                query={{ venue_id: venue._id }}
                onChange={this.onProductChange}
                renderNoResult={<div className='add-product-no-result'>{t('Please select a venue or add a new category for venue')}</div>}
              ></Select>
            </Col>
            <Col md={6}>
              <div className='discount-products-area'>
                {products &&
                  products.length > 0 &&
                  products.map((product) => {
                    return (
                      <Checkbox
                        key={product._id}
                        text={product.name}
                        value={product._id.toString()}
                        checked={selectedProducts.includes(product._id.toString())}
                        onChange={this.onSelectedProductChange}
                      />
                    );
                  })}
              </div>
            </Col>
            <Col md={12}>
              <p className='discount-type-subtitle'>{t('Discount Type')}</p>
              <Col md={6}>
                <div className='discount-type-div'>
                  <Checkbox text={t('Rate')} value={'RATE'} checked={discountType === 'RATE' ? true : false} onChange={this.onDiscountRateChange} />
                  <Checkbox text={t('Free Item')} value={'FREE_ITEM'} checked={discountType === 'FREE_ITEM' ? true : false} onChange={this.onDiscountRateChange} />
                </div>
              </Col>
              {discountType === 'RATE' ? (
                <Row>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      label={t('Percent')}
                      error={invalidPost.percent}
                      errorText={t('min 0.01% max 100%')}
                      value={percent}
                      rightBtnLabel='%'
                      onChange={(e) => this.setState({ percent: Converter.onlyNumber(e.target.value) })}
                    ></TextField>
                  </Col>
                </Row>
              ) : (
                <Row>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      label={t('Buy')}
                      value={buy}
                      rightBtnLabel='#'
                      onChange={(e) => this.setState({ buy: Converter.onlyNumber(e.target.value, false) })}
                    ></TextField>
                  </Col>
                  <Col md={4}>
                    <TextField
                      fullWidth
                      label={t('Free')}
                      value={free}
                      rightBtnLabel='#'
                      onChange={(e) => this.setState({ free: Converter.onlyNumber(e.target.value, false) })}
                    ></TextField>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>

          <div className='modal-buttons-container'>
            <div className='modal-button'>{loading || <DefaultButton text={t('CANCEL')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={discountId ? t('UPDATE') : t('SAVE DISCOUNT')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onSaveBtnCLick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveDiscountModal);
