import React from 'react';
import moment from 'moment';
import Constants from '../../Constants';
import { withTranslation } from 'react-i18next';
import { Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { Converter } from '../../utils';
import { TextField, Select } from '../fields';

import '../../styles/fields/addoption.css';

class AddOptionField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      price: '',
      ingredients: [],
      perUnits: [],
      invalidForm: {
        name: false,
      },
    };

    this.onAddBtnClick = this.onAddBtnClick.bind(this);
    this.onRemoveIngredientBtnClick = this.onRemoveIngredientBtnClick.bind(this);
    this.onRemoveBtnClick = this.onRemoveBtnClick.bind(this);
    this.onAddIngredientBtnClick = this.onAddIngredientBtnClick.bind(this);
  }

  onAddBtnClick() {
    const { name, price, ingredients, perUnits } = this.state;
    const { onOptionAdded } = this.props;

    this.setState({ invalidForm: { ...this.state.invalidForm, name: name.length <= 1 } });

    if (name.length > 1) {
      this.setState({ name: '', price: '', ingredients: [], perUnits: [] });

      onOptionAdded({
        timestamp: moment().valueOf(),
        name: Converter.capitalize(name),
        price: parseFloat(price) || 0,
        ingredients: ingredients.map((ingredient, index) => {
          return { ingredient_id: ingredient._id, name: ingredient.name, per_unit: perUnits[index] ? parseFloat(perUnits[index]) : 0, unit: ingredient.unit };
        }),
      });
    }
  }

  onRemoveIngredientBtnClick(index) {
    const { ingredients, perUnits } = this.state;

    ingredients.splice(index, 1);
    perUnits.splice(index, 1);

    this.setState({ ingredients, perUnits });
  }

  onRemoveBtnClick(index) {
    const { onOptionRemoved } = this.props;
    onOptionRemoved(index);
  }

  onAddIngredientBtnClick() {
    const { ingredients, perUnits } = this.state;

    ingredients.push(null);
    perUnits.push('');

    this.setState({ ingredients, perUnits });
  }

  render() {
    const { name, price, ingredients, perUnits, invalidForm } = this.state;
    const { t, venue, prices } = this.props;
    const currency = venue.currency;

    const priceList =
      prices &&
      prices.sort((a, b) => {
        return a.timestamp > b.timestamp ? 1 : -1;
      });

    return (
      <div className='add-option-container'>
        <div className='add-option-header'>
          <span className='add-option-header-span'>{t('Prices')}</span>
        </div>
        <div className='add-option-body'>
          <Container>
            <Row>
              <div className='add-option-col'>
                <TextField
                  fullWidth
                  label={t('Name')}
                  value={name}
                  error={invalidForm.name}
                  errorText={t('Name is invalid')}
                  onChange={(e) => this.setState({ name: e.target.value })}
                ></TextField>
              </div>
              <div className='add-option-col'>
                <TextField
                  fullWidth
                  label={t('Price')}
                  value={price}
                  onChange={(e) => this.setState({ price: Converter.onlyNumber(e.target.value) })}
                  rightBtnLabel={Constants.CURRENCIES[currency].symbol}
                ></TextField>
              </div>
              <div className='add-option-col-2'>
                <div className='add-option-group'>
                  <div className='add-option-col-2'>
                    {ingredients &&
                      ingredients.map((ingredient, index) => {
                        return (
                          <Select
                            key={`ig_${index}_${Date.now()}`}
                            fullWidth
                            type='ingredients'
                            field='name'
                            label={t('Ingredient')}
                            error={invalidForm.ingredients}
                            errorText={t('Ingredient is required')}
                            defaultValue={ingredient}
                            query={{ venue_id: venue._id }}
                            onChange={(value) => {
                              ingredients[index] = {
                                _id: value._id,
                                name: value.name,
                                unit: value.unit,
                              };
                              this.setState({ ingredients });
                            }}
                          ></Select>
                        );
                      })}
                  </div>
                  <div className='add-option-col-2'>
                    {perUnits &&
                      perUnits.map((perUnit, index) => {
                        return (
                          <div key={`pud_${index}`} className='add-option-col-last'>
                            <TextField
                              key={`pu_${index}}`}
                              fullWidth
                              label={t('Per Unit')}
                              value={perUnit}
                              error={invalidForm.per_unit}
                              errorText={t('Per Unit is invalid')}
                              onChange={(e) => {
                                perUnits[index] = Converter.onlyNumber(e.target.value);
                                this.setState({ perUnits });
                              }}
                              rightBtnLabel={ingredients[index] && ingredients[index].unit}
                            />
                            <div className='add-option-remove-area'>
                              <button
                                className='add-option-remove-btn'
                                onClick={() => {
                                  this.onRemoveIngredientBtnClick(index);
                                }}
                              >
                                <FontAwesomeIcon icon={faMinus} size='1x' color='#ff6961'></FontAwesomeIcon>
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
                <div className='add-option-group'>
                  <button className='add-option-add-btn' onClick={this.onAddIngredientBtnClick}>
                    <FontAwesomeIcon icon={faPlus} size='1x' color='#222b45'></FontAwesomeIcon> {t('Add Ingredient')}
                  </button>
                  <button className='add-option-add-btn' onClick={this.onAddBtnClick}>
                    <FontAwesomeIcon icon={faPlus} size='1x' color='#222b45'></FontAwesomeIcon> {t('Add Option')}
                  </button>
                </div>
              </div>
            </Row>
            <div className='add-option-list'>
              {priceList.map((option, index) => {
                const ingredientList =
                  option.ingredients &&
                  option.ingredients.sort((a, b) => {
                    return a.name > b.name ? 1 : -1;
                  });

                return (
                  <Row className='add-option-list-row' key={`opl_${index}_${Date.now()}`}>
                    <div className='add-option-col'>
                      <div className='add-option-list-item'>{option.name}</div>
                    </div>
                    <div className='add-option-col'>
                      <div className='add-option-list-item'>
                        {option.price} <span>{Constants.CURRENCIES[currency].symbol}</span>
                      </div>
                    </div>
                    <div className='add-option-col'>
                      {option.ingredients &&
                        option.ingredients.map((ingredient, index) => {
                          return (
                            <div key={`opli_${index}_${Date.now()}`} className='add-option-list-item'>
                              {ingredient.name}
                            </div>
                          );
                        })}
                    </div>
                    <div className='add-option-col'>
                      {ingredientList &&
                        ingredientList.map((ingredient, index) => {
                          return (
                            <div key={`opli2_${index}_${Date.now()}`} className='add-option-list-item'>
                              {ingredient.per_unit} <span>{ingredient.unit}</span>
                            </div>
                          );
                        })}
                    </div>
                    <div className='add-option-delete-area'>
                      <button
                        className='add-option-remove-btn'
                        onClick={() => {
                          this.onRemoveBtnClick(index);
                        }}
                      >
                        <FontAwesomeIcon icon={faMinus} size='1x' color='#ff6961'></FontAwesomeIcon>
                      </button>
                    </div>
                  </Row>
                );
              })}
            </div>
          </Container>
        </div>
      </div>
    );
  }
}

export default withTranslation()(AddOptionField);
