import React from 'react';
import PropTypes from 'prop-types';
import ReactSwitch from 'react-switch';
import ReactLoading from 'react-loading';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight, faStepBackward, faStepForward } from '@fortawesome/free-solid-svg-icons';
import { DropDown, Filter } from '../fields';

import '../../styles/tables/default.css';
import { Converter } from '../../utils';

class DefaultTable extends React.Component {
  constructor(props) {
    super(props);

    this.getField = this.getField.bind(this);
    this.parseField = this.parseField.bind(this);
    this.getFilter = this.getFilter.bind(this);
    this.onPaginationChange = this.onPaginationChange.bind(this);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  getField(row, field) {
    const notations = field.split('.');

    if (notations.length > 1) {
      let value = row;

      for (let i = 0; i < notations.length; i++) {
        const notation = notations[i];

        if (value[notation]) {
          value = value[notation];
        } else {
          return null;
        }
      }

      return value;
    } else if (notations.length > 0) {
      return row[notations[0]];
    } else {
      return null;
    }
  }

  parseField(row, fields) {
    if (Array.isArray(fields)) {
      let result = '';

      for (let i = 0; i < fields.length; i++) {
        const field = fields[i];

        result += this.getField(row, field) + ' ';
      }

      return result.trim();
    } else if (typeof fields === 'string') {
      return this.getField(row, fields);
    } else {
      return null;
    }
  }

  getFilter() {
    return this.filter;
  }

  onPaginationChange(data, action) {
    const { onPaginationChange } = this.props;

    onPaginationChange(data, action ? action.value : null);
  }

  render() {
    const { t, loading, columns, rows, searchText, filter, actions, pagination, onSearchChange, onSwitchChange, onActionChange, onFilterChange, onCellClick } = this.props;
    const skip = pagination ? pagination.skip : 0;
    const limit = pagination ? pagination.limit : 0;
    const count = pagination ? pagination.count : 0;
    const isMin = skip - limit >= 0 ? false : true;
    const isMax = skip + limit < count ? false : true;

    return (
      <div className='default-table-container'>
        <div className='default-table-header'>
          <Filter
            searchText={searchText}
            fields={filter}
            onSearchChange={onSearchChange}
            onFilterChange={onFilterChange}
            onRef={(ref) => {
              this.filter = ref;
            }}
          />
        </div>
        <div className='default-table-wrapper'>
          {loading ? (
            <div className='default-table-loading'>
              <ReactLoading type='spin' color='#000' height='30px' width='30px' />
            </div>
          ) : (
            <table className='default-table'>
              <thead>
                <tr>
                  {columns.map((column) => {
                    return (
                      <th key={column.title} style={column.titleStyle}>
                        {column.title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {rows.length > 0 ? (
                  !loading &&
                  rows.map((row, rowIndex) => {
                    return (
                      <tr key={rowIndex}>
                        {columns.map((column) => {
                          const style = column.style || {};

                          let value = this.parseField(row, column.field);
                          value = column.removeSpecialCharacters ? value.replace(/[^a-zA-Z ]/g, ' ') : value;

                          if (column.action) {
                            // ACTION BUTTONS
                            return (
                              <td key={column.title} style={style}>
                                <DropDown data={row} bull={true} isTable={true} actions={actions} onChange={onActionChange}></DropDown>
                              </td>
                            );
                          } else if (column.switch) {
                            // SWITCH BUTTON
                            return (
                              <td key={column.title} style={style}>
                                <span className='default-table-mobile-title'>{column.title}</span>
                                <ReactSwitch
                                  checked={value || false}
                                  onChange={(checked) => {
                                    onSwitchChange(checked, row, rowIndex);
                                  }}
                                  onColor='#fec802'
                                  onHandleColor='#ffffff'
                                  handleDiameter={16}
                                  uncheckedIcon={false}
                                  checkedIcon={false}
                                  height={20}
                                  width={41}
                                ></ReactSwitch>
                              </td>
                            );
                          } else if (value !== null) {
                            // String, Number, Data, etc
                            if (column.render) {
                              // RENDER RESULT
                              return (
                                <td key={column.title} style={style} onClick={() => onCellClick(column, row, rowIndex)}>
                                  <span className='default-table-mobile-title'>{column.title}</span>
                                  <span className='default-table-value' title={value ? value.toString() : ''}>
                                    {column.render(value)}
                                  </span>
                                </td>
                              );
                            } else {
                              return (
                                <td key={column.title} style={style} onClick={() => onCellClick(column, row, rowIndex)}>
                                  <span className='default-table-mobile-title'>{column.title}</span>
                                  <span className='default-table-value' title={value}>
                                    {column.capitalize ? Converter.capitalize(value) : value}
                                  </span>
                                </td>
                              );
                            }
                          } else {
                            // Nulls
                            return <td key={column.title} style={style}></td>;
                          }
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={columns.length} className='default-table-no-record'>
                      {t('No Data Found')}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
        <div className='default-table-footer'>
          {pagination && (
            <div>
              <DropDown
                label={pagination.limit.toString()}
                buttonStyle='default-table-footer-dropdown'
                data='LIMIT'
                actions={[
                  { label: '10', title: '10', value: 10 },
                  { label: '15', title: '15', value: 15 },
                  { label: '25', title: '25', value: 25 },
                  { label: '50', title: '50', value: 50 },
                  { label: '100', title: '100', value: 100 },
                ]}
                isTable={true}
                isAbsolute={true}
                up={true}
                onChange={this.onPaginationChange}
              />
              <span className='default-table-footer-count'>
                {(skip + 1).toString() + ' - ' + (skip + limit >= count ? count : skip + limit).toString() + ' / ' + count.toString()}
              </span>
              <span
                className='default-table-footer-btn'
                onClick={() => {
                  if (!isMin) {
                    this.onPaginationChange('NAVIGATE', { value: { skip: 0, limit: limit, count: count } });
                  }
                }}
              >
                <FontAwesomeIcon icon={faStepBackward} />
              </span>
              <span
                className='default-table-footer-btn'
                onClick={() => {
                  if (!isMin) {
                    this.onPaginationChange('NAVIGATE', { value: { skip: skip - limit, limit: limit, count: count } });
                  }
                }}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </span>
              <span
                className='default-table-footer-btn'
                onClick={() => {
                  if (!isMax) {
                    this.onPaginationChange('NAVIGATE', { value: { skip: skip + limit, limit: limit, count: count } });
                  }
                }}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </span>
              <span
                className='default-table-footer-btn'
                onClick={() => {
                  if (!isMax) {
                    this.onPaginationChange('NAVIGATE', { value: { skip: Math.floor((count - 1) / limit) * limit, limit: limit, count: count } });
                  }
                }}
              >
                <FontAwesomeIcon icon={faStepForward} />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  }
}

DefaultTable.propTypes = {
  loading: PropTypes.bool,
  columns: PropTypes.array,
  rows: PropTypes.array,
  searchText: PropTypes.string,
  filter: PropTypes.array,
  actions: PropTypes.array,
  pagination: PropTypes.any,
  onSearchChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onSwitchChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onActionChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onFilterChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onCellClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onPaginationChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

DefaultTable.defaultProps = {
  loading: false,
  searchText: '',
  onActionChange: Function.prototype,
  onFilterChange: Function.prototype,
  onCellClick: Function.prototype,
  onPaginationChange: Function.prototype,
};

export default withTranslation()(withRouter(DefaultTable));
