import QueryString from 'query-string';
import { FetchService } from '.';

export default class DiscountService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.delete = this.delete.bind(this);
  }

  get(query) {
    return this.Get('/products/category?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(venue_id, name) {
    return this.Post('/products/category', { body: JSON.stringify({ venue_id, name }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  put(venue_id, product_categories) {
    return this.Put('/products/category', { body: JSON.stringify({ venue_id, product_categories }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(product_category_id) {
    return this.Post('/products/category/delete', { body: JSON.stringify({ product_category_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
