import moment from 'moment';
import { FetchService } from '.';

export default class DateService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
  }

  get(query) {
    const dates = [
      // TODAY
      {
        id: 'TODAY',
        index: 0,
        name: 'Today',
        start: moment().startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
      },
      // YESTERDAY
      {
        id: 'YESTERDAY',
        index: 1,
        name: 'Yesterday',
        start: moment().add(-1, 'day').startOf('day').toDate(),
        end: moment().add(-1, 'day').endOf('day').toDate(),
      },
      // THIS WEEK
      {
        id: 'THIS_WEEK',
        index: 2,
        name: 'This week',
        start: moment().startOf('isoWeek').startOf('day').toDate(),
        end: moment().endOf('isoWeek').endOf('day').toDate(),
      },
      // THIS MONTH
      {
        id: 'THIS_MONTH',
        index: 3,
        name: 'This month',
        start: moment().startOf('month').startOf('day').toDate(),
        end: moment().endOf('month').endOf('day').toDate(),
      },
      // LAST 90 DAYS
      {
        id: 'LAST_90',
        index: 0,
        name: 'Last 90 Days',
        start: moment().add(-90, 'day').startOf('day').toDate(),
        end: moment().endOf('day').toDate(),
      },
    ];

    return query.search ? { success: true, date_ranges: dates.filter((date) => date.name.includes(query.search)) } : { success: true, date_ranges: dates };
  }
}
