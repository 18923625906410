import React from 'react';
import QRCode from 'qrcode.react';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { ModalLayout } from '../layouts';
import { DefaultButton } from '../buttons';
import Constants from '../../Constants';
import beerIcon from '../../assets/images/beer.png';

import '../../styles/modals/modal.css';
import '../../styles/modals/vieworder.css';
import { Converter } from '../../utils';

class ViewOrderModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      order: null,
      currency: 'GBP',
    };

    this.show = this.show.bind(this);
    this.getOrderType = this.getOrderType.bind(this);
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  show(set = true, order = null, currency = 'GBP') {
    this.setState({
      show: set,
      order: order,
      currency: currency,
    });
  }

  getOrderType(type) {
    const { t } = this.props;

    switch (type) {
      case 1:
        return t('Pick Up');
      case 2:
        return t('Table Service');
      default:
        return '';
    }
  }

  render() {
    const { loading, show, order, currency } = this.state;
    const { t } = this.props;
    const serviceFee = order && order.service_fee ? order.service_fee.total : 0;

    return (
      show && (
        <ModalLayout width={530}>
          <span className='view-order-card-header-top'>{this.getOrderType(order.order_type)}</span>
          <Row>
            <Col md={12}>
              <span className='view-order-card-header-title'># {order.id}</span>
              {order.seat_number && (
                <span className='view-order-card-header-title'>
                  {t('Seat Number')} {`${order.seat_code || ''}${order.seat_number}`}
                </span>
              )}
            </Col>
            <Col md={12} className='text-center'>
              <QRCode value={order.qr_code} />
              <span className='view-order-card-header-qr'>{order.qr_code}</span>
            </Col>
            <Col md={12}>
              {order &&
                order.list &&
                order.list.map((item) => {
                  return (
                    <div key={item._id} className='view-order-card-container'>
                      <div className='view-order-card-image'>
                        <img src={item.product.photo || beerIcon} alt='' />
                      </div>
                      <div className='view-order-card-body'>
                        <p className='view-order-card-title'>
                          {item.qty}x {item.product.name} <span className='view-order-card-title-span'>{'(' + item.name + ')'}</span>
                        </p>
                        <p className='view-order-card-subtitle'>
                          <span className='view-order-card-subtitle-alt'>{item.product.note}</span>
                        </p>
                        {item.discount_id && (
                          <p className='view-order-card-subtitle'>
                            <span className='view-order-card-subtitle-alt'>{item.discount_name}</span>
                          </p>
                        )}
                      </div>
                      <div className='view-order-card-right'>
                        <span>
                          {Constants.CURRENCIES[currency].symbol} {Converter.fixedFloat(item.total_price).toFixed(2)}
                        </span>
                      </div>
                    </div>
                  );
                })}
            </Col>
            <Col md={12}>
              <span className='view-order-grand-total'>
                <span className='view-order-span'>{t('Subtotal')}</span>
                {Constants.CURRENCIES[currency].symbol} {Converter.fixedFloat(order.list.reduce((a, b) => a + b.total_price || 0, 0)).toFixed(2)}
              </span>
            </Col>
            <Col md={12}>
              <span className='view-order-discount-total'>
                <span className='view-order-span'>{t('Discount')}</span>
                {Constants.CURRENCIES[currency].symbol} {order.discount_total && order.discount_total > 0 ? Converter.fixedFloat(order.discount_total).toFixed(2) : '0.00'}
              </span>
            </Col>
            <Col md={12}>
              <span className='view-order-total'>
                <span className='view-order-span'>
                  {t('Service Charge')} ({order.service_fee ? Converter.fixedFloat(order.service_fee.rate).toFixed(2) : '0.00'}%)
                </span>
                {Constants.CURRENCIES[currency].symbol} {Converter.fixedFloat(serviceFee).toFixed(2)}
              </span>
            </Col>
            {order.tip && (
              <Col md={12}>
                <span className='view-order-total'>
                  <span className='view-order-span'>{t('Tip')}</span>
                  {Constants.CURRENCIES[currency].symbol} {order.tip ? Converter.fixedFloat(order.tip.amount).toFixed(2) : '0.00'}
                </span>
              </Col>
            )}
            <Col md={12}>
              <span className='view-order-total'>
                <span className='view-order-span'>
                  {t('VAT')} {order.vat && order.vat.rate ? `(${order.vat.rate.toFixed(2)}%)` : '(0.00%)'}
                </span>
                {Constants.CURRENCIES[currency].symbol} {order.vat && order.vat.amount ? Converter.fixedFloat(order.vat.amount).toFixed(2) : '0.00'}
              </span>
            </Col>
            <Col md={12}>
              <span className='view-order-grand-total'>
                <span className='view-order-span'>{t('Total')}</span>
                {Constants.CURRENCIES[currency].symbol} {Converter.fixedFloat(order.grand_total).toFixed(2)}
              </span>
            </Col>
          </Row>

          <div className='modal-buttons-container'>
            <div className='modal-button-full-width'>{loading || <DefaultButton text={t('CLOSE')} onClick={() => this.show(false)} fullWidth></DefaultButton>}</div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(ViewOrderModal);
