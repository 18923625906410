import React from 'react';
import moment from 'moment';
import ReactLoading from 'react-loading';
import { withTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import { EventService, BarService, ProductService } from '../../services';
import { DefaultButton, LoadingButton } from '../buttons';
import { DateTimePicker, Select, TextField } from '../fields';
import { ModalLayout } from '../layouts';
import { EventBarCard, EventProductCard } from '../cards';
import { Converter, Validator } from '../../utils';

import '../../styles/modals/modal.css';
import '../../styles/modals/saveevent.css';

const maxIndex = 2;

class SaveEventModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      show: false,
      index: 0,
      venue: null,
      date: moment().add(1, 'hours').toDate(),
      category: null,
      duration: 90,
      homeTeam: null,
      guestTeam: null,
      bars: null,
      barProducts: null,
      goal: 0,
      invalidPost: {
        venue_id: false,
        date: false,
        duration: false,
        category: false,
        homeTeam: false,
        guestTeam: false,
        goal: false,
      },
    };

    this.resetFields = this.resetFields.bind(this);
    this.show = this.show.bind(this);
    this.validateInputs = this.validateInputs.bind(this);
    this.nextTab = this.nextTab.bind(this);
    this.filterProducts = this.filterProducts.bind(this);
    this.save = this.save.bind(this);
    this.onBackBtnClick = this.onBackBtnClick.bind(this);
    this.onNextBtnClick = this.onNextBtnClick.bind(this);
    this.onCategoryChange = this.onCategoryChange.bind(this);
    this.onBarSwitchChange = this.onBarSwitchChange.bind(this);
    this.onProductSwitchChange = this.onProductSwitchChange.bind(this);

    this.eventService = new EventService();
    this.barService = new BarService();
    this.productService = new ProductService();
  }

  componentDidMount() {
    if (this.props.onRef) {
      this.props.onRef(this);
    }
  }

  resetFields() {
    this.setState({
      loading: false,
      show: false,
      index: 0,
      venue: null,
      date: moment().add(1, 'hours').toDate(),
      category: null,
      duration: 90,
      homeTeam: null,
      guestTeam: null,
      bars: null,
      barProducts: null,
      goal: 0,
      invalidPost: {
        venue_id: false,
        date: false,
        duration: false,
        category: false,
        homeTeam: false,
        guestTeam: false,
        goal: false,
      },
    });
  }

  show(set = true) {
    this.resetFields();
    this.setState({
      show: set,
    });
  }

  validateInputs() {
    const { venue, date, category, duration, homeTeam, guestTeam, goal } = this.state;

    this.setState({
      invalidPost: {
        venue_id: false,
        date: false,
        category: false,
        duration: false,
        homeTeam: false,
        guestTeam: false,
        goal: false,
      },
    });

    let invalidPost = {};

    if (!venue) invalidPost.venue_id = true;
    if (!date) invalidPost.date = true;
    if (!category) invalidPost.category = true;
    if (!duration || !Number.isInteger(duration)) invalidPost.duration = true;
    if (!homeTeam) invalidPost.homeTeam = true;
    if (!guestTeam) invalidPost.guestTeam = true;
    if (!goal) invalidPost.goal = true;

    if (Object.keys(invalidPost).length > 0) {
      this.setState({ invalidPost: invalidPost });

      return false;
    } else {
      return true;
    }
  }

  async nextTab(nextIndex) {
    const { venue } = this.state;

    if (nextIndex === 1) {
      const validateInputs = this.validateInputs();

      if (validateInputs) {
        this.setState({ loading: true, index: nextIndex });

        const result = await this.barService.get({ venue_id: venue._id, show_inactives: false });

        if (result && result.success) {
          this.setState({ loading: false, bars: result.bars });
        }
      }
    } else if (nextIndex === 2) {
      this.setState({ loading: false, index: nextIndex });

      const result = await this.productService.getBarProductList({ venue_id: venue._id });

      if (result && result.success) {
        const filteredBarProdcuts = this.filterProducts(result.bar_products);

        this.setState({ loading: false, barProducts: filteredBarProdcuts });
      }
    }
  }

  filterProducts(barProducts) {
    const { bars } = this.state;
    const barIds = Validator.populateArray('_id', Validator.filter('enable', true, bars));

    return barProducts
      .filter((barProduct) => {
        if (barIds.indexOf(barProduct._id) > -1) {
          return barProduct;
        } else {
          return false;
        }
      })
      .sort((a, b) => (a.name > b.name ? 1 : -1));
  }

  async save() {
    const { venue, date, category, duration, homeTeam, guestTeam, barProducts, goal } = this.state;
    const { onComplete } = this.props;
    const formatedDate = moment(date).toISOString();
    const barProductIds = barProducts.reduce((prev, curr) => {
      return prev.concat(
        Validator.populateArray(
          '_id',
          curr.list.filter((bp) => bp.enable)
        )
      );
    }, []);
    const teamIds = Validator.populateArray('id', [homeTeam, guestTeam]);

    this.setState({ loading: true });

    const result = await this.eventService.post(venue ? venue._id : null, category ? category.id : null, duration, formatedDate, barProductIds, teamIds, goal);

    if (result && result.success) {
      this.setState({ loading: false, show: false }, () => {
        if (onComplete) {
          onComplete();
        }
      });
    }
  }

  onBackBtnClick() {
    const { index } = this.state;

    if (index > 0) {
      this.setState({
        index: index - 1,
      });
    } else {
      this.setState({
        show: false,
      });
    }
  }

  onNextBtnClick() {
    const { index } = this.state;
    const nextIndex = index + 1;

    if (index < maxIndex) {
      this.nextTab(nextIndex);
    } else {
      this.save();
    }
  }

  onCategoryChange(newCategory) {
    const { category } = this.state;

    this.setState({ category: newCategory, homeTeam: null, guestTeam: null }, () => {
      if (category && newCategory && category.id !== newCategory.id) {
        this.homeTeamSelect.reset();
        this.guestTeamSelect.reset();
      }
    });
  }

  onBarSwitchChange(checked, bar) {
    const { bars } = this.state;

    Validator.setFieldInArray('_id', bar._id, 'enable', checked, bars);

    this.setState({
      bars,
    });
  }

  onProductSwitchChange(checked, barProduct, index) {
    const { barProducts } = this.state;

    Validator.setFieldInArray('_id', barProduct._id, 'enable', checked, barProducts[index].list);

    this.setState({
      barProducts,
    });
  }

  render() {
    const { show, loading, index, venue, date, category, duration, homeTeam, guestTeam, bars, barProducts, goal, invalidPost } = this.state;
    const { t } = this.props;
    const subtitles = ['1. ' + t('Add details about the event'), '2. ' + t('Set your venue bars'), '3. ' + t('Manage stock')];

    return (
      show && (
        <ModalLayout title={t('Create Event')} titleAlign='left' subtitle={subtitles[index]} width={640}>
          {index === 0 && (
            <Row>
              <Col md={12}>
                <DateTimePicker showTimeSelect={true} label={t('Date and Time')} defaultValue={date} onChange={(date) => this.setState({ date: date })} />
              </Col>
              <Col md={6}>
                <Select
                  fullWidth
                  type='venues'
                  field='name'
                  label={t('Venue')}
                  error={invalidPost.venue_id}
                  errorText={t('Venue is required')}
                  defaultValue={venue}
                  onChange={(value) => this.setState({ venue: value })}
                ></Select>
              </Col>
              <Col md={6}>
                <TextField
                  fullWidth
                  label={t('Goal')}
                  error={invalidPost.goal}
                  errorText={t('Goal is required')}
                  value={goal}
                  rightBtnLabel={t('Item')}
                  onChange={(e) => this.setState({ goal: Converter.onlyNumber(e.target.value) })}
                ></TextField>
              </Col>
              <Col md={6}>
                <Select
                  fullWidth
                  type='categories'
                  field='name'
                  label={t('Sport Category')}
                  error={invalidPost.category}
                  errorText={t('Sport Category is required')}
                  defaultValue={category}
                  iconField='icon'
                  onChange={this.onCategoryChange}
                ></Select>
              </Col>
              <Col md={6}>
                <TextField
                  fullWidth
                  label={t('Duration')}
                  error={invalidPost.duration}
                  errorText={t('Duration is required')}
                  value={duration}
                  onChange={(e) => this.setState({ duration: Converter.onlyNumber(e.target.value) })}
                ></TextField>
              </Col>
              <Col md={6}>
                <Select
                  fullWidth
                  type='teams'
                  field='name'
                  label={t('Home Team')}
                  error={invalidPost.homeTeam}
                  errorText={t('Home Team is required')}
                  defaultValue={homeTeam}
                  iconField='icon'
                  query={{ category_id: category ? category.id : null }}
                  onChange={(team) => this.setState({ homeTeam: team })}
                  onRef={(ref) => (this.homeTeamSelect = ref)}
                ></Select>
              </Col>
              <Col md={6}>
                <Select
                  fullWidth
                  type='teams'
                  field='name'
                  label={t('Guest Team')}
                  error={invalidPost.guestTeam}
                  errorText={t('Guest Team is required')}
                  defaultValue={guestTeam}
                  iconField='icon'
                  query={{ category_id: category ? category.id : null }}
                  onChange={(team) => this.setState({ guestTeam: team })}
                  onRef={(ref) => (this.guestTeamSelect = ref)}
                ></Select>
              </Col>
            </Row>
          )}
          {index === 1 &&
            (loading ? (
              <div className='margin-auto'>
                <ReactLoading type='spin' color='#000' height='24px' width='24px' />
              </div>
            ) : (
              <Row>
                <Col md={12}>
                  <p className='save-event-bar-title'>{t('Bars Available')}</p>
                  {bars &&
                    bars.map((bar) => {
                      return <EventBarCard key={bar._id} value={bar.enable} bar={bar} onSwitchChange={this.onBarSwitchChange} />;
                    })}
                </Col>
              </Row>
            ))}
          {index === 2 &&
            (loading ? (
              <div className='margin-auto'>
                <ReactLoading type='spin' color='#000' height='24px' width='24px' />
              </div>
            ) : (
              <Row>
                <Col md={12}>
                  <p className='save-event-bar-title'>{t('Products on stock')}</p>
                  {barProducts &&
                    barProducts.map((barProduct, index) => {
                      const list = barProduct.list || [];
                      return (
                        <div key={`bp_list_${index}`} className='save-event-bar-product-div'>
                          <p>{barProduct.name}</p>
                          <div key={`bp_list_div_${index}`} className='save-event-bar-product-list'>
                            {list.map((barproduct) => {
                              return (
                                <EventProductCard
                                  key={barproduct._id}
                                  value={barproduct.enable}
                                  barProduct={barproduct}
                                  onSwitchChange={(c, bp) => {
                                    this.onProductSwitchChange(c, bp, index);
                                  }}
                                />
                              );
                            })}
                          </div>
                        </div>
                      );
                    })}
                </Col>
              </Row>
            ))}

          <div className='modal-buttons-container'>
            <div className='modal-button'>{<DefaultButton text={index > 0 ? t('BACK') : t('CANCEL')} onClick={this.onBackBtnClick} fullWidth></DefaultButton>}</div>
            <div className='modal-button'>
              <LoadingButton text={index === maxIndex ? t('CREATE EVENT') : t('NEXT')} state={loading ? 'LOADING' : 'DONE'} onClick={this.onNextBtnClick} fullWidth></LoadingButton>
            </div>
          </div>
        </ModalLayout>
      )
    );
  }
}

export default withTranslation()(SaveEventModal);
