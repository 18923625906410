import QueryString from 'query-string';
import { FetchService } from '.';

export default class BarService extends FetchService {
  constructor() {
    super();

    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.setStatus = this.setStatus.bind(this);
    this.delete = this.delete.bind(this);
  }

  get(query) {
    return this.Get('/bars?' + QueryString.stringify(query), { cache: 'no-store' })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  post(venue_id, name, email, members, photo = null, seat_first_number = null, seat_last_number = null, service_type = null) {
    const formData = new FormData();

    formData.append('venue_id', venue_id);
    formData.append('name', name);
    formData.append('email', email);
    if (seat_first_number) {
      formData.append('seat_first_number', seat_first_number);
    }
    if (seat_last_number) {
      formData.append('seat_last_number', seat_last_number);
    }
    for (let i = 0; i < members.length; i++) {
      formData.append('members[]', members[i]);
    }
    if (photo) {
      formData.append('photo', photo);
    }
    if (service_type) {
      formData.append('service_type', service_type);
    }

    return this.Post('/bars', { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  put(bar_id, name, email, members, photo = null, seat_first_number = null, seat_last_number = null, service_type = null) {
    const formData = new FormData();

    formData.append('bar_id', bar_id);
    formData.append('name', name);
    formData.append('email', email);
    if (seat_first_number) {
      formData.append('seat_first_number', seat_first_number);
    }
    if (seat_last_number) {
      formData.append('seat_last_number', seat_last_number);
    }
    for (let i = 0; i < members.length; i++) {
      formData.append('members[]', members[i]);
    }
    if (photo) {
      formData.append('photo', photo);
    }
    if (service_type) {
      formData.append('service_type', service_type);
    }

    return this.Put('/bars', { body: formData }, true)
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  setStatus(bar_id, status) {
    return this.Put('/bars', { body: JSON.stringify({ bar_id, status }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  delete(bar_id) {
    return this.Post('/bars/delete', { body: JSON.stringify({ bar_id }) })
      .then((result) => {
        return Promise.resolve(result);
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }
}
