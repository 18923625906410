import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { DropDown } from '../fields';

import '../../styles/cards/topproducts.css';

class TopProductsCard extends React.Component {
  render() {
    const { t, title, rows, currencySymbol, plimit, onActionBtnClick, onLimitChange } = this.props;

    return (
      <div className='top-products-container'>
        <div className='top-products-dropdown'>
          <DropDown
            label={plimit ? plimit.toString() : '-'}
            buttonStyle='top-products-limit-dropdown'
            data={{ field: 'plimit' }}
            actions={[
              { label: '-', title: '-', value: null },
              { label: '3', title: '3', value: 3 },
              { label: '5', title: '5', value: 5 },
              { label: '10', title: '10', value: 10 },
              { label: '50', title: '50', value: 50 },
              { label: '100', title: '100', value: 100 },
            ]}
            isTable={true}
            noMaxHeight={true}
            onChange={onLimitChange}
          />
          <DropDown
            bull={true}
            data={{ field: 'products' }}
            actions={[
              { label: 'PDF', title: t('PDF'), type: 'pdf' },
              { label: 'CSV', title: t('CSV'), type: 'csv' },
            ]}
            onChange={onActionBtnClick}
          />
        </div>

        <p className='top-products-title'>{title}</p>
        <div className='top-products-table-container'>
          {rows && rows.length > 0 ? (
            <table className='top-products-table'>
              <thead>
                <tr>
                  <th scope='col'></th>
                  <th scope='col'>{t('Brand')}</th>
                  <th scope='col'>{t('Items Sold')}</th>
                  <th scope='col'>{t('Discount Total')}</th>
                  <th scope='col'>{t('Amount')}</th>
                </tr>
              </thead>
              <tbody>
                {rows.map((row, index) => {
                  return (
                    <tr key={row._id}>
                      <td>{index + 1}.</td>
                      <td>
                        <img src={row.product.photo} alt='' /> {row.product.name}
                      </td>
                      <td>{row.total_qty}</td>
                      <td className={`${row.discount_total ? 'top-products-td-red' : 'top-products-td-gray'}`}>
                        {currencySymbol} {row.discount_total.toFixed(2)}
                      </td>
                      <td>
                        {currencySymbol} {row.total_amount.toFixed(2)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <p>{t('No Data Found')}</p>
          )}
        </div>
      </div>
    );
  }
}

TopProductsCard.propTypes = {
  title: PropTypes.string,
  rows: PropTypes.array,
  plimit: PropTypes.oneOfType([PropTypes.string, PropTypes.any]),
  currencySymbol: PropTypes.string,
  onActionBtnClick: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
  onLimitChange: PropTypes.oneOfType([PropTypes.func, PropTypes.any]),
};

TopProductsCard.defaultProps = {
  title: '',
  rows: [],
  plimit: '-',
  currencySymbol: '',
  onActionBtnClick: Function.prototype,
  onLimitChange: Function.prototype,
};

export default withTranslation()(TopProductsCard);
